<script setup lang="ts">
import { graphql, useFragment, type FragmentType } from "@/generated";
import type {
  Customers_Insert_Input,
  Customers_Set_Input
} from "@/generated/graphql";
import { v4 } from "uuid";
import { computed, ref, watch } from "vue";
import ComboBox from "./ComboBox/ComboBox.vue";
import SHField from "./SHField.vue";
import SHInput from "./SHInput.vue";

const customerFragment = graphql(/* GraphQL */ `
  fragment CustomerForm on customers {
    title
    abbreviation
    mailing_address
    price_book_id

    price_book {
      id
      title
      label: title
    }
  }
`);

const optionsFragment = graphql(/* GraphQL */ `
  fragment CustomerFormOptions on query_root {
    price_books {
      id
      title
      label: title
    }
  }
`);

const props = defineProps<{
  options: FragmentType<typeof optionsFragment>;
  customer?: FragmentType<typeof customerFragment>;
}>();

const customer = computed(() => ({
  ...useFragment(customerFragment, props.customer)
}));
const options = computed(() => useFragment(optionsFragment, props.options));
const priceBooks = computed(() => options.value?.price_books ?? []);

const emit = defineEmits<{
  (e: "update:inserts", value: Customers_Insert_Input | null): void;
  (e: "update:updates", value: Customers_Set_Input | null): void;
}>();

const form = ref<{
  id: string;
  title?: string;
  abbreviation?: string;
  mailingAddress?: string;
  priceBook?: (typeof priceBooks)["value"][number] | null;
}>({
  id: v4()
});

const isValid = computed(() => {
  return (
    (!!form.value.title || customer.value?.title) &&
    (!!form.value.priceBook || customer.value?.price_book)
  );
});

watch(
  form,
  ({ id, title, abbreviation, mailingAddress, priceBook }) => {
    if (isValid.value) {
      emit("update:inserts", {
        id,
        title,
        abbreviation,
        mailing_address: mailingAddress,
        price_book_id: priceBook?.id
      });
      emit("update:updates", {
        title,
        abbreviation,
        mailing_address: mailingAddress,
        price_book_id: priceBook?.id
      });
    } else {
      emit("update:inserts", null);
      emit("update:updates", null);
    }
  },
  { deep: true }
);
</script>

<template>
  <article class="customer-form vertical">
    <SHField label="Full Company Name">
      <SHInput
        :model-value="form.title ?? customer?.title"
        @update:model-value="form.title = $event"
      />
    </SHField>
    <SHField label="Abbreviation">
      <SHInput
        :model-value="form.abbreviation ?? customer?.abbreviation ?? ''"
        @update:model-value="form.abbreviation = $event"
      />
    </SHField>
    <SHField label="Pricebook">
      <ComboBox
        enable-fuse
        :fuse-options="{
          keys: ['title'],
          threshold: 0.5
        }"
        :model-value="form?.priceBook ?? customer?.price_book"
        :options="priceBooks"
        mobile-header="Choose Pricebook..."
        placeholder="Choose Pricebook..."
        @update:model-value="
          form.priceBook = $event as (typeof priceBooks)[number]
        "
      />
    </SHField>
  </article>
</template>
