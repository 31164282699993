<script setup lang="ts" generic="T extends ListOption">
import type { ListOption } from "@/types";
import { onKeyStroke, useActiveElement } from "@vueuse/core";
import { computed, ref } from "vue";

const {
  options = [],
  togglable = false,
  getOptionLabel = i => i?.label ?? i?.title ?? "Label Undefined",
  limit,
  ...props
} = defineProps<{
  options: T[];
  limit?: number;
  modelValue?: T | null | undefined;
  multiple?: T[] | undefined;

  getOptionLabel?: (i: T | null | undefined) => string;

  togglable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  closeOnSelect?: boolean;
}>();

const emit = defineEmits<{
  (e: "select"): void;
  (e: "update:model-value", value: ListOption | null): void;
  (e: "update:multiple", value: ListOption[]): void;
}>();

const modelValue = computed<T | null | undefined>({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val ?? null);
  }
});

const multiple = computed<T[] | undefined>({
  get() {
    return props.multiple;
  },
  set(val) {
    if (val) {
      emit("update:multiple", val);
    }
  }
});

const limitedOptions = computed(() => {
  if (limit) {
    return options.slice(0, limit);
  }

  return options;
});

const itemEls = ref<HTMLButtonElement[]>([]);
const activeElement = useActiveElement();

onKeyStroke(["ArrowUp", "ArrowDown"], event => {
  if (!activeElement.value) {
    return;
  }

  const activeIdx = itemEls.value.findIndex(
    el => el === (activeElement.value as HTMLButtonElement)
  );

  if (activeIdx < 0) {
    return;
  }

  event.preventDefault();

  const previousIdx =
    activeIdx - 1 < 0 ? itemEls.value.length - 1 : activeIdx - 1;

  const nextIdx = activeIdx + 1 > itemEls.value.length - 1 ? 0 : activeIdx + 1;

  switch (event.key) {
    case "ArrowUp":
      return focusItem(previousIdx);

    case "ArrowDown":
      return focusItem(nextIdx);
  }
});

function isSelected(option: ListOption) {
  if (multiple.value) {
    return multiple.value.some(selectedItem => selectedItem.id === option.id);
  }

  return modelValue.value === option;
}

function isFocused(item: T) {
  return itemEls.value.at(options.indexOf(item)) === activeElement.value;
}

function selectOption(option: T) {
  emit("select");

  if (isSelected(option)) {
    return;
  }

  if (multiple.value) {
    return (multiple.value = [...multiple.value, option]);
  } else {
    return (modelValue.value = option);
  }
}

function deselectOption(option: ListOption) {
  if (!isSelected(option)) {
    return;
  }

  if (multiple.value) {
    return (multiple.value = multiple.value.filter(
      value => value.id !== option.id
    ));
  } else {
    return (modelValue.value = null);
  }
}

function toggleOption(option: T) {
  if (isSelected(option)) {
    deselectOption(option);
  } else {
    selectOption(option);
  }
}

function focusItem(idx: number) {
  itemEls.value[idx].focus();
}

defineExpose({
  focusItem,
  isSelected,
  selectItem: selectOption,
  deselectItem: deselectOption,
  toggleItem: toggleOption
});
</script>

<template>
  <article class="sh-menu">
    <slot :items="options" name="list">
      <div class="sh-list">
        <button
          v-for="(item, i) in limitedOptions"
          :key="item.id"
          ref="itemEls"
          class="sh-list-item"
          tabindex="-1"
          :class="{
            selected: isSelected(item),
            focus: isFocused(item)
          }"
          @click="
            togglable || multiple ? toggleOption(item) : selectOption(item)
          "
        >
          <slot
            :item="item"
            :isSelected="isSelected(item)"
            :isFocused="isFocused(item)"
            :i="i"
            name="item"
          >
            <span>{{ getOptionLabel(item) }}</span>
          </slot>
        </button>
      </div>
    </slot>
  </article>
</template>

<style lang="scss" scoped>
.sh-menu {
  background: var(--color-surface-100);
  .sh-list {
    display: flex;
    flex-direction: column;
  }

  .sh-list-item {
    outline: unset;
    background-color: var(--color-surface-100);
    color: var(--color-surface-900);
    border: none;
    display: flex;
    margin: 0;

    text-align: left;
    padding: 0.25em 0.5em;

    &:hover {
      background-color: var(--color-primary-opacity-15);
      cursor: pointer;
    }

    &:not(:hover) {
      &.focus {
        box-shadow: inset 0 0 1px 1px var(--color-primary);
      }
      &.selected {
        background-color: var(--color-primary-opacity-15);
        &.focus {
          background-color: var(--color-primary-opacity-30);
        }
      }
    }

    > span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0.75em; // to make the second line indent
      text-indent: -0.75em; // to make the second line indent
    }
  }
}
</style>
