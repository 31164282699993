<script setup lang="ts">
import RouterTabs from "@/components/RouterTabs.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHNote from "@/components/SHNote.vue";
import SHRoleBadge from "@/components/SHRoleBadge.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { useRole } from "@/composables/useRole";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const { agentId } = defineProps<{
  agentId: string;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query AgentDetail($where: organization_agents_bool_exp!) {
      organization_agents(where: $where) {
        user_roles {
          user_role {
            short_name
            ...UserRole
          }
        }
        agent {
          id
          email
          fname
          lname
          deleted_at
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      agent_id: { _eq: agentId }
    }
  }))
});

const orgAgent = computed(() => data.value?.organization_agents.at(0));
const agent = computed(() => orgAgent.value?.agent);

const { can } = useRole();
const currentUser = injectStrict(CurrentUserKey);

const agentTabs = computed(() => {
  const out = [
    { title: "Info", to: { name: "OrganizationAgentInfo" } },
    { title: "Tickets", to: { name: "OrganizationAgentTickets" } },
    {
      title: "Dedicated Customers",
      to: { name: "OrganizationAgentCustomers" },
      allowedPermissions: ["customer_agents:update"]
    },

    {
      title: "Service Report",
      to: { name: "CreateServiceReportFromAgent", params: { agentId: agentId } }
    }
  ];
  if (
    can("time_sheets:view") ||
    (can("time_sheets:view_self") && currentUser.value?.id === agentId)
  ) {
    out.push({
      title: currentUser.value?.id === agentId ? "My Time Sheet" : "Time Sheet",
      to: { name: "OrganizationAgentTimeSheet" }
    });
  }

  if (
    can("agent_shifts:manage_others") ||
    (currentUser.value?.id === agentId && can("agent_shifts:manage_self"))
  ) {
    out.push({
      title: currentUser.value?.id === agentId ? "My Shifts" : "Shifts",
      to: { name: "AgentDetailShiftsView" }
    });
  }

  out.push({
    title: currentUser.value?.id === agentId ? "My Expenses" : "Expenses",
    to: { name: "AgentExpenses" }
  });
  return out;
});
</script>

<template>
  <article class="vertical">
    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>

    <main v-else-if="agent" class="vertical xmargin">
      <div class="level loose">
        <UserAvatar
          :user="agent"
          :seed-string="`${agent.fname} ${agent.lname}`"
          size="large"
          style="border-radius: 0.25em"
        />
        <div class="level-cols agent-info">
          <h2>{{ agent.fname }} {{ agent.lname || "" }}</h2>
          <div class="level tight">
            <template
              v-for="r of orgAgent?.user_roles"
              :key="r.user_role.short_name"
            >
              <SHRoleBadge
                v-if="r.user_role"
                :role="r.user_role"
                class="role-badge"
                size="sm"
              />
            </template>
            <SHBadge
              v-if="agent.deleted_at !== null"
              reverse-color
              color="var(--color-danger)"
            >
              BLOCKED
            </SHBadge>
          </div>
        </div>
      </div>

      <RouterTabs :tabs="agentTabs" />
    </main>
  </article>
</template>

<style lang="scss" scoped>
.agent-invite-data {
  padding: var(--padding);
  border-radius: var(--border-radius);
  background: var(--color-surface-100);
}

.role-badge {
  max-width: fit-content;
}
</style>
