import { Feature_Flags_Enum } from "@/generated/graphql";
import {
  faArrowProgress,
  faArrowRightFromBracket,
  faCalendarRange,
  faChartMixed,
  faCircleDollar,
  faClipboardListCheck,
  faCogs,
  faDollarCircle,
  faDollarSquare,
  faFileInvoiceDollar,
  faFolderBookmark,
  faIdCard,
  faLandmark,
  faRectanglesMixed,
  faToolbox,
  faUserCircle,
  faUsers
} from "@fortawesome/sharp-light-svg-icons";
import { computed } from "vue";
import type { NavigationNode } from "./types";

const orgSection: NavigationNode = {
  title: "Organization",
  icon: faLandmark,
  children: [
    {
      title: "Customers",
      icon: faUsers,
      to: { name: "CustomersView" },
      allowedPermissions: ["customers:view"]
    },
    {
      title: "Agents",
      icon: faIdCard,
      to: { name: "AgentsView" },
      allowedPermissions: ["users:view"]
    },
    {
      title: "Pricebooks",
      abbreviation: "Books",
      icon: faCircleDollar,
      to: "/pricebooks",
      allowedPermissions: ["price_books:view"]
    },
    {
      title: "Catalogs",
      icon: faToolbox,
      to: { name: "CatalogsView" },
      allowedPermissions: ["catalogs:view"]
    },
    {
      title: "Settings",
      icon: faCogs,
      to: { name: "OrganizationSettingsView" },
      allowedPermissions: ["organizations:update_self"]
    }
  ]
};

const reviewSection: NavigationNode = {
  title: "Review",
  icon: faChartMixed,
  children: [
    {
      title: "Invoices",
      icon: faFileInvoiceDollar,
      to: { name: "InvoicesView" },
      allowedPermissions: ["invoices:view"]
    },
    { title: "Reports", icon: faFolderBookmark, to: { name: "ReportsView" } },
    { title: "Expenses", icon: faDollarCircle, to: { name: "ExpensesView" } }
  ]
};

const workFlowSection: NavigationNode = {
  title: "Work Flows",
  icon: faArrowProgress,
  children: [
    {
      title: "Quotes",
      icon: faDollarSquare,
      to: "/quotes",
      allowedWithFeatureFlags: [Feature_Flags_Enum.Quote]
    },
    {
      title: "Work Orders",
      abbreviation: "Orders",
      icon: faCalendarRange,
      to: { name: "WorkOrdersView" },
      allowedPermissions: ["work_orders:view"]
    },
    {
      title: "Tickets",
      icon: faClipboardListCheck,
      to: { name: "TicketsView" },
      allowedPermissions: ["tickets:view"]
    }
  ]
};
const youSection: NavigationNode = {
  title: "My Profile",
  avatar: true,
  icon: faUserCircle,
  children: [
    {
      title: "Dashboard",
      icon: faRectanglesMixed,
      to: { name: "Dashboard" }
    },
    { title: "Logout", icon: faArrowRightFromBracket, to: { name: "Logout" } }
  ]
};

export const bottomNavigationOrder = computed<NavigationNode[]>(() => [
  orgSection,
  reviewSection,
  workFlowSection,
  youSection
]);

export const sidebarOrder = computed<NavigationNode[]>(() => [
  workFlowSection,
  reviewSection,
  orgSection,
  youSection
]);
