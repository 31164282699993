<script setup lang="ts">
import type { MediaObjectOrUploadTask } from "@/composables/useUpload";
import { graphql, useFragment } from "@/generated";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { clamp, onKeyStroke, useSwipe } from "@vueuse/core";
import { addV, useDrag, usePinch } from "@vueuse/gesture";
import { computed, ref, watch } from "vue";

import {
  faChevronLeft,
  faChevronRight,
  faTimes
} from "@fortawesome/sharp-light-svg-icons";

import AppLink from "@/components/AppLink.vue";
import MediaUploadCard from "@/components/MediaUploadCard.vue";
import SHButton from "@/components/SHButton.vue";
import SHImage from "@/components/SHImage.vue";
import SHModal from "@/components/SHModal.vue";

const fragment = graphql(/* GraphQL */ `
  fragment SHLightbox on media_uploads {
    id
    orig_filename
    content_type
    content_size
    url
    caption

    author {
      full_name
      ...UserLink
    }
  }
`);

const { mediaUploads } = defineProps<{
  mediaUploads: MediaObjectOrUploadTask[];
}>();

const emit = defineEmits<{
  (e: "opened"): void;
  (e: "closed"): void;
}>();

const isShowing = ref(false);
const currentIndex = ref<number>();
defineExpose({
  open: (idx = 0) => {
    currentIndex.value = idx;
    isShowing.value = true;
  },
  close: () => {
    currentIndex.value = undefined;
    isShowing.value = true;
  }
});

const lightBoxEl = ref<HTMLElement>();
const { direction } = useSwipe(lightBoxEl, {
  onSwipeEnd: () => (direction.value === "left" ? onNext() : onPrev())
});

const scaleFactor = ref(0.01);
const zoom = ref(1);
const offset = ref([0, 0]);
const _pinching = ref(false);

watch(zoom, val => {
  if (val === 1) {
    offset.value = [0, 0];
  }
});

const transform = computed(() => `scale(${zoom.value})`);
// Composable usage
usePinch(
  ({ delta: [d], pinching }) => {
    if (!lightBoxEl.value) {
      return;
    }
    _pinching.value = pinching;

    // Calculate new zoom level
    const newZoom = clamp(zoom.value + d * scaleFactor.value, 1, 3);

    if (newZoom !== zoom.value) {
      // Update the zoom level
      zoom.value = newZoom;
    }
  },
  {
    domTarget: lightBoxEl,
    eventOptions: {
      passive: true
    }
  }
);

useDrag(
  ({ delta: [dx, dy] }) => {
    if (!lightBoxEl.value || _pinching.value || zoom.value === 1) {
      return;
    }

    // Adjust the delta based on the current zoom level
    const adjustedDelta = [dx / zoom.value, dy / zoom.value];

    // Update the offset using the addV utility
    offset.value = addV(offset.value, adjustedDelta);
  },
  {
    domTarget: lightBoxEl,
    eventOptions: {
      passive: true
    }
  }
);

const onPrev = () => {
  currentIndex.value =
    (currentIndex.value ?? 0) - 1 < 0
      ? mediaUploads.length - 1
      : (currentIndex.value ?? 0) - 1;
};

const onNext = () => {
  currentIndex.value =
    (currentIndex.value ?? 0) + 1 >= mediaUploads.length
      ? 0
      : (currentIndex.value ?? 0) + 1;
};

onKeyStroke("Escape", () => {
  isShowing.value = false;
  emit("closed");
});

const getUrl = (u: MediaObjectOrUploadTask) => {
  if (u) {
    if ("progress" in u) {
      return URL.createObjectURL(u.file);
    }
    const imageFromFragment = useFragment(fragment, u).url;
    return imageFromFragment;
  }
  return "unknown";
};

const shouldProxyRequest = (u: MediaObjectOrUploadTask) => {
  // do not proxy tasks
  return u && !("progress" in u);
};
</script>

<template>
  <SHModal :show="isShowing" no-header @close="$emit('closed')">
    <article class="sh-lightbox">
      <header class="level-end">
        <SHButton
          @click="
            isShowing = false;
            $emit('closed');
          "
        >
          <FontAwesomeIcon :icon="faTimes" />
        </SHButton>
      </header>

      <main ref="lightBoxEl">
        <SHImage
          v-if="typeof currentIndex !== 'undefined' && mediaUploads.length"
          class="main-image"
          type="lightbox"
          :src="getUrl(mediaUploads[currentIndex])"
          alt="TODO"
          :style="{
            transform: `scale(${zoom}) translateX(${offset[0]}px) translateY(${offset[1]}px)`
          }"
          :proxy-request="shouldProxyRequest(mediaUploads[currentIndex])"
          responsive
        />
        <div class="prev">
          <AppLink block @click="onPrev">
            <FontAwesomeIcon :icon="faChevronLeft" fixed-width size="2x" />
          </AppLink>
        </div>
        <div class="next">
          <AppLink block @click="onNext">
            <FontAwesomeIcon :icon="faChevronRight" fixed-width size="2x" />
          </AppLink>
        </div>
      </main>

      <footer v-if="mediaUploads.length">
        <MediaUploadCard
          v-for="(upload, i) in mediaUploads"
          :key="upload?.id || '?'"
          :media-upload="upload"
          thumbnail-size="md"
          :is-ssr="false"
          no-controls
          @image:clicked="currentIndex = i"
        />
      </footer>
    </article>
  </SHModal>
</template>

<style lang="scss" scoped>
.sh-lightbox {
  --header-height: 2em;
  --footer-height: 9em;
  --main-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: purple;
  background: var(--color-surface-opacity-25);

  display: grid;
  grid-template-rows: var(--header-height) 1fr var(--footer-height);
  grid-template-columns: 1fr;

  header {
    padding: 0.5em;
  }
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--main-height);
    .main-image {
      max-height: var(--main-height);
      transform: v-bind(transform);
      object-fit: fill;
    }
  }

  .prev {
    left: 0;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  .next {
    right: 0;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  .prev,
  .next {
    padding: 0.5em 0.25em;
    position: fixed;
    top: 50%;
    translate: 0 -50%;
    z-index: 1;
  }

  footer {
    border-top: thin solid var(--color-primary);
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5em;
    padding: var(--padding);
    padding-top: 0.5em;

    width: 100%;
    background: var(--color-surface-700);
    overflow-x: scroll;

    :deep(.sh-image) {
      width: auto;
      min-width: 4em;
      height: 9em;
      object-fit: cover;
    }
  }
}
</style>
