<script setup lang="ts">
import { computed } from "vue";
import { useQuery } from "@urql/vue";
import { graphql } from "@/generated";
import type {
  Work_Orders_Bool_Exp,
  Work_Orders_Order_By
} from "@/generated/graphql";
import { getColor, getIcon } from "@/lib/lifecycleStatus";
import {
  faArrowRight,
  faCalendarRange,
  faClipboardListCheck,
  faPlus
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import SHButton from "@/components/SHButton.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import WorkOrderStatusBadge from "@/components/WorkOrderStatusBadge.vue";

const {
  limit = 5,
  where = {},
  orderBy
} = defineProps<{
  limit?: number;
  where?: Work_Orders_Bool_Exp;
  orderBy?: Work_Orders_Order_By[];
  showAddTicket?: boolean;
  showAddNextWorkOrder?: boolean;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query WorkOrderSummary(
      $where: work_orders_bool_exp!
      $limit: Int!
      $orderBy: [work_orders_order_by!]
    ) {
      work_orders(where: $where, limit: $limit, order_by: $orderBy) {
        id
        ref
        service_date_start
        service_date_end
        lifecycle_status
        customer {
          id
          title
          abbreviation
        }

        ...WorkOrderStatus
      }
      work_orders_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where,
    limit,
    orderBy
  })),
  context: {
    additionalTypenames: ["work_orders"]
  }
});

const total = computed(
  () => data.value?.work_orders_aggregate.aggregate?.count ?? 0
);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else-if="data" class="work-order-summary">
    <div :class="{ 'level-spread': total }">
      <h2 class="level tight">
        <slot />
      </h2>
      <slot v-if="total > limit" name="seeall" v-bind="{ total, limit }" />
      <slot v-else-if="!total" name="none">No work orders found.</slot>
    </div>

    <SHTable v-if="total" fluid :rows="data.work_orders" class="foo">
      <template #header>
        <th>Order</th>
        <th>Customer</th>
        <th>Status</th>
        <th>Date Range</th>
        <th></th>
      </template>

      <template #row="{ row }">
        <td class="level tight">
          <SHButton
            class="number"
            :to="{
              name: 'WorkOrderDetail',
              params: {
                workOrderId: row.id
              }
            }"
          >
            {{ row.ref }}
          </SHButton>
          <FontAwesomeIcon
            :icon="getIcon(row.lifecycle_status)"
            :color="getColor(row.lifecycle_status)"
          />
        </td>
        <td class="truncate">
          {{ row.customer.abbreviation || row.customer?.title }}
        </td>

        <td>
          <WorkOrderStatusBadge
            :work-order="row"
            style="max-width: max-content; min-width: max-content"
          />
        </td>

        <td class="number level tight" style="font-weight: 300">
          <span>
            <SHInlineDate :d="row.service_date_start" />
          </span>
          <FontAwesomeIcon
            :icon="faArrowRight"
            style="color: var(--color-primary)"
          />
          <span>
            <SHInlineDate :d="row.service_date_end" />
          </span>
        </td>
        <td>
          <div class="level-end tight">
            <SHButton
              v-if="showAddTicket"
              color="primary"
              :to="{
                name: 'WorkOrderTicketCreate',
                params: {
                  workOrderId: row.id
                }
              }"
            >
              <span class="level tight" style="padding: 0.25em">
                <FontAwesomeIcon :icon="faPlus" />
                <FontAwesomeIcon :icon="faClipboardListCheck" />
              </span>
            </SHButton>
            <SHButton
              v-if="showAddNextWorkOrder"
              color="primary"
              :to="{
                name: 'WorkOrderCreate',
                params: {
                  customerId: row.customer.id
                }
              }"
            >
              <span class="level tight" style="padding: 0.25em">
                <FontAwesomeIcon :icon="faPlus" />
                <FontAwesomeIcon :icon="faCalendarRange" />
              </span>
            </SHButton>
          </div>
        </td>
      </template>
    </SHTable>
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.work-order-summary {
  display: flex;
  flex-direction: column;
  gap: 1em;
  table {
    td:nth-child(3),
    th:nth-child(3),
    td:nth-child(4),
    th:nth-child(4) {
      display: none;
    }

    @include bp.laptop {
      td:nth-child(1) svg {
        display: none;
      }
      td:nth-child(3),
      th:nth-child(3) {
        display: table-cell;
      }
    }

    @include bp.desktop {
      td:nth-child(4),
      th:nth-child(4) {
        display: table-cell;
      }
    }
  }
}
</style>
