<script setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation } from "@urql/vue";
import { onKeyDown } from "@vueuse/core";
import { v4 } from "uuid";
import { computed, ref, watch } from "vue";
import SHButton from "./SHButton.vue";
import SHDollarInput from "./SHDollarInput.vue";
import SHField from "./SHField.vue";
import UndoConfirm from "./UndoConfirm.vue";

const { createToast } = useToaster();

const inputComponentRef = ref<InstanceType<typeof SHDollarInput>>();

const travelPriceFragment = graphql(/* GraphQL */ `
  fragment TravelPriceEditor on travel_prices {
    cents_per_hour
    cents_per_mile
    valid_from
  }
`);

const { inline, ...props } = defineProps<{
  priceBookId: string;
  travelPrice?: FragmentType<typeof travelPriceFragment>;
  inline?: boolean;
}>();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "update:travel-price"): void;
  (e: "update:is-editing", value: boolean): void;
}>();

const travelPrice = computed(() =>
  useFragment(travelPriceFragment, props.travelPrice)
);

const form = ref<{
  centsPerHour?: number | null;
  centsPerMile?: number | null;
}>({
  centsPerHour: null,
  centsPerMile: null
});

const isValid = computed(() => {
  return (
    (!!form.value.centsPerHour || !!travelPrice.value?.cents_per_hour) &&
    (!!form.value.centsPerMile || !!travelPrice.value?.cents_per_mile)
  );
});

const insertMutation = graphql(/* GraphQL */ `
  mutation TravelPriceEditorInsert($travelPrice: travel_prices_insert_input!) {
    insert_travel_prices_one(object: $travelPrice) {
      id
    }
  }
`);

const { executeMutation: insert } = useMutation(insertMutation);

async function onConfirm() {
  const { data: updateTravelPriceData, error: updateTravelPriceError } =
    await insert({
      travelPrice: {
        id: v4(),
        price_book_id: props.priceBookId,
        cents_per_hour:
          form.value.centsPerHour ?? travelPrice.value?.cents_per_hour,
        cents_per_mile:
          form.value.centsPerMile ?? travelPrice.value?.cents_per_mile,
        valid_from: "now()"
      }
    });

  if (updateTravelPriceError) {
    createToast({
      title: "Unable to upload the travel price.",
      message: updateTravelPriceError.message || "Unknown error.",
      theme: "danger"
    });
  } else if (updateTravelPriceData) {
    createToast({
      message: "Travel price updated.",
      theme: "success"
    });
    emit("update:travel-price");
  }
}

function onCancel() {
  emit("cancel");
}

watch(inputComponentRef, () => {
  if (inputComponentRef.value) {
    inputComponentRef.value.focus();
    inputComponentRef.value.select();
  }
});

onKeyDown("Enter", onConfirm);
</script>

<template>
  <article class="travel-price-editor level loose" :class="{ inline }">
    <component
      :is="inline ? 'div' : SHField"
      label="Cost per hour in cents"
      class="level tight"
    >
      <SHDollarInput
        ref="inputComponentRef"
        type="number"
        :model-value="form.centsPerHour ?? travelPrice?.cents_per_hour"
        placeholder="0.00"
        @update:model-value="form.centsPerHour = $event"
      />
      per hour
    </component>
    <component
      :is="inline ? 'div' : SHField"
      label="Cost per mile in cents"
      class="level tight"
    >
      <SHDollarInput
        type="number"
        :model-value="form.centsPerMile ?? travelPrice?.cents_per_mile"
        placeholder="0.00"
        @update:model-value="form.centsPerMile = $event"
      />
      per mile
    </component>
    <div v-if="inline" class="level tight">
      <SHButton color="success" link square @click="onConfirm">
        <FontAwesomeIcon :icon="faCheckCircle" />
      </SHButton>
      <SHButton color="danger" link square @click="onCancel">
        <FontAwesomeIcon :icon="faTimesCircle" />
      </SHButton>
    </div>
    <UndoConfirm
      v-else
      class="level-spread"
      :confirm-enabled="isValid"
      @confirm="onConfirm"
      @undo="onCancel"
    />
  </article>
</template>

<style lang="scss" scoped>
.travel-price-editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  > * {
    width: 100%;
  }

  &.inline {
    flex-direction: row;
  }
}
</style>
