<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import FacetCombo from "@/components/FacetCombo.vue";
import FacetInline from "@/components/FacetInline.vue";
import OrderBy from "@/components/OrderBy.vue";
import SHButton from "@/components/SHButton.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHModal from "@/components/SHModal.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import type { FacetComboOption } from "@/composables/useFacets";
import {
  type Facet,
  type OrderSet,
  type QueryStringMap
} from "@/composables/useQueryStringFilters";
import {
  Order_By,
  type Ticket_Custom_Form_Entries_Bool_Exp,
  type Tickets_Bool_Exp,
  type Tickets_Order_By,
  type Work_Orders_Bool_Exp,
  type Work_Orders_Order_By,
  type Work_Sites_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { BreakPointsKey, IsMobileKey } from "@/providerKeys";
import {
  faBars,
  faCalendar,
  faFilter,
  faFilterSlash,
  faGrid2,
  faSearch
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useSessionStorage } from "@vueuse/core";
import { computed } from "vue";

const { log } = useLogger("QueryFilter"); // eslint-disable-line @typescript-eslint/no-unused-vars
const isMobile = injectStrict(IsMobileKey);
const breakpoints = injectStrict(BreakPointsKey);

const {
  clearAllFilters,
  facetModels,
  facets,
  isFetching,
  noCalendarView,
  noCardView,
  noTableView,
  orderSet,
  placeholder = "Search...",
  resultCount,
  searchable,
  viewMode
} = defineProps<{
  clearAllFilters?: boolean;
  viewMode?: string;
  facets: Facet<
    | Tickets_Bool_Exp
    | Work_Orders_Bool_Exp
    | Ticket_Custom_Form_Entries_Bool_Exp
  >[];
  facetModels: QueryStringMap;
  isFetching?: boolean;
  noCalendarView?: boolean;
  noCardView?: boolean;
  noTableView?: boolean;
  orderSet: OrderSet<
    Tickets_Order_By | Work_Orders_Order_By | Work_Sites_Order_By
  >;
  placeholder?: string;
  resultCount?: number;
  searchable?: boolean;
}>();

const emit = defineEmits<{
  (e: "reset:facets"): void;
  (e: "update:facet", payload: FacetComboOption): void;
  (e: "update:search", payload: string): void;
  (e: "update:sort-direction", payload: string): void;
  (e: "update:sort-type", payload: string): void;
  (e: "update:view-mode", mode: CollectionView): void;
  (e: "update:where", payload: string): void;
}>();

const modeTabs = computed(() => [
  ...(noCardView ? [] : [{ icon: faGrid2, value: CollectionView.Card }]),
  ...(noTableView ? [] : [{ icon: faBars, value: CollectionView.Table }]),
  ...(noCalendarView
    ? []
    : [{ icon: faCalendar, value: CollectionView.Calendar }])
]);

const onSearchChanged = (newValue: string) => {
  if (searchable) {
    // console.log("onSearchChanged() called!");
    emit("update:search", newValue);
  }
};

const onUpdateFacet = (newOption: FacetComboOption) => {
  // console.log("onUpdateFacetCombo() called!");
  emit("update:facet", newOption);
};

const onUpdateSortDirection = (newValue: string) => {
  // console.log("onUpdateSortDirection() called!");
  emit("update:sort-direction", newValue);
};

const onUpdateSortType = (newValue: string) => {
  // console.log("onUpdateSortType() called!");
  emit("update:sort-type", newValue);
};

const showFilters = useSessionStorage("showTicketFilters", false);
</script>

<template>
  <header class="query-filter vertical tight">
    <div class="level-spread loose">
      <slot v-if="$slots.leftOfSearch" name="leftOfSearch" />
      <SHInput
        v-if="searchable && breakpoints.includes('mobileLandscape')"
        style="flex-grow: 1"
        :model-value="`${facetModels.query.value}`"
        :placeholder="placeholder"
        clearable
        @update:model-value="onSearchChanged($event)"
      >
        <template #prefix><FontAwesomeIcon :icon="faSearch" /></template>
      </SHInput>

      <div class="query-menu level tight">
        <SHButton
          v-if="clearAllFilters && !isMobile"
          color="warning"
          @click="emit('reset:facets')"
        >
          Reset
        </SHButton>

        <AppLink v-if="facetModels" @click.prevent="showFilters = !showFilters">
          <FontAwesomeIcon
            v-if="facets.length > 0"
            style="font-size: 1.125em"
            :icon="showFilters ? faFilterSlash : faFilter"
            fixed-width
          />
        </AppLink>

        <OrderBy
          v-if="breakpoints.includes('tablet')"
          match-width
          :order-set="orderSet"
          :field="
            typeof facetModels?.orderField.value === 'string'
              ? facetModels?.orderField.value
              : orderSet.defaultValue
          "
          :direction="
            typeof facetModels.orderDirection?.value === 'string'
              ? facetModels.orderDirection.value
              : Order_By.Desc
          "
          @update:order-field="onUpdateSortType($event)"
          @update:order-direction="onUpdateSortDirection($event)"
        />

        <nav v-show="modeTabs.length">
          <AppLink
            v-for="tab in modeTabs"
            :key="tab.icon.iconName"
            :active="viewMode === tab.value"
            @click.prevent="$emit('update:view-mode', tab.value)"
          >
            <FontAwesomeIcon :icon="tab.icon" fixed-width />
          </AppLink>
        </nav>
      </div>
    </div>

    <div v-if="isMobile">
      <SHModal :show="showFilters" @close="showFilters = false">
        <template #brand>
          <h3><slot name="mobile-header">Query Filter</slot></h3>
        </template>
        <div class="xmargin">
          <SHSpinner v-if="isFetching" />
          <div v-else class="vertical loose">
            <SHField v-if="searchable" label="Keyword Search">
              <SHInput
                style="flex-grow: 1"
                :model-value="
                  typeof facetModels.query === 'string' ? facetModels.query : ''
                "
                :placeholder="placeholder"
                clearable
                @update:model-value="onSearchChanged($event)"
              >
                <template #prefix>
                  <FontAwesomeIcon :icon="faSearch" />
                </template>
              </SHInput>
            </SHField>

            <FacetInline
              v-for="f of facets"
              v-show="!f.hideOnMobile && !f.hidden"
              :key="f.modelName"
              :filter-set="f"
              :label="f.label"
              :model-value="facetModels[f.modelName].value || f.defaultValue"
              @update:model-value="
                onUpdateFacet({
                  modelName: f.modelName,
                  value: $event as string
                })
              "
            />

            <OrderBy
              inline
              :order-set="orderSet"
              :field="
                typeof facetModels?.orderField.value === 'string'
                  ? facetModels?.orderField.value
                  : orderSet.defaultValue
              "
              :direction="
                typeof facetModels.orderDirection?.value === 'string'
                  ? facetModels.orderDirection.value
                  : Order_By.Desc
              "
              @update:order-field="onUpdateSortType($event)"
              @update:order-direction="onUpdateSortDirection($event)"
            />

            <div class="level-spread" style="margin-top: 2em">
              <SHButton color="warning" @click="emit('reset:facets')">
                Reset Filters
              </SHButton>
              <SHButton color="success" @click="showFilters = false">
                View {{ resultCount }} Result{{ resultCount === 1 ? "" : "s" }}
              </SHButton>
            </div>
          </div>
        </div>
      </SHModal>
    </div>

    <Transition v-else name="fade">
      <div v-if="showFilters">
        <div class="level-cols tight">
          <FacetCombo
            v-for="f of facets"
            v-show="!f.hidden"
            :key="f.modelName"
            :model-value="facetModels[f.modelName].value || f.defaultValue"
            :label="f.label"
            :filter-set="f"
            @update:model-value="
              onUpdateFacet({
                modelName: f.modelName,
                value: $event as string
              })
            "
          />
        </div>
      </div>
    </Transition>
  </header>
</template>

<style lang="scss" scoped>
.query-filter {
  nav {
    height: 2.5em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em;
    gap: 0.5em;

    border-radius: var(--border-radius);
    background: var(--color-surface-100);
  }
}
</style>
