import { ref, type MaybeRef } from "vue";

import { useRouter } from "vue-router";

export function usePreventNavigation(
  msg: MaybeRef<string>,
  enabled: MaybeRef<boolean>,
  onResponse?: (isLeaving: boolean) => void
) {
  const router = useRouter();
  const _msg = ref(msg);
  const _enabled = ref(enabled);

  router.beforeEach(() => {
    return preventRouterNavigation(_msg.value);
  });
  window.onbeforeunload = preventWindowNavigation;

  function preventRouterNavigation(msg: string) {
    if (_enabled.value) {
      const isLeaving = confirm(msg);
      console.log("preventing nav: dirty?", _enabled.value);
      onResponse?.(isLeaving);
      return isLeaving;
    }
    onResponse?.(false);
    return true;
  }

  function preventWindowNavigation() {
    if (navigator.userActivation.hasBeenActive && _enabled.value) return "show";
  }
}
