<script setup lang="ts">
import { useCSV } from "@/composables/useCSV";
import { useRole } from "@/composables/useRole";
import { formatDate, formatDateForExcel, numberFormatter } from "@/formatters";
import { graphql } from "@/generated";
import type {
  Agent_Shifts_Bool_Exp,
  Interfaces_Time_Sheet_Entries_Bool_Exp
} from "@/generated/graphql";
import {
  Order_By,
  Time_Sheet_Billing_Intervals_Enum
} from "@/generated/graphql";
import { useQuery } from "@urql/vue";
import { endOfMonth, parseISO, startOfDay, startOfMonth } from "date-fns";
import { computed, reactive } from "vue";

import CustomerLink from "@/components/Customer/CustomerLink.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import SHAgentChooser from "@/components/SHAgentChooser.vue";
import SHButton from "@/components/SHButton.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHEnumChooser from "@/components/SHEnumChooser.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import TicketLink from "@/components/TicketLink.vue";
import UserLink from "@/components/UserLink.vue";
import WorkSiteLink from "@/components/WorkSiteLink.vue";
import type { AgentChoice } from "@/composables/useAgentChoice";
import { useTimezone } from "@/composables/useTimezone";
import {
  CURRENT_DATE_PRESETS,
  PAST_DATE_PRESETS,
  YEAR_BACK
} from "@/lib/datetime";
import { faFileCsv } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const { orgTimezoneName } = useTimezone();
const { can } = useRole();
const { generate, download } = useCSV();

const { customerId, agentId, ticketId } = defineProps<{
  customerId?: string;
  agentId?: string;
  ticketId?: string;
}>();

const viewMode = defineModel<Time_Sheet_Billing_Intervals_Enum>("viewMode", {
  default: Time_Sheet_Billing_Intervals_Enum.Shift
});

const form = reactive<{
  agent?: AgentChoice;
  range?: [Date, Date] | null | undefined;
}>({
  agent: undefined,
  range: ticketId ? null : [startOfMonth(new Date()), endOfMonth(new Date())]
});

const agent = computed({
  get: () => form.agent,
  set: value => (form.agent = value)
});

const shiftRange = computed({
  get: () => form.range,
  set: value => (form.range = value)
});

const selectedMonth = defineModel<{
  month: number;
  year: number;
}>({
  default: {
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  }
});

const shiftsWhere = computed<Agent_Shifts_Bool_Exp>(() => {
  const where: Agent_Shifts_Bool_Exp = {
    agent_id:
      !!form.agent || !!agentId ? { _eq: form.agent?.id || agentId } : {},
    started_at: form.range
      ? {
          _gte: form.range?.[0],
          _lt: form.range?.[1]
        }
      : {},
    _and: [
      {
        logs: customerId
          ? {
              ticket: {
                work_order: {
                  customer_id: { _eq: customerId }
                }
              }
            }
          : {}
      },
      {
        logs: ticketId
          ? {
              ticket: {
                id: { _eq: ticketId }
              }
            }
          : {}
      }
    ]
  };

  return where;
});

const timeSheetsWhere = computed<Interfaces_Time_Sheet_Entries_Bool_Exp>(
  () => ({
    customer_id: customerId ? { _eq: customerId } : {},
    ticket_ids: ticketId ? { _contains: [ticketId] } : {}
  })
);

const {
  data: shiftData,
  fetching: shiftFetching,
  error: shiftError
} = useQuery({
  query: graphql(/* GraphQL */ `
    query TimeSheetByShift(
      $shiftsWhere: agent_shifts_bool_exp!
      $shiftsOrderBy: [agent_shifts_order_by!]!
      $timeSheetsWhere: interfaces_time_sheet_entries_bool_exp!
    ) {
      agent_shifts(where: $shiftsWhere, order_by: $shiftsOrderBy) {
        time_sheet_entries(
          where: $timeSheetsWhere
          order_by: { started_at: asc, ended_at: asc }
        ) {
          total_hours
          travel_miles
          started_at
          ended_at

          afe_numbers

          invoice_days
          overtime_hours

          agent {
            ...UserLink
            full_name
          }

          work_site {
            title
            data
            customer {
              title
              abbreviation
              ...CustomerLink
            }
            ...WorkSiteLink
          }

          product {
            title
          }

          tickets {
            id
            ref

            ...TicketLink

            work_order {
              ref
            }

            work_logs {
              notesj
              started_at
              ended_at
            }

            travel_logs {
              notesj
              miles_traveled
              departed_at
              arrived_at
            }

            expense_logs {
              notesj
              cents_recorded
            }
          }
        }
      }
    }
  `),
  variables: computed(() => ({
    shiftsWhere: shiftsWhere.value,
    shiftsOrderBy: [{ started_at: Order_By.Asc }],
    timeSheetsWhere: timeSheetsWhere.value
  })),
  pause: computed(
    () => viewMode.value !== Time_Sheet_Billing_Intervals_Enum.Shift
  ),
  context: {
    additionalTypenames: [
      "work_logs",
      "travel_logs",
      "expense_logs",
      "agent_shift_overrides",
      "time_sheet_rules",
      "customers_xref_time_sheet_rules"
    ]
  }
});

const {
  data: monthData,
  fetching: monthFetching,
  error: monthError
} = useQuery({
  query: graphql(/* GraphQL */ `
    query TimeSheetByMonth(
      $day: timestamptz!
      $timeSheetsWhere: interfaces_time_sheet_entries_bool_exp!
      $timezone: String!
    ) {
      time_sheet_entries: time_sheet_entries_by_month(
        args: { _day_in_month: $day, _timezone: $timezone }
        where: $timeSheetsWhere
        order_by: { started_at: asc, ended_at: asc }
      ) {
        total_hours
        travel_miles
        started_at
        ended_at

        afe_numbers

        invoice_days
        overtime_hours

        agent {
          ...UserLink
          full_name
        }

        work_site {
          title
          data
          customer {
            title
            abbreviation
            ...CustomerLink
          }
          ...WorkSiteLink
        }

        product {
          title
        }

        tickets {
          id
          ref

          ...TicketLink

          work_order {
            ref
          }

          work_logs {
            notesj
            started_at
            ended_at
          }

          travel_logs {
            notesj
            miles_traveled
            departed_at
            arrived_at
          }

          expense_logs {
            notesj
            cents_recorded
          }
        }
      }
    }
  `),
  variables: computed(() => ({
    day: new Date(selectedMonth.value.year, selectedMonth.value.month, 15),
    timeSheetsWhere: {
      author_id: { _eq: form.agent?.id || agentId },
      customer_id: { _eq: customerId }
    },
    timezone: orgTimezoneName.value ?? "unknown timezone"
  })),
  pause: computed(
    () => viewMode.value === Time_Sheet_Billing_Intervals_Enum.Shift
  ),
  context: {
    additionalTypenames: [
      "work_logs",
      "travel_logs",
      "expense_logs",
      "agent_shift_overrides",
      "time_sheet_rules",
      "customers_xref_time_sheet_rules"
    ]
  }
});

const fetching = computed(() => shiftFetching.value || monthFetching.value);
const error = computed(() => shiftError.value || monthError.value);

const rows = computed(() =>
  viewMode.value === Time_Sheet_Billing_Intervals_Enum.Shift
    ? (shiftData.value?.agent_shifts.flatMap(s => s.time_sheet_entries) ?? [])
    : (monthData.value?.time_sheet_entries ?? [])
);

const tableRows = computed(() =>
  rows.value
    .map((r, idx) => ({ id: idx, ...r }))
    .sort((a, b) => {
      const timeDiff =
        startOfDay(parseISO(a.ended_at!)).getTime() -
        startOfDay(parseISO(b.ended_at!)).getTime();
      if (timeDiff !== 0) {
        return timeDiff;
      }

      if (!a.agent || !b.agent) {
        return 0;
      }

      return a.agent.full_name!.localeCompare(b.agent.full_name!) || 0;
    })
);

const totals = computed(() =>
  tableRows.value.reduce(
    (acc, r) => {
      acc.total_hours += r.total_hours || 0;
      acc.travel_miles += r.travel_miles || 0;
      acc.invoice_days += r.invoice_days || 0;
      acc.overtime_hours += r.overtime_hours || 0;

      return acc;
    },
    {
      total_hours: 0,
      travel_miles: 0,
      invoice_days: 0,
      overtime_hours: 0
    }
  )
);

const rowHeaders = [
  "Day",
  "Agent",
  "Customer",
  "Worksite",
  "Product",
  "Tickets",
  "AFE",
  "Accounting ID",
  "Start",
  "End",
  "Hours",
  "Miles",
  "Days",
  "OT Hours"
];

const exportCSV = () => {
  const csv = generate(
    rowHeaders,
    tableRows.value.map(r => [
      formatDateForExcel(startOfDay(new Date(r.ended_at || 0)).toISOString()),
      r.agent?.full_name,
      r.work_site?.customer?.abbreviation ?? r.work_site?.customer.title,
      r.work_site?.title,
      r.product?.title,
      r.tickets?.map(t => t.ref).join(", ") || "-",
      r.afe_numbers
        ?.reduce<string[]>((prev, cur) => {
          if (cur) prev.push(cur);
          return prev;
        }, [])
        .join(", ") || "-",

      r.work_site?.data?.cost_center ?? "-",
      formatDateForExcel(r.started_at),
      formatDateForExcel(r.ended_at),
      r.total_hours,
      r.travel_miles,
      r.invoice_days ?? 0,
      r.overtime_hours ?? 0
    ])
  );
  const today = formatDate(new Date(), "yyyy_MM_dd");
  download(csv, `timesheet_${agentId || ticketId || "all_users"}_${today}.csv`);
};
</script>

<template>
  <article class="timesheets">
    <div
      v-if="!fetching && tableRows.length > 0"
      class="controls level-cols tight"
    >
      <SHField
        v-if="can('time_sheets:view') && !agentId && !ticketId"
        block
        label="Agent"
      >
        <div class="level tight">
          <SHAgentChooser v-model="agent" :context-customer-id="customerId" />
          <SHButton v-if="!!agent" color="primary" @click="agent = undefined">
            Show Everyone
          </SHButton>
        </div>
      </SHField>
      <SHField v-if="!ticketId" block label="Billing Interval">
        <template #help>
          <p>
            Time sheets cannot combine overtime calculations across different
            billing intervals, so one must be selected
          </p>
        </template>
        <SHEnumChooser
          v-model="viewMode"
          :options="Time_Sheet_Billing_Intervals_Enum"
          mobile-header="Billing Interval"
        />
      </SHField>
      <SHField
        v-if="viewMode === Time_Sheet_Billing_Intervals_Enum.Shift && !ticketId"
        block
        label="Date Range"
      >
        <DateRangePicker
          v-model="shiftRange"
          :presets="[
            ...CURRENT_DATE_PRESETS,
            ...PAST_DATE_PRESETS,
            ...YEAR_BACK
          ]"
        />
      </SHField>

      <SHField
        v-else-if="
          viewMode === Time_Sheet_Billing_Intervals_Enum.Month && !ticketId
        "
        block
        label="Month"
      >
        <SHDatePicker
          v-model="selectedMonth"
          :max-date="new Date()"
          format="MMMM yyyy"
          month-picker
          auto-apply
        />
      </SHField>

      <aside class="buttons level tight">
        <SHButton size="lg" color="primary" @click="exportCSV">
          <FontAwesomeIcon :icon="faFileCsv" />
        </SHButton>
      </aside>
    </div>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error.message }}
    </SHNote>

    <div v-else class="scroller">
      <SHTable
        :rows="tableRows"
        :columns="rowHeaders"
        empty-message="No time sheets found."
      >
        <template #row="{ row }">
          <td>
            <SHInlineDate :d="row.ended_at" />
          </td>
          <td><UserLink :user="row.agent" /></td>
          <td><CustomerLink :customer="row.work_site!.customer" abbr /></td>
          <td><WorkSiteLink :work-site="row.work_site!" /></td>
          <td>{{ row.product?.title }}</td>
          <td>
            <div class="level tight">
              <TicketLink
                v-for="ticket in row.tickets"
                :key="ticket.id"
                :ticket="ticket"
                :to="{
                  name: 'TicketDetail',
                  params: { ticketId: ticket.id }
                }"
              >
                {{ ticket.ref }}
              </TicketLink>
            </div>
          </td>
          <td>
            {{
              row.afe_numbers
                ?.reduce((prev: string[], cur: string) => {
                  if (cur) prev.push(cur);
                  return prev;
                }, [])
                .join(", ") || "-"
            }}
          </td>
          <td>{{ row.work_site!.data?.cost_center ?? "-" }}</td>
          <td><SHInlineDate :d="row.started_at" format="time" /></td>
          <td><SHInlineDate :d="row.ended_at" format="time" /></td>
          <td class="number align-right">
            {{ numberFormatter.format(row.total_hours || 0) }}
          </td>
          <td class="number align-right">
            {{ numberFormatter.format(row.travel_miles || 0) }}
          </td>
          <td class="number align-right">
            {{ numberFormatter.format(row.invoice_days || 0) }}
          </td>
          <td class="number align-right">
            {{ numberFormatter.format(row.overtime_hours || 0) }}
          </td>
        </template>
        <template #footer>
          <tr v-if="tableRows.length > 0" class="totals">
            <td colspan="10">Totals</td>
            <td class="number align-right">
              {{ numberFormatter.format(totals.total_hours) }}
            </td>
            <td class="number align-right">
              {{ numberFormatter.format(totals.travel_miles) }}
            </td>
            <td class="number align-right">
              {{ numberFormatter.format(totals.invoice_days) }}
            </td>
            <td class="number align-right">
              {{ numberFormatter.format(totals.overtime_hours) }}
            </td>
          </tr>
        </template>
      </SHTable>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets//scss/breakpoints.scss" as bp;
article.timesheets {
  display: flex;
  gap: 1em;
  flex-direction: column;
  justify-content: center;

  .totals {
    font-weight: bold;
  }

  .controls {
    > .buttons {
      margin-left: auto;
    }
  }

  .scroller {
    min-width: 100%;
    overflow-x: auto;
    padding-bottom: 0.5em;
  }
}
</style>
