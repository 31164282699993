<script setup lang="ts">
import AgentShiftSuggestionCard from "@/components/AgentShiftSuggestionCard.vue";
import AppLink from "@/components/AppLink.vue";
import SHButton from "@/components/SHButton.vue";
import type { TimeSliderRange } from "@/components/SHTimeInput/SHTimeSlider.vue";
import SHTimeSlider from "@/components/SHTimeInput/SHTimeSlider.vue";
import {
  useShiftSuggestions,
  type AgentShiftSuggestion
} from "@/composables/useShiftSuggestions";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { humanizeEnum } from "@/lib/helpers";
import { clamp, endOfDay, format, isBefore, parseISO } from "date-fns";
import { computed, ref } from "vue";
import AgentShiftCard from "./AgentShiftCard.vue";
import SHSpinner from "./SHSpinner.vue";

const shiftFragment = graphql(/* GraphQL */ `
  fragment AgentShiftSlider_Shift on agent_shifts {
    hash
    started_at
    ended_at
    agent_id
    created_at
    updated_at
    ...AgentShiftCard
    ...ShiftLink
  }
`);

const logFragment = graphql(/* GraphQL */ `
  fragment AgentShiftSlider_Log on combined_logs {
    ...UseShiftSuggestions
    id
    started_at
    ended_at
    author_id
    created_at
    updated_at
  }
`);

export interface ShiftSliderRange extends TimeSliderRange {
  shift: FragmentType<typeof shiftFragment>;
}

export interface ShiftSuggestionSliderRange extends TimeSliderRange {
  suggestion: AgentShiftSuggestion;
}

const props = defineProps<{
  shifts: FragmentType<typeof shiftFragment>[];
  logs: FragmentType<typeof logFragment>[];
}>();

const date = defineModel<Date>("date", { default: new Date() });

const logs = computed(() => useFragment(logFragment, props.logs));
const shifts = computed(() => useFragment(shiftFragment, props.shifts));
const { shiftSuggestions } = useShiftSuggestions(logs);

const shiftRanges = computed(() =>
  shifts.value.map(s => ({
    start: clamp(parseISO(s.started_at!), {
      start: date.value,
      end: endOfDay(date.value)
    }),
    end: clamp(parseISO(s.ended_at!), {
      start: date.value,
      end: endOfDay(date.value)
    }),
    theme: "primary" as TimeSliderRange["theme"],
    shift: s,
    suggestion: null,
    log: null
  }))
);

const logRanges = computed(() =>
  logs.value.map(l => ({
    start: clamp(parseISO(l.started_at!), {
      start: date.value,
      end: endOfDay(date.value)
    }),
    end: clamp(parseISO(l.ended_at!), {
      start: date.value,
      end: endOfDay(date.value)
    }),
    theme: "danger" as TimeSliderRange["theme"],
    shift: null,
    suggestion: null,
    log: l
  }))
);

const minLogStart = computed(() =>
  logRanges.value.reduce(
    (min, r) => (isBefore(r.start, min) ? r.start : min),
    endOfDay(date.value)
  )
);

const maxLogEnd = computed(() =>
  logRanges.value.reduce(
    (max, r) => (isBefore(max, r.end) ? r.end : max),
    date.value
  )
);

const suggestionRange = computed(() => ({
  start: clamp(minLogStart.value, {
    start: date.value,
    end: endOfDay(date.value)
  }),
  end: clamp(maxLogEnd.value, {
    start: date.value,
    end: endOfDay(date.value)
  }),
  theme: "secondary" as TimeSliderRange["theme"],
  shift: null,
  suggestions: shiftSuggestions.value,
  log: null
}));

const ranges = computed(() => [
  ...shiftRanges.value,
  // ...logRanges.value,
  suggestionRange.value
]);

const keyTimes = computed(() =>
  shifts.value.flatMap(s => [
    {
      label: format(parseISO(s.started_at!), "hh:mm a"),
      value: parseISO(s.started_at!)
    },
    {
      label: format(parseISO(s.ended_at!), "hh:mm a"),
      value: parseISO(s.ended_at!)
    }
  ])
);

const showShift = ref<(typeof shiftRanges.value)[number]["shift"]>();
const selectedSuggestion = ref<AgentShiftSuggestion>();
</script>

<template>
  <article class="agent-shift-slider">
    <SHTimeSlider
      compact
      readonly
      :model-value="date"
      :ranges="ranges"
      :key-times="keyTimes"
    >
      <template #range="{ range, style }">
        <AppLink
          v-if="range.shift"
          class="range shift-btn"
          :class="range.theme || 'primary'"
          :style="style"
          :shift="range.shift"
          @click="
            () => {
              showShift = !!showShift ? undefined : range.shift;
              selectedSuggestion = undefined;
            }
          "
        />
        <AppLink
          v-else-if="range.suggestions"
          class="range shift-btn"
          :class="range.theme || 'primary'"
          :style="style"
          @click="
            () => {
              selectedSuggestion = selectedSuggestion
                ? undefined
                : range.suggestions[0];
              showShift = undefined;
            }
          "
        />
      </template>
    </SHTimeSlider>
    <Suspense v-if="showShift">
      <AgentShiftCard
        :shift="showShift"
        @updated="showShift = undefined"
        @deleted="showShift = undefined"
      />
      <template #fallback>
        <div class="level-center">
          <SHSpinner />
        </div>
      </template>
    </Suspense>
    <template v-if="selectedSuggestion">
      <AgentShiftSuggestionCard
        :suggestion="selectedSuggestion"
        @accept="selectedSuggestion = undefined"
      >
        <template #top-right>
          <div class="level tight">
            <SHButton
              v-for="s in shiftSuggestions"
              :key="s.action"
              class="suggestion-btn"
              :class="{ active: s.action === selectedSuggestion.action }"
              color="secondary"
              @click="selectedSuggestion = s"
            >
              {{ humanizeEnum(s.action) }}
            </SHButton>
          </div>
        </template>
      </AgentShiftSuggestionCard>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.agent-shift-slider {
  display: flex;
  flex-direction: column;
  gap: 2em;

  .shift-btn {
    transition: background-color 0.2s;
    &:hover {
      background-color: var(--color-primary-opacity-50);
    }
  }

  .suggestion-btn {
    border: none;
    color: var(--color-surface-500);
    background: var(--color-surface-200);

    &.active {
      color: var(--color-surface-900);
      background: var(--color-secondary);
    }

    &:focus:not(.active) {
      color: var(--color-surface-900);
      background: var(--color-surface-300);
    }

    &:not(.active):hover {
      background: var(--color-surface-200);
    }
  }
}
</style>
