<script setup lang="ts">
import OrgLogo from "@/components/OrgLogo.vue";
import SHPill from "@/components/SHPill.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { computed } from "vue";

const customerFragment = graphql(/* GraphQL */ `
  fragment ReportHeaderCustomer on customers {
    id
    title
    abbreviation
    organization {
      ...OrgLogo
    }
  }
`);

const agentFragment = graphql(/* GraphQL */ `
  fragment ReportHeaderAgent on users {
    id
    full_name
    email
    phone_number
  }
`);

const ticketFragment = graphql(/* GraphQL */ `
  fragment ReportHeaderTicket on tickets {
    ref
    product {
      title
    }
  }
`);

const props = defineProps<{
  title: string;
  customer?: FragmentType<typeof customerFragment>;
  agent?: FragmentType<typeof agentFragment>;
  ticket?: FragmentType<typeof ticketFragment>;
}>();

const customer = computed(() => useFragment(customerFragment, props.customer));
const reportAgent = computed(() => useFragment(agentFragment, props.agent));
const ticket = computed(() => useFragment(ticketFragment, props.ticket));
</script>

<template>
  <header class="report-header">
    <OrgLogo
      v-if="customer?.organization"
      :organization="customer?.organization"
    />
    <main>
      <div class="level-spread">
        <span class="title">{{ title }}</span>
        <SHPill v-if="ticket" color="var(--color-surface-600)">
          <template #left>
            {{ ticket.product.title }}
          </template>
          <template #right>#{{ ticket.ref }}</template>
        </SHPill>
      </div>
      <span class="customer">
        {{ customer?.title }}
      </span>
    </main>

    <div v-if="reportAgent" class="agent">
      By {{ reportAgent.full_name }}
      <br />
      {{ reportAgent?.email }}
      <br />
      {{ reportAgent?.phone_number }}
    </div>

    <div class="details">
      <slot />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.report-header {
  --logo-w: 1.5in;

  display: grid;
  grid-template-columns:
    [logo] var(--logo-w)
    [gap1] 1.5em
    [main] 1fr;
  grid-template-rows:
    [top] min-content
    [bottom] 1fr;
  row-gap: 1em;
  align-items: start;

  :deep(.org-logo) {
    grid-column: logo;
    grid-row: 1;
  }

  main {
    // background: var(--color-info);
    grid-column: main;
    grid-row: top;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: 100%;
    .title {
      font-weight: 600;
      font-size: 2.5em;
    }
    .customer {
      font-weight: 400;
      font-size: 1.75em;
      color: var(--color-surface-900);
    }
  }
  .details {
    text-align: left;
    // background: var(--color-info);
    grid-column: logo/span 1;
    grid-row: bottom;
    :deep(.number) {
      font-weight: 600;
      color: var(--color-secondary);
    }
  }
  .agent {
    // background: var(--color-danger);
    grid-column: main;
    grid-row: bottom;
  }
}
</style>
