<script setup lang="ts">
import type { IconDefinition } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AppLink from "@/components/AppLink.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import { useRouter, type RouteLocationRaw } from "vue-router";
import UserAvatar from "../UserAvatar.vue";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";

export interface SideBarLinkProps {
  avatar?: boolean;
  to?: RouteLocationRaw;
  icon?: IconDefinition;
  iconColor?: string;
  isCollapsed?: boolean;
}
const { to, icon, iconColor, isCollapsed } = defineProps<SideBarLinkProps>();
const currentUser = injectStrict(CurrentUserKey);

const router = useRouter();

function goToAgentDetails() {
  router.push({
    name: "AgentDetail",
    params: { agentId: currentUser.value?.id }
  });
}
</script>

<template>
  <AppLink
    v-if="!isCollapsed && to"
    v-bind="$attrs"
    class="sidebar-link level tight"
    :class="{ isCollapsed }"
    :to="to"
  >
    <UserAvatar
      v-if="avatar"
      :seed-string="currentUser?.fname + ' ' + currentUser?.lname"
      initials-color="var(--color-surface-900)"
      size="small"
      class="avatar-rounded"
    />
    <FontAwesomeIcon v-else-if="icon" :icon="icon" fixed-width />
    <span><slot /></span>
  </AppLink>

  <div v-else-if="!isCollapsed" class="level tight">
    <UserAvatar
      v-if="avatar"
      :seed-string="currentUser?.fname + ' ' + currentUser?.lname"
      initials-color="white"
      size="small"
      class="avatar-rounded"
      @click="goToAgentDetails"
    />
    <FontAwesomeIcon
      v-else-if="icon"
      :color="iconColor"
      :icon="icon"
      fixed-width
    />
    <span
      :style="{
        color: 'var(--color-surface-600)',
        fontWeight: 300
      }"
    >
      <slot />
    </span>
  </div>

  <SHDropdown v-else placement="right-start" :offset="18" flip open-on-hover>
    <template #default="{ toggle, isHovering, isOpen }">
      <a style="font-size: 2em" :class="{ isHovering }" @click="toggle">
        <UserAvatar
          v-if="avatar"
          :seed-string="currentUser?.fname + ' ' + currentUser?.lname"
          initials-color="var(--color-surface-900)"
          size="medium"
          class="avatar-rounded"
          @click="goToAgentDetails"
        />
        <FontAwesomeIcon
          v-else-if="icon"
          :color="isHovering || isOpen ? 'var(--color-primary)' : iconColor"
          :icon="icon"
          fixed-width
        />
      </a>
    </template>
    <template #popup="slotProps">
      <slot name="popup" v-bind="slotProps" />
    </template>
  </SHDropdown>
</template>

<style lang="scss">
.sidebar-link {
  color: var(--link-color, var(--color-primary));
  font-weight: 500;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  gap: 0.25em;
  transition: background 0.2s ease-out;

  :deep(span) {
    width: max-content;
    font-weight: 500;
    transition: all 0.2s ease-out;

    .active & {
      font-weight: 700;
    }

    .isCollapsed & {
      overflow-x: hidden;
      flex-shrink: 1;
      text-overflow: clip;
      width: 0;
      opacity: 0;
    }
  }
}
.avatar-rounded {
  border: thin solid var(--color-surface-900);
  border-radius: var(--border-radius);
}
.avatar-rounded:hover {
  cursor: pointer;
}
</style>
