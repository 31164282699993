<script setup lang="ts">
import { useLogger } from "@/logger";
import type { Icon } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { RouteLocationRaw } from "vue-router";

const { log } = useLogger("AppLink"); // eslint-disable-line @typescript-eslint/no-unused-vars

const isSSR = import.meta.env.SSR;

export type AppLinkProps = {
  to?: RouteLocationRaw | undefined;
  href?: string;
  disabled?: boolean;
  active?: boolean; // force link to appear active regardless of url/query match
  block?: boolean; // render as block-level element
  prefix?: string | Icon;
  activeClass?: string;
  inactiveClass?: string;
  exactActiveClass?: string;
};

const {
  to,
  href,
  disabled,
  active,
  block,
  prefix,
  activeClass = "active",
  inactiveClass = "",
  exactActiveClass = "isExactActive"
} = defineProps<AppLinkProps>();

const emit = defineEmits<{
  (e: "click", value: MouseEvent): void;
}>();
</script>

<template>
  <router-link
    v-if="to && !isSSR"
    v-slot="linkProps"
    v-bind="$attrs"
    custom
    :to="to"
    :activeClass="activeClass"
    :inactiveClass="inactiveClass"
    :exactActiveClass="exactActiveClass"
    ariaCurrentValue="page"
  >
    <component
      :is="disabled ? 'span' : 'a'"
      v-bind="$attrs"
      :href="linkProps.href || ''"
      class="app-link router-link"
      :class="{
        [activeClass]: linkProps.isActive || active,
        [inactiveClass]: !linkProps.isActive,
        disabled,
        block
      }"
      @click="
        (e: MouseEvent) => {
          if (disabled) return;
          linkProps?.navigate(e);
          emit('click', e);
        }
      "
    >
      <slot v-if="$slots.prefix" name="prefix"></slot>
      <span v-else-if="typeof prefix === 'string'" class="prefix">
        {{ prefix }}
      </span>
      <FontAwesomeIcon v-else-if="prefix" :icon="prefix" />
      <slot v-bind="linkProps" />
    </component>
  </router-link>
  <component
    :is="disabled ? 'span' : 'a'"
    v-else
    v-bind="$attrs"
    :href="href"
    target="_blank"
    class="app-link"
    :class="{ block, disabled, active }"
    @click="!disabled && emit('click', $event)"
  >
    <slot v-if="$slots.prefix" name="prefix"></slot>
    <span v-else-if="typeof prefix === 'string'" class="prefix">
      {{ prefix }}
    </span>
    <FontAwesomeIcon v-else-if="prefix" :icon="prefix" />
    <slot />
  </component>
</template>

<style lang="scss" scoped>
.app-link {
  text-decoration: underline;
  transition: color 0.15s ease-out;
  position: relative;
  font-weight: 600;
  color: var(--color-surface-900);
  cursor: pointer;

  &:hover {
    color: var(--color-primary);
  }
  &.block {
    display: block;
    width: 100%;
  }

  &.disabled {
    cursor: default;
    color: var(--color-surface-400) !important;
    text-decoration: none;
  }
  &.active,
  &:active {
    color: var(--color-secondary);

    &:hover {
      color: var(--color-secondary-up-300);
    }
  }
}
</style>
