<script setup lang="ts">
import { graphql } from "@/generated";
import type { Tickets_Bool_Exp, Tickets_Order_By } from "@/generated/graphql";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import TicketCard from "@/components/TicketCard.vue";

const {
  limit = 5,
  where = {},
  orderBy,
  routeName = "TicketDetail"
} = defineProps<{
  limit?: number;
  where?: Tickets_Bool_Exp;
  orderBy?: Tickets_Order_By[];
  routeName?: string;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query TicketsSummary(
      $where: tickets_bool_exp!
      $limit: Int!
      $orderBy: [tickets_order_by!]
    ) {
      tickets(where: $where, limit: $limit, order_by: $orderBy) {
        id
        ...TicketCard
      }
      tickets_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where,
    limit,
    orderBy
  }))
});
const total = computed(
  () => data.value?.tickets_aggregate?.aggregate?.count || 0
);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else-if="data" class="ticket-summary">
    <div :class="{ 'level-spread': total }">
      <h2 class="level tight">
        <slot />
      </h2>
      <slot v-if="total > limit" name="seeall" v-bind="{ total, limit }" />
      <slot v-else-if="total === 0" name="none">No tickets found.</slot>
    </div>

    <div class="vertical tight">
      <TicketCard
        v-for="t of data.tickets"
        :key="t.id"
        :ticket="t"
        @click:id="
          $router.push({ name: routeName, params: { ticketId: t.id } })
        "
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.ticket-summary {
  display: flex;
  flex-direction: column;
  gap: 1em;
  table {
    td:nth-child(3),
    th:nth-child(3),
    td:nth-child(4),
    th:nth-child(4) {
      display: none;
    }

    @include bp.laptop {
      td:nth-child(1) svg {
        display: none;
      }
      td:nth-child(3),
      th:nth-child(3) {
        display: table-cell;
      }
    }

    @include bp.desktop {
      td:nth-child(4),
      th:nth-child(4) {
        display: table-cell;
      }
    }
  }
}
</style>
