<script setup lang="ts">
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { useIntersectionObserver } from "@vueuse/core";
import { computed, ref } from "vue";

const recordsPerPage = ref(100);
const limit = ref(recordsPerPage.value);

const CustomerContactsViewQuery = graphql(/* GraphQL */ `
  query CustomerContactsView($where: customer_contacts_bool_exp) {
    customer_contacts(where: $where, order_by: [{ full_name: asc }]) {
      id
      full_name
      job_title
      email
      phone_office
    }

    customer_contacts_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

const { customerId } = defineProps<{
  customerId: string;
}>();

const { data, error, fetching } = useQuery({
  query: CustomerContactsViewQuery,
  variables: {
    where: {
      customer_id: { _eq: customerId }
    }
  },
  context: { additionalTypenames: ["customer_contacts"] }
});

const contacts = computed(() => data.value?.customer_contacts ?? []);

const contactCount = computed(
  () => data.value?.customer_contacts_aggregate.aggregate?.count || 0
);

const scrollMarker = ref();
const loadMore = () => {
  if (limit.value <= contactCount.value) limit.value += recordsPerPage.value;
};

useIntersectionObserver(scrollMarker, ([{ isIntersecting }]) => {
  if (isIntersecting) loadMore();
});
</script>

<template>
  <SHSpinner v-if="fetching" />

  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>

  <article v-else class="vertical">
    <SHTable
      :rows="contacts"
      clickable
      empty-message="No contacts found."
      @row:click="
        $router.push({
          name: 'CustomerContactDetail',
          params: {
            customerContactId: $event.id
          }
        })
      "
    >
      <template #header>
        <th>Name</th>
        <th>Title</th>
      </template>

      <template #row="{ row }">
        <td width="65%">{{ row.full_name }}</td>
        <td>{{ row.job_title }}</td>
      </template>

      <template #footer>
        <SHTableRowCount :count="contactCount" label="contact" />
      </template>
    </SHTable>

    <section v-if="!fetching && limit < contactCount" ref="scrollMarker" />
  </article>
</template>
