<script setup lang="ts">
import QueryFilter from "@/components/QueryFilter.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import TicketBadge from "@/components/TicketBadge.vue";
import UserLink from "@/components/UserLink.vue";
import { useFacets, type FacetComboOption } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { centsToDollars, dollarFormatter } from "@/formatters";
import { graphql } from "@/generated";
import {
  Order_By,
  type Expense_Logs_Bool_Exp,
  type Expense_Logs_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { injectStrict } from "@/lib/helpers";
import { BreakPointsKey } from "@/providerKeys";
import { faCheck, faXmark } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

const { customerId = "", agentId = "" } = defineProps<{
  noMargin?: boolean;
  agentId?: string;
  customerId?: string;
}>();

const filterViewMode = ref<CollectionView>(CollectionView.Table);

const breakpoints = injectStrict(BreakPointsKey);

const orderByFilters: OrderSet<Expense_Logs_Order_By> = {
  defaultValue: "expensed_at",
  defaultOrderBy: Order_By.Desc,
  options: [
    {
      id: "expensed_at",
      title: "Date Expensed",
      asc: [{ expensed_at: Order_By.Asc }],
      desc: [{ expensed_at: Order_By.Desc }]
    },
    {
      id: "updated",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    },
    {
      id: "cents",
      title: "Dollar Amount",
      asc: [{ cents_recorded: Order_By.Asc }],
      desc: [{ cents_recorded: Order_By.Desc }]
    }
  ]
};

const internalFilter: Facet<Expense_Logs_Bool_Exp> = {
  modelName: "internalExternal",
  label: "Internal/External",
  defaultValue: "all",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    {
      id: "internal",
      title: "Internal",
      predicate: { is_external: { _eq: false } }
    },
    {
      id: "external",
      title: "External",
      predicate: { is_external: { _eq: true } }
    }
  ]
};

const {
  facetModels,
  filterWhereClause,
  resetFacets,
  sortOrder,
  updateFacet,
  updateSortDirection,
  updateSortType
} = useFacets([internalFilter], orderByFilters);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query ExpensesView(
      $where: expense_logs_bool_exp
      $order_by: [expense_logs_order_by!]
      $limit: Int!
      $offset: Int!
    ) {
      expense_logs(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        id
        created_at
        updated_at
        is_external
        cents_recorded
        notesj
        expensed_at
        invoice_id
        ticket_id

        author {
          ...UserLink
        }

        ticket {
          ...TicketBadge
          ref
          work_order {
            customer {
              abbreviation
              title
            }
          }
        }
      }

      expense_logs_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        { deleted_at: { _is_null: true } },
        // this picks up when we're nested under a customer
        customerId
          ? { ticket: { work_order: { customer_id: { _eq: customerId } } } }
          : {},
        agentId ? { author_id: { _eq: agentId } } : {},
        ...(filterWhereClause.value || [])
      ]
    },
    order_by: sortOrder.value,
    limit: 50,
    offset: 0
  })),
  pause: computed(() => !filterWhereClause.value),
  context: { additionalTypenames: ["expense_logs"] }
});

const expenseLogs = computed(() => data.value?.expense_logs ?? []);
const expenseLogCount = computed(
  () => data.value?.expense_logs_aggregate?.aggregate?.count || 0
);
</script>

<template>
  <article class="expenses-view vertical" :class="{ xmargin: !noMargin }">
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[internalFilter]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="expenseLogCount"
      clear-all-filters
      no-card-view
      no-calendar-view
      no-table-view
      placeholder="Search by Ref..."
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event as FacetComboOption)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Expense Filter</template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <SHTable
      v-else
      :rows="expenseLogs"
      clickable
      empty-message="No expenses found."
      @row:click="
        row =>
          $router.push({
            name: 'ExpenseLogEdit',
            params: { ticketId: row.ticket_id, expenseLogId: row.id }
          })
      "
    >
      <template #header>
        <th>Expense Date</th>
        <th v-if="!customerId && breakpoints.includes('desktop')">Customer</th>
        <th v-if="breakpoints.includes('desktop')">Ticket</th>
        <th v-if="!agentId && breakpoints.includes('tablet')">Author</th>
        <th style="text-align: center">Include in Reports</th>
        <th style="text-align: right">Dollar Amount</th>
      </template>

      <template #row="{ row }">
        <td>
          <SHInlineDate :d="row.expensed_at" format="long" />
        </td>
        <td v-if="!customerId && breakpoints.includes('desktop')">
          {{ row.ticket?.work_order?.customer.abbreviation || "?" }}
        </td>
        <td v-if="breakpoints.includes('desktop')">
          <TicketBadge :ticket="row.ticket" />
        </td>
        <td v-if="!agentId && breakpoints.includes('tablet')">
          <UserLink :user="row.author" />
        </td>
        <td style="text-align: center">
          <FontAwesomeIcon
            :color="
              row.is_external
                ? 'var(--color-primary)'
                : 'var(--color-surface-600)'
            "
            :icon="row.is_external ? faCheck : faXmark"
          />
        </td>
        <td style="text-align: right; font-weight: 600" class="number">
          {{ dollarFormatter.format(centsToDollars(row.cents_recorded)) }}
        </td>
      </template>
      <template #footer>
        <SHTableRowCount :count="expenseLogCount" label="expense" />
      </template>
    </SHTable>
  </article>
</template>
