<script setup lang="ts">
import SHDropdown from "@/components/SHDropdown.vue";
import SHNote from "@/components/SHNote.vue";
import { randomId } from "@/random";
import { faInfoCircle } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export interface SHField {
  block?: boolean; // render as `display: block`
  disabled?: boolean;
  hideLabel?: boolean;
  invalid?: string | boolean;
  label?: string;
  required?: boolean;
  requirementSatisfied?: boolean;
}

defineProps<SHField>();

const myId = randomId("field");
</script>

<template>
  <article
    class="sh-field"
    :class="{ invalid, required, requirementSatisfied }"
  >
    <label
      :class="{ disabled: $props.disabled }"
      :for="block ? myId : undefined"
    >
      <span class="text level tight">
        <slot name="label">
          <template v-if="hideLabel">
            <span></span>
          </template>
          <template v-else>{{ label ?? "Missing Label!" }}</template>
        </slot>
        <SHDropdown
          v-if="$slots.help"
          open-on-hover
          teleport-to-body
          class="tooltip"
        >
          <button class="tooltip-trigger">
            <FontAwesomeIcon :icon="faInfoCircle" />
          </button>
          <template #popup>
            <SHNote theme="warning">
              <slot name="help" />
            </SHNote>
          </template>
        </SHDropdown>
        <small
          v-if="typeof invalid === 'string'"
          style="color: var(--color-danger)"
        >
          {{ invalid }}
        </small>
      </span>
      <template v-if="!block">
        <span class="value">
          <slot :class="{ disabled: $props.disabled }" :my-id="myId" />
        </span>
      </template>
    </label>
    <template v-if="block">
      <span class="value">
        <slot :class="{ disabled: $props.disabled }" v-bind="{ myId }" />
      </span>
    </template>
  </article>
</template>

<style lang="scss" scoped>
article.sh-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .text {
    user-select: none;
    color: var(--color-surface-500);
    margin-bottom: 0.25em;
    font-size: 0.9em;
    font-weight: 400;
  }

  .tooltip-trigger {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: var(--color-surface-500);
    font-size: 0.9em;
  }

  &.disabled {
    color: var(--color-surface-700);
    background: var(--color-surface-400);
  }

  &.invalid {
    :deep(input) {
      border-color: var(--color-danger);
    }
  }

  &.required {
    .text {
      color: var(--color-danger);
    }
    &.requirementSatisfied {
      .text {
        color: unset;
      }
    }
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }

  .tooltip {
    > .floating {
      max-width: 20em;
    }
  }
}
</style>
