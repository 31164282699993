<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import SHInput from "@/components/SHInput.vue";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  notation: "standard"
});

const props = defineProps<{
  modelValue?: number | null;
  alignRight?: boolean;
  formatter?: Intl.NumberFormat;
  decimals?: number;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: number | null | undefined): void;
}>();

const formatter = computed(() => {
  if (typeof props.decimals !== "undefined") {
    return new Intl.NumberFormat("en-US", {
      ...(props.formatter ?? numberFormatter).resolvedOptions(),
      maximumFractionDigits: props.decimals
    });
  }

  return props.formatter ?? numberFormatter;
});

const showNumberInput = ref(false);

const componentRef = ref<InstanceType<typeof SHInput> | null>(null);
const inputEl = computed(() => componentRef.value?.inputEl);

const inputValue = ref(roundNumber(props.modelValue)?.toString() ?? "");

watch(
  () => props.modelValue,
  () => {
    if (!showNumberInput.value) {
      inputValue.value = roundNumber(props.modelValue)?.toString() ?? "";
    }
  }
);

watch(inputValue, value => {
  if (!value.length) {
    return emit("update:modelValue", null);
  }

  if (!isNaN(parseFloat(value))) {
    return emit("update:modelValue", roundNumber(parseFloat(value)));
  }
});

const displayValue = computed(() => {
  return typeof props.modelValue !== "undefined" && props.modelValue !== null
    ? formatter.value.format(props.modelValue)
    : "";
});

function roundNumber(value: number | null | undefined) {
  if (
    typeof value !== "undefined" &&
    value !== null &&
    typeof props.decimals !== "undefined"
  ) {
    return Math.round(value * 10 ** props.decimals) / 10 ** props.decimals;
  }

  return value;
}

function handleFocus() {
  showNumberInput.value = true;
  nextTick(() => inputEl.value?.focus());
}

function handleBlur() {
  showNumberInput.value = false;
  if (inputValue.value) {
    inputValue.value =
      roundNumber(parseFloat(inputValue.value))?.toString() ?? "";
  }
}

defineExpose({
  focus() {
    inputEl.value?.focus();
  },
  select() {
    inputEl.value?.select();
  }
});
</script>

<template>
  <article class="sh-number-input">
    <SHInput
      v-show="!showNumberInput"
      v-bind="{ ...$attrs, ...$props }"
      type="text"
      class="number"
      :class="{ alignRight }"
      :model-value="displayValue"
      @focus="handleFocus"
    >
      <template v-if="$slots.prefix" #prefix>
        <slot name="prefix" />
      </template>
      <template v-if="$slots.suffix" #suffix>
        <slot name="suffix" />
      </template>
    </SHInput>
    <SHInput
      v-show="showNumberInput"
      v-bind="{ ...$attrs, ...$props }"
      ref="componentRef"
      v-model="inputValue"
      type="number"
      class="number"
      :class="{ alignRight }"
      @blur="handleBlur"
    >
      <template v-if="$slots.prefix" #prefix>
        <slot name="prefix" />
      </template>
      <template v-if="$slots.suffix" #suffix>
        <slot name="suffix" />
      </template>
    </SHInput>
  </article>
</template>
