<script setup lang="ts">
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import {
  useProductChoice,
  type ProductChoice
} from "@/composables/useProductChoice";
import { graphql } from "@/generated/gql";
import type { ListOption } from "@/types";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import SHNote from "./SHNote.vue";

const { catalogId, priceBookId, disabled } = defineProps<{
  multiple?: ProductChoice[];
  modelValue?: ProductChoice | null;
  priceBookId?: string;
  catalogId?: string;
  disabled?: boolean;
}>();

defineEmits<{
  (e: "update:modelValue", value: ProductChoice): void;
  (e: "update:multiple", value: ProductChoice[]): void;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query SHProductChooser($where: products_bool_exp) {
      products(order_by: [{ title: desc }], where: $where) {
        ...ProductChoice
      }
    }
  `),
  variables: computed(() => ({
    where: {
      prices: {
        price_book_id: { _eq: priceBookId }
      },
      catalog_id: { _eq: catalogId },
      deleted_at: { _is_null: true }
    }
  })),
  pause: computed(() => disabled)
});

const getLabel = (product: ProductChoice | ListOption | null | undefined) =>
  product?.title ? product.title : "";

const products = computed(
  () => data.value?.products.map(useProductChoice) ?? []
);
</script>
<template>
  <SHNote v-if="error" theme="danger">{{ error }}</SHNote>
  <main v-else>
    <ComboBox
      enable-fuse
      :fuse-options="{
        keys: ['title'],
        threshold: 0.5
      }"
      :disabled="disabled"
      :options="products"
      :get-option-label="getLabel"
      :loading="fetching"
      mobile-header="Choose Product"
      placeholder="Choose Product..."
      :multiple="multiple"
      :model-value="modelValue"
      @update:multiple="$emit('update:multiple', $event as ProductChoice[])"
      @update:model-value="$emit('update:modelValue', $event as ProductChoice)"
    >
      <template #mobile-option="{ option }">
        {{ getLabel(option) }}
      </template>
    </ComboBox>
  </main>
</template>
