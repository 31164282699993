<script setup lang="ts">
import { computed, ref } from "vue";

import { dollarFormatter } from "@/formatters";
import SHNumberInput from "./SHNumberInput.vue";

const componentRef = ref<InstanceType<typeof SHNumberInput>>();

const props = defineProps<{
  modelValue?: number | null;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: number | null | undefined): void;
}>();

const modelCents = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  }
});

const modelDollars = computed({
  get() {
    if (typeof modelCents.value !== "undefined" && modelCents.value !== null) {
      return modelCents.value / 100;
    }
    return null;
  },
  set(val) {
    if (typeof val !== "undefined" && val !== null) {
      const valueStr = val.toString(); // Convert the number to a string
      const integerStr = valueStr.replace(".", ""); // Remove the decimal point
      modelCents.value = parseInt(integerStr, 10); // Parse the resulting string as an integer
    } else {
      modelCents.value = null;
    }
  }
});

defineExpose({
  focus() {
    componentRef.value?.focus();
  },
  select() {
    componentRef.value?.select();
  }
});
</script>

<template>
  <article class="sh-currency-input">
    <SHNumberInput
      ref="componentRef"
      v-bind="{ ...$attrs, ...$props }"
      v-model="modelDollars"
      inputmode="decimal"
      :decimals="2"
      :formatter="dollarFormatter"
      class="input-dollars"
    />
  </article>
</template>

<style lang="scss" scoped></style>
