<script setup lang="ts">
import RouterTabs from "@/components/RouterTabs.vue";

const tabs = [
  { title: "Agents", to: { name: "AgentsView" } },
  {
    title: "Time Sheets",
    to: { name: "AgentTimeSheets" },
    allowedPermissions: ["time_sheets:view"]
  },
  {
    title: "Shifts",
    to: { name: "AgentShiftsView" },
    allowedPermissions: ["agent_shifts:manage_others"]
  }
];
</script>

<template>
  <article class="xmargin">
    <RouterTabs exact-match :tabs="tabs" />
  </article>
</template>
