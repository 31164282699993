import type { TimeModel } from "@vuepic/vue-datepicker";
import { format, formatDistanceToNow, formatISO9075, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useLogger } from "./logger";
const { log } = useLogger("formatters");

const DEFAULT_DATE_FORMAT = "MMM d, yyyy h:mm a";
const DEFAULT_USER_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const CST = "America/Chicago";
// const EST = "America/New_York";
// const MST = "America/Denver";
// const PST = "America/Los_Angeles";
const PREFERRED_TIMEZONE = undefined;

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  notation: "standard",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const centsToDollars = (cents: number): number =>
  Math.floor(cents / 100) + (cents % 100) / 100;

export const formatUSD = (cents: number | undefined | null): string => {
  return dollarFormatter.format(centsToDollars(cents ?? 0));
};

export const formatPostgres = (date: string | Date) =>
  formatISO9075(typeof date === "string" ? parseISO(date) : date);

export const formatDate = (
  date:
    | undefined
    | null
    | string
    | number
    | Date
    | Date[]
    | TimeModel
    | TimeModel[]
    | {
        month: string | number;
        year: string | number;
      },
  fmt?: string
): string => {
  if (!date) {
    return "";
  }

  if (Array.isArray(date)) {
    throw new TypeError("Date and time arrays not supported.");
  }

  if (typeof date === "string") {
    date = parseISO(new Date(date).toISOString());
  } else if (typeof date === "number") {
    date = new Date(date);
  }

  if ("hours" in date) {
    throw new TypeError("TimeModel not supported.");
  }

  if ("year" in date) {
    throw new TypeError("Special date model not supported.");
  }

  const d =
    date instanceof Date ? date : parseISO(new Date(date).toISOString());

  try {
    return format(d, fmt || DEFAULT_DATE_FORMAT);
  } catch (e) {
    log("error formatting date", date, e);
    return "";
  }
};

export const formatDateWithTimeZone = (date: string | Date, format: string) => {
  return formatInTimeZone(
    date,
    PREFERRED_TIMEZONE || DEFAULT_USER_TIME_ZONE,
    format.toString() || DEFAULT_DATE_FORMAT
  );
};

export const formatDateRelative = (date: Date | string | undefined | null) => {
  if (!date) return "";
  const d =
    date instanceof Date ? date : parseISO(new Date(date).toISOString());
  return formatDistanceToNow(d, { addSuffix: true }); //, new Date().getUTCDate());
};

export const formatPostgresDayOfWeek = (
  dow: number | null | undefined
): string => {
  switch (dow) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    default:
      return `unknown day of week: ${dow} (0=Sunday, 6=Saturday)`;
  }
};

export function formatDateForExcel(date: Date | string | null | undefined) {
  if (!date) return "";
  return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
}

export function formatBytes(bytes: number | null | undefined, decimals = 2) {
  if (!bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
