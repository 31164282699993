<script setup lang="ts">
import CustomFormLoader from "@/components/CustomFormLoader.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { useLogPermissions } from "@/composables/useLogPermissions";
import { injectStrict } from "@/lib/helpers";
import { CurrentTicketKey } from "@/providerKeys";

const {
  ticketId,
  customFormDefinitionId = "",
  ticketCustomFormEntryId = ""
} = defineProps<{
  ticketId: string;
  ticketItemId?: string;
  customFormDefinitionId?: string;
  ticketCustomFormEntryId?: string;
}>();

const ticket = injectStrict(CurrentTicketKey);
const { canAddLogsOnTicket } = useLogPermissions(ticket);
</script>

<template>
  <article class="custom-form-detail">
    <suspense>
      <template #fallback>
        <div>
          <SHSpinner />
        </div>
      </template>

      <CustomFormLoader
        :custom-form-definition-id="customFormDefinitionId"
        :editable="canAddLogsOnTicket"
        :ticket-custom-form-entry-id="ticketCustomFormEntryId"
        :ticket-id="ticketId"
        @undo="$router.back"
      />
    </suspense>
  </article>
</template>

<style lang="scss" scoped>
.custom-form-detail {
  .form-document {
    background-color: var(--color-surface-50);
  }
}
</style>
