<script lang="ts">
import component from "./UserLinkNode.vue";
import { Node as TipTapNode } from "@tiptap/core";
import { VueNodeViewRenderer, mergeAttributes } from "@tiptap/vue-3";

export const Node = TipTapNode.create({
  name: "userLink",
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      user: {
        default: null,
        parseHTML: el => {
          const user = el.getAttribute("data-user");

          return {
            user: user ? JSON.parse(user) : null
          };
        },
        renderHTML: attributes => ({
          "data-user": JSON.stringify(attributes.user)
        })
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-user]`
      }
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes),
      `@${node.attrs.user.fname}`
    ];
  },

  renderText({ node }) {
    return `@${node.attrs.user.fname}`;
  },

  addNodeView() {
    return VueNodeViewRenderer(component);
  }
});
</script>

<script setup lang="ts">
import { NodeViewWrapper, type NodeViewProps } from "@tiptap/vue-3";
import UserLink from "@/components/UserLink.vue";

const props = defineProps<{
  node: NodeViewProps["node"];
}>();

const user = props.node.attrs.user;
</script>

<template>
  <NodeViewWrapper as="div" class="user-link-node">
    <UserLink v-if="user" :user="user" />
  </NodeViewWrapper>
</template>

<style lang="scss">
.user-link-node {
  display: inline;
}
</style>
