<script setup lang="ts">
import { computed } from "vue";
import type { Editor, JSONContent } from "@tiptap/vue-3";

import TextEditor from "@/components/TextEditor/TextEditor.vue";
import TextEditorSSR from "@/components/TextEditor/TextEditorSSR.vue";

const props = defineProps<{
  modelValue: JSONContent | null | undefined;
  isSsr?: boolean;
  text?: string | null | undefined;
  editor?: Editor;
  editable?: boolean;
  inline?: boolean;
  rows?: number;
}>();

const isSSR = computed(() => props.isSsr || import.meta.env.SSR);

const emit = defineEmits<{
  (e: "update:model-value", value: JSONContent | null | undefined): void;
  (e: "update:text", value: string): void;
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val);
  }
});
</script>

<template>
  <TextEditorSSR v-if="isSSR" v-bind="{ ...props }" />
  <TextEditor
    v-else
    v-bind="{ ...props }"
    @update:model-value="(val: any) => (modelValue = val)"
  />
</template>
