<script setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { useMutation, useQuery } from "@urql/vue";
import { computed } from "vue";

import AgentPriceEditor from "@/components/AgentPriceEditor.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";

const { priceBookId } = defineProps<{
  priceBookId: string;
}>();

const { createToast } = useToaster();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query AgentPricesView($priceBookId: uuid!) {
      agents: users(order_by: [{ lname: asc }, { fname: asc }]) {
        id
        full_name

        organization_agents {
          organization {
            default_agent_day_rate_cents
          }
        }

        agent_prices(
          limit: 1
          order_by: { valid_from: desc }
          where: { valid_from: { _lte: "now()" } }
        ) {
          id
          hourly_rate_cents
          day_rate_cents
          valid_from
        }

        price_book_agent_prices(
          limit: 1
          order_by: { valid_from: desc }
          where: {
            price_book_id: { _eq: $priceBookId }
            valid_from: { _lte: "now()" }
          }
        ) {
          id
          hourly_rate_cents
          day_rate_cents
          valid_from
          ...AgentPriceEditor
        }
      }
    }
  `),
  variables: {
    priceBookId
  },
  context: { additionalTypenames: ["agent_prices"] }
});

const agents = computed(() => data.value?.agents || []);

type PriceInfo = {
  price: number;
  source: "organization" | "organization_agent" | "pricebook";
  valid_from: Date;
};

const getPriceInfo = (a: (typeof agents.value)[number]): PriceInfo => {
  const out: PriceInfo = {
    price: 0,
    source: "organization",
    valid_from: new Date(2000, 1, 1)
  };
  // the "price" for an agent can come from 3 places, in descending overide order
  // - pricebook agent price (most specific)
  // - organization_agent price (applies to an agent across all pricebooks)
  // - organization default agent price (least specific)
  const perOrg = a.organization_agents.at(0)?.organization;
  const perAgent = a.agent_prices.at(0);
  const perPricebook = a.price_book_agent_prices.at(0);

  if (perPricebook) {
    out.price = perPricebook.day_rate_cents;
    out.source = "pricebook";
    out.valid_from = new Date(perPricebook.valid_from);
  } else if (perAgent) {
    out.price = perAgent.day_rate_cents;
    out.source = "organization_agent";
    out.valid_from = new Date(perAgent.valid_from);
  } else if (perOrg) {
    out.price = perOrg.default_agent_day_rate_cents;
    out.source = "organization";
  }

  return out;
};

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation SetPricebookAgentPrice(
      $agent_id: String!
      $price_book_id: uuid!
      $cents: Int!
      $valid_from: timestamptz!
    ) {
      insert_price_book_agent_prices_one(
        object: {
          agent_id: $agent_id
          price_book_id: $price_book_id
          day_rate_cents: $cents
          valid_from: $valid_from
        }
        on_conflict: {
          constraint: price_book_agent_prices_price_book_id_agent_id_valid_from_key
          update_columns: []
        }
      ) {
        id
        agent {
          id
        }
      }
    }
  `)
);

const onConfirm = async (
  agent_id: string,
  cents: number,
  valid_from: string
) => {
  console.log("CONFIRM", agent_id, cents, valid_from);

  // turn this back into a local date
  const validFrom = new Date(valid_from);

  const { data, error } = await executeMutation({
    agent_id,
    price_book_id: priceBookId,
    cents,
    valid_from: validFrom
  });
  if (error) {
    createToast({
      title: "Unable to update the agent price.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else if (data) {
    createToast({
      message: "Agent price updated.",
      theme: "success"
    });
  }
};
</script>

<template>
  <SHSpinner v-if="fetching" />

  <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>

  <article v-else class="agent-prices-view">
    <SHTable
      :rows="agents"
      :columns="['Agent', 'Day Rate']"
      fluid
      empty-message="No agents found."
    >
      <template #row="{ row }">
        <td width="32%">{{ row.full_name }}</td>
        <td
          :class="{
            isOrgDefault: getPriceInfo(row).source === 'organization',
            isPricebookPrice: getPriceInfo(row).source === 'pricebook',
            isAgentPrice: getPriceInfo(row).source === 'organization_agent'
          }"
        >
          <AgentPriceEditor
            :price="row.price_book_agent_prices.at(0)"
            :default-price-cents="
              row.organization_agents.at(0)?.organization
                .default_agent_day_rate_cents
            "
            @confirm="
              (cents: number, valid_from: string) => {
                onConfirm(row.id, cents, valid_from);
              }
            "
          />
        </td>
      </template>

      <template #footer>
        <SHTableRowCount :count="agents.length" label="agent" />
      </template>
    </SHTable>
  </article>
</template>

<style lang="scss" scoped>
.agent-prices-view {
  .price-edit {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
