<script setup lang="ts">
import { computed } from "vue";

const { progress, barColor } = defineProps<{
  progress?: number;
  barColor?: string;
}>();

const progressWidth = computed(() => `${(progress || 0) * 100}%`);
const color = computed(() =>
  barColor
    ? `var(--color-${barColor})`
    : progress
      ? progress >= 1
        ? "var(--color-surface-400)"
        : progress >= 0.7
          ? "var(--color-warning)"
          : "var(--color-success)"
      : "var(--color-surface-300)"
);
</script>

<template>
  <div class="sh-progress" />
</template>

<style lang="scss" scoped>
.sh-progress {
  $barHeight: 0.5em;
  $borderRadius: calc($barHeight / 2);
  position: relative;
  height: $barHeight;
  width: 100%;
  background-color: var(--color-surface-200);
  border-radius: $borderRadius;

  span {
    position: absolute;
  }

  &::before {
    @extend .sh-progress;

    transition: all 100ms ease-in-out;
    position: absolute;
    content: " ";
    width: v-bind(progressWidth);
    background-color: v-bind(color);
  }
}
</style>
