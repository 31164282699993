<script setup lang="ts">
import { graphql } from "@/generated/gql";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import SHNote from "./SHNote.vue";
import {
  useCatalogChoice,
  type CatalogChoice
} from "@/composables/useCatalogChoice";

defineProps<{
  multiple?: CatalogChoice[];
  modelValue?: CatalogChoice;
}>();

defineEmits<{
  (e: "update:modelValue", value: CatalogChoice): void;
  (e: "update:multiple", value: CatalogChoice[]): void;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query SHCatalogChooser($where: catalogs_bool_exp!) {
      catalogs(order_by: [{ title: asc }], where: $where) {
        ...CatalogChoice
      }
    }
  `),
  variables: computed(() => ({
    where: {
      deleted_at: { _is_null: true }
    }
  })),
  context: { additionalTypenames: ["catalogs"] }
});

const catalogs = computed(
  () => data.value?.catalogs.map(useCatalogChoice) ?? []
);
</script>
<template>
  <SHNote v-if="error" theme="danger">{{ error }}</SHNote>
  <main v-else>
    <ComboBox
      enable-fuse
      :fuse-options="{
        keys: ['title'],
        threshold: 0.5
      }"
      :disabled="!catalogs"
      :options="catalogs"
      :loading="fetching"
      mobile-header="Choose Catalog"
      placeholder="Choose Catalog..."
      :multiple="multiple"
      :model-value="modelValue"
      @update:multiple="$emit('update:multiple', $event as CatalogChoice[])"
      @update:model-value="$emit('update:modelValue', $event as CatalogChoice)"
    />
  </main>
</template>
