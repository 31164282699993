<script setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type { AgentCustomersQuery } from "@/generated/graphql";
import { faPen } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { computed, reactive, ref } from "vue";

import SHButton from "@/components/SHButton.vue";
import SHCustomerChooser from "@/components/SHCustomerChooser.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import {
  useCustomerChoice,
  type CustomerChoice
} from "@/composables/useCustomerChoice";

const { agentId } = defineProps<{
  agentId: string;
}>();

const { createToast } = useToaster();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query AgentCustomers($where: customer_agents_bool_exp) {
      customer_agents(where: $where, order_by: [{ customer: { title: asc } }]) {
        dedicated_since
        customer {
          id
          title
          ...CustomerChoice
        }
      }
    }
  `),
  context: { additionalTypenames: ["customer_agents"] },
  variables: computed(() => ({
    where: {
      agent_id: { _eq: agentId || "" }
    }
  })),
  pause: computed(() => !agentId)
});
const entries = computed(() => data.value?.customer_agents || []);

const isFormOpen = ref(false);

const form = reactive<{
  customer: CustomerChoice | undefined;
  isDedicated: boolean;
}>({
  customer: undefined,
  isDedicated: false
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation DedicateAgent($object: customer_agents_insert_input!) {
      insert_customer_agents_one(
        object: $object
        on_conflict: {
          constraint: customer_agents_pkey
          update_columns: [dedicated_since]
        }
      ) {
        agent {
          id
        }
      }
    }
  `)
);

const isValid = computed(() => !!form.customer);

const openEditor = (e: AgentCustomersQuery["customer_agents"][number]) => {
  form.customer = useCustomerChoice(e.customer);
  form.isDedicated = !!e.dedicated_since;
  isFormOpen.value = true;
};

const onAdd = async () => {
  if (!isValid.value) {
    return;
  }

  const { data, error } = await executeMutation({
    object: {
      agent_id: agentId,
      customer_id: form.customer?.id,
      dedicated_since: form.isDedicated ? "now()" : null
    }
  });
  if (error) {
    createToast({
      title: "Unable to update the agent.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else if (data) {
    form.customer = undefined;
    form.isDedicated = false;
    isFormOpen.value = false;

    createToast({
      message: "Agent updated.",
      theme: "success"
    });
  }
};
</script>

<template>
  <article class="agent-customers vertical">
    <SHButton @click="isFormOpen = !isFormOpen">
      {{ isFormOpen ? "Hide" : "Dedicate to customer..." }}
    </SHButton>

    <div v-if="isFormOpen" class="addForm">
      <SHField label="Customer">
        <SHCustomerChooser v-model="form.customer" />
      </SHField>
      <SHField label="Is Dedicated">
        <input v-model="form.isDedicated" type="checkbox" />
      </SHField>
      <SHButton :disabled="!isValid" @click="onAdd">Dedicate</SHButton>
    </div>

    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>
    <div v-else-if="entries" class="vertical">
      <TransitionGroup name="list">
        <div v-for="e of entries" :key="e.customer.id" class="level-spread">
          {{ e.customer.title }}
          <div class="level tight">
            <div v-if="e.dedicated_since" class="level tight">
              Dedicated Since
              <SHInlineDate :d="e.dedicated_since" />
            </div>
            <div v-else>Not dedicated (can create calloff tickets)</div>
            <SHButton color="primary" link @click="openEditor(e)">
              <FontAwesomeIcon :icon="faPen" />
            </SHButton>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </article>
</template>

<style lang="scss" scoped>
article.agent-customers {
  .addForm {
    display: grid;
    gap: 1em;
  }
}
</style>
