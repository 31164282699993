<script setup lang="ts">
import RouterTabs from "@/components/RouterTabs.vue";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";

const currentUser = injectStrict(CurrentUserKey);

const tabs = [
  { title: "General", to: { name: "OrganizationSettingsGeneral" } },
  { title: "Forms", to: { name: "OrganizationCustomFormsView" } },
  { title: "Invoices", to: { name: "OrganizationSettingsInvoices" } },
  { title: "Time Sheets", to: { name: "OrganizationSettingsTimeSheets" } }
];
const protectedTabs = currentUser.value.isSupport
  ? [
      {
        title: "Feature Flags",
        to: { name: "OrganizationSettingsFeatureFlags" }
      }
    ]
  : [];
</script>
<template>
  <article class="vertical xmargin">
    <RouterTabs :tabs="[...tabs, ...protectedTabs]" />
  </article>
</template>
