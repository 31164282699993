export const getInitials = (value: string): string => {
  if (!value || !value.length) return "?";
  const words = value.trim().match(/\p{L}+|\p{M}+/gmu);
  let initials = "?";
  if (words && words.length > 0) {
    initials = words
      .reduce((prev: string[], cur: string, idx: number) => {
        if (idx === 0 || idx === words.length - 1) {
          prev.push(cur[0]);
        }
        return prev;
      }, [])
      .join("")
      .toUpperCase();
  }
  return initials || "?";
};

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export const toArrayLiteral = <T>(arr: T[]): string =>
  JSON.stringify(arr).replace("[", "{").replace("]", "}");
