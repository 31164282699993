<script setup lang="ts">
const { BASE_URL } = import.meta.env;
</script>

<template>
  <footer class="report-footer">
    <img style="max-height: 0.33in" :src="`${BASE_URL}img/report_footer.png`" />
  </footer>
</template>

<style lang="scss" scoped>
.report-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  img {
    margin-left: auto;
  }
}
</style>
