<script setup lang="ts">
import { useQuery } from "@urql/vue";
import { computed } from "vue";

import PriceBookCard from "@/components/PriceBookCard.vue";
import RouterTabs from "@/components/RouterTabs.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { graphql } from "@/generated";

const props = defineProps<{
  priceBookId: string;
}>();

const query = graphql(/* GraphQL */ `
  query PriceBookDetail($id: uuid!) {
    price_books_by_pk(id: $id) {
      id
      title

      ...PriceBookCard
    }
  }
`);

const { data, error, fetching } = useQuery({
  query,
  variables: {
    id: props.priceBookId
  }
});

const priceBook = computed(() => data.value?.price_books_by_pk);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="priceBook" class="price-book-detail vertical xmargin">
    <section>
      <PriceBookCard :price-book="priceBook" />
    </section>

    <section>
      <RouterTabs
        :tabs="[
          {
            title: 'Products',
            to: { name: 'ProductPricesView' }
          },
          {
            title: 'Agents',
            to: { name: 'AgentPricesView' }
          },
          {
            title: 'Travel',
            to: { name: 'TravelPricesView' }
          }
        ]"
      />
    </section>
  </article>
</template>
