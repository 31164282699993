<script setup lang="ts">
import { graphql, useFragment, type FragmentType } from "@/generated";
import { computed } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTrafficCone } from "@fortawesome/sharp-light-svg-icons";
import AppLink from "@/components/AppLink.vue";

const fragment = graphql(/* GraphQL */ `
  fragment WorkSiteLink on work_sites {
    id
    title
    customer_id
  }
`);

const props = defineProps<{
  workSite: FragmentType<typeof fragment>;
  hideIcon?: boolean;
}>();

const workSite = computed(() => useFragment(fragment, props.workSite));
</script>

<template>
  <AppLink
    class="customer-link"
    :to="{
      name: 'WorkSiteDetail',
      params: { customerId: workSite.customer_id, workSiteId: workSite.id }
    }"
  >
    <template v-if="!hideIcon" #prefix>
      <FontAwesomeIcon
        color="var(--color-secondary)"
        :icon="faTrafficCone"
        fixed-width
      />
    </template>
    <slot>
      {{ workSite.title }}
    </slot>
  </AppLink>
</template>
