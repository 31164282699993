<script setup lang="ts">
import { graphql, type FragmentType, useFragment } from "@/generated";
import { computed } from "vue";
import UserLink from "@/components/UserLink.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFileSpreadsheet } from "@fortawesome/sharp-light-svg-icons";
import AppLink from "@/components/AppLink.vue";

const fragment = graphql(/* GraphQL */ `
  fragment FormEvent on ticket_custom_form_entries {
    id
    ticket_id
    updated_at
    custom_form_definition {
      id
      title
    }
    author {
      ...UserLink
    }
  }
`);

const props = defineProps<{
  event: FragmentType<typeof fragment>;
}>();

const event = computed(() => useFragment(fragment, props.event));
</script>

<template>
  <TimeLineEntry
    :start="new Date(event.updated_at)"
    icon-fg-color="var(--color-success-up-300)"
    icon-bg-color="var(--color-success-down-300)"
  >
    <template #icon>
      <FontAwesomeIcon :icon="faFileSpreadsheet" fixed-width />
    </template>

    <UserLink :user="event.author" link />
    filled out the
    <AppLink
      :to="{
        name: 'CustomFormDetail',
        params: {
          ticketId: event.ticket_id
        },
        query: {
          ticketCustomFormEntryId: event.id,
          customFormDefinitionId: event.custom_form_definition.id
        }
      }"
    >
      {{ event.custom_form_definition.title }}
    </AppLink>
    form.
  </TimeLineEntry>
</template>

<style lang="scss" scoped></style>
