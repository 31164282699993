<script async setup lang="ts">
import type { JSONContent } from "@tiptap/vue-3";

import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { OrganizationKey } from "@/providerKeys";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref, watch } from "vue";

import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import {
  Time_Sheet_Billing_Intervals_Enum,
  Time_Units_Enum
} from "@/generated/graphql";
import { v4 } from "uuid";

import AppLink from "@/components/AppLink.vue";
import SHButton from "@/components/SHButton.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHEnumChooser from "@/components/SHEnumChooser.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHInput from "@/components/SHInput.vue";
import SHNumberInput from "@/components/SHNumberInput.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import { faPlus, faTrash } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const { log } = useLogger("TimeSheetSettings");
const { createToast } = useToaster();
const { can } = useRole();
const canEdit = computed(() => can("time_sheet_rules:manage"));

const organization = injectStrict(OrganizationKey);

const props = defineProps<{
  customerId?: string;
  showTitle?: boolean;
  showNotes?: boolean;
}>();

const { data, fetching, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TimeSheetSettings(
      $includeOrg: Boolean!
      $orgId: String!
      $includeCustomer: Boolean!
      $customerId: uuid!
    ) {
      organizations_by_pk(id: $orgId) @include(if: $includeOrg) {
        xref_time_sheet_rules(order_by: { valid_from: desc }) {
          ruleset_id
          valid_from
          ruleset {
            id
            title
            billing_interval
            notesj
            rules(order_by: { threshold_hours: asc }) {
              id
              ruleset_id
              threshold_hours
              modifier
              time_unit
            }
          }
        }
      }

      customers_by_pk(id: $customerId) @include(if: $includeCustomer) {
        xref_time_sheet_rules(order_by: { valid_from: desc }) {
          ruleset_id
          valid_from
          ruleset {
            id
            title
            billing_interval
            notesj
            rules(order_by: { threshold_hours: asc }) {
              id
              ruleset_id
              threshold_hours
              modifier
              time_unit
            }
          }
        }
      }
    }
  `),
  variables: computed(() => ({
    includeOrg: !props.customerId,
    orgId: organization.value?.id ?? "unknown org",
    includeCustomer: !!props.customerId,
    customerId: props.customerId ?? "unknown customer"
  }))
});

type Form = XrefForm[];

type XrefForm = {
  __isNew: boolean;
  ruleset_id: string;
  valid_from: Date;
  ruleset: {
    id: string;
    title: string;
    billing_interval: string;
    notesj: JSONContent | null;
    rules: RuleForm[];
  };
};

type RuleForm = {
  id: string;
  ruleset_id: string;
  threshold_hours: number;
  modifier: number;
  time_unit: string;
};

const xrefRulesets = computed(
  () =>
    data.value?.customers_by_pk?.xref_time_sheet_rules ??
    data.value?.organizations_by_pk?.xref_time_sheet_rules ??
    []
);

const form = ref<Form>(xrefRulesets.value.map(getXrefForm));
watch(data, () => {
  form.value = xrefRulesets.value.map(getXrefForm);
});

function getXrefForm(data?: (typeof xrefRulesets.value)[number]): XrefForm {
  const newRulesetId = v4();
  return {
    __isNew: !data,
    ruleset_id: data?.ruleset_id ?? newRulesetId,
    valid_from: data?.valid_from ? new Date(data.valid_from) : new Date(),
    ruleset: {
      id: data?.ruleset_id ?? newRulesetId,
      title: data?.ruleset.title ?? "",
      billing_interval:
        data?.ruleset.billing_interval ??
        Time_Sheet_Billing_Intervals_Enum.Shift,
      notesj: data?.ruleset.notesj ?? null,
      rules: data?.ruleset.rules.map(getRuleForm) ?? [
        getRuleForm(data?.ruleset_id ?? newRulesetId)
      ]
    }
  };
}

function addNewXref() {
  log("addNewXref");
  form.value.push(getXrefForm());
}

function getRuleForm(
  dataOrRulesetId?:
    | (typeof xrefRulesets.value)[number]["ruleset"]["rules"][number]
    | string
): RuleForm {
  log("addNewRule");
  if (typeof dataOrRulesetId === "string") {
    const rulesetId = dataOrRulesetId;
    return {
      id: v4(),
      ruleset_id: rulesetId,
      threshold_hours: 0,
      modifier: 1,
      time_unit: Time_Units_Enum.Day
    };
  }
  const data = dataOrRulesetId;
  return {
    id: data?.id ?? v4(),
    ruleset_id: data?.ruleset_id ?? "unknown ruleset",
    threshold_hours: data?.threshold_hours ?? 0,
    modifier: data?.modifier ?? 1,
    time_unit: data?.time_unit ?? Time_Units_Enum.Day
  };
}

const { executeMutation: insertOrgXref } = useMutation(
  graphql(/* GraphQL */ `
    mutation InsertOrgXrefTimeSheetRules(
      $ruleset: time_sheet_rulesets_insert_input!
      $xref: organizations_xref_time_sheet_rules_insert_input!
    ) {
      insert_time_sheet_rulesets_one(object: $ruleset) {
        id
      }

      insert_organizations_xref_time_sheet_rules_one(object: $xref) {
        ruleset_id
      }
    }
  `)
);

const { executeMutation: insertCustomerXref } = useMutation(
  graphql(/* GraphQL */ `
    mutation InsertCustomersXrefTimeSheetRules(
      $ruleset: time_sheet_rulesets_insert_input!
      $xref: customers_xref_time_sheet_rules_insert_input!
    ) {
      insert_time_sheet_rulesets_one(object: $ruleset) {
        id
      }

      insert_customers_xref_time_sheet_rules_one(object: $xref) {
        ruleset_id
      }
    }
  `)
);

function insert(form: XrefForm) {
  log("insert", form);
  const xref = {
    ruleset: {
      id: form.ruleset.id,
      title: form.ruleset.title,
      billing_interval: form.ruleset
        .billing_interval as Time_Sheet_Billing_Intervals_Enum,
      notesj: form.ruleset.notesj,
      rules: {
        data: form.ruleset.rules.map(rule => ({
          id: rule.id,
          threshold_hours: rule.threshold_hours,
          modifier: rule.modifier,
          time_unit: rule.time_unit as Time_Units_Enum
        }))
      }
    },
    xref: {
      customer_id: props.customerId,
      ruleset_id: form.ruleset_id,
      valid_from: form.valid_from
    }
  };
  return props.customerId ? insertCustomerXref(xref) : insertOrgXref(xref);
}

const { executeMutation: updateOrgXref } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateOrgXrefTimeSheetRules(
      $xref: organizations_xref_time_sheet_rules_insert_input!
      $ruleset_id: uuid!
    ) {
      delete_time_sheet_rulesets_by_pk(id: $ruleset_id) {
        id
      }

      insert_organizations_xref_time_sheet_rules_one(object: $xref) {
        ruleset_id
      }
    }
  `)
);

const { executeMutation: updateCustomerXref } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateCustomersXrefTimeSheetRules(
      $xref: customers_xref_time_sheet_rules_insert_input!
      $ruleset_id: uuid!
    ) {
      delete_time_sheet_rulesets_by_pk(id: $ruleset_id) {
        id
      }

      insert_customers_xref_time_sheet_rules_one(object: $xref) {
        ruleset_id
      }
    }
  `)
);

function update(form: XrefForm) {
  log("update", form);
  const xref = {
    customer_id: props.customerId,
    valid_from: form.valid_from,
    ruleset: {
      data: {
        title: form.ruleset.title,
        billing_interval: form.ruleset
          .billing_interval as Time_Sheet_Billing_Intervals_Enum,
        notesj: form.ruleset.notesj,
        rules: {
          data: form.ruleset.rules.map(rule => ({
            id: rule.id,
            threshold_hours: rule.threshold_hours,
            modifier: rule.modifier,
            time_unit: rule.time_unit as Time_Units_Enum
          }))
        }
      }
    }
  };

  return props.customerId
    ? updateCustomerXref({
        xref,
        ruleset_id: form.ruleset.id
      })
    : updateOrgXref({
        xref,
        ruleset_id: form.ruleset.id
      });
}

async function submit(form: XrefForm) {
  log("submit", form);
  // check if form is for new ruleset or existing
  let data, error;
  if (form.__isNew) {
    ({ data, error } = await insert(form));
  } else {
    ({ data, error } = await update(form));
  }

  if (error) {
    createToast({
      title: "Unable to save the time sheet settings.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else {
    log("submit success", data);
    createToast({
      message: "Time sheet settings saved.",
      theme: "success"
    });
  }
}

function remove(rule: RuleForm, ruleset: XrefForm["ruleset"]) {
  log("remove", rule);
  ruleset.rules = ruleset.rules.filter(r => r.id !== rule.id);
}

const { executeMutation: executeDelete } = useMutation(
  graphql(/* GraphQL */ `
    mutation DeleteTimeSheetRuleset($ruleset_id: uuid!) {
      delete_time_sheet_rulesets_by_pk(id: $ruleset_id) {
        id
      }
    }
  `)
);

function deleteXref(xref: XrefForm) {
  log("deleteXref", xref);
  return executeDelete({ ruleset_id: xref.ruleset.id });
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="data" class="time-sheet-settings vertical">
    <p v-if="customerId && !data.customers_by_pk?.xref_time_sheet_rules.length">
      No time sheet settings have been configured for this customer. Defaulting
      to
      <AppLink :to="{ name: 'OrganizationSettingsTimeSheets' }">
        organization settings.
      </AppLink>
    </p>
    <article class="time-sheet-settings-manager vertical">
      <article
        v-for="xref in form"
        :key="xref.ruleset_id"
        class="time-sheet-xref-form vertical"
      >
        <div class="vertical tight">
          <div class="level-cols tight">
            <SHField block label="Starting Date">
              <SHDatePicker
                v-if="canEdit"
                v-model="xref.valid_from"
                auto-apply
              />
              <SHInlineDate v-else format="long_time" :d="xref.valid_from" />
              <small v-if="canEdit">
                Select the date and time that these settings should go into
                effect.
              </small>
            </SHField>

            <SHField block label="Billing Interval">
              <SHEnumChooser
                v-if="canEdit"
                v-model="xref.ruleset.billing_interval"
                :disabled="!canEdit"
                :options="Time_Sheet_Billing_Intervals_Enum"
                mobile-header="Billing Interval"
              />
              <strong v-else>{{ xref.ruleset.billing_interval }}</strong>
              <small v-if="canEdit">
                Select the span of time that overtime rules should apply to.
              </small>
            </SHField>
            <SHField v-if="showTitle" label="Title">
              <SHInput v-if="canEdit" v-model="xref.ruleset.title" />
              <p v-else>{{ xref.ruleset.title }}</p>
            </SHField>

            <SHField v-if="showNotes" block label="Notes">
              <SHTextEditor v-model="xref.ruleset.notesj" :editable="canEdit" />
            </SHField>
          </div>

          <article
            v-for="(rule, idx) in xref.ruleset.rules"
            :key="rule.id"
            class="time-sheet-rule-form"
          >
            <div class="level loose">
              <SHButton
                v-if="canEdit"
                square
                color="danger"
                @click="remove(rule, xref.ruleset)"
              >
                <FontAwesomeIcon :icon="faTrash" />
              </SHButton>
              <div class="level tight">
                <p>If an agent works</p>
                <SHNumberInput
                  v-if="canEdit"
                  v-model="rule.threshold_hours"
                  style="max-width: 9ch"
                  :decimals="0"
                />
                <strong v-else class="number">
                  {{ rule.threshold_hours }}
                </strong>
                <p>hrs or more per</p>

                <strong>{{ xref.ruleset.billing_interval }}</strong>
                <p v-if="rule.time_unit === Time_Units_Enum.Day">
                  then set the
                </p>
                <p v-else>then multiply</p>
                <SHEnumChooser
                  v-if="canEdit"
                  v-model="rule.time_unit"
                  :disabled="!canEdit"
                  :options="Time_Units_Enum"
                  mobile-header="Time Unit"
                />
                <strong v-else>{{ rule.time_unit }}</strong>
                <p v-if="rule.time_unit === Time_Units_Enum.Day">rate to</p>
                <p v-else>rate by</p>
                <SHNumberInput
                  v-if="canEdit"
                  v-model="rule.modifier"
                  :disabled="!canEdit"
                  :step="0.1"
                  style="max-width: 9ch"
                  :decimals="2"
                />
                <strong v-else class="number">{{ rule.modifier }}</strong>
                <SHButton
                  v-if="canEdit && idx === xref.ruleset.rules.length - 1"
                  square
                  color="success"
                  @click="xref.ruleset.rules.push(getRuleForm(xref.ruleset.id))"
                >
                  <FontAwesomeIcon :icon="faPlus" />
                </SHButton>
              </div>
            </div>
          </article>

          <div class="level tight">
            <SHButton v-if="canEdit" @click="submit(xref)">
              Save Ruleset
            </SHButton>
            <SHButton
              v-if="canEdit && !xref.__isNew"
              color="danger"
              @click="deleteXref(xref)"
            >
              Delete
            </SHButton>
          </div>
        </div>
      </article>
      <SHButton v-if="canEdit" @click="addNewXref">
        <div class="level tight">
          <FontAwesomeIcon :icon="faPlus" />
          <span>Add New Ruleset</span>
        </div>
      </SHButton>
    </article>
  </article>
</template>

<style lang="scss" scoped>
.time-sheet-settings {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  button {
    height: 2.25em;
    align-self: baseline;
  }

  :deep(.sh-field) {
    .hidden {
      height: 0;
    }
  }
}
</style>
