<script setup lang="ts">
import { formatPostgresDayOfWeek, formatUSD } from "@/formatters";
import { graphql, type FragmentType, useFragment } from "@/generated";
import { computed, reactive } from "vue";
import { formatDate, numberFormatter } from "@/formatters";
import {
  addDays,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  startOfDay,
  startOfToday,
  subDays
} from "date-fns";

import SHField from "@/components/SHField.vue";
import { useLogger } from "@/logger";

const { log } = useLogger("AgentActivityChart"); // eslint-disable-line @typescript-eslint/no-unused-vars

const fragment = graphql(/* GraphQL */ `
  fragment AgentActivityChart on organization_agents {
    agent {
      id
      full_name
    }

    combined_logs(
      where: {
        deleted_at: { _is_null: true }
        _or: [
          { started_at: { _gte: $start, _lt: $end } }
          { ended_at: { _gt: $start, _lte: $end } }
        ]
      }
    ) {
      started_at
      ended_at
      expense_total_cents
      travel_miles
      travel_hours
      work_hours
    }
  }
`);

const props = defineProps<{
  orgAgent: FragmentType<typeof fragment>;
  vertical?: boolean;
}>();

const oa = computed(() => useFragment(fragment, props.orgAgent));

const fmt = numberFormatter.format;

type ActivityBox = {
  day: Date;
  dow: number;
  total_hours: number;
  work_hours: number;
  travel_hours: number;
  travel_miles: number;
  expense_total_cents: number;
  logs: number;
  gridColumn: number;
  gridRow: number;
};

const boxColor = (hours: number | null | undefined): string => {
  const defaultBg = "var(--color-primary-opacity-15)";
  if (!hours) {
    return defaultBg;
  } else if (hours >= 16) {
    return "var(--color-danger)";
  } else if (hours >= 14) {
    return "var(--color-warning)";
  } else if (hours >= 12) {
    return "var(--color-primary)";
  } else if (hours >= 10) {
    return "var(--color-primary-down-100)";
  } else if (hours >= 8) {
    return "var(--color-primary-down-200)";
  } else if (hours >= 4) {
    return "var(--color-primary-down-300)";
  } else if (hours >= 1) {
    return "var(--color-primary-down-400)";
  }
  return defaultBg;
};

const popupState = reactive<{
  isHovering: boolean;
  x: number;
  y: number;
  day?: ActivityBox;
  transform: string;
}>({
  isHovering: false,
  x: 0,
  y: 0,
  day: undefined,
  transform: ""
});
let timer = 0;

const onHover = (e: MouseEvent, d: typeof popupState.day) => {
  if (!e || !e.target || !e.target) return;
  const target = e.target as HTMLElement;
  const parent = target.parentElement;

  if (!parent || parent.classList.contains("box")) return;
  // console.log("parent", parent);

  const targetBounds = target.getBoundingClientRect();
  const parentBounds = parent.getBoundingClientRect();

  // are we on left of chart?
  const x = targetBounds.x - parentBounds.x;
  const y = targetBounds.y - parentBounds.y;
  // console.log({ x, y });

  if (x <= parentBounds.width / 2) {
    popupState.transform = "translateX(10%)";
    popupState.x = x;
    popupState.y = y;
  } else {
    popupState.transform = "translateX(-110%)";
    popupState.x = x;
    popupState.y = y;
  }

  popupState.isHovering = true;
  popupState.day = d;
  clearTimeout(timer);
};

const onMouseLeave = () => {
  timer = setTimeout(() => {
    popupState.isHovering = false;
  }, 200);
};
const popupStyle = computed(() => {
  return {
    top: popupState.y + "px",
    left: popupState.x + "px",
    transform: popupState.transform
  };
});

const boxStyle = (d: ActivityBox) => {
  if (!d || d.dow === undefined || d.dow === null) return {};
  const shouldMarkFirstDayOfMonth =
    d?.day && isFirstDayOfMonth(new Date(d.day)) && !d.total_hours;
  return {
    gridRow: props.vertical ? "default" : d.gridRow,
    gridColumn: props.vertical ? `${d.dow + 2}/${d.dow + 3}` : d.gridColumn,
    background: boxColor(d.total_hours),
    borderBottom: shouldMarkFirstDayOfMonth
      ? `2px solid var(--color-primary-opacity-30)`
      : "default"
  };
};

const logs = computed(() => oa.value.combined_logs ?? []);

const activity = computed(() => {
  let weekColumn = 0;
  const chartDays = 365;
  const out = Array.from({
    length: chartDays
  })
    .fill(null)
    .map<ActivityBox>((_, idx) => {
      const day = subDays(startOfToday(), chartDays - idx - 1);

      // Increment column on Sunday
      if (day.getDay() === 0) weekColumn++;

      const {
        total_hours,
        work_hours,
        travel_hours,
        travel_miles,
        expense_total_cents,
        logs
      } = getAggregatesByDay(day);

      return {
        day,
        dow: day.getDay(),
        total_hours,
        work_hours,
        travel_hours,
        travel_miles,
        expense_total_cents,
        logs,
        gridColumn: weekColumn + 2, // offset by 2 for the month labels
        gridRow: day.getDay() + 2 // offset by 2 for the day labels
      };
    });

  return out;
});

function getAggregatesByDay(day: Date) {
  const dayStarted = startOfDay(day);
  const dayEnded = addDays(day, 1);

  return logs.value.reduce<{
    total_hours: number;
    work_hours: number;
    travel_hours: number;
    travel_miles: number;
    expense_total_cents: number;
    logs: number;
  }>(
    (acc, timeLog) => {
      if (!timeLog.started_at || !timeLog.ended_at) {
        return acc;
      }

      const logStarted = new Date(timeLog.started_at);
      const logEnded = new Date(timeLog.ended_at);

      if (
        (isAfter(logStarted, dayStarted) && isBefore(logStarted, dayEnded)) ||
        (isAfter(logEnded, dayStarted) && isBefore(logEnded, dayEnded))
      ) {
        acc.logs++;

        const clampedStarted = isBefore(logStarted, day) ? day : logStarted;
        const clampedEnded = isAfter(logEnded, dayEnded) ? dayEnded : logEnded;
        const dayRatio =
          differenceInHours(clampedEnded, clampedStarted) /
          differenceInHours(logEnded, logStarted);

        acc.total_hours += differenceInHours(clampedEnded, clampedStarted);

        acc.work_hours += timeLog.work_hours
          ? differenceInHours(clampedEnded, clampedStarted)
          : 0;

        acc.travel_hours += timeLog.travel_hours
          ? differenceInHours(clampedEnded, clampedStarted)
          : 0;

        acc.travel_miles += timeLog.travel_miles
          ? timeLog.travel_miles * dayRatio
          : 0;

        acc.expense_total_cents += timeLog.expense_total_cents
          ? timeLog.expense_total_cents * dayRatio
          : 0;
      }

      return acc;
    },
    {
      total_hours: 0,
      work_hours: 0,
      travel_hours: 0,
      travel_miles: 0,
      expense_total_cents: 0,
      logs: 0
    }
  );
}

function differenceInHours(a: Date, b: Date) {
  return Math.abs(a.getTime() - b.getTime()) / 36e5;
}
</script>

<template>
  <article ref="container" class="agentActivity">
    <div class="graph" :class="{ isVertical: props.vertical }">
      <div :style="{ gridColumn: 1, gridRow: 3 }" class="dow">M</div>
      <div :style="{ gridColumn: 1, gridRow: 5 }" class="dow">W</div>
      <div :style="{ gridColumn: 1, gridRow: 7 }" class="dow">F</div>
      <template v-for="(d, i) of activity" :key="d.day || i">
        <div
          class="box"
          :style="boxStyle(d)"
          :class="{
            hasWork: (d.total_hours ?? 0) > 0,
            firstOfMonth:
              d?.day &&
              isFirstDayOfMonth(new Date(d.day || '')) &&
              !d.total_hours
          }"
          @mouseover="e => onHover(e, d)"
          @mouseleave="onMouseLeave"
        />
        <div
          v-if="isFirstDayOfMonth(new Date(d.day || ''))"
          class="month-label"
          :style="{
            gridRow: 1,
            gridColumn: d.gridColumn
          }"
        >
          <span>{{ formatDate(d.day, "MMM") }}</span>
        </div>
      </template>

      <div v-show="popupState.isHovering" class="popup" :style="popupStyle">
        <h4>
          {{ formatPostgresDayOfWeek(popupState?.day?.dow) }}
          {{ formatDate(popupState.day?.day, "MMM d, yyyy") }}
        </h4>

        <div v-if="(popupState.day?.total_hours ?? 0) > 0" class="fields">
          <SHField label="Total Hours">
            <span class="number">
              {{ fmt(popupState.day?.total_hours ?? 0) }}
            </span>
          </SHField>
          <SHField label="Work Hours">
            <span class="number">
              {{ fmt(popupState.day?.work_hours ?? 0) }}
            </span>
          </SHField>
          <SHField label="Travel Hours">
            <span class="number">
              {{ fmt(popupState.day?.travel_hours ?? 0) }}
            </span>
          </SHField>
          <SHField label="Travel Miles">
            <span class="number">
              {{ fmt(popupState.day?.travel_miles ?? 0) }}
            </span>
          </SHField>
          <SHField label="Expenses">
            <span class="number">
              {{
                popupState.day?.expense_total_cents
                  ? formatUSD(popupState.day?.expense_total_cents)
                  : "-"
              }}
            </span>
          </SHField>
          <!-- <SHField label="Total Logs">
            <span class="number">
              {{ fmt(popupState.day?.logs ?? 0) }}
            </span>
          </SHField> -->
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;

article.agentActivity {
  position: relative;
  .popup {
    border-radius: var(--border-radius);
    padding: var(--padding);
    box-shadow: var(--box-shadow);
    background: var(--color-surface-100);
    position: absolute;
    transition: all 0.2s ease-out;
    min-width: 14em;
    z-index: 1;
    display: flex;
    gap: 1em;
    flex-direction: column;
    .fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  }

  div.graph {
    --cell-size: 14px;
    position: relative;
    display: grid;
    gap: 3px;
    // grid-auto-flow: row;
    grid: repeat(8, var(--cell-size)) / auto-flow max-content;

    .month-label {
      position: relative;
      & > span {
        position: absolute;
        top: -0.5em;
        left: 0;
        font-size: 12px;
        font-weight: 600;
      }
    }

    &.isVertical {
      grid-template-columns: 1fr repeat(7, var(--cell-size)) 1fr;
      grid-auto-flow: row;
    }
  }
  .dow {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: var(--cell-size);
    width: 20px;
    padding: 0;
    margin-right: 0.5em;
    color: var(--color-surface-600);
  }
  .box {
    width: var(--cell-size);
    height: var(--cell-size);
    // border: thin solid var(--color-surface-300);

    &.firstOfMonth {
      border-bottom: 2px solid var(--color-primary-opacity-30);
      // border-left: thin solid var(--color-secondary);
    }
    &:hover:not(.hasWork) {
      box-shadow: inset 0 0 0 1px var(--color-info-up-200);
      cursor: pointer;
    }
  }
}
</style>
