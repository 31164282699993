<script setup lang="ts">
import { graphql } from "@/generated/gql";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import SHNote from "./SHNote.vue";
import {
  useCustomerChoice,
  type CustomerChoice
} from "@/composables/useCustomerChoice";

const { mustHaveUnbilledLogs } = defineProps<{
  disabled?: boolean;
  multiple?: CustomerChoice[];
  modelValue?: CustomerChoice | null;
  mustHaveUnbilledLogs?: boolean;
}>();

defineEmits<{
  (e: "update:modelValue", value: CustomerChoice): void;
  (e: "update:multiple", value: CustomerChoice[]): void;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query SHCustomerChooser($where: customers_bool_exp!) {
      customers(order_by: [{ title: asc }], where: $where) {
        ...CustomerChoice
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        { deleted_at: { _is_null: true } },
        mustHaveUnbilledLogs
          ? { combined_logs_aggregate: { count: { predicate: { _gt: 0 } } } }
          : {}
      ]
    }
  }))
});

const customers = computed(
  () => data.value?.customers.map(useCustomerChoice) ?? []
);
</script>
<template>
  <SHNote v-if="error" theme="danger">{{ error }}</SHNote>
  <main v-else>
    <ComboBox
      enable-fuse
      :fuse-options="{
        keys: ['title', 'abbreviation'],
        threshold: 0.5
      }"
      :disabled="disabled || !customers"
      :options="customers"
      :loading="fetching"
      mobile-header="Choose Customer"
      placeholder="Choose Customer..."
      :multiple="multiple"
      :model-value="modelValue"
      @update:multiple="$emit('update:multiple', $event as CustomerChoice[])"
      @update:model-value="$emit('update:modelValue', $event as CustomerChoice)"
    />
  </main>
</template>
