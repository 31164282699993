import { computed, ref, type MaybeRef } from "vue";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { graphql, type FragmentType, useFragment } from "@/generated";
import { differenceInDays } from "date-fns";

const ticketFragment = graphql(/* GraphQL */ `
  fragment CustomFormEntries on tickets {
    id
    ref

    product {
      products_xref_custom_forms(
        order_by: [{ custom_form_definition: { title: asc } }]
      ) {
        id
        is_required

        definition: custom_form_definition {
          id
          title
          component_name

          entries: ticket_custom_form_entries(
            where: { ticket_id: { _eq: $ticketId } }
            limit: 1
            order_by: [{ updated_at: desc }]
          ) {
            id
            updated_at
          }
        }
      }
    }
  }
`);

type CustomFormCache = {
  [ticketId: string]: {
    [definitionId: string]: CustomFormEntryCacheItem;
  };
};

type CustomFormEntryCacheItem =
  | {
      updated_at: string;
      payload: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  | undefined;

export function useCustomForms(
  ticket: MaybeRef<FragmentType<typeof ticketFragment> | null | undefined>,
  disableCache?: MaybeRef<boolean>
) {
  const _ticketFragment = ref(ticket);
  const _ticket = computed(() =>
    useFragment(ticketFragment, _ticketFragment.value)
  );
  const _disableCache = ref(disableCache);

  const cache = useStorage<CustomFormCache | null>(
    "custom-forms",
    null,
    undefined,
    {
      serializer: StorageSerializers.object
    }
  );

  const formBindings = computed(
    () =>
      _ticket.value?.product?.products_xref_custom_forms.map(binding => {
        return {
          isRequired: binding.is_required,
          definition: binding.definition,
          serverValue: binding.definition.entries.at(0),
          cache: getCachedEntry(binding.definition.id)
        };
      }) ?? []
  );

  /**
   * Logic for deleting old entries from local storage
   * TODO: Move this up to a global level because user would
   * need to open an old entry to trigger this and thus defeats the purpose
   */
  const localStorageExpirationInDays = 30;
  if (cache.value) {
    formBindings.value.forEach(binding => {
      const cachedEntry = getCachedEntry(binding.definition.id);
      if (
        cachedEntry &&
        differenceInDays(
          new Date(),
          new Date(cachedEntry.value?.updated_at as string)
        ) > localStorageExpirationInDays
      ) {
        deleteCachedEntry(binding.definition.id);
      }
    });
  }

  function getCachedEntry(definitionId: string) {
    return computed({
      get() {
        if (_disableCache.value || !_ticket.value || !cache.value) {
          return undefined;
        }
        return cache.value[_ticket.value?.id]?.[definitionId];
      },
      set(value) {
        if (_disableCache.value) {
          return;
        }
        if (!_ticket.value?.id) {
          throw new Error("ticket id is undefined");
        }

        cache.value = {
          ...cache.value,
          [_ticket.value.id]: {
            ...cache.value?.[_ticket.value.id],
            [definitionId]: value
          }
        };
      }
    });
  }

  function deleteCachedEntry(definitionId: string) {
    if (_disableCache.value) {
      return;
    }
    if (!_ticket.value?.id) {
      throw new Error("ticket id is undefined");
    }

    cache.value = {
      ...cache.value,
      [_ticket.value.id]: {
        ...cache.value?.[_ticket.value.id],
        [definitionId]: undefined
      }
    };
  }

  return {
    formBindings,
    getCachedEntry,
    deleteCachedEntry
  };
}
