<script setup lang="ts">
import { useUploadQueue } from "@/composables/useUpload";
useUploadQueue();
</script>

<template>
  <div class="upload-queue-plugin"></div>
</template>

<style lang="scss" scoped></style>
