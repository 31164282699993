<script setup lang="ts">
import { formatDateRelative, formatDateWithTimeZone } from "@/formatters";
import { computed } from "vue";
import SHDropdown from "./SHDropdown.vue";
import SHNote from "./SHNote.vue";

const { format = "short", d = new Date(0) } = defineProps<{
  d: string | Date | undefined | null;
  format?:
    | "long"
    | "long_time"
    | "month_name_day"
    | "short"
    | "time"
    | "rel"
    | "month_dow";
}>();

const dateDisplay = computed(() => {
  if (!d) {
    return null;
  }

  switch (format) {
    case "long":
      return formatDateWithTimeZone(d, "MMM d, yyyy");
    case "long_time":
      return formatDateWithTimeZone(d, "MMM d, yyyy h:mmaaa zzz");
    case "month_name_day":
      return formatDateWithTimeZone(d, "MMM d");
    case "month_dow":
      return formatDateWithTimeZone(d, "EEEE, MMM d");
    case "short":
      return formatDateWithTimeZone(d, "MM/dd");
    case "rel":
      return formatDateRelative(d);
    case "time":
      return formatDateWithTimeZone(d, "h:mmaaa zzz");
    default:
      throw new Error(`Unknown date format: ${format}`);
  }
});
</script>

<template>
  <article v-if="d" class="sh-inline-date">
    <SHDropdown
      v-if="format === 'rel'"
      class="sh-inline-date"
      open-on-hover
      strategy="fixed"
    >
      <template #popup>
        <SHNote theme="primary">
          {{ formatDateWithTimeZone(d, "MMM d, yyyy h:mmaaa zzz") }}
        </SHNote>
      </template>
      <span class="date">
        {{ dateDisplay }}
      </span>
    </SHDropdown>
    <span v-else class="date">
      {{ dateDisplay }}
    </span>
  </article>
  <span v-else>Unknown Date</span>
</template>

<style lang="scss" scoped>
.sh-inline-date {
  display: inline;
  .date {
    position: relative;
    display: inline-block;
  }
}
</style>
