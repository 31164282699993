import { graphql, type FragmentType, useFragment } from "@/generated";
import type { UserRoleChoiceFragment } from "@/generated/graphql";

const fragment = graphql(/* GraphQL */ `
  fragment UserRoleChoice on user_roles {
    id: short_name
    label: title
    auth0_id
    ...UserRole
  }
`);

export type UserRoleChoice = UserRoleChoiceFragment;

/**
 * Overload the useFragment function to provide a more specific type for the agent choice fragment.
 * The overloads are written this make typescript work better when agent is null or undefined.
 */
export function useUserRoleChoice(userRole: null): null;
export function useUserRoleChoice(userRole: undefined): undefined;
export function useUserRoleChoice(
  userRole: FragmentType<typeof fragment>
): UserRoleChoice;
export function useUserRoleChoice(
  userRole: FragmentType<typeof fragment> | null | undefined
): UserRoleChoice | null | undefined;
export function useUserRoleChoice(
  userRole: FragmentType<typeof fragment> | null | undefined
) {
  return useFragment(fragment, userRole);
}
