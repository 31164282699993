<script async setup lang="ts">
import ReviewEventCard from "@/components/ActivityView/ReviewEventCard.vue";
import AppLink from "@/components/AppLink.vue";
import SHAgentChooser from "@/components/SHAgentChooser.vue";
import SHButton, { type ButtonTheme } from "@/components/SHButton.vue";
import SHField from "@/components/SHField.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import TimeLine from "@/components/TimeLine.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import UserLink from "@/components/UserLink.vue";
import type { AgentChoice } from "@/composables/useAgentChoice";
import { useCustomForms } from "@/composables/useCustomForms";
import { useRole } from "@/composables/useRole";
import { useTipTap } from "@/composables/useTipTap";
import { useToaster } from "@/composables/useToaster";
import { graphql, useFragment } from "@/generated";
import {
  Event_Types_Enum,
  ReviewEventCardFragmentDoc,
  Review_Statuses_Enum,
  Ticket_Review_Event_Types_Enum
} from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { CurrentUserKey } from "@/providerKeys";
import { faArrowDownFromLine } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { JSONContent } from "@tiptap/vue-3";
import { useMutation, useQuery, useSubscription } from "@urql/vue";
import { v4 } from "uuid";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const fragment = graphql(/* GraphQL */ `
  fragment TicketReview_Ticket on tickets {
    id
    ref
    created_at
    review_status
    reviewer_id
    author {
      id
      ...UserLink
    }
    work_order {
      author {
        id
        ...UserLink
      }
    }
    ticket_agents {
      agent_id
    }
    ticket_review_events(order_by: [{ created_at: asc }]) {
      id
      created_at
      ticket_review_event_type
      desired_reviewer_id
      ...ReviewEventCard
    }
    latest_request_event: ticket_review_events(
      where: { ticket_review_event_type: { _eq: REQUEST } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      desired_reviewer_id
    }

    ...CustomFormEntries
  }
`);

const { log } = useLogger("TicketReview"); // eslint-disable-line @typescript-eslint/no-unused-vars
const router = useRouter();
const currentUser = injectStrict(CurrentUserKey);
const { roleName } = useRole();
const userId = currentUser?.value.id ?? "";
const { createToast } = useToaster();

const { ticketId } = defineProps<{
  ticketId: string;
}>();

const { data, fetching, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query GetTicketReviewData($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        ...TicketReview_Ticket
      }
    }
  `),
  variables: computed(() => ({
    ticketId
  }))
});

const { data: subscriptionData } = useSubscription({
  query: graphql(/* GraphQL */ `
    subscription GetTicketReviewDataSubscription($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        ...TicketReview_Ticket
      }
    }
  `),
  variables: computed(() => ({
    ticketId
  }))
});

const ticket = computed(() =>
  useFragment(
    fragment,
    subscriptionData.value?.tickets_by_pk ?? data.value?.tickets_by_pk
  )
);

const { formBindings } = useCustomForms(ticket);

const reviewState = computed<Review_Statuses_Enum>(() => {
  return ticket.value?.review_status ?? Review_Statuses_Enum.Inapplicable;
});

const isRequested = computed(() => {
  return reviewState.value == Review_Statuses_Enum.Requested;
});

const latestRequestEvent = computed(() => {
  return ticket.value?.latest_request_event.at(0);
});

const isReviewer = computed(() => {
  return ticket.value?.reviewer_id
    ? ticket.value?.reviewer_id === userId
    : roleName?.value === "back_office" || roleName?.value === "supervisor";
});

const isTicketAgent = computed(
  () => ticket.value?.ticket_agents.some(o => o.agent_id === userId) ?? false
);

const allRequiredFormsFilledOut = computed(() => {
  return formBindings.value.filter(o => o.isRequired).every(o => o.serverValue);
});

const canRequest = computed(
  () =>
    (reviewState.value === Review_Statuses_Enum.Pending ||
      reviewState.value === Review_Statuses_Enum.Rejected) &&
    isTicketAgent.value &&
    allRequiredFormsFilledOut.value
);

const canComment = computed(() => true);

const canAccept = computed(
  () => reviewState.value == Review_Statuses_Enum.Requested && isReviewer.value
);

const canReject = computed(
  () => reviewState.value == Review_Statuses_Enum.Requested && isReviewer.value
);

const canCancel = computed<boolean>(
  () => isRequested.value && isTicketAgent.value
);

type EventTheme = {
  label: string;
  value: Ticket_Review_Event_Types_Enum;
  color: ButtonTheme;
  show: boolean;
};

const eventOptions = computed<EventTheme[]>(() => [
  {
    label: "Request",
    value: Ticket_Review_Event_Types_Enum.Request,
    color: "info",
    show: canRequest.value
  },
  {
    label: "Cancel",
    value: Ticket_Review_Event_Types_Enum.Cancel,
    color: "warning",
    show: canCancel.value
  },
  {
    label: "Accept",
    value: Ticket_Review_Event_Types_Enum.Accept,
    color: "success",
    show: canAccept.value
  },
  {
    label: "Reject",
    value: Ticket_Review_Event_Types_Enum.Reject,
    color: "danger",
    show: canReject.value
  },
  {
    label: "Comment",
    value: Ticket_Review_Event_Types_Enum.Comment,
    color: "primary",
    show: canComment.value
  }
]);

const reviewEventForm = ref<{
  notes: JSONContent | null;
  requestingReviewer: AgentChoice | null;
}>({
  notes: null,
  requestingReviewer: null
});

const reviewEvents = computed(() => ticket.value?.ticket_review_events ?? []);

const currentReviewerId = computed(() =>
  isRequested.value
    ? (latestRequestEvent.value?.desired_reviewer_id ?? null)
    : null
);

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation AddTicketReviewEvent(
      $reviewEvent: ticket_review_events_insert_input!
      $eventNotification: event_notifications_insert_input!
    ) {
      insert_ticket_review_events_one(object: $reviewEvent) {
        id
        ticket {
          id
        }
      }
      insert_event_notifications_one(object: $eventNotification) {
        event_id
      }
    }
  `)
);

async function onSubmit(reviewEvent: Ticket_Review_Event_Types_Enum) {
  let notificationEventType: Event_Types_Enum;
  let desiredReviewerId: string | null | undefined;
  let toastMessage = "";
  let toastTitle = "";
  let toastErrorMessage = "";

  switch (reviewEvent) {
    case Ticket_Review_Event_Types_Enum.Request:
      toastMessage = "Review requested";
      toastTitle = "Review requested";
      toastErrorMessage = "Review not requested";
      notificationEventType = Event_Types_Enum.TicketReviewRequested;
      desiredReviewerId = reviewEventForm.value.requestingReviewer?.id;
      break;
    case Ticket_Review_Event_Types_Enum.Cancel:
      toastMessage = "Review cancelled";
      toastTitle = "Review cancelled";
      toastErrorMessage = "Review not cancelled";
      notificationEventType = Event_Types_Enum.TicketReviewCancelled;
      desiredReviewerId = currentReviewerId.value;
      break;
    case Ticket_Review_Event_Types_Enum.Accept:
      toastMessage = "Review accepted";
      toastTitle = "Review accepted";
      toastErrorMessage = "Review not accepted";
      notificationEventType = Event_Types_Enum.TicketReviewAccepted;
      desiredReviewerId = currentReviewerId.value;
      break;
    case Ticket_Review_Event_Types_Enum.Reject:
      toastMessage = "Review rejected";
      toastTitle = "Review rejected";
      toastErrorMessage = "Review not rejected";
      notificationEventType = Event_Types_Enum.TicketReviewRejected;
      desiredReviewerId = currentReviewerId.value;
      break;
    case Ticket_Review_Event_Types_Enum.Comment:
      toastMessage = "Comment added";
      toastTitle = "Comment added";
      toastErrorMessage = "Comment not added";
      notificationEventType = Event_Types_Enum.TicketReviewCommented;
      desiredReviewerId = currentReviewerId.value;
      break;
    default:
      throw new Error("Unknown notification event type");
  }

  const eventId = v4();
  const { data, error } = await executeMutation({
    reviewEvent: {
      id: eventId,
      ticket_id: ticketId,
      ticket_review_event_type: reviewEvent,
      desired_reviewer_id: desiredReviewerId,
      notesj: reviewEventForm.value.notes
    },
    eventNotification: {
      event_id: v4(),
      payload: {
        type: notificationEventType,
        version: 1,
        data: {
          author_id: userId,
          ticket_review_event_id: eventId,
          ticket_id: ticketId
        }
      }
    }
  });

  if (error) {
    createToast({
      theme: "danger",
      title: toastErrorMessage,
      message: error.message || "Unkown error."
    });
  }

  if (data) {
    createToast({
      theme: "success",
      title: toastTitle,
      message: toastMessage
    });
  }

  reviewEventForm.value.notes = null;

  if (reviewEvent === Ticket_Review_Event_Types_Enum.Accept) {
    router.back();
  }
}

const editor = useTipTap(reviewEventForm.value.notes);

const bindingsWithCacheValue = computed(() => {
  return formBindings.value.filter(o => !!o.cache.value);
});
</script>

<template>
  <article class="vertical loose">
    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>
    <SHNote v-else-if="!ticket" theme="danger">404</SHNote>
    <template v-else>
      <SHNote
        v-if="ticket && !allRequiredFormsFilledOut"
        theme="danger"
        class="required-forms-warning"
      >
        Not all required forms are filled out.
      </SHNote>
      <SHNote v-else-if="bindingsWithCacheValue.length" theme="warning">
        <p>You have unsaved changes in one or more forms:</p>
        <ul>
          <li
            v-for="binding in bindingsWithCacheValue"
            :key="binding.definition.component_name"
          >
            <AppLink
              :to="{
                name: 'CustomFormDetail',
                query: {
                  ticketCustomFormEntryId: binding.serverValue?.id,
                  customFormDefinitionId: binding.definition.id
                }
              }"
            >
              {{ binding.definition.title }}
            </AppLink>
          </li>
        </ul>
      </SHNote>
      <SHNote v-else-if="canRequest" theme="warning">
        By requesting review of this ticket, you are signaling the work is
        finished. Once requested, you can no longer edit work logs, travel logs,
        expenses, or forms. You may cancel the request to be able to edit.
      </SHNote>
      <TimeLine>
        <!-- Manually placed timeline "event" for when this ticket was created -->
        <TimeLineEntry
          icon-fg-color="var(--color-surface-400)"
          icon-bg-color="var(--color-surface-100)"
          :start="ticket?.created_at"
        >
          <template #icon>
            <FontAwesomeIcon :icon="faArrowDownFromLine" fixed-width />
          </template>

          <UserLink :user="ticket?.author" link />
          created ticket.
        </TimeLineEntry>

        <TransitionGroup name="list">
          <ReviewEventCard
            v-for="r in reviewEvents"
            :key="useFragment(ReviewEventCardFragmentDoc, r)?.id"
            :reviewEvent="r"
          />
        </TransitionGroup>
      </TimeLine>
    </template>

    <footer class="vertical">
      <article class="review-event-form vertical">
        <SHField v-if="false && canRequest" label="Select reviewer">
          <SHAgentChooser
            placeholder="All supervisors"
            :model-value="reviewEventForm.requestingReviewer"
            :clearable="false"
            @update:model-value="reviewEventForm.requestingReviewer = $event"
          />
        </SHField>

        <div class="vertical">
          <SHField label="Notes" block>
            <SHTextEditor
              v-model="reviewEventForm.notes"
              style="flex: 1"
              :rows="5"
              :editor="editor"
              editable
            />
          </SHField>
          <div class="level-end tight">
            <template v-for="option in eventOptions" :key="option.label">
              <SHButton
                v-if="option.show"
                :color="option.color as ButtonTheme"
                :disabled="
                  (option.value === Ticket_Review_Event_Types_Enum.Reject &&
                    editor?.isEmpty) ||
                  (option.value === Ticket_Review_Event_Types_Enum.Comment &&
                    editor?.isEmpty)
                "
                @click="onSubmit(option.value)"
              >
                {{ option.label }}
              </SHButton>
            </template>
          </div>
        </div>
      </article>
    </footer>
  </article>
</template>
<style lang="scss" scoped>
.required-forms-warning {
  width: 100%;
}
</style>
