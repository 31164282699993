<script setup lang="ts">
import {
  faPalette,
  faTreePalm,
  type IconDefinition
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { injectStrict } from "@/lib/helpers";
import { ThemeKey } from "@/providerKeys";
import ComboBox from "./ComboBox/ComboBox.vue";
import SHField from "./SHField.vue";
import { computed } from "vue";

const activeTheme = injectStrict(ThemeKey);

type Theme = {
  icon: IconDefinition;
  id: string;
  label: string;
};

const defaultTheme: Theme = {
  icon: faPalette,
  id: "default",
  label: "Default"
};

const tropicalTheme: Theme = {
  icon: faTreePalm,
  id: "tropical",
  label: "Tropical"
};

const themes: Theme[] = [defaultTheme, tropicalTheme];

const selectedTheme = computed({
  get() {
    return themes.find(theme => theme.id === activeTheme.value);
  },

  set(newValue) {
    activeTheme.value = newValue?.id || "default";
  }
});

const { disabled = false, placeholder = "Unknown theme" } = defineProps<{
  placeholder?: string;
  disabled?: boolean;
}>();
</script>

<template>
  <div>
    <SHField label="Theme:">
      <ComboBox
        v-bind="$attrs"
        v-model="selectedTheme"
        :disabled="disabled"
        :options="themes"
        :placeholder="placeholder"
        mobile-header="Choose A Theme"
      >
        <template #option="{ option }">
          <span class="level tight">
            <FontAwesomeIcon :icon="option.icon" />
            <div>
              {{ option?.label }}
            </div>
          </span>
        </template>

        <template #selected-option="{ option }">
          <span v-if="option?.id && selectedTheme" class="selected-option">
            <span class="level tight">
              <FontAwesomeIcon :icon="selectedTheme.icon" />
              <div>
                {{ selectedTheme.label }}
              </div>
            </span>
          </span>
          <div v-else>
            {{ placeholder }}
          </div>
        </template>
      </ComboBox>
    </SHField>
  </div>
</template>

<style lang="scss" scoped>
.condensed {
  max-width: 2em;
}
</style>
