import { Order_By } from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { useLogger } from "@/logger";
import { computed, toValue, type MaybeRefOrGetter } from "vue";
import {
  useQueryStringFilters,
  type Facet,
  type OrderSet
} from "./useQueryStringFilters";

const { log } = useLogger("useFacets");

export type FacetComboOption = {
  modelName: string;
  value: string;
};

export function useFacets<Bool_Exp, Order_By_Exp>(
  facets: MaybeRefOrGetter<Facet<Bool_Exp>[]>,
  orderSet: OrderSet<Order_By_Exp>,
  viewMode?: string | undefined
) {
  // get facet settings from the URL query string parameters
  const facetModels = useQueryStringFilters(
    toValue(facets).reduce(
      (prev, f) => {
        prev[f.modelName] = f.defaultValue;
        return prev;
      },
      {
        ...{ query: "" },
        orderField: orderSet.defaultValue, // leave this blank, to keep the url clean, defaults will still apply
        orderDirection: orderSet.defaultOrderBy ?? Order_By.Desc,
        viewMode: viewMode
      } as Record<string, string>
    )
  );

  const filterWhereClause = computed(() => {
    const results =
      toValue(facets).map(facet => {
        return (
          facet.filters.find(
            f => f.id === facetModels[facet.modelName]?.value
          ) || facet.filters[0]
        )?.predicate;
      }) || [];
    return results;
  });

  const resetFacets = () => {
    toValue(facets).forEach(f => {
      facetModels[f.modelName].value = f.defaultValue;
    });
    facetModels.query.value = "";
    facetModels.orderField.value = orderSet.defaultValue;
    facetModels.orderDirection.value = orderSet.defaultOrderBy ?? Order_By.Desc;
  };

  const searchString = computed(() => {
    return facetModels.query.value?.toString()
      ? (facetModels.query.value as string)
      : "";
  });

  const sortOrder = computed(() => {
    const currentOption = orderSet.options.find(o => {
      return o.id === facetModels.orderField.value;
    });
    if (!currentOption) {
      log(
        "Error: The defaultValue doesn't match any of the sort options. Make sure the defaultValue matches the id of one of the options."
      );
    }
    const currentDirection =
      facetModels.orderDirection.value === Order_By.Asc
        ? Order_By.Asc
        : Order_By.Desc;
    return currentOption?.[currentDirection] ?? [];
  });

  const updateFacet = (newOption: FacetComboOption) => {
    const { modelName, value } = newOption;
    facetModels[modelName].value = value;
  };

  const updateSearch = (newValue: string) => {
    facetModels.query.value = newValue || "";
  };

  const updateSortDirection = (newValue: string) => {
    facetModels.orderDirection.value = newValue;
  };

  const updateSortType = (newValue: string) => {
    facetModels.orderField.value = newValue;
  };

  const updateViewMode = (newValue: string) => {
    facetModels.viewMode.value = newValue;
  };

  const mode = computed(() => {
    let result;
    switch (facetModels.viewMode.value) {
      case "card":
        result = CollectionView.Card;
        break;
      case "calendar":
        result = CollectionView.Calendar;
        break;
      case "table":
        result = CollectionView.Table;
        break;
    }
    return result;
  });

  return {
    facetModels,
    filterWhereClause,
    mode,
    resetFacets,
    searchString,
    sortOrder,
    updateFacet,
    updateSearch,
    updateSortDirection,
    updateSortType,
    updateViewMode
  };
}
