import { useRole } from "./useRole";
import { computed, type MaybeRef, unref } from "vue";
import { graphql, type FragmentType, useFragment } from "@/generated";
import {
  Lifecycle_Statuses_Enum,
  Ticket_Statuses_Enum,
  Work_Order_Types_Enum
} from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";

const fragment = graphql(/* GraphQL */ `
  fragment TicketCanAddLogs on tickets {
    ticket_status

    ticket_agents_aggregate(where: { agent_id: { _eq: $currentUserId } }) {
      aggregate {
        count
      }
    }

    work_order {
      work_order_type
      lifecycle_status

      customer {
        customer_agents_aggregate(
          where: { agent_id: { _eq: $currentUserId } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`);

export function useLogPermissions(
  ticket: MaybeRef<FragmentType<typeof fragment> | null | undefined>
) {
  const _ticket = computed(() => useFragment(fragment, unref(ticket)));
  const _workOrder = computed(() => _ticket.value?.work_order);

  const currentUser = injectStrict(CurrentUserKey);
  const { can } = useRole();

  const isTicketOpenOrInProgress = computed(
    () =>
      _ticket.value?.ticket_status === Ticket_Statuses_Enum.Open ||
      _ticket.value?.ticket_status === Ticket_Statuses_Enum.InProgress
  );

  const isTicketInReview = computed(
    () => _ticket.value?.ticket_status === Ticket_Statuses_Enum.InReview
  );

  const isCurrentUserAssigned = computed(
    () => (_ticket?.value?.ticket_agents_aggregate.aggregate?.count || 0) > 0
  );

  const isCurrentUserDedicated = computed(
    () =>
      (_ticket.value?.work_order.customer.customer_agents_aggregate?.aggregate
        ?.count || 0) > 0
  );

  const isDedicatedWorkOrder = computed(
    () =>
      _ticket.value?.work_order.work_order_type ===
      Work_Order_Types_Enum.Dedicated
  );

  const roleCanCreate = computed(
    () =>
      can("work_logs:create") &&
      can("travel_logs:create") &&
      (isDedicatedWorkOrder.value
        ? isCurrentUserDedicated.value
        : isCurrentUserAssigned.value)
  );

  const roleCanEdit = computed(
    () =>
      (can("work_logs:update_all") && can("travel_logs:update_all")) ||
      (can("work_logs:update") &&
        can("travel_logs:update") &&
        _ticket.value?.ticket_status &&
        ![Ticket_Statuses_Enum.Closed, Ticket_Statuses_Enum.InReview].includes(
          _ticket.value?.ticket_status
        ))
  );

  const canAddLogsOnTicket = computed(
    () =>
      roleCanCreate.value &&
      _workOrder.value?.lifecycle_status !== Lifecycle_Statuses_Enum.Closed &&
      _workOrder.value?.lifecycle_status !== Lifecycle_Statuses_Enum.Pending &&
      isTicketOpenOrInProgress.value
  );

  const reasonAddForbid = computed(() => {
    const lc = _workOrder.value?.lifecycle_status;

    if (!canAddLogsOnTicket.value) {
      if (!roleCanCreate.value) {
        return "You have not been given the proper role to add logs to this ticket.";
      } else if (lc === Lifecycle_Statuses_Enum.Closed) {
        return "Ticket is locked because its work order is expired.";
      } else if (lc === Lifecycle_Statuses_Enum.Pending) {
        return "Ticket is locked because its work order is pending.";
      } else if (!isTicketOpenOrInProgress.value) {
        return "This ticket is no longer open or in progress. You cannot add, edit, or remove items.";
      } else if (isDedicatedWorkOrder.value) {
        if (!isCurrentUserDedicated.value) {
          return "You are not a dedicated agent of this customer.";
        } else if (!isCurrentUserAssigned.value) {
          return "You are not assigned to this ticket.";
        }
      } else {
        return "Cannot log work, please contact support.";
      }
    }
    return undefined;
  });

  function canEditLog(authorId: string | null | undefined) {
    if (!roleCanEdit.value || !authorId) {
      return false;
    }

    if (can("work_logs:update_all") && can("travel_logs:update_all")) {
      return true;
    }

    return (
      authorId === currentUser.value.id &&
      can("work_logs:update") &&
      can("travel_logs:update")
    );
  }

  return {
    canAddLogsOnTicket,
    reasonAddForbid,
    canEditLog
  };
}
