<script setup lang="ts">
import { computed } from "vue";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { formatUSD } from "@/formatters";
import { useWindowSize } from "@vueuse/core";
import { faBarcodeRead } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TravelPriceEditor from "@/components/TravelPriceEditor.vue";
import SHButton from "@/components/SHButton.vue";
import SHModal from "@/components/SHModal.vue";
import SHTable from "@/components/SHTable.vue";
import UseToggle from "@/components/UseToggle.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHNote from "@/components/SHNote.vue";

const { priceBookId } = defineProps<{
  priceBookId: string;
}>();

const { data, error, fetching, executeQuery } = useQuery({
  query: graphql(/* GraphQL */ `
    query TravelPriceIndex($priceBookId: uuid!) {
      travel_prices(
        limit: 1
        order_by: { valid_from: desc }
        where: {
          price_book_id: { _eq: $priceBookId }
          valid_from: { _lte: "now()" }
        }
      ) {
        id
        cents_per_hour
        cents_per_mile
        ...TravelPriceEditor
      }
    }
  `),
  variables: {
    priceBookId
  },
  context: { additionalTypenames: ["travel_prices"] }
});

const travelPrices = computed(() => data.value?.travel_prices ?? []);

const { width } = useWindowSize();
const isMobileMode = computed(() => width.value < 600);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="data && travelPrices" class="travel-prices-view">
    <template v-if="data">
      <SHTable :clickable="false" class="mobile" :rows="travelPrices">
        <template #body="{ rows }">
          <UseToggle v-slot="{ on, disable, enable }">
            <tr
              class="travel-row"
              :class="{
                open: on
              }"
            >
              <td class="level-spread">
                <div class="level tight">
                  <FontAwesomeIcon
                    style="color: var(--color-secondary)"
                    :icon="faBarcodeRead"
                  />
                  <span>Travel</span>
                </div>
                <template v-if="!on">
                  <SHButton
                    v-if="rows?.[0]"
                    link
                    class="travel-price level tight"
                    @click="enable"
                  >
                    <span class="number">
                      {{ formatUSD(rows[0]?.cents_per_hour) }}
                    </span>
                    per hour
                    <span class="number">
                      {{ formatUSD(rows[0]?.cents_per_mile) }}
                    </span>
                    per mile
                  </SHButton>
                  <SHButton v-else link color="primary" @click="enable">
                    Add Prices
                  </SHButton>
                </template>
                <template v-else>
                  <SHModal v-if="isMobileMode" :show="on" @close="disable">
                    <template #brand>
                      <h1 class="level tight">
                        <FontAwesomeIcon
                          style="color: var(--color-secondary)"
                          :icon="faBarcodeRead"
                        />
                        <span>Travel</span>
                      </h1>
                    </template>
                    <div class="xmargin">
                      <TravelPriceEditor
                        :price-book-id="priceBookId"
                        :options="data"
                        :travel-price="rows?.at(0)"
                        @update:travel-price="
                          executeQuery();
                          disable();
                        "
                        @cancel="disable"
                      />
                    </div>
                  </SHModal>
                  <TravelPriceEditor
                    v-else-if="on"
                    :price-book-id="priceBookId"
                    inline
                    :options="data"
                    :travel-price="rows?.at(0)"
                    @update:travel-price="
                      executeQuery();
                      disable();
                    "
                    @cancel="disable"
                  />
                </template>
              </td>
            </tr>
          </UseToggle>
        </template>
      </SHTable>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.travel-prices-view {
  .catalog-row {
    border-bottom: none;
  }

  .travel-row {
    border-color: var(--color-surface-200);

    > td {
      min-height: 4em;
    }

    &.last {
      border-bottom: none;
    }

    .travel-price {
      color: var(--color-primary);
      font-size: var(--font-size-lg);
    }
  }

  .travel-editor-row {
    > .travel-price-editor {
      padding: 0.25em;
    }
  }
}
</style>
