<script setup lang="ts">
import { ref } from "vue";
import { faTimesSquare } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { DarkModeKey } from "@/providerKeys";
import { injectStrict } from "@/lib/helpers";
import AppLink from "@/components/AppLink.vue";
import SHSpinner from "@/components/SHSpinner.vue";

const { placeholder, selectOnFocus } = defineProps<{
  id?: string;
  type?:
    | "text"
    | "number"
    | "time"
    | "email"
    | "tel"
    | "date"
    | "datetime-local";
  modelValue?: string | number | null | Date;
  alignRight?: boolean;
  selectOnFocus?: boolean;
  compact?: boolean;
  clearable?: boolean;
  fetching?: boolean;
  spellcheck?: boolean;
  placeholder?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:model-value", value: string): void;
  (e: "focus"): void;
}>();

const inputEl = ref<HTMLInputElement>();
const onFocus = (e: Event) => {
  if (selectOnFocus) {
    (e.target as HTMLInputElement).select();
  }
  emit("focus");
};

const isDark = injectStrict(DarkModeKey);

defineExpose({
  inputEl,
  select: () => inputEl.value?.select(),
  focus: () => inputEl.value?.focus()
});
</script>

<template>
  <article
    class="sh-input"
    :class="{
      hasPrefix: !!$slots.prefix,
      hasSuffix: !!$slots.suffix,
      compact,
      disabled
    }"
  >
    <div v-if="$slots.prefix" class="prefix"><slot name="prefix"></slot></div>

    <input
      :id="id"
      ref="inputEl"
      :type="type"
      :value="modelValue"
      :class="{ alignRight, isDark }"
      :spellcheck="spellcheck"
      :clearable="clearable"
      :placeholder="placeholder"
      :disabled="disabled"
      v-bind="$attrs"
      @input="
        $emit('update:model-value', ($event.target as HTMLInputElement).value)
      "
      @focus="onFocus"
    />
    <SHSpinner v-if="fetching" size="sm" />
    <AppLink
      v-if="clearable && modelValue !== ''"
      title="clear"
      @click.prevent="$emit('update:model-value', '')"
    >
      <FontAwesomeIcon :icon="faTimesSquare" fixed-width />
    </AppLink>

    <div v-if="$slots.suffix" class="suffix"><slot name="suffix"></slot></div>
  </article>
</template>

<style lang="scss" scoped>
article {
  --border-width: 1.75px;
  --border-color: var(--color-surface-200);
  --border-focus: var(--color-primary);
  --border: var(--border-width) solid var(--border-color);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  border: 0;

  .prefix,
  .suffix {
    flex-shrink: 0;
    white-space: nowrap;
  }

  &:hover {
    --border-color: var(--color-secondary);
  }

  &.hasPrefix {
    input {
      border-left: var(--border);
    }
  }
  &.hasSuffix {
    input {
      border-right: var(--border);
    }
  }

  &:focus-within {
    --border-color: var(--color-primary);
  }

  input {
    flex-grow: 1;
    outline: none;
    font-size: 1em;
    font-family: inherit;
    width: 100%;
    padding: 0.3em 0.7em;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    color: var(--color-surface-900);
    background: var(--color-surface-100);

    &:disabled {
      color: var(--color-surface-500);
      font-weight: 300;
      cursor: not-allowed;
    }

    &[type="time"] {
      // prevent jank when dragging SHTimeSlider by using a monospace font
      font-family: var(--font-family-monospace);
    }

    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    &.isDark {
      color-scheme: dark;
    }

    &:hover {
      --border-color: var(--color-secondary);
    }

    &:focus {
      --border-color: var(--color-primary);
    }

    &::selection {
      color: var(--color-surface-0);
      background: var(--color-surface-900);
    }

    &::placeholder {
      color: var(--color-surface-200);
    }

    &::-webkit-datetime-edit-text {
      color: var(--color-surface-900);
    }

    &[type="date"]:invalid::-webkit-datetime-edit {
      color: var(--color-surface-200);
    }
  }

  &.compact {
    align-items: flex-end;
    border-bottom: var(--border);
    input {
      padding: 0;
      border: 0;
      background: transparent;
      border-radius: 0;

      &.hasPrefix {
        border-left: 0;
      }

      &:focus {
        border-color: var(--color-primary);
      }

      &:not(:focus).alignRight {
        text-align: right;
      }
    }
  }
}
</style>
