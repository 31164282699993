<script setup lang="ts">
import SHDropdown from "@/components/SHDropdown.vue";
import SHInput from "@/components/SHInput.vue";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import SHMenu from "./SHMenu.vue";

const { domain, modelValue } = defineProps<{
  domain: string;
  modelValue?: string;
}>();

const emit = defineEmits<{
  (e: "update:model-value", value: string): void;
}>();

const { log } = useLogger("SHAutoComplete"); // eslint-disable-line @typescript-eslint/no-unused-vars
const isFocused = ref<boolean>(false);

const { data, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query FuzzySearchCustomDomains($args: fuzzysearchdomain_args!) {
      fuzzysearchdomain(args: $args) {
        token
        domain
      }
    }
  `),
  variables: computed(() => ({
    args: {
      search_domain: domain,
      search_term: modelValue
    }
  })),
  pause: computed(
    () => !isFocused.value || !modelValue || modelValue.length < 2
  ),
  context: { additionalTypenames: ["fuzzysearchdomain"] }
});

const suggestions = computed(() => data.value?.fuzzysearchdomain || []);

const onUpdate = (val: string) => {
  emit("update:model-value", val);
};
</script>

<template>
  <SHDropdown ref="dropdown" placement="bottom-start" match-width>
    <template #default="{ isOpen, open }">
      <SHInput
        v-bind="$attrs"
        class="shautocomplete"
        :fetching="fetching"
        :model-value="modelValue"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @update:model-value="
          if (!isOpen && suggestions.length) {
            open();
          }
          onUpdate($event);
        "
      />
    </template>

    <template #popup="{ close }">
      <SHMenu
        :items="
          suggestions?.map(s => ({
            id: s.token,
            label: s.token,
            onClick: () => {
              $emit('update:model-value', s.token);
              close();
            }
          }))
        "
      />
    </template>
  </SHDropdown>
</template>
