<script setup lang="ts">
import {
  Lifecycle_Statuses_Enum,
  Ticket_Statuses_Enum,
  Work_Order_Types_Enum,
  Order_By
} from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faRectanglesMixed } from "@fortawesome/sharp-light-svg-icons";

import AppLink from "@/components/AppLink.vue";
import DashboardActions from "@/components/Dashboards/DashboardActions.vue";
import TicketSummary from "@/components/Dashboards/TicketSummary.vue";
import UserLink from "@/components/UserLink.vue";
import WorkOrderSummary from "@/components/Dashboards/WorkOrderSummary.vue";

const currentUser = injectStrict(CurrentUserKey);
</script>

<template>
  <article class="dashboard-agent">
    <h1 class="level-spread">
      <div class="level tight">
        <FontAwesomeIcon
          :icon="faRectanglesMixed"
          style="color: var(--color-secondary)"
        />
        My Dashboard
      </div>
      <UserLink :user="currentUser" avatar-only avatar-size="large" />
    </h1>

    <DashboardActions />

    <WorkOrderSummary
      show-add-ticket
      :order-by="[{ updated_at: Order_By.Desc }]"
      :where="{
        deleted_at: { _is_null: true },
        work_order_type: { _eq: Work_Order_Types_Enum.Dedicated },
        customer: {
          customer_agents: {
            agent_id: { _eq: currentUser?.id }
          }
        },
        _or: [
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Open } },
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Grace } },
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Expiring } }
        ]
      }"
    >
      My Active Work Orders
      <template #seeall="{ total }">
        <AppLink :to="{ name: 'WorkOrdersView' }" color="surface-300">
          See all {{ total }} Work Orders
        </AppLink>
      </template>

      <template #none>
        <div>You have no active work orders.</div>
      </template>
    </WorkOrderSummary>

    <TicketSummary
      :order-by="[{ updated_at: Order_By.Desc }]"
      :where="{
        deleted_at: { _is_null: true },
        ticket_agents: {
          agent_id: { _eq: currentUser?.id || 'unknown user' }
        },
        _or: [
          { ticket_status: { _eq: Ticket_Statuses_Enum.Open } },
          { ticket_status: { _eq: Ticket_Statuses_Enum.InProgress } }
        ]
      }"
    >
      Recent Activity
      <template #seeall="{ total }">
        <AppLink :to="{ name: 'TicketsView' }" color="surface-300">
          See all {{ total }} Active Tickets
        </AppLink>
      </template>

      <template #none>
        <div>You have no recent ticket activity.</div>
      </template>
    </TicketSummary>
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.dashboard-agent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
