<script setup lang="ts">
import { Delivery_Statuses_Enum } from "@/generated/graphql";
import SHBadge from "@/components/SHBadge.vue";
import { computed } from "vue";

const props = defineProps<{
  status: Delivery_Statuses_Enum | null | undefined;
}>();

const color = computed(() => {
  switch (props.status) {
    case Delivery_Statuses_Enum.Draft:
      return "var(--color-warning)";
    case Delivery_Statuses_Enum.Sent:
      return "var(--color-primary)";
    default:
      return "var(--color-info)";
  }
});
</script>

<template>
  <SHBadge :color="color">{{ props.status }}</SHBadge>
</template>

<style lang="scss" scoped></style>
