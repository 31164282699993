<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHField from "@/components/SHField.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { useToaster } from "@/composables/useToaster";
import { formatDate } from "@/formatters";
import { graphql } from "@/generated";
import { faPhone } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

const { createToast } = useToaster();
const router = useRouter();

const { customerContactId } = defineProps<{
  customerContactId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomerContactDetail($id: uuid!) {
      customer_contacts_by_pk(id: $id) {
        id
        created_at
        updated_at
        deleted_at
        full_name
        job_title
        email
        phone_office
        phone_mobile
        phone_fax
      }
    }
  `),
  variables: {
    id: customerContactId
  }
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation ArchiveCustomerContact($id: uuid!, $deleted_at: timestamptz) {
      update_customer_contacts_by_pk(
        pk_columns: { id: $id }
        _set: { deleted_at: $deleted_at }
      ) {
        id
      }
    }
  `)
);

const customerContact = computed(() => data.value?.customer_contacts_by_pk);

async function onDelete() {
  if (!customerContact.value) {
    return;
  }

  const { data: deleteResult, error: deleteError } = await executeMutation({
    id: customerContact.value.id,
    deleted_at: "now()"
  });

  if (deleteError) {
    createToast({
      title: " Unable to delete the contact.",
      message: deleteError.message || "Unknown error.",
      theme: "danger"
    });
  }

  if (deleteResult) {
    createToast({
      message: "Contact deleted.",
      theme: "success"
    });
    router.push({
      name: "CustomerContactsView"
    });
  }
}

async function onRestore() {
  if (!customerContact.value) {
    return;
  }

  const { data: restoreResult, error: restoreError } = await executeMutation({
    id: customerContact.value.id,
    deleted_at: null
  });

  if (restoreError) {
    createToast({
      title: "Unable to restore the contact.",
      message: restoreError.message || "Unknown error.",
      theme: "danger"
    });
  }

  if (restoreResult) {
    createToast({
      message: "Contact restored.",
      theme: "success"
    });
    router.push({
      name: "CustomerContactsView"
    });
  }
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article
    v-else-if="data && customerContact"
    class="customer-contact-detail vertical"
  >
    <header class="level-spread">
      <div class="level tight">
        <h1>{{ customerContact.full_name }}</h1>
        <SHBadge v-if="customerContact.deleted_at">Archived</SHBadge>
      </div>
      <SHButton
        :to="{ name: 'CustomerContactEdit', params: { customerContactId } }"
      >
        Edit
      </SHButton>
    </header>
    <section class="vertical">
      <SHField label="Job Title">
        {{ customerContact.job_title || "-" }}
      </SHField>

      <SHField label="Email">
        <AppLink
          v-if="customerContact.email"
          :href="`mailto:${customerContact.email}`"
        >
          {{ customerContact.email ?? "-" }}
        </AppLink>
        <span v-else>-</span>
      </SHField>

      <SHField label="Work Phone">
        <AppLink
          v-if="customerContact.phone_office"
          :href="`tel:${customerContact.phone_office}`"
          class="level tight"
        >
          <FontAwesomeIcon :icon="faPhone" />
          {{ customerContact.phone_office ?? "-" }}
        </AppLink>
        <span v-else>-</span>
      </SHField>

      <SHField label="Mobile Phone">
        <AppLink
          v-if="customerContact.phone_mobile"
          :href="`tel:${customerContact.phone_mobile}`"
          class="level tight"
        >
          <FontAwesomeIcon :icon="faPhone" />
          {{ customerContact.phone_mobile ?? "-" }}
        </AppLink>
        <span v-else>-</span>
      </SHField>

      <SHField label="Fax Number">
        <AppLink
          v-if="customerContact.phone_fax"
          :href="`tel:${customerContact.phone_fax}`"
          class="level tight"
        >
          <FontAwesomeIcon :icon="faPhone" />
          {{ customerContact.phone_fax ?? "-" }}
        </AppLink>
        <span v-else>-</span>
      </SHField>

      <SHField
        :label="
          customerContact.updated_at === customerContact.created_at
            ? 'Created'
            : 'Updated'
        "
      >
        {{ formatDate(customerContact.updated_at) }}
      </SHField>
    </section>
    <footer>
      <SHButton
        v-if="!customerContact.deleted_at"
        color="danger"
        @click="onDelete"
      >
        Delete Contact
      </SHButton>
      <SHButton v-else color="success" @click="onRestore">
        Restore Contact
      </SHButton>
    </footer>
  </article>
</template>
