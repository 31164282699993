<script setup lang="ts">
import { formatUSD } from "@/formatters";
import { graphql, type FragmentType, useFragment } from "@/generated";
import { computed, inject, ref } from "vue";
import { DarkModeKey } from "@/providerKeys";
import SHTable from "@/components/SHTable.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import OrgLogo from "@/components/OrgLogo.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";

const fragment = graphql(/* GraphQL */ `
  fragment InvoiceDocument on invoices {
    id
    ref
    due_at
    total_cost_in_cents
    notesj
    issued_at

    invoice_line_items(
      where: { deleted_at: { _is_null: true } }
      order_by: [{ label: asc }]
    ) {
      id
      label
      description
      details
      cents_calculated
      cents_invoiced
    }

    payments(where: { deleted_at: { _is_null: true } }) {
      id
      amount_in_cents
    }

    payments_aggregate(where: { deleted_at: { _is_null: true } }) {
      aggregate {
        sum {
          amount_in_cents
        }
      }
    }

    organization {
      title
      reply_to_address
      postal_address
      accent_color
      short_name
      invoice_template_above_line_items
      invoice_template_below_line_items

      ...OrgLogo
    }
    customer {
      title
      mailing_address
    }
  }
`);

const props = defineProps<{
  invoice: FragmentType<typeof fragment>;
}>();

const invoice = computed(() => useFragment(fragment, props.invoice));
const subtotal = computed(() => invoice.value?.total_cost_in_cents ?? 0);

const paymentAmount = computed(
  () => invoice.value?.payments_aggregate.aggregate?.sum?.amount_in_cents ?? 0
);

const balance = computed(() => subtotal.value - paymentAmount.value);

const isDark = inject(DarkModeKey, ref(false));

// const accentColorCSS = computed(
//   () => invoice.value?.organization?.accent_color || "var(--color-primary)"
// );
</script>

<template>
  <article class="invoice-document">
    <header>
      <OrgLogo :organization="invoice?.organization" square :dark="isDark" />
      <div class="vertical tight">
        <h1>Invoice</h1>
        {{ invoice.organization.title }}
        <div>TODO: tagline</div>
      </div>
      <div class="vertical tight" style="align-items: end">
        <h1 class="invoice-number">#{{ invoice.ref }}</h1>
        <span>INVOICE NUMBER</span>
        <span>
          Issued On
          <span style="font-weight: 600">
            <SHInlineDate :d="invoice.issued_at" />
          </span>
        </span>
      </div>
    </header>

    <div class="addresses">
      <address>
        <h4>TO</h4>
        <strong>
          {{ invoice.customer.title }}
        </strong>
        {{ invoice.customer.mailing_address }}
      </address>
      <address>
        <h4>FROM</h4>
        {{ invoice.organization.postal_address }}
      </address>
      <div class="due-box">
        <h4>AMOUNT</h4>
        <span class="d5 bold-number">
          {{ formatUSD(invoice.total_cost_in_cents) }}
        </span>
        <span v-if="invoice.due_at">
          Due on
          <span style="font-weight: 600">
            <SHInlineDate :d="invoice.due_at" />
          </span>
        </span>
      </div>
    </div>

    <p
      v-if="invoice.organization.invoice_template_above_line_items"
      class="org_template"
    >
      {{ invoice.organization.invoice_template_above_line_items }}
    </p>

    <SHTable
      class="line-items-table"
      :clickable="false"
      :rows="invoice.invoice_line_items ?? []"
      :columns="['Item', 'Description', 'Amount']"
      fluid
    >
      <template #row="{ row }">
        <td class="title">
          {{ row.label }}
        </td>
        <td class="description">
          {{ row.description }}
        </td>
        <td class="level-end tight bold-number">
          <template
            v-if="
              row.cents_invoiced && row.cents_invoiced !== row.cents_calculated
            "
          >
            <s>{{ formatUSD(row.cents_calculated) }}</s>
            <span>{{ formatUSD(row.cents_invoiced) }}</span>
          </template>
          <template v-else>
            {{ formatUSD(row.cents_calculated) }}
          </template>
        </td>
      </template>
    </SHTable>

    <section class="summary">
      <div class="vertical tight" style="align-items: end">
        <span>
          Subtotal
          <span class="bold-number">{{ formatUSD(subtotal) }}</span>
        </span>
        <template v-for="(payment, idx) in invoice.payments" :key="payment.id">
          <span>
            {{ `Payment #${idx + 1}` }}
            <span class="bold-number">
              {{ formatUSD(payment.amount_in_cents) }}
            </span>
          </span>
        </template>
        <span>
          Amount Due
          <span class="bold-number">
            {{ formatUSD(balance) }}
          </span>
        </span>
      </div>
      <div>
        <SHTextEditor :model-value="invoice.notesj" />
      </div>
    </section>

    <p
      v-if="invoice.organization.invoice_template_below_line_items"
      class="org_template"
    >
      {{ invoice.organization.invoice_template_below_line_items }}
    </p>
  </article>
</template>

<style lang="scss" scoped>
.invoice-document {
  width: 8.5in;
  min-height: 11in;
  background-color: var(--color-surface-50);
  color: var(--color-surface-900);
  padding: var(--padding);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  font-size: 12pt;

  .bold-number {
    font-weight: 500;
    font-family: var(--font-family-monospace);
  }

  header {
    display: grid;
    grid-template-columns: 2in 4fr 1fr;
    align-items: center;
    gap: 1em;

    .invoice-number {
      font-weight: 500;
      font-family: var(--font-family-monospace);
    }
  }

  .addresses {
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background: var(--color-surface-100);
    h4 {
      color: var(--color-surface-600);
      // TODO: decide if we use the org's accent color in the invoice anywhere
      // color: v-bind(accentColorCSS);
    }
    address,
    .due-box {
      gap: 0.25em;
      padding: 1em;
    }

    address {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      white-space: pre-line;
      margin: 0;
    }

    .due-box {
      border-radius: var(--border-radius);
      background: var(--color-surface-0);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: end;
    }
  }

  :deep(.line-items-table) {
    border-radius: var(--border-radius);
    border-collapse: collapse;
    font-size: 10pt;
    tr:hover {
      background-color: inherit;
    }
    th {
      font-weight: 700;
    }
    td.title {
      font-weight: 600;
    }
    td.description {
      font-weight: normal;
    }

    th {
      &:last-child {
        text-align: right;
      }
    }

    td {
      white-space: pre-line;
      &:last-child {
        font-family: var(--font-family-monospace);
        text-align: right;
        font-weight: 600;
        font-size: 1.25em;
      }
    }
  }

  .summary {
    display: flex;
    justify-content: end;
    padding: var(--padding);
  }
  .org_template {
    font-size: 11pt;
    font-weight: 300;
    white-space: pre;
    margin: 0;
  }
}
</style>
