<script setup lang="ts">
import SHProgress from "@/components/SHProgress.vue";
import type { MediaUploadTask } from "@/composables/useUpload";
import { useLogger } from "@/logger";
import { formatDuration } from "date-fns";
import { computed } from "vue";

const { activeUploads } = defineProps<{
  activeUploads: MediaUploadTask[];
}>();

const { log } = useLogger("SHUploadQueue");

let lastUpdated = Date.now();
let bytesUploaded = 0;
const totals = computed(() => {
  const totals = activeUploads.reduce(
    (sum, cur) => {
      sum.uploaded += cur.bytesUploaded;
      sum.total += cur.file.size;
      return sum;
    },
    { uploaded: 0, total: 0 }
  );
  const now = Date.now();
  const elapsed = (now - lastUpdated) / 1000;
  lastUpdated = now;
  const delta = totals.uploaded - bytesUploaded;
  bytesUploaded = totals.uploaded;
  const speed = delta / elapsed;
  const secondsLeft = (totals.total - totals.uploaded) / speed;

  let eta = `<${formatDuration({ minutes: Math.ceil(secondsLeft / 60) })}`;
  if (secondsLeft < 60)
    eta = formatDuration({ seconds: Math.round(secondsLeft) });

  const total = totals.total / 1000000;
  const uploaded = totals.uploaded / 1000000;

  return {
    uploaded: uploaded.toFixed(2),
    total: total.toFixed(2),
    eta,
    progress: uploaded / total
  };
});

log("Upload Queue Toast Card Created!!!!");
</script>

<template>
  <div class="queue-wrapper">
    <div class="upload-status">
      <span class="uploaded">
        {{ totals.uploaded }} MB of {{ totals.total }} MB
      </span>
      <span class="eta">{{ totals.eta }}</span>
    </div>
    <div class="progress-bar">
      <SHProgress :progress="totals.progress" bar-color="primary" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.queue-wrapper {
  background-color: var(--color-primary-opacity-25);
  padding: var(--padding);

  .upload-status {
    display: flex;
    font-family: var(--font-family-monospace);

    .uploaded {
      flex-grow: 1;
    }
  }
}
</style>
