<script setup lang="ts">
import { computed } from "vue";
import { useQuery } from "@urql/vue";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import WorkOrderCard from "@/components/WorkOrderCard.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import RouterTabs from "@/components/RouterTabs.vue";

const { log } = useLogger("WorkOrderDetail"); // eslint-disable-line @typescript-eslint/no-unused-vars

const { workOrderId } = defineProps<{
  workOrderId: string;
}>();

const WorkOrderDetailQuery = graphql(/* GraphQL */ `
  query WorkOrderDetail($id: uuid!) {
    work_orders_by_pk(id: $id) {
      ref
      ...WorkOrderCard
    }
  }
`);

//#region queries
const { data, fetching } = useQuery({
  query: WorkOrderDetailQuery,
  variables: { id: workOrderId }
});

const workOrder = computed(() => data.value?.work_orders_by_pk);

const tabs = computed(() => [
  { title: "Tickets", to: { name: "WorkOrderTicketsView" } },
  {
    title: "Service Report",
    to: {
      name: "CreateServiceReportFromWorkOrder",
      params: { workOrderId: workOrderId }
    }
  }
]);
</script>

<template>
  <article class="vertical xmargin">
    <SHSpinner v-if="fetching" />
    <main v-else-if="workOrder" class="vertical _padding:1">
      <WorkOrderCard v-if="workOrder" :work-order="workOrder" />
      <RouterTabs :tabs="tabs" />
    </main>
  </article>
</template>
