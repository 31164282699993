<script setup lang="ts">
import { onMounted } from "vue";

// @ts-expect-error - ChangeLog is a markdown file and for some reason typescript won't resolve
import ChangeLog from "../../../CHANGELOG.md";

const emit = defineEmits<{
  (e: "change-log-loaded"): void;
}>();

onMounted(() => {
  emit("change-log-loaded");
});
</script>

<template>
  <main>
    <section class="xmargin">
      <ChangeLog class="changeLog" />
    </section>
  </main>
</template>

<style lang="scss">
.changeLog {
  h1 {
    background-color: var(--color-primary-opacity-25);
    border: 1px solid var(--color-primary);
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    font-size: 1em;
    margin: 1em 0 0 0;
    padding: 0.25em 0.5em;
  }

  li {
    padding: 0.25em 0.25em 0.25em 0;
  }

  li:first-child {
    padding-top: 1em;
  }

  li:last-child {
    padding-bottom: 1em;
  }

  ul {
    background-color: var(--color-surface-0);
    border: 1px solid var(--color-primary);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin: 0 0 1.5em 0;
  }
}
</style>
