import ModuleHeader from "@/components/ModuleHeader.vue";
import SHButton from "@/components/SHButton.vue";
import AgentPricesView from "@/views/AgentPricesView.vue";
import PriceBookCreate from "@/views/PriceBookCreate.vue";
import PriceBookDetail from "@/views/PriceBookDetail.vue";
import PriceBookEdit from "@/views/PriceBookEdit.vue";
import PriceBooksView from "@/views/PriceBooksView.vue";
import ProductPricesView from "@/views/ProductPricesView.vue";
import TravelPricesView from "@/views/TravelPricesView.vue";
import {
  faDollarCircle,
  faPen,
  faPlus
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";

export const pricebookRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "PriceBooksView",
    components: {
      default: PriceBooksView,
      header: h(
        ModuleHeader,
        { icon: faDollarCircle },
        {
          default: () => "Pricebooks",
          nav: () =>
            h(
              SHButton,
              { color: "primary", to: { name: "PriceBookCreate" }, size: "sm" },
              () =>
                h("div", [
                  h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                  h(FontAwesomeIcon, { icon: faDollarCircle, fixedWidth: true })
                ])
            )
        }
      )
    },
    props: true
  },
  {
    path: "new",
    name: "PriceBookCreate",
    components: {
      default: PriceBookCreate,
      header: h(ModuleHeader, { icon: faDollarCircle }, () => "New Pricebook")
    },
    props: true
  },
  {
    path: ":priceBookId",
    name: "PriceBookDetail",
    components: {
      default: PriceBookDetail,
      header: h(
        ModuleHeader,
        { icon: faDollarCircle },
        {
          default: () => "Pricebook Details",
          nav: () =>
            h(
              SHButton,
              { color: "primary", to: { name: "PriceBookEdit" }, size: "sm" },
              () => h(FontAwesomeIcon, { icon: faPen, fixedWidth: true })
            )
        }
      )
    },
    props: true,
    redirect: { name: "ProductPricesView" },
    children: [
      {
        path: "products",
        name: "ProductPricesView",
        component: ProductPricesView,
        props: true
      },
      {
        path: "travel",
        name: "TravelPricesView",
        component: TravelPricesView,
        props: true
      },
      {
        path: "agents",
        name: "AgentPricesView",
        component: AgentPricesView,
        props: true
      }
    ]
  },
  {
    path: ":priceBookId/edit",
    name: "PriceBookEdit",
    components: {
      default: PriceBookEdit,
      header: h(ModuleHeader, { icon: faDollarCircle }, () => "Edit Pricebook")
    },
    props: true
  }
];
