<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";

import PriceBookForm from "@/components/PriceBookForm.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { useMutation } from "@urql/vue";

const router = useRouter();
const { createToast } = useToaster();

const insertMutation = graphql(/* GraphQL */ `
  mutation PriceBookCreateInsert($priceBook: price_books_insert_input!) {
    insert_price_books_one(object: $priceBook) {
      id
    }
  }
`);

const { executeMutation: insert } = useMutation(insertMutation);

const variables = ref();

async function onSubmit() {
  if (!variables.value) {
    return;
  }

  const { data, error } = await insert({
    priceBook: variables.value
  });

  if (error) {
    createToast({
      theme: "danger",
      lifetimeMS: 5000,
      title: "Pricebook not created.",
      message: error.message || "Unknown error."
    });
  }

  if (data?.insert_price_books_one?.id) {
    createToast({
      theme: "success",
      message: "Pricebook created."
    });
    router.push({
      name: "PriceBookDetail",
      params: {
        priceBookId: data.insert_price_books_one.id
      }
    });
  }
}
</script>

<template>
  <article class="vertical xmargin">
    <section>
      <PriceBookForm v-model:inserts="variables" />
    </section>

    <section>
      <UndoConfirm
        :confirm-enabled="true"
        @confirm="onSubmit()"
        @undo="router.push({ name: 'PriceBooksView' })"
      />
    </section>
  </article>
</template>
