<script setup lang="ts">
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type { Organizations_Set_Input } from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { AuthClientKey, OrganizationKey } from "@/providerKeys";
import { useMutation, useQuery } from "@urql/vue";
import { computed, reactive, ref } from "vue";

const { log } = useLogger("OrganizationSettingsGeneral"); // eslint-disable-line @typescript-eslint/no-unused-vars
const { createToast } = useToaster();

const organization = injectStrict(OrganizationKey);
const authClient = injectStrict(AuthClientKey);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query OrganizationSettingsGeneral($id: String!) {
      organizations_by_pk(id: $id) {
        accent_color
        invoice_template_above_line_items
        invoice_template_below_line_items

        timezone_history_entries(
          where: { valid_from: { _lte: "now()" } }
          order_by: [{ valid_from: desc }]
          limit: 1
        ) {
          timezone_name
        }
      }
    }
  `),
  variables: computed(() => ({ id: organization.value?.id || "" }))
});

const accessToken = ref("");
authClient.getAccessTokenSilently().then(token => (accessToken.value = token));

// get current record
const org = computed(() => data.value?.organizations_by_pk);
const timezone = computed(
  () => org.value?.timezone_history_entries[0]?.timezone_name
);

// track pending updates, none at the start

const updates = reactive<Organizations_Set_Input>({});

const accentColor = computed({
  get() {
    return (
      updates.accent_color ||
      data.value?.organizations_by_pk?.accent_color ||
      "#FF00FF"
    );
  },
  set(value: string) {
    updates.accent_color = value;
  }
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateOrgSettings(
      $set: organizations_set_input!
      $where: organizations_bool_exp!
    ) {
      update_organizations(where: $where, _set: $set) {
        affected_rows
      }
    }
  `)
);

const onSubmit = async () => {
  const { data, error } = await executeMutation({
    set: updates,
    where: {
      id: { _eq: organization.value?.id }
    }
  });

  if (error) {
    createToast({
      title: "Unable to update the settings.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  }
  if (data) {
    createToast({
      message: "Settings updated.",
      theme: "success"
    });
  }
};
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="org" class="vertical">
    <h3>General</h3>
    <SHField label="Timezone">
      <SHInput v-model="timezone" disabled />
    </SHField>

    <SHField label="Accent Color">
      <div class="level tight wrap">
        <input v-model="accentColor" type="color" />
        <SHInput v-model="accentColor" />
      </div>
    </SHField>

    <UndoConfirm
      :confirm-enabled="Object.entries(updates).length > 0"
      @confirm="onSubmit"
      @undo="$router.back"
    />
  </article>
</template>

<style lang="scss" scoped></style>
