<script setup lang="ts">
import { inject } from "vue";
import { faMoon, faSunBright } from "@fortawesome/sharp-light-svg-icons";
import { DarkModeKey } from "@/providerKeys";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import SHToggle from "@/components/SHToggle.vue";

defineProps<{
  condensed?: boolean;
}>();

const isDark = inject(DarkModeKey);
</script>

<template>
  <div class="level-center tight" :class="{ condensed }">
    <FontAwesomeIcon :icon="faSunBright" />
    <SHToggle v-model="isDark" />
    <FontAwesomeIcon :icon="faMoon" />
  </div>
</template>

<style lang="scss" scoped>
.condensed {
  svg {
    display: none;
  }
}
</style>
