<script setup lang="ts">
import { computed } from "vue";
import { useRole } from "@/composables/useRole";

import DashboardAgent from "@/components/Dashboards/DashboardAgent.vue";
import DashboardBackOffice from "@/components/Dashboards/DashboardBackOffice.vue";
import DashboardSupervisor from "@/components/Dashboards/DashboardSupervisor.vue";

const { roleName } = useRole();

const component = computed(() => {
  switch (roleName?.value) {
    case "back_office":
      return DashboardBackOffice;
    case "supervisor":
      return DashboardSupervisor;
    case "agent":
      return DashboardAgent;
  }
  return DashboardAgent;
});
</script>

<template>
  <Component :is="component" />
</template>
