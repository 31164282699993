<script async setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTimeInput from "@/components/SHTimeInput/SHTimeInput.vue";
import type { TimeSliderRange } from "@/components/SHTimeInput/SHTimeSlider.vue";
import SHWorkSiteChooser, {
  type WorkSiteChoice
} from "@/components/SHWorkSiteChooser.vue";
import SHTextEditorWithToolbar from "@/components/TextEditor/SHTextEditorWithToolbar.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useConflictingLogs } from "@/composables/useConflictingLogs";
import { useTipTap } from "@/composables/useTipTap";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import type { UploadedMedia } from "@/types";
import { faPersonDigging, faTruck } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { ResultOf } from "@graphql-typed-document-node/core";
import type { JSONContent } from "@tiptap/vue-3";
import { useMutation, useQuery } from "@urql/vue";
import { add, isAfter, startOfHour, sub } from "date-fns";
import { v4 } from "uuid";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();
const HOME_BASE_ID = "home_base";
const homeBaseWorkSite: WorkSiteChoice = {
  id: HOME_BASE_ID,
  title: "Home Base"
};
const currentUser = injectStrict(CurrentUserKey);

const { ticketId, travelLogId } = defineProps<{
  ticketId: string;
  travelLogId?: string;
}>();

defineEmits<{
  (e: "travel-log:create", value: ResultOf<ReturnType<typeof insert>>): void;
  (e: "travel-log:update", value: ResultOf<ReturnType<typeof update>>): void;
  (e: "travel-log:cancel"): void;
}>();

const {
  data: ticketData,
  error: ticketError,
  fetching: ticketFetching
} = await useQuery({
  query: graphql(/* GraphQL */ `
    query TravelLogFormTicket(
      $ticketId: uuid!
      $currentUserId: String!
      $oldestRelevantLog: timestamptz!
    ) {
      last_log: combined_logs(
        where: {
          author_id: { _eq: $currentUserId }
          ended_at: { _gte: $oldestRelevantLog }
          deleted_at: { _is_null: true }
        }
        limit: 1
        order_by: { ended_at: desc_nulls_last }
      ) {
        ended_at
      }

      tickets_by_pk(id: $ticketId) {
        id
        work_site {
          id
          title
        }

        previous_travel_log: travel_logs(
          where: { deleted_at: { _is_null: true } }
          limit: 1
          order_by: { arrived_at: desc }
        ) {
          id
        }

        work_order {
          customer_id
          customer {
            price_book {
              travel_prices(
                where: { valid_from: { _lte: "now()" } }
                limit: 1
                order_by: { valid_from: desc }
              ) {
                id
              }
            }
          }
        }
      }

      current_user: users_by_pk(id: $currentUserId) {
        previous_travel_log: travel_logs(
          where: { deleted_at: { _is_null: true } }
          limit: 1
          order_by: { arrived_at: desc }
        ) {
          arrived_at
          from_work_site {
            id
            title
          }
          to_work_site {
            id
            title
          }
          miles_traveled
        }
      }
    }
  `),
  variables: computed(() => ({
    ticketId: ticketId ?? "",
    currentUserId: currentUser?.value.id || "",
    oldestRelevantLog: sub(new Date(), { hours: 12 })
  }))
});

const ticket = computed(() => ticketData.value?.tickets_by_pk);

const customerId = computed(
  () => ticketData.value?.tickets_by_pk?.work_order.customer_id
);

const { data: travelLogData, fetching: travelLogFetching } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TravelLogFormTravelLog($travelLogId: uuid!) {
      travel_logs_by_pk(id: $travelLogId) {
        id
        author_id
        notes
        notesj
        departed_at
        arrived_at
        miles_traveled
        deleted_at

        media_uploads(
          where: { deleted_at: { _is_null: true } }
          order_by: [{ created_at: asc }]
        ) {
          id
          ...MediaUploadCard
          ...SHLightbox
        }

        to_work_site {
          id
          title

          customer {
            title
          }
        }
        to_work_site_unconfirmed

        from_work_site {
          id
          title

          customer {
            title
          }
        }
        from_work_site_unconfirmed
      }
    }
  `),
  variables: computed(() => ({
    travelLogId: travelLogId ?? ""
  })),
  pause: !travelLogId
});

const travelLog = computed(() =>
  travelLogData.value?.travel_logs_by_pk
    ? {
        ...travelLogData.value.travel_logs_by_pk,
        from_work_site: travelLogData?.value?.travel_logs_by_pk?.from_work_site
          ? travelLogData?.value?.travel_logs_by_pk?.from_work_site
          : homeBaseWorkSite,
        to_work_site: travelLogData?.value?.travel_logs_by_pk?.to_work_site
          ? travelLogData?.value?.travel_logs_by_pk?.to_work_site
          : homeBaseWorkSite
      }
    : null
);

const form = ref<{
  id?: string;
  notes?: string;
  notesj?: JSONContent | null;
  mediaUploads: UploadedMedia[];
  departed_at?: Date;
  arrived_at?: Date;
  to_work_site?: WorkSiteChoice;
  to_work_site_unconfirmed?: string | null;
  from_work_site?: WorkSiteChoice;
  from_work_site_unconfirmed?: string | null;
  miles_traveled?: number;
}>({
  id: travelLogId ? undefined : v4(),
  departed_at: undefined,
  arrived_at: undefined,
  mediaUploads: travelLogData.value?.travel_logs_by_pk?.media_uploads ?? []
});

const previousUserTravelLog = computed(() => {
  return ticketData.value?.current_user?.previous_travel_log.at(0);
});

const previousTicketTravelLog = computed(() => {
  return ticketData.value?.tickets_by_pk?.previous_travel_log.at(0);
});

const selectedDepartureSite = computed({
  get(): WorkSiteChoice {
    return (
      form.value.from_work_site ||
      travelLog.value?.from_work_site ||
      previousUserTravelLog.value?.to_work_site ||
      homeBaseWorkSite
    );
  },
  set(newValue: WorkSiteChoice) {
    form.value.from_work_site = newValue;
  }
});

const departureSiteUnconfirmed = computed({
  get() {
    if (selectedDepartureSite.value.title === "TBD") {
      return (
        form.value.from_work_site_unconfirmed ||
        travelLog.value?.from_work_site_unconfirmed ||
        null
      );
    }

    return null;
  },
  set(newValue: string | null) {
    form.value.from_work_site_unconfirmed = newValue;
  }
});

const selectedArrivalSite = computed({
  get() {
    return (
      (form.value?.to_work_site as WorkSiteChoice) ||
      (travelLog.value?.to_work_site as WorkSiteChoice) ||
      (previousTicketTravelLog.value
        ? homeBaseWorkSite // Home Base
        : ticket.value?.work_site) ||
      homeBaseWorkSite
    );
  },
  set(newValue: WorkSiteChoice) {
    form.value.to_work_site = newValue;
  }
});

const arrivalSiteUnconfirmed = computed({
  get() {
    if (selectedArrivalSite.value.title === "TBD") {
      return (
        form.value.to_work_site_unconfirmed ||
        travelLog.value?.to_work_site_unconfirmed ||
        null
      );
    }
    return null;
  },
  set(newValue: string | null) {
    form.value.to_work_site_unconfirmed = newValue;
  }
});

const { executeMutation: update } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateTravelLog(
      $travelLogId: uuid!
      $travelLog: travel_logs_set_input
    ) {
      update_travel_logs_by_pk(
        pk_columns: { id: $travelLogId }
        _set: $travelLog
      ) {
        id
      }
    }
  `)
);

async function onUpdate() {
  if (!travelLogId) {
    throw new ReferenceError("No travel log to update.");
  }

  const { data, error } = await update({
    travelLogId: travelLogId,
    travelLog: {
      miles_traveled: form.value.miles_traveled,
      notesj: form.value.notesj,
      departed_at: departureDateTime.value,
      arrived_at: arrivalDateTime.value,
      to_work_site_id:
        selectedArrivalSite.value.id === HOME_BASE_ID
          ? null
          : selectedArrivalSite.value.id,
      to_work_site_unconfirmed: arrivalSiteUnconfirmed.value,
      from_work_site_id:
        selectedDepartureSite.value.id === HOME_BASE_ID
          ? null
          : selectedDepartureSite.value.id,
      from_work_site_unconfirmed: departureSiteUnconfirmed.value
    }
  });

  if (error || !data?.update_travel_logs_by_pk) {
    createToast({
      title: "Unable to save the mileage.",
      message: error?.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (data.update_travel_logs_by_pk) {
    createToast({
      message: `Mileage updated.`,
      theme: "success"
    });
    router.go(-1);
  }
}

const { executeMutation: insert } = useMutation(
  graphql(/* GraphQL */ `
    mutation InsertTravelLog($travelLog: travel_logs_insert_input!) {
      insert_travel_logs_one(object: $travelLog) {
        id
        ticket_id
      }
    }
  `)
);

async function onInsert() {
  const { data, error } = await insert({
    travelLog: {
      ticket_id: ticketId,
      id: form.value.id,
      notesj: form.value.notesj,
      miles_traveled: form.value.miles_traveled,
      departed_at: departureDateTime.value,
      arrived_at: arrivalDateTime.value,
      to_work_site_id:
        selectedArrivalSite.value?.id === HOME_BASE_ID
          ? null
          : selectedArrivalSite.value.id,
      to_work_site_unconfirmed: arrivalSiteUnconfirmed.value,
      from_work_site_id:
        selectedDepartureSite.value?.id === HOME_BASE_ID
          ? null
          : selectedDepartureSite.value.id,
      from_work_site_unconfirmed: departureSiteUnconfirmed.value
    }
  });

  if (error) {
    createToast({
      title: "Unable to add the mileage.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (data) {
    createToast({
      message: `Mileage added.`,
      theme: "success"
    });
    router.go(-1);
  }
}

function onConfirm() {
  if (travelLogId) {
    return onUpdate();
  }
  return onInsert();
}

const notes = computed<JSONContent | null | undefined>({
  get() {
    return travelLog.value?.notesj || form.value.notesj;
  },
  set(val) {
    form.value.notesj = val;
  }
});

const editor = useTipTap(notes);

const departureDateTime = computed({
  get() {
    if (form.value.departed_at) return form.value.departed_at; // they changed the form manually
    if (travelLog.value?.departed_at)
      return new Date(travelLog.value?.departed_at); // they didn't change the form, we're editing

    const lastLog = ticketData.value?.last_log[0];

    return lastLog?.ended_at
      ? new Date(lastLog.ended_at)
      : startOfHour(new Date());
  },
  set(newDateAndTime) {
    form.value.departed_at = newDateAndTime;
  }
});

const arrivalDateTime = computed({
  get() {
    return (
      form.value?.arrived_at ||
      (travelLog.value?.arrived_at
        ? new Date(travelLog.value.arrived_at)
        : add(departureDateTime.value, { minutes: 30 }))
    );
  },
  set(newDateAndTime) {
    form.value.arrived_at = newDateAndTime;
  }
});

const milesTraveled = computed({
  get() {
    return form.value?.miles_traveled || travelLog.value?.miles_traveled || 0;
  },
  set(newMileageValue) {
    form.value.miles_traveled = newMileageValue;
  }
});

const hasInvalidTimeFrame = computed(
  () =>
    departureDateTime.value &&
    arrivalDateTime.value &&
    isAfter(departureDateTime.value, arrivalDateTime.value)
);

const conflictingAuthorId = computed(
  () => travelLog.value?.author_id ?? currentUser.value.id
);

const {
  isConflicting,
  conflictingRanges,
  fetching: conflictingLogsFetching,
  activeConflicts
} = useConflictingLogs(
  conflictingAuthorId,
  travelLogId,
  departureDateTime,
  arrivalDateTime,
  travelLogFetching
);

const currentLogRange = computed<TimeSliderRange>(() => {
  return {
    start: departureDateTime.value,
    end: arrivalDateTime.value,
    theme: "secondary"
  };
});

const hasDifferentSitesSelected = computed(
  () =>
    (selectedDepartureSite.value.title === "TBD" &&
      selectedArrivalSite.value.title === "TBD") ||
    selectedArrivalSite.value.id !== selectedDepartureSite.value.id
);

const isValid = computed(
  () =>
    hasDifferentSitesSelected.value &&
    !hasInvalidTimeFrame.value &&
    !isConflicting.value &&
    (selectedDepartureSite.value.title !== "TBD" ||
      !!departureSiteUnconfirmed.value) &&
    (selectedArrivalSite.value.title !== "TBD" ||
      !!arrivalSiteUnconfirmed.value)
);
</script>

<template>
  <article>
    <SHSpinner v-if="travelLogFetching || ticketFetching" />
    <SHNote v-else-if="ticketError" theme="danger">
      {{ ticketError }}
    </SHNote>
    <section v-else class="vertical loose">
      <SHField label="Departure Date" block>
        <SHDatePicker
          v-model="departureDateTime"
          auto-apply
          position="left"
          class="vdp"
          enable-time-picker
          format="MMM dd, yyyy"
        />
      </SHField>

      <SHField label="Departure Time" style="flex-grow: 1">
        <SHTimeInput
          v-model="departureDateTime"
          :minute-resolution="15"
          :ranges="[...conflictingRanges, currentLogRange]"
        >
          <template #toolTip>
            <AppLink
              v-if="activeConflicts.length"
              :to="{
                name: activeConflicts[0].work_log_id
                  ? 'WorkLogEdit'
                  : 'TravelLogEdit',
                params: {
                  ticketId: activeConflicts[0].ticket_id,
                  travelLogId: activeConflicts[0].travel_log_id,
                  workLogId: activeConflicts[0].work_log_id
                }
              }"
            >
              Edit log

              <FontAwesomeIcon
                v-if="activeConflicts[0].work_log_id"
                :icon="faPersonDigging"
              />

              <FontAwesomeIcon
                v-if="activeConflicts[0].travel_log_id"
                :icon="faTruck"
              />
            </AppLink>
          </template>
        </SHTimeInput>
      </SHField>

      <SHField label="Departure Site">
        <SHWorkSiteChooser
          v-model="selectedDepartureSite"
          :customer-id="customerId"
          :disabled="!customerId"
          include-home-base
        />
      </SHField>
      <SHField
        v-if="selectedDepartureSite.title === 'TBD'"
        label="Departure Worksite Name (best guess)"
      >
        <SHInput v-model="departureSiteUnconfirmed" />
      </SHField>

      <SHField label="Arrival Date" block>
        <SHDatePicker
          v-model="arrivalDateTime"
          auto-apply
          position="left"
          class="vdp"
          enable-time-picker
          format="MMM dd, yyyy"
        />
      </SHField>

      <SHField label="Arrival Time" style="flex-grow: 1">
        <SHTimeInput
          v-model="arrivalDateTime"
          :minute-resolution="15"
          :ranges="[...conflictingRanges, currentLogRange]"
        >
          <template #toolTip>
            <AppLink
              v-if="activeConflicts.length"
              :to="{
                name: activeConflicts[0].work_log_id
                  ? 'WorkLogEdit'
                  : 'TravelLogEdit',
                params: {
                  ticketId: activeConflicts[0].ticket_id,
                  travelLogId: activeConflicts[0].travel_log_id,
                  workLogId: activeConflicts[0].work_log_id
                }
              }"
            >
              Edit log

              <FontAwesomeIcon
                v-if="activeConflicts[0].work_log_id"
                :icon="faPersonDigging"
              />

              <FontAwesomeIcon
                v-if="activeConflicts[0].travel_log_id"
                :icon="faTruck"
              />
            </AppLink>
          </template>
        </SHTimeInput>
      </SHField>

      <SHField label="Arrival Site">
        <SHWorkSiteChooser
          v-model="selectedArrivalSite"
          :customer-id="customerId"
          :disabled="!customerId"
          include-home-base
        />
      </SHField>
      <SHField
        v-if="selectedArrivalSite.title === 'TBD'"
        label="Destination Worksite Name (best guess)"
      >
        <SHInput v-model="arrivalSiteUnconfirmed" />
      </SHField>

      <SHField label="Mileage">
        <SHInput
          :model-value="milesTraveled"
          type="number"
          :min="0"
          @update:model-value="form.miles_traveled = Number($event)"
        />
      </SHField>

      <SHField label="Notes" block>
        <SHTextEditorWithToolbar
          v-if="editor"
          v-model="notes"
          v-model:media-uploads="form.mediaUploads"
          :file-path="`travel_logs/${form.id ?? travelLogId}`"
          :context="{ travel_log_id: form.id ?? travelLogId }"
          :editor="editor"
          editable
        />
      </SHField>

      <SHNote
        v-show="hasInvalidTimeFrame"
        theme="warning"
        style="text-align: left"
      >
        The Departure time must be later than the Arrival time.
      </SHNote>

      <UndoConfirm
        :confirm-enabled="isValid && !conflictingLogsFetching"
        @undo="$router.back"
        @confirm="onConfirm"
      />
      <SHNote
        v-show="!hasDifferentSitesSelected"
        theme="danger"
        style="text-align: left"
      >
        The Departure Site and the Arrival Site cannot be the same.
      </SHNote>
    </section>
  </article>
</template>
<style lang="scss" scoped>
.hide {
  visibility: hidden;
}
.show {
  visibility: visible;
}
</style>
