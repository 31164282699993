<script setup lang="ts" generic="T extends ListOption, M extends ListOption">
import AppLink from "@/components/AppLink.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import SHList from "@/components/SHList.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { useLogger } from "@/logger";
import type { ComponentInstance, ListOption } from "@/types";
import { faChevronRight, faTimes } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke, useFocusWithin } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";

const { log } = useLogger("SHCombo"); // eslint-disable-line @typescript-eslint/no-unused-vars

const { getOptionLabel = i => i?.label || i?.title || "", ...props } =
  defineProps<{
    options: T[];
    limit?: number;
    modelValue?: T | null | undefined;
    multiple?: M[] | undefined;
    search?: string;
    searchable?: boolean;
    getOptionLabel?: (i: T | M | null | undefined) => string;
    loading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    closeOnSelect?: boolean;
    compact?: boolean;
  }>();

const options = computed(() => props.options);

const emit = defineEmits<{
  (e: "select", value?: ListOption | null): void;
  (e: "open"): void;
  (e: "close"): void;
  (e: "update:model-value", value: ListOption | null): void;
  (e: "update:multiple", value: ListOption[]): void;
  (e: "update:search", value: string | undefined): void;
}>();

const modelValue = computed<T | null | undefined>({
  get() {
    return props.modelValue;
  },
  set(val) {
    if (props.closeOnSelect) dropdownComponent.value?.close();
    emit("update:model-value", val ?? null);
  }
});

const search = computed<string>({
  get() {
    return props.search ?? "";
  },
  set(val) {
    emit("update:search", val);
  }
});

const multiple = computed<Array<T | M> | undefined>({
  get() {
    return props.multiple;
  },
  set(val) {
    if (val) {
      emit("update:multiple", val);
    }
  }
});

const inputEl = ref<HTMLInputElement>();

const dropdownComponent = ref<InstanceType<typeof SHDropdown>>();
const dropdownEl = computed(() => dropdownComponent.value?.$el);
const menuEl = ref<ComponentInstance<typeof SHList<T>>>();

const { focused: inputFocused } = useFocusWithin(inputEl);
const { focused: dropdownFocused } = useFocusWithin(dropdownEl);

watch(inputFocused, val => val && inputEl.value?.select());

watch(dropdownFocused, val => {
  if (!val) {
    dropdownComponent.value?.close();
  }
});

onKeyStroke("Backspace", () => {
  if (!dropdownFocused.value) {
    return;
  }

  if (!search.value) {
    if (multiple.value) {
      emit("update:multiple", [
        ...multiple.value.slice(0, multiple.value.length - 1)
      ]);
    }
  }
});

onKeyStroke("Enter", () => {
  if (!dropdownFocused.value) {
    return;
  }

  if (!inputFocused.value) {
    return;
  }

  if (!options.value.length) {
    return;
  }

  if (menuEl.value?.isSelected(options.value[0])) {
    return;
  }

  if (multiple.value) {
    multiple.value = [...multiple.value, options.value[0]];
    // search.value = "";
  } else {
    modelValue.value = options.value[0];
    // search.value = "";
    dropdownComponent.value?.close();
  }
});

onKeyStroke(["ArrowUp", "ArrowDown"], () => {
  if (inputFocused.value) {
    menuEl.value?.focusItem(0);
  }
});

function onRemove(item: ListOption) {
  if (multiple.value) {
    multiple.value = multiple.value.filter(value => value !== item);
  }
}

const showInputElement = ref(!!multiple.value);

function handleFocusInput() {
  if (props.searchable) {
    showInputElement.value = true;
  }
  nextTick(() => inputEl.value?.focus());
}

function handleBlurInput() {
  showInputElement.value = false;
}

defineExpose({
  inputEl
});
</script>

<template>
  <article class="sh-combo">
    <SHDropdown
      ref="dropdownComponent"
      :disabled="disabled || loading"
      match-width
      :offset="-1"
      @open="$emit('open')"
      @close="$emit('close')"
    >
      <template #default="{ toggle, open, isOpen, renderedPlacement }">
        <div
          class="outer"
          :class="{ isOpen, disabled, compact, [renderedPlacement]: true }"
        >
          <div class="input-wrapper" @click="open">
            <template v-if="multiple">
              <div class="badges">
                <SHBadge
                  v-for="option in multiple.slice(0, 2)"
                  :key="option.id"
                  class="level loose"
                  color="var(--color-primary)"
                >
                  <AppLink @click.prevent="onRemove(option)">
                    <FontAwesomeIcon :icon="faTimes" fixed-width />
                  </AppLink>
                  <slot name="selected-option" v-bind="{ option }">
                    {{ getOptionLabel(option) }}
                  </slot>
                </SHBadge>
                <SHBadge v-if="multiple.length > 2">
                  {{ multiple.length - 2 }} more...
                </SHBadge>
                <input
                  ref="inputEl"
                  v-model="search"
                  type="text"
                  class="real-input"
                  @focus="open"
                />
              </div>
              <SHSpinner v-if="loading" size="sm" />
            </template>
            <template v-else>
              <span v-if="compact">
                <slot
                  name="selected-option"
                  v-bind="{ option: modelValue as T, placeholder }"
                >
                  {{ getOptionLabel(modelValue) || placeholder }}
                </slot>
              </span>
              <template v-else>
                <button
                  v-show="!showInputElement"
                  class="phony-input"
                  :disabled="disabled"
                  @focus="handleFocusInput"
                >
                  <slot
                    name="selected-option"
                    v-bind="{ option: modelValue as T, placeholder }"
                  >
                    {{ getOptionLabel(modelValue) || placeholder }}
                  </slot>
                </button>
              </template>
              <input
                v-show="showInputElement && searchable"
                ref="inputEl"
                v-model="search"
                :placeholder="placeholder || getOptionLabel(modelValue)"
                :disabled="disabled"
                type="text"
                v-bind="$attrs"
                class="real-input"
                @focus="open"
                @blur="handleBlurInput"
              />
              <SHSpinner v-show="loading" size="sm" />
            </template>
          </div>

          <button
            :disabled="disabled"
            tabindex="-1"
            class="arrow noprint"
            @click="toggle"
          >
            <FontAwesomeIcon
              fixed-width
              :rotation="isOpen ? 270 : 90"
              :icon="faChevronRight"
            />
          </button>
        </div>
      </template>
      <template #popup>
        <div class="list">
          <template v-if="options.length">
            <SHList
              ref="menuEl"
              v-model="modelValue"
              v-model:multiple="multiple"
              :options="options"
              :limit="limit"
              :get-option-label="getOptionLabel"
            >
              <template #item="{ item }">
                <slot name="option" v-bind="{ option: item as T }"></slot>
              </template>
            </SHList>
          </template>
          <template v-else-if="loading">
            <div class="spinner-container">
              <SHSpinner size="1x" />
            </div>
          </template>
          <template v-else-if="search">
            <span class="no-matches">No matches</span>
          </template>
          <template v-else>
            <span class="no-options">No options</span>
          </template>
        </div>
      </template>
    </SHDropdown>
  </article>
</template>

<style lang="scss" scoped>
.sh-combo {
  .outer {
    background: var(--color-surface-100);
    border-radius: var(--border-radius);
    border: thin solid var(--color-surface-200);
    display: flex;
    justify-content: space-between;
    min-width: 5em;

    &.compact {
      background: unset;
      border: 0;
      border-radius: 0;
      border-bottom: 1.75px solid var(--color-surface-200);
      .input-wrapper {
        padding: 0;
        border-right: 0;
      }
    }

    .arrow {
      &:hover {
        color: var(--color-secondary);
        cursor: pointer;
      }
      svg {
        transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }

    .input-wrapper {
      flex-grow: 1;
      display: flex;
      border-right: thin solid var(--color-surface-200);
      padding: 0.3em;

      overflow: hidden;
      white-space: nowrap;

      .badges {
        flex-grow: 1;
        display: flex;
        gap: 0.25em;
        align-items: center;
      }

      @media print {
        border-right: var(--border-right-width) solid var(--border-right-color);
        border-radius: var(--border-top-left-radius)
          var(--border-top-right-radius) var(--border-bottom-right-radius)
          var(--border-bottom-left-radius);
      }

      .phony-input {
        flex-grow: 1;
        text-align: left;
        color: var(--color-surface-700);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .real-input {
        flex-grow: 1;
        padding: 0 0.5em;
        background: none;
        color: var(--color-surface-700);
        border: 0;
        appearance: none;
        &:focus-visible {
          outline: none;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }

      .phony-input,
      .real-input {
        min-height: 1.5em;
      }
    }
    button {
      border: none;
      color: var(--color-surface-700);
      background: none;
      padding: 0 0.5em;
      transition: transform 150ms ease-in-out;

      &:active {
        outline: none;
        border: none;
        background: none;
      }
      &:disabled {
        cursor: not-allowed;
      }
      &:hover {
        cursor: pointer;
      }
    }

    &.isOpen {
      border-color: var(--color-secondary);
      border-radius: var(--border-radius);
      border-bottom-color: transparent;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &.top-start {
        border-radius: var(--border-radius);
        border-top-color: thin solid transparent;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      filter: grayscale(85%) brightness(0.7);
    }

    &:hover:not(.isOpen):not(.disabled) {
      // border-color: var(--color-primary-up-200);
      border-color: var(--color-secondary-up-200);
    }
  }

  :deep(.floating) {
    width: 100%;
    border: thin solid var(--color-secondary);
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);

    // the .floating window sets it's placement as a class "top-start", "bottom-end" etc... for where it ended up
    &.top-start {
      border-radius: var(--border-radius);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
