<script setup lang="ts">
import {
  Lifecycle_Statuses_Enum,
  Ticket_Statuses_Enum,
  Work_Order_Types_Enum,
  Order_By
} from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faRectanglesMixed } from "@fortawesome/sharp-light-svg-icons";

import AppLink from "@/components/AppLink.vue";
import AwaitingReviewTicketSummary from "./AwaitingReviewTicketSummary.vue";
import DashboardActions from "@/components/Dashboards/DashboardActions.vue";
import OverloadedAgentSummary from "@/components/Dashboards/OverloadedAgentSummary.vue";
import TicketSummary from "@/components/Dashboards/TicketSummary.vue";
import UnassignedAgentSummary from "@/components/Dashboards/UnassignedAgentSummary.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import WorkOrderSummary from "@/components/Dashboards/WorkOrderSummary.vue";

const currentUser = injectStrict(CurrentUserKey);
</script>

<template>
  <article class="dashboard-supervisor">
    <h1 class="level-spread">
      <div class="level tight">
        <FontAwesomeIcon
          :icon="faRectanglesMixed"
          style="color: var(--color-secondary)"
        />
        My Dashboard
      </div>
      <UserAvatar
        size="large"
        :seed-string="currentUser?.fname + ' ' + currentUser?.lname"
      />
    </h1>

    <DashboardActions view-all />

    <WorkOrderSummary
      :order-by="[{ updated_at: Order_By.Desc }]"
      :where="{
        deleted_at: { _is_null: true },
        work_order_type: { _eq: Work_Order_Types_Enum.Dedicated },
        tickets: {
          ticket_agents: {
            agent_id: { _eq: currentUser?.id || 'unknown user' }
          }
        },
        _or: [
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Open } },
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Grace } },
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Expiring } }
        ]
      }"
    >
      My Active Work Orders
      <template #seeall="{ total }">
        <AppLink :to="{ name: 'WorkOrdersView' }" color="surface-300">
          See all {{ total }} Work Orders
        </AppLink>
      </template>

      <template #none>
        <div>There are no active work orders.</div>
      </template>
    </WorkOrderSummary>

    <AwaitingReviewTicketSummary />

    <TicketSummary
      :order-by="[{ updated_at: Order_By.Desc }]"
      :where="{
        deleted_at: { _is_null: true },
        work_order: {
          work_order_type: { _eq: Work_Order_Types_Enum.Calloff }
        },
        _or: [
          // { ticket_status: { _eq: Ticket_Statuses_Enum.Open } },
          { ticket_status: { _eq: Ticket_Statuses_Enum.InProgress } }
        ]
      }"
    >
      Recent Calloff Activity
      <template #seeall="{ total }">
        <AppLink
          :to="{ name: 'TicketsView', query: { ticketType: 'calloff' } }"
          color="surface-300"
        >
          See all {{ total }} Calloff Tickets
        </AppLink>
      </template>

      <template #none>
        <div>There are no recent calloff activities.</div>
      </template>
    </TicketSummary>

    <OverloadedAgentSummary />

    <UnassignedAgentSummary />
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.dashboard-supervisor {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .quick-actions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5em;

    @include bp.phone-landscape {
      grid-template-columns: repeat(2, 1fr);
    }

    @include bp.laptop {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
