import { Extension } from "@tiptap/vue-3";
import { textInputRule } from "@tiptap/core";

export default Extension.create({
  addInputRules() {
    return [
      textInputRule({
        find: /ohm$/,
        replace: "Ω"
      })
    ];
  }
});
