<script setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type {
  Tickets_Updates,
  Travel_Logs_Updates,
  Work_Sites_Insert_Input
} from "@/generated/graphql";
import { useLogger } from "@/logger";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import TBDMatcher, { type TBDRow } from "@/components/TBDMatcher.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import WorkSiteForm from "@/components/WorkSiteForm.vue";

const router = useRouter();
const { createToast } = useToaster();

const { log } = useLogger("WorkSiteCreate"); // eslint-disable-line @typescript-eslint/no-unused-vars

const { workSiteId = "missing worksite", customerId } = defineProps<{
  customerId: string;
  workSiteId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query WorkSiteEdit($id: uuid!) {
      work_sites_by_pk(id: $id) {
        title
        ...WorkSiteForm
      }
    }
  `),
  variables: {
    id: workSiteId
  }
});

const workSite = computed(() => data.value?.work_sites_by_pk);

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateWorkSite(
      $id: uuid!
      $workSite: work_sites_set_input!
      $ticketUpdates: [tickets_updates!]!
      $travelLogUpdates: [travel_logs_updates!]!
    ) {
      update_work_sites_by_pk(pk_columns: { id: $id }, _set: $workSite) {
        id
      }
      update_tickets_many(updates: $ticketUpdates) {
        affected_rows
        returning {
          id
          ref
        }
      }
      update_travel_logs_many(updates: $travelLogUpdates) {
        affected_rows
      }
    }
  `)
);

const updates = ref<Work_Sites_Insert_Input>();

const onSubmit = async () => {
  if (!updates.value && !selectedGuesses.value.length) {
    return;
  }

  const ticketUpdates = selectedGuesses.value.reduce<Tickets_Updates[]>(
    (acc, cur) => {
      cur.tbds.forEach(tbd => {
        if (tbd.__typename === "tickets") {
          acc.push({
            where: {
              id: { _eq: tbd.id },
              deleted_at: { _is_null: true }
            },
            _set: {
              work_site_unconfirmed: null,
              work_site_id: workSiteId
            }
          });
        }
      });
      return acc;
    },
    []
  );
  const travelLogUpdates = selectedGuesses.value.reduce<Travel_Logs_Updates[]>(
    (acc, cur) => {
      cur.tbds.forEach(tbd => {
        if (tbd.__typename === "travel_logs") {
          acc.push({
            where: {
              id: { _eq: tbd.id },
              deleted_at: { _is_null: true }
            },
            _set: tbd.to_work_site_id
              ? {
                  to_work_site_unconfirmed: null,
                  to_work_site_id: workSiteId
                }
              : {
                  from_work_site_unconfirmed: null,
                  from_work_site_id: workSiteId
                }
          });
        }
      });
      return acc;
    },
    []
  );

  const { data, error } = await executeMutation({
    id: workSiteId,
    workSite: updates.value || {},
    ticketUpdates,
    travelLogUpdates
  });

  if (error) {
    createToast({
      title: "Unable to update the worksite.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (data) {
    createToast({
      message: "Worksite updated.",
      theme: "success"
    });

    return router.push({
      name: "WorkSiteDetail",
      params: { workSiteId: workSiteId }
    });
  }
};
const selectedGuesses = ref<TBDRow[]>([]);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="workSite" class="vertical">
    <h2>Edit Worksite</h2>

    <WorkSiteForm
      v-model:updates="updates"
      :work-site="workSite"
      :customer-id="customerId"
    />

    <div class="level-end">
      <div class="level tight">
        <SHButton @click="$router.back">Cancel</SHButton>
        <SHDropdown
          :disabled="!updates && !selectedGuesses.length"
          strategy="fixed"
          :offset="16"
        >
          <template #default="{ toggle }">
            <SHButton
              :disabled="!updates && !selectedGuesses.length"
              color="primary"
              @click="selectedGuesses.length ? toggle() : onSubmit()"
            >
              Update Worksite
            </SHButton>
          </template>
          <template #popup="{ toggle }">
            <div class="popup">
              <h3>Are you sure?</h3>
              <div class="level tight wrap">
                Replace TBD work site{{ selectedGuesses.length > 1 ? "s" : "" }}
                <SHBadge
                  v-for="sg of selectedGuesses"
                  :key="sg.id"
                  color="var(--color-surface-500)"
                >
                  {{ sg.guess }}
                </SHBadge>

                with
                <SHBadge color="var(--color-surface-500)">
                  {{ updates?.title || workSite?.title }}
                </SHBadge>
                ?
              </div>
              <UndoConfirm
                :confirm-enabled="!!updates || !!selectedGuesses.length"
                @undo="toggle"
                @confirm="onSubmit"
              >
                <template #confirm>Yes, update</template>
              </UndoConfirm>
            </div>
          </template>
        </SHDropdown>
      </div>
    </div>

    <TBDMatcher v-model="selectedGuesses" :customer-id="customerId" />
  </article>
</template>

<style lang="scss" scoped>
.popup {
  max-width: 97vw;
  border: 2px solid var(--color-warning);
  padding: var(--padding);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
</style>
