<script async setup lang="ts">
import { ref } from "vue";
import { Client, fetchExchange } from "@urql/vue";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";

const { log } = useLogger("OrganizationProvider");
const client = new Client({
  url: window.config.GRAPHQL_URL || import.meta.env.VITE_GRAPHQL_URL,
  exchanges: [fetchExchange]
});
const isLoading = ref(true);
const params = new URLSearchParams(window.location.search);

const { data, error } = await client.query(
  graphql(/* GraphQL */ `
    query OrganizationProvider($where: organizations_bool_exp!) {
      organizations(where: $where) {
        id
        short_name

        ...OrgLogo

        accent_color
        reply_to_address
        postal_address
      }
    }
  `),
  {
    where: {
      _or: [
        { id: { _eq: params.get("organization") ?? "" } },
        { organization_hosts: { id: { _eq: window.location.hostname } } }
      ]
    }
  }
);

const organization = ref(data?.organizations[0]);

if (!organization.value) {
  log("No Organization Found");
} else {
  log("Loaded organization", organization.value.id);
}
if (error) {
  log(error);
}
isLoading.value = false;
</script>
<template>
  <div v-if="!isLoading">
    <slot :organization="organization"></slot>
  </div>
</template>
