<script setup lang="ts">
import {
  Lifecycle_Statuses_Enum,
  Work_Order_Types_Enum,
  Order_By
} from "@/generated/graphql";

import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faRectanglesMixed } from "@fortawesome/sharp-light-svg-icons";
import DashboardActions from "@/components/Dashboards/DashboardActions.vue";
import OverloadedAgentSummary from "@/components/Dashboards/OverloadedAgentSummary.vue";
import AppLink from "@/components/AppLink.vue";
// import TicketSummary from "@/components/TicketSummary.vue";
import UnassignedAgentSummary from "@/components/Dashboards/UnassignedAgentSummary.vue";

import UserAvatar from "@/components/UserAvatar.vue";
import WorkOrderSummary from "@/components/Dashboards/WorkOrderSummary.vue";
import AwaitingReviewTicketSummary from "./AwaitingReviewTicketSummary.vue";

const currentUser = injectStrict(CurrentUserKey);
</script>

<template>
  <article class="dashboard-back-office">
    <h1 class="level-spread">
      <div class="level tight">
        <FontAwesomeIcon
          :icon="faRectanglesMixed"
          style="color: var(--color-secondary)"
        />
        My Dashboard
      </div>
      <UserAvatar
        size="large"
        :seed-string="currentUser?.fname + ' ' + currentUser?.lname"
      />
    </h1>

    <DashboardActions view-all />

    <WorkOrderSummary
      show-add-next-work-order
      :order-by="[{ updated_at: Order_By.Desc }]"
      :where="{
        deleted_at: { _is_null: true },
        work_order_type: { _eq: Work_Order_Types_Enum.Dedicated },
        _or: [
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Expiring } },
          { lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Grace } }
        ],
        _not: {
          customer: {
            work_orders: {
              work_order_type: { _eq: Work_Order_Types_Enum.Dedicated },
              lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Pending }
            }
          }
        }
      }"
    >
      Expiring Work Orders
      <template #seeall="{ total }">
        <AppLink :to="{ name: 'WorkOrdersView' }" color="surface-300">
          See all {{ total }} Work Orders
        </AppLink>
      </template>

      <template #none>
        <div>There are no expiring work orders.</div>
      </template>
    </WorkOrderSummary>

    <AwaitingReviewTicketSummary />

    <OverloadedAgentSummary />

    <UnassignedAgentSummary />
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.dashboard-back-office {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
