import type { TimeSliderRange } from "@/components/SHTimeInput/SHTimeSlider.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  datesWithinThreshold,
  isWithinIntervalExclusive
} from "@/lib/datetime";
import { useLogger } from "@/logger";
import { useMutation } from "@urql/vue";
import { format, parseISO, type Interval } from "date-fns";
import { v4 } from "uuid";
import { computed, toValue, type MaybeRefOrGetter } from "vue";

const SHIFT_SUGGESTION_THRESHOLD_MINUTES = 90;

export enum SuggestionActionEnum {
  Append = "append",
  Prepend = "prepend",
  ExtendLeft = "extend_left",
  ExtendRight = "extend_right",
  Create = "create"
}

const { log } = useLogger("useShiftSuggestions"); // eslint-disable-line @typescript-eslint/no-unused-vars

const fragment = graphql(/* GraphQL */ `
  fragment UseShiftSuggestions on combined_logs {
    id
    author_id

    author {
      ...UserLink
      full_name
    }

    work_site {
      ...WorkSiteLink
    }

    customer {
      ...CustomerLink
    }

    work_log {
      id
    }

    travel_log {
      id
    }

    work_site {
      title
    }

    ticket {
      id
      ...TicketLink
    }

    started_at
    ended_at
    travel_hours
    work_hours

    prev_shifts {
      hash
      started_at
      ended_at
    }

    next_shifts {
      hash
      started_at
      ended_at
    }
  }
`);

export function useShiftSuggestions(
  _logs: MaybeRefOrGetter<readonly FragmentType<typeof fragment>[]>
) {
  const { executeMutation } = useMutation(
    graphql(/* GraphQL */ `
      mutation UseShiftSuggestions_Execute(
        $inserts: [agent_shift_overrides_insert_input!]!
        $updates: [agent_shift_overrides_updates!]!
        $deletes: [String!]!
      ) {
        update_agent_shift_overrides_many(updates: $updates) {
          affected_rows
          returning {
            hash
          }
        }

        insert_agent_shift_overrides(objects: $inserts) {
          affected_rows
          returning {
            hash
          }
        }

        delete_agent_shift_overrides(where: { hash: { _in: $deletes } }) {
          affected_rows
          returning {
            hash
          }
        }
      }
    `)
  );

  const logs = computed(() => useFragment(fragment, toValue(_logs)));
  const sortedLogs = computed(() =>
    [...logs.value]
      .sort((a, b) => a.author!.full_name!.localeCompare(b.author!.full_name!))
      .sort(
        (a, b) =>
          parseISO(a.started_at!).getTime() - parseISO(b.started_at!).getTime()
      )
      .sort(
        (a, b) =>
          parseISO(a.ended_at!).getTime() - parseISO(b.ended_at!).getTime()
      )
  );

  const logGroups = computed(() =>
    sortedLogs.value.reduce(
      (groups, currentLog, idx, arr) => {
        const prevLog = arr[idx - 1];

        if (
          !prevLog ||
          !datesWithinThreshold(
            prevLog.ended_at!,
            currentLog.started_at!,
            SHIFT_SUGGESTION_THRESHOLD_MINUTES
          ) ||
          prevLog.author_id !== currentLog.author_id
        ) {
          groups.push([currentLog]);
        } else {
          const lastSuggestion = groups.at(-1);

          if (!lastSuggestion) {
            throw new Error("Unable to find last suggestion");
          }

          lastSuggestion.push(currentLog);
        }

        return groups;
      },
      [] as (typeof sortedLogs.value)[]
    )
  );

  const shiftSuggestions = computed(() =>
    logGroups.value.reduce(
      (suggestions, group) => {
        const firstLog = group[0];
        const prevShift = firstLog.prev_shifts?.[0];
        const lastLog = group[group.length - 1];
        const nextShift = lastLog.next_shifts?.[0];

        const innerShiftRight =
          lastLog.prev_shifts?.[0] &&
          isWithinIntervalExclusive(
            parseISO(lastLog.prev_shifts[0].ended_at!),
            {
              start: parseISO(firstLog.started_at!),
              end: parseISO(lastLog.ended_at!)
            }
          )
            ? lastLog.prev_shifts?.[0]
            : null;

        const innerShiftLeft =
          firstLog.next_shifts?.[0] &&
          isWithinIntervalExclusive(
            parseISO(firstLog.next_shifts[0].started_at!),
            {
              start: parseISO(firstLog.started_at!),
              end: parseISO(lastLog.ended_at!)
            }
          )
            ? firstLog.next_shifts?.[0]
            : null;

        const shouldExtendRight = !!innerShiftRight;

        const shouldExtendLeft = !!innerShiftLeft;

        const shouldAppend =
          prevShift &&
          datesWithinThreshold(
            prevShift.ended_at!,
            firstLog.started_at!,
            SHIFT_SUGGESTION_THRESHOLD_MINUTES
          );

        const shouldPrepend =
          nextShift &&
          datesWithinThreshold(
            lastLog.ended_at!,
            nextShift.started_at!,
            SHIFT_SUGGESTION_THRESHOLD_MINUTES
          );

        if (shouldExtendRight) {
          suggestions.push({
            action: SuggestionActionEnum.ExtendRight,
            agent: group[0].author,
            logs: group,
            ranges: [
              {
                start: parseISO(innerShiftRight.started_at!),
                end: parseISO(innerShiftRight.ended_at!)
              },
              {
                start: parseISO(firstLog.started_at!),
                end: parseISO(lastLog.ended_at!),
                theme: "success"
              }
            ],
            keyTimes: [
              {
                label: format(parseISO(innerShiftRight.started_at!), "hh:mm a"),
                value: parseISO(innerShiftRight.ended_at!)
              },
              {
                label: format(parseISO(lastLog.ended_at!), "hh:mm a"),
                value: parseISO(lastLog.ended_at!)
              }
            ],
            existingInterval: {
              start: parseISO(innerShiftRight.started_at!),
              end: parseISO(innerShiftRight.ended_at!)
            },
            newInterval: {
              start: parseISO(innerShiftRight.started_at!),
              end: parseISO(lastLog.ended_at!)
            },
            execute: () =>
              executeMutation({
                inserts: [],
                updates: [
                  {
                    where: { hash: { _eq: innerShiftRight.hash } },
                    _set: { ended_at: lastLog.ended_at }
                  }
                ],
                deletes: []
              })
          });
        }
        if (shouldExtendLeft) {
          suggestions.push({
            action: SuggestionActionEnum.ExtendLeft,
            agent: group[0].author,
            logs: group,
            ranges: [
              {
                start: parseISO(innerShiftLeft.started_at!),
                end: parseISO(innerShiftLeft.ended_at!)
              },
              {
                start: parseISO(firstLog.started_at!),
                end: parseISO(lastLog.ended_at!),
                theme: "success"
              }
            ],
            keyTimes: [
              {
                label: format(parseISO(innerShiftLeft.started_at!), "hh:mm a"),
                value: parseISO(innerShiftLeft.started_at!)
              },
              {
                label: format(parseISO(firstLog.started_at!), "hh:mm a"),
                value: parseISO(firstLog.started_at!)
              }
            ],
            existingInterval: {
              start: parseISO(innerShiftLeft.started_at!),
              end: parseISO(innerShiftLeft.ended_at!)
            },
            newInterval: {
              start: parseISO(firstLog.started_at!),
              end: parseISO(innerShiftLeft.ended_at!)
            },
            execute: () =>
              executeMutation({
                inserts: [],
                updates: [
                  {
                    where: { hash: { _eq: innerShiftLeft.hash } },
                    _set: { started_at: firstLog.started_at }
                  }
                ],
                deletes: []
              })
          });
        }
        if (shouldAppend) {
          suggestions.push({
            action: SuggestionActionEnum.Append,
            agent: group[0].author,
            logs: group,
            ranges: [
              {
                start: parseISO(prevShift.started_at!),
                end: parseISO(prevShift.ended_at!)
              },
              {
                start: parseISO(firstLog.started_at!),
                end: parseISO(lastLog.ended_at!),
                theme: "success"
              }
            ],
            keyTimes: [
              {
                label: format(parseISO(prevShift.started_at!), "hh:mm a"),
                value: parseISO(prevShift.started_at!)
              },
              {
                label: format(parseISO(prevShift.ended_at!), "hh:mm a"),
                value: parseISO(prevShift.ended_at!)
              },
              {
                label: format(parseISO(lastLog.ended_at!), "hh:mm a"),
                value: parseISO(lastLog.ended_at!)
              }
            ],
            existingInterval: {
              start: parseISO(prevShift.started_at!),
              end: parseISO(prevShift.ended_at!)
            },
            newInterval: {
              start: parseISO(prevShift.started_at!),
              end: parseISO(lastLog.ended_at!)
            },
            execute: () =>
              executeMutation({
                inserts: [],
                updates: [
                  {
                    where: { hash: { _eq: prevShift.hash } },
                    _set: { ended_at: lastLog.ended_at }
                  }
                ],
                deletes: []
              })
          });
        }
        if (shouldPrepend) {
          suggestions.push({
            action: SuggestionActionEnum.Prepend,
            agent: group[0].author,
            logs: group,
            ranges: [
              {
                start: parseISO(nextShift.started_at!),
                end: parseISO(nextShift.ended_at!)
              },
              {
                start: parseISO(firstLog.started_at!),
                end: parseISO(lastLog.ended_at!),
                theme: "success"
              }
            ],
            keyTimes: [
              {
                label: format(parseISO(nextShift.started_at!), "hh:mm a"),
                value: parseISO(nextShift.started_at!)
              },
              {
                label: format(parseISO(lastLog.ended_at!), "hh:mm a"),
                value: parseISO(firstLog.started_at!)
              }
            ],
            existingInterval: {
              start: parseISO(nextShift.started_at!),
              end: parseISO(nextShift.ended_at!)
            },
            newInterval: {
              start: parseISO(firstLog.started_at!),
              end: parseISO(nextShift.ended_at!)
            },
            execute: () =>
              executeMutation({
                inserts: [],
                updates: [
                  {
                    where: { hash: { _eq: nextShift.hash } },
                    _set: { started_at: firstLog.started_at }
                  }
                ],
                deletes: []
              })
          });
        }
        suggestions.push({
          action: SuggestionActionEnum.Create,
          agent: group[0].author,
          logs: group,
          ranges: group.map(log => ({
            start: parseISO(log.started_at!),
            end: parseISO(log.ended_at!),
            theme: "success"
          })),
          keyTimes: [
            {
              label: format(parseISO(firstLog.started_at!), "hh:mm a"),
              value: parseISO(firstLog.started_at!)
            },
            {
              label: format(parseISO(lastLog.ended_at!), "hh:mm a"),
              value: parseISO(lastLog.ended_at!)
            }
          ],
          newInterval: {
            start: parseISO(firstLog.started_at!),
            end: parseISO(lastLog.ended_at!)
          },
          execute: () =>
            executeMutation({
              inserts: [
                {
                  id: v4(),
                  agent_id: firstLog.author_id,
                  started_at: firstLog.started_at,
                  ended_at: lastLog.ended_at
                }
              ],
              updates: [],
              deletes: []
            })
        });

        return suggestions;
      },
      [] as {
        action: SuggestionActionEnum;
        agent: (typeof sortedLogs.value)[number]["author"];
        logs: typeof sortedLogs.value;
        ranges: TimeSliderRange[];
        keyTimes?: { label: string; value: Date }[];
        existingInterval?: Interval;
        newInterval: Interval;
        execute: () => ReturnType<typeof executeMutation>;
      }[]
    )
  );

  return {
    shiftSuggestions
  };
}

export type AgentShiftSuggestion = ReturnType<
  typeof useShiftSuggestions
>["shiftSuggestions"]["value"][number];
