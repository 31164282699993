import type { RouteRecordRaw } from "vue-router";
import TicketForm from "@/views/TicketForm.vue";
import ModuleHeader from "@/components/ModuleHeader.vue";
import { h } from "vue";
import { faClipboardListCheck } from "@fortawesome/sharp-light-svg-icons";
import { faCalendar } from "@fortawesome/sharp-light-svg-icons";
import TicketsView from "@/views/TicketsView.vue";
import { useRole } from "@/composables/useRole";

const { can } = useRole();

export const ticketRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "CustomerTicketsView",
    components: {
      default: TicketsView,
      header: h(
        ModuleHeader,
        { icon: faClipboardListCheck },
        () => "Customer Tickets"
      )
    },
    props: {
      default(route) {
        return {
          ...route.params,
          noMargin: true,
          showAddTicket: can("???")
        };
      }
    }
  },
  {
    path: "new",
    name: "CustomerTicketCreate",
    components: {
      default: TicketForm,
      header: h(
        ModuleHeader,
        { icon: faCalendar },
        () => "Create New Ticket (Call-Off)"
      )
    },
    props: {
      default(route) {
        return {
          ...route.params,
          noMargin: true
        };
      }
    }
  }
];
