import ModuleHeader from "@/components/ModuleHeader.vue";
import ExpensesView from "@/views/ExpensesView.vue";
import { faFileInvoiceDollar } from "@fortawesome/sharp-light-svg-icons";
import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";

export const expenseRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "ExpensesView",
    components: {
      default: ExpensesView,
      header: h(
        ModuleHeader,
        { icon: faFileInvoiceDollar },
        {
          default: () => "Expenses"
        }
      )
    }
  }
];
