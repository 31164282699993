<script setup lang="ts">
import ReportFooter from "@/components/ReportFooter.vue";
import ReportHeader from "@/components/ReportHeader.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHPill from "@/components/SHPill.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import { numberFormatter } from "@/formatters";
import type { FragmentType } from "@/generated";
import { graphql, useFragment } from "@/generated";
import type { ServiceReportFragment } from "@/generated/graphql";
import MediaUploadsView from "@/views/MediaUploadsView.vue";
import { faPersonDigging, faTruck } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { isBefore, isEqual, startOfDay } from "date-fns";
import { computed } from "vue";

const fragment = graphql(/* GraphQL */ `
  fragment ServiceReport on query_root {
    customers_by_pk(id: $customerId) {
      ...ReportHeaderCustomer
    }

    users_by_pk(id: $userId) {
      ...ReportHeaderAgent
    }

    agent_shifts(
      where: {
        started_at: { _gte: $startDate, _lt: $endDate }
        agent_id: { _eq: $userId }
      }
    ) {
      time_sheet_entries(where: { customer_id: { _eq: $customerId } }) {
        started_at
        ended_at
        travel_miles
        total_hours

        work_logs {
          __typename
          id
          notesj
          started_at
          ended_at
          ticket {
            ref
            notesj
            product {
              title
            }
            work_site {
              title
            }
          }

          media_uploads(where: { deleted_at: { _is_null: true } })
            @include(if: $includeMedia) {
            id
            ...MediaUploadCard
            ...SHLightbox
          }
        }

        travel_logs {
          __typename
          id
          notesj
          started_at: departed_at
          ended_at: arrived_at
          miles_traveled

          ticket {
            ref
            notesj
            product {
              title
            }
            work_site {
              title
            }
          }
          media_uploads(where: { deleted_at: { _is_null: true } })
            @include(if: $includeMedia) {
            id
            ...MediaUploadCard
            ...SHLightbox
          }
        }
      }
    }
  }
`);

const props = defineProps<{
  query: FragmentType<typeof fragment>;
}>();

const timeSheetEntries = computed(
  () =>
    (useFragment(fragment, props.query).agent_shifts.flatMap(
      s => s.time_sheet_entries
    ) ?? []) as NonNullable<
      ServiceReportFragment["agent_shifts"][number]["time_sheet_entries"]
    >
);

const totalHours = computed(() =>
  timeSheetEntries.value.reduce((acc, tse) => acc + (tse.total_hours ?? 0), 0)
);

const totalMiles = computed(() =>
  timeSheetEntries.value.reduce((acc, tse) => acc + (tse.travel_miles ?? 0), 0)
);

const logs = computed(() =>
  [
    ...timeSheetEntries.value.flatMap(tse => tse.work_logs),
    ...timeSheetEntries.value.flatMap(tse => tse.travel_logs)
  ].sort((a, b) =>
    isBefore(new Date(a?.started_at ?? 0), new Date(b?.started_at ?? 0))
      ? -1
      : 1
  )
);

const customer = computed(
  () => useFragment(fragment, props.query).customers_by_pk
);

const agent = computed(() => useFragment(fragment, props.query).users_by_pk);

function isNewDay(
  logLeft: (typeof logs.value)[number] | undefined,
  logRight: (typeof logs.value)[number] | undefined
) {
  if (!logLeft?.started_at || !logRight?.started_at) return true;

  return !isEqual(
    startOfDay(new Date(logLeft.started_at)),
    startOfDay(new Date(logRight.started_at ?? 0))
  );
}

function isNewTicket(
  logLeft: (typeof logs.value)[number] | undefined,
  logRight: (typeof logs.value)[number] | undefined
) {
  return logLeft?.ticket?.ref !== logRight?.ticket?.ref;
}
</script>

<template>
  <article class="service-report">
    <ReportHeader
      v-if="customer && agent"
      title="Service Report"
      :customer="customer"
      :agent="agent"
    >
      Total Hours:
      <strong>
        {{ numberFormatter.format(totalHours) }}
      </strong>
      <br />
      Total Mileage
      <strong>
        {{ numberFormatter.format(totalMiles) }}
      </strong>
      <br />
      Generated on
      <SHInlineDate :d="new Date()" />
    </ReportHeader>

    <div class="vertical tight">
      <section
        v-for="(log, idx) in logs"
        :key="log?.id ?? 'no-id'"
        class="vertical"
      >
        <h2 v-if="isNewDay(logs[idx - 1], log)">
          <SHInlineDate format="long" :d="log?.started_at" />
        </h2>
        <article class="summary-row">
          <header v-if="isNewTicket(logs[idx - 1], log)" class="ticket-header">
            <h3 class="level-spread loose">
              <SHPill color="var(--color-surface-600)">
                <template #left>
                  {{ log?.ticket?.product.title }}
                </template>
                <template #right>#{{ log?.ticket?.ref }}</template>
              </SHPill>
              {{ log?.ticket?.work_site.title }}
            </h3>

            <SHTextEditor
              v-if="log?.ticket?.notesj"
              :model-value="log?.ticket?.notesj"
            />
          </header>

          <section class="vertical loose">
            <h4 class="level tight">
              <FontAwesomeIcon
                fixed-width
                :icon="
                  log?.__typename === 'work_logs' ? faPersonDigging : faTruck
                "
              />
              <span class="times">
                <SHInlineDate format="time" :d="log?.started_at" />
                -
                <SHInlineDate format="time" :d="log?.ended_at" />
              </span>
              <span v-if="log?.__typename === 'work_logs'" class="log">
                {{ log?.ticket.product.title }}
              </span>
              <span v-if="log?.__typename === 'travel_logs'" class="log">
                {{ numberFormatter.format(log?.miles_traveled ?? 0) }}mi
              </span>
            </h4>
            <SHTextEditor
              v-if="log?.notesj"
              style="margin-bottom: 1em"
              :model-value="log?.notesj"
            />
            <MediaUploadsView
              :media-uploads="log?.media_uploads ?? []"
              is-ssr
              hide-empty-message
              no-controls
            />
          </section>
        </article>
      </section>
    </div>

    <ReportFooter />
  </article>
</template>

<style lang="scss" scoped>
.service-report {
  background: var(--color-surface-0);

  h2 {
    font-size: 12pt;
  }

  h3 {
    font-size: 11pt;
  }

  font-size: 10pt;

  > section {
    h2 {
      margin-top: 0.25in;
      font-weight: 900;
      border-bottom: thin dashed var(--color-surface-300);
    }
    .summary-row {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .times {
        font-weight: 900;
      }
      .log {
        font-weight: 600;
      }
    }
  }
}
</style>
