<script setup lang="ts">
import { ref, inject } from "vue";
import { formatDate } from "@/formatters";
import type { VueDatePickerProps } from "@vuepic/vue-datepicker";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCalendar, faTimes } from "@fortawesome/sharp-light-svg-icons";
import VueDatePicker from "@vuepic/vue-datepicker";
import { DarkModeKey } from "@/providerKeys";
import SHButton from "@/components/SHButton.vue";
const { format = "eee, MMM d, yyyy" } = defineProps<
  Pick<
    VueDatePickerProps,
    | "position"
    | "clearable"
    | "autoApply"
    | "timePicker"
    | "monthPicker"
    | "maxDate"
  > & {
    format?: string;
    compact?: boolean;
  }
>();

const modelValue = defineModel<VueDatePickerProps["modelValue"]>();

const isDark = inject(DarkModeKey);

const vdp = ref();

const clear = () => {
  vdp.value.clearValue();
};
</script>

<template>
  <VueDatePicker
    ref="vdp"
    v-bind="($attrs, $props)"
    v-model="modelValue"
    :dark="isDark"
    :format="(d: Date) => formatDate(d, format)"
    position="left"
    six-weeks="fair"
    class="vdp"
    :class="{ compact: !!compact }"
  >
    <template #input-icon>
      <FontAwesomeIcon :icon="faCalendar" style="margin-left: 0.5em" />
    </template>
    <template #clear-icon>
      <SHButton link color="secondary" @click.stop="clear">
        <FontAwesomeIcon :icon="faTimes" style="margin-right: 0.5em" />
      </SHButton>
    </template>
    <template v-if="$slots.trigger" #trigger>
      <slot name="trigger"></slot>
    </template>
    <template v-if="$slots.leftSidebar" #left-sidebar>
      <slot name="left-sidebar"></slot>
    </template>
  </VueDatePicker>
</template>

<style lang="scss">
.vdp.dp__main,
.vdp .dp__menu {
  --button--border-width: 0;
  --button--box-shadow: none;

  --dp-background-color: var(--color-surface-100);
  --dp-text-color: var(--color-surface-900);
  --dp-hover-color: var(--color-primary-opacity-15);

  --dp-hover-text-color: var(--color-surface-900);
  --dp-hover-icon-color: #959595;
  --dp-primary-color: var(--color-primary);
  --dp-primary-text-color: var(--color-surface-900);
  --dp-secondary-color: var(--color-surface-300);
  --dp-border-color: var(--color-surface-200);
  --dp-menu-border-color: var(--color-primary);
  --dp-border-color-hover: var(--color-primary);
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: var(--color-success);
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: var(--color-secondary);
  --dp-danger-color: var(--color-danger);
  --dp-highlight-color: rgba(210, 50, 25, 0.5);

  --dp-font-family: var(--font-family-primary);

  .dp--menu-content-wrapper-collapsed {
    flex-direction: row;
  }

  box-shadow: unset;

  .dp__input {
    text-overflow: ellipsis;
    &:hover {
      border-color: var(--color-secondary);
    }
  }
  .dp__disabled {
    background: var(--color-surface-100);
  }
  .dp__range_between {
    border: 1px solid transparent;
  }
  .dp__calendar_header {
    color: var(--color-secondary);
  }
  .dp__cell_inner {
    &:hover {
      background-color: var(--color-secondary);
    }
  }
  .dp__sidebar_left {
    background: var(--color-surface-50);
  }

  &.compact {
    --dp-input-padding: 0;
    .dp__input {
      background: unset;
      border-width: 0;
      border-radius: 0;
      border-bottom-width: 1.75px;
    }
  }

  .dp__overlay_cell_disabled {
    color: var(--color-surface-300);
    background: var(--color-surface-200);
  }
}
</style>
