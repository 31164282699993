<script setup lang="ts">
import type { Customer_Contacts_Insert_Input } from "@/generated/graphql";

import CustomerContactForm from "@/components/CustomerContactForm.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import { useMutation } from "@urql/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const { log } = useLogger("CustomerContactCreate"); // eslint-disable-line @typescript-eslint/no-unused-vars

const { createToast } = useToaster();
const router = useRouter();

const { customerId } = defineProps<{
  customerId: string;
}>();

const InsertCompanyContactMutation = graphql(/* GraphQL */ `
  mutation InsertCompanyContactMutation(
    $object: customer_contacts_insert_input!
  ) {
    insert_customer_contacts_one(object: $object) {
      id
    }
  }
`);

const { executeMutation } = useMutation(InsertCompanyContactMutation);

const inserts = ref<Customer_Contacts_Insert_Input>();

const onSubmit = async () => {
  if (!inserts.value) {
    return;
  }

  const { data, error } = await executeMutation({
    object: {
      ...inserts.value,
      customer_id: customerId
    }
  });

  if (error) {
    createToast({
      title: "Unable to create the customer contact.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  }

  if (data) {
    createToast({
      message: "Contact created.",
      theme: "success"
    });
    router.replace({
      name: "CustomerContactDetail",
      params: { customerContactId: data.insert_customer_contacts_one?.id }
    });
  }
};
</script>

<template>
  <article class="vertical">
    <h3>Add Contact</h3>
    <CustomerContactForm v-model:inserts="inserts" />
    <UndoConfirm
      :confirm-enabled="!!inserts?.fname || !!inserts?.lname"
      @undo="$router.back"
      @confirm="onSubmit"
    />
  </article>
</template>
