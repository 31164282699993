<script setup lang="ts">
import { computed } from "vue";

const { theme } = defineProps<{
  theme: "primary" | "secondary" | "info" | "success" | "warning" | "danger";
}>();
const cssColor = computed(() => `var(--color-${theme}-down-400)`);
</script>

<template>
  <article class="sh-note">
    <slot />
  </article>
</template>

<style lang="scss" scoped>
article {
  padding: var(--padding);
  border-radius: var(--border-radius);
  color: var(--color-surface-900);
  background-color: v-bind(cssColor);
  box-shadow: var(--box-shadow);
}
</style>
