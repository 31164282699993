<script setup lang="ts">
import { graphql } from "@/generated";
import { useMutation } from "@urql/vue";
import { computed, ref } from "vue";

import ProductForm, {
  type ProductFormInserts
} from "@/components/ProductForm.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { useLogger } from "@/logger";
import { useRouter } from "vue-router";

const { log } = useLogger("ProductCreate"); // eslint-disable-line @typescript-eslint/no-unused-vars

const router = useRouter();
const { createToast } = useToaster();

const { catalogId } = defineProps<{
  catalogId: string;
}>();

const insertMutation = graphql(/* GraphQL */ `
  mutation InsertProduct($product: products_insert_input!) {
    insert_products_one(object: $product) {
      id
    }
  }
`);

const { executeMutation: insertProduct } = useMutation(insertMutation);

async function onSubmit() {
  if (!inserts.value) {
    return;
  }

  const { data: insertData, error: insertError } = await insertProduct({
    product: inserts.value.product
  });

  if (insertError) {
    createToast({
      title: `Unable to add the product.`,
      message: insertError.message || "An error occurred.",
      theme: "danger",
      requiresInteraction: true
    });
  }

  if (insertData) {
    createToast({
      title: " Success",
      message: `The product was added successfully.`,
      theme: "success"
    });
    router.replace({
      name: "CatalogDetail"
    });
  }
}

const inserts = ref<ProductFormInserts>();

const isValid = computed(() => !!inserts.value?.product?.title);
</script>

<template>
  <article class="product-create vertical">
    <ProductForm v-model:inserts="inserts" :catalog-id="catalogId" />
    <section>
      <UndoConfirm
        class="level-spread"
        :confirm-enabled="isValid"
        @confirm="onSubmit"
        @undo="$router.back"
      />
    </section>
  </article>
</template>

<style lang="scss" scoped>
.product-create {
  section {
    padding: 0 0.5em;
  }
}
</style>
