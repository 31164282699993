import { graphql, type FragmentType, useFragment } from "@/generated";
import type { CustomerChoiceFragment } from "@/generated/graphql";

const fragment = graphql(/* GraphQL */ `
  fragment CustomerChoice on customers {
    id
    abbreviation
    title
    price_book_id
  }
`);

export type CustomerChoice = CustomerChoiceFragment;

/**
 * Overload the useFragment function to provide a more specific type for the agent choice fragment.
 * The overloads are written this make typescript work better when agent is null or undefined.
 */
export function useCustomerChoice(obj: FragmentType<typeof fragment>) {
  return useFragment(fragment, obj);
}
