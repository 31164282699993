<script setup lang="ts">
import { computed } from "vue";
import { useQuery } from "@urql/vue";
import { graphql } from "@/generated";
import CatalogCard from "@/components/CatalogCard.vue";

const { catalogId } = defineProps<{
  catalogId: string;
  productId?: string;
}>();

const CatalogDetailQuery = graphql(/* GraphQL */ `
  query CatalogDetail($id: uuid!) {
    catalogs_by_pk(id: $id) {
      title
      ...CatalogCard
    }
  }
`);

const { data } = useQuery({
  query: CatalogDetailQuery,
  variables: { id: catalogId }
});

const catalog = computed(() => data.value?.catalogs_by_pk);
</script>

<template>
  <article class="vertical loose xmargin">
    <CatalogCard v-if="catalog" :catalog="catalog" />
    <RouterView />
  </article>
</template>
