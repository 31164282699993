<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import { useRole } from "@/composables/useRole";
import {
  faArrowLeft,
  type IconDefinition
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { useRouter, type RouteLocationNamedRaw } from "vue-router";

const { label, icon, backRoute, allowedPermissions } = defineProps<{
  label?: string; // if you want to use a prop vs the label slot
  icon?: IconDefinition;
  backRoute?: RouteLocationNamedRaw;
  allowedPermissions?: string[]; // if the user has any of the listed permissions the slot will show
}>();

const router = useRouter();
const { can } = useRole();

function onBack() {
  if (backRoute) {
    return router.push(backRoute);
  }
  return router.go(-1);
}

const hasPermission = computed(
  () => !allowedPermissions?.length || allowedPermissions?.some(p => can(p))
);
</script>

<template>
  <article class="xmargin noprint">
    <header>
      <nav>
        <slot name="back">
          <AppLink @click="onBack">
            <FontAwesomeIcon :icon="faArrowLeft" />
          </AppLink>
        </slot>
        <FontAwesomeIcon
          v-if="icon"
          :icon="icon"
          color="var(--color-secondary)"
          size="sm"
        />
        <slot>{{ label }}</slot>
      </nav>

      <aside v-if="$slots.nav && hasPermission">
        <slot name="nav" />
      </aside>
    </header>
  </article>
</template>

<style lang="scss" scoped>
article {
  header {
    padding-top: 1em;
    padding-bottom: 1em;
    display: grid;
    min-height: 4em;
    gap: 0.25em;
    grid-template-columns: 2fr minmax(100px, 1fr);
    font-size: 1em;
    font-weight: 400;
    color: var(--color-surface-600);
    white-space: nowrap;

    nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25em;

      :slotted(h1) {
        font-size: 1em;
      }
    }
  }

  aside {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25em;
  }
}
</style>
