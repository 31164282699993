<script setup lang="ts">
import type {
  Tickets_Updates,
  Travel_Logs_Updates,
  Work_Sites_Insert_Input
} from "@/generated/graphql";

import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import TBDMatcher, { type TBDRow } from "@/components/TBDMatcher.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import WorkSiteForm from "@/components/WorkSiteForm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import { useMutation } from "@urql/vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const { createToast } = useToaster();
const router = useRouter();

const { log } = useLogger("WorkSiteCreate"); // eslint-disable-line @typescript-eslint/no-unused-vars

const { customerId } = defineProps<{
  customerId: string;
  tbdDescription?: string;
}>();

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation InsertWorkSite(
      $object: work_sites_insert_input!
      $ticketUpdates: [tickets_updates!]!
      $travelLogUpdates: [travel_logs_updates!]!
    ) {
      insert_work_sites_one(object: $object) {
        id
      }
      update_tickets_many(updates: $ticketUpdates) {
        affected_rows
        returning {
          id
          ref
        }
      }
      update_travel_logs_many(updates: $travelLogUpdates) {
        affected_rows
      }
    }
  `)
);

const inserts = ref<Work_Sites_Insert_Input>();

const onSubmit = async () => {
  if (!inserts.value) {
    return;
  }

  const ticketUpdates = selectedGuesses.value.reduce<Tickets_Updates[]>(
    (acc, cur) => {
      cur.tbds.forEach(tbd => {
        if (tbd.__typename === "tickets") {
          acc.push({
            where: {
              id: { _eq: tbd.id }
            },
            _set: {
              work_site_unconfirmed: null,
              work_site_id:
                inserts.value?.id || "unknown replacement work_site_id"
            }
          });
        }
      });
      return acc;
    },
    []
  );
  const travelLogUpdates = selectedGuesses.value.reduce<Travel_Logs_Updates[]>(
    (acc, cur) => {
      cur.tbds.forEach(tbd => {
        if (tbd.__typename === "travel_logs") {
          acc.push({
            where: {
              id: { _eq: tbd.id }
            },
            _set: tbd.to_work_site_id
              ? {
                  to_work_site_unconfirmed: null,
                  to_work_site_id:
                    inserts.value?.id || "unknown replacement work_site_id"
                }
              : {
                  from_work_site_unconfirmed: null,
                  from_work_site_id:
                    inserts.value?.id || "unknown replacement work_site_id"
                }
          });
        }
      });
      return acc;
    },
    []
  );

  const { data, error } = await executeMutation({
    object: {
      ...inserts.value,
      customer_id: customerId
    },
    ticketUpdates,
    travelLogUpdates
  });

  if (error) {
    createToast({
      title: "Unable to create the worksite.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  }

  if (data?.insert_work_sites_one?.id) {
    createToast({
      message: "Worksite created.",
      theme: "success"
    });

    return router.push({
      name: "WorkSiteDetail",
      params: { workSiteId: data.insert_work_sites_one.id }
    });
  }
};
const selectedGuesses = ref<TBDRow[]>([]);

const isValid = computed(() => {
  return !!inserts.value;
});
</script>

<template>
  <article class="vertical">
    <h2>New Worksite</h2>

    <WorkSiteForm v-model:inserts="inserts" :customer-id="customerId" />

    <div class="level-end">
      <div class="level tight">
        <SHButton @click="$router.back">Cancel</SHButton>
        <SHDropdown :disabled="!isValid" strategy="fixed" :offset="16">
          <template #default="{ toggle }">
            <SHButton
              :disabled="!isValid"
              color="primary"
              @click="selectedGuesses.length ? toggle() : onSubmit()"
            >
              Create Worksite
            </SHButton>
          </template>
          <template #popup="{ toggle }">
            <div class="popup">
              <h3>Are you sure?</h3>
              <div class="level tight wrap">
                Replace TBD work site{{ selectedGuesses.length > 1 ? "s" : "" }}
                <SHBadge
                  v-for="sg of selectedGuesses"
                  :key="sg.id"
                  color="var(--color-secondary)"
                >
                  {{ sg.guess }}
                </SHBadge>

                with
                <SHBadge color="var(--color-primary)">
                  {{ inserts?.title }}
                </SHBadge>
                ?
              </div>
              <UndoConfirm
                :confirm-enabled="isValid"
                @undo="toggle"
                @confirm="onSubmit"
              >
                <template #confirm>Yes, create</template>
              </UndoConfirm>
            </div>
          </template>
        </SHDropdown>
      </div>
    </div>

    <TBDMatcher
      v-model="selectedGuesses"
      :customer-id="customerId"
      :query="tbdDescription"
    />
  </article>
</template>

<style lang="scss" scoped>
.popup {
  max-width: 97vw;
  border: 2px solid var(--color-warning);
  padding: var(--padding);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
</style>
