<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import UserLink from "@/components/UserLink.vue";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import { useQuery } from "@urql/vue";
import { useIntersectionObserver } from "@vueuse/core";
import { computed, ref } from "vue";

const { log } = useLogger("CustomerAgentsView"); // eslint-disable-line @typescript-eslint/no-unused-vars
const recordsPerPage = ref(100);
const limit = ref(recordsPerPage.value);

const CustomerAgentsViewQuery = graphql(/* GraphQL */ `
  query CustomerAgentsViewQuery($where: customer_agents_bool_exp) {
    customer_agents(where: $where, order_by: [{ agent: { lname: asc } }]) {
      id: agent_id
      dedicated_since
      agent {
        id
        ...UserLink
      }
    }

    customer_agents_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    all_agents: users(
      order_by: [{ lname: asc_nulls_last }, { fname: asc_nulls_last }]
    ) {
      id
      email
      fname
      lname
    }
  }
`);

const { customerId } = defineProps<{
  customerId: string;
}>();

const { data, fetching } = useQuery({
  query: CustomerAgentsViewQuery,
  variables: {
    where: {
      customer_id: { _eq: customerId }
    }
  },
  context: { additionalTypenames: ["customer_agents", "users"] }
});
// const allAgents = computed(() => data.value?.all_agents ?? []);
const customerAgents = computed(() => data.value?.customer_agents ?? []);
const customerCount = computed(
  () => data.value?.customer_agents_aggregate.aggregate?.count || 0
);

const scrollMarker = ref();
const loadMore = () => {
  if (limit.value <= customerCount.value) limit.value += recordsPerPage.value;
};

useIntersectionObserver(scrollMarker, ([{ isIntersecting }]) => {
  if (isIntersecting) loadMore();
});
</script>

<template>
  <article class="vertical">
    <SHNote v-if="!customerAgents.length" theme="warning">
      No agents dedicated to this customer.
      <AppLink style="text-decoration: underline" :to="{ name: 'AgentsView' }">
        Find an agent
      </AppLink>
      .
    </SHNote>

    <SHTable
      v-else
      :rows="customerAgents"
      :columns="['Agent', 'Dedicated Since']"
    >
      <template #row="{ row }">
        <td><UserLink :user="row.agent" /></td>
        <td><SHInlineDate :d="row.dedicated_since" /></td>
      </template>

      <template #footer>
        <SHTableRowCount :count="customerCount" label="agent" />
      </template>
    </SHTable>
    <section v-if="!fetching && limit < customerCount" ref="scrollMarker" />
    <SHSpinner v-else-if="fetching" />
  </article>
</template>
<style lang="scss" scoped></style>
