import type {
  AuthenticateClientMutation,
  TicketDetailQuery
} from "@/generated/graphql";
import type { Auth0VueClient } from "@auth0/auth0-vue";
import type { Client } from "@urql/vue";
import type { InjectionKey, MaybeRef, Ref } from "vue";
import type { CreateToastFn } from "./composables/useToaster";
import type { MediaUploadContext, OrganizationUser } from "./types";

export const OrganizationKey: InjectionKey<
  Ref<
    | NonNullable<
        AuthenticateClientMutation["authenticate_client"]
      >["organization_agents"][number]["organization"]
    | undefined
  >
> = Symbol("organization");

export const AuthClientKey: InjectionKey<Auth0VueClient> = Symbol("authClient");

// this is the result of calling authenticate_client via GQL + postgres user info
export const CurrentUserKey: InjectionKey<Ref<OrganizationUser>> =
  Symbol("currentUser");

export const CurrentTicketKey: InjectionKey<
  Ref<TicketDetailQuery["tickets_by_pk"]>
> = Symbol("currentTicket");

export const RoleKey: InjectionKey<Ref<string>> = Symbol("role");

export const ClientKey: InjectionKey<Client> = Symbol("urqlClient");

export const DarkModeKey: InjectionKey<Ref<boolean>> = Symbol("darkMode");

export const ThemeKey: InjectionKey<Ref<string>> = Symbol("theme");

export const CreateToastKey: InjectionKey<CreateToastFn> =
  Symbol("createToast");

export const IsMobileKey: InjectionKey<Ref<boolean>> = Symbol("isMobile");

export const BreakPointsKey: InjectionKey<Ref<string[]>> =
  Symbol("BreakPointsKey");

export const UploadContextKey: InjectionKey<MaybeRef<MediaUploadContext>> =
  Symbol("uploadContext");

export const ImageProxySaltKey: InjectionKey<Ref<string>> =
  Symbol("imageProxySalt");

export const DisableFormKey: InjectionKey<Ref<boolean>> = Symbol("disableForm");
