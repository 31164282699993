<script setup lang="ts">
import { computed } from "vue";
import { graphql } from "@/generated/gql";
import { faCircleExclamation } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFragment, type FragmentType } from "@/generated";
import { useRole } from "@/composables/useRole";

import AppLink from "@/components/AppLink.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";

const fragment = graphql(/* GraphQL */ `
  fragment TBDWorkSiteLink on work_sites {
    id
    title
    customer_id
  }
`);

const props = defineProps<{
  workSite: FragmentType<typeof fragment>;
  guess?: string | null;
  disabled?: boolean;
}>();

const workSite = computed(() => useFragment(fragment, props.workSite));
const { can } = useRole();
</script>

<template>
  <article class="tbd-work-site-link">
    <SHDropdown
      :open-on-hover="!disabled && workSite.title === 'TBD'"
      strategy="fixed"
      :offset="6"
    >
      <AppLink
        :disabled="disabled"
        :to="{
          name: 'WorkSiteDetail',
          params: {
            customerId: workSite.customer_id,
            workSiteId: workSite.id
          }
        }"
      >
        <div class="level tight">
          {{ workSite.title }}
          <FontAwesomeIcon
            v-if="workSite.title === 'TBD'"
            :icon="faCircleExclamation"
            style="color: var(--color-danger)"
          />
        </div>
      </AppLink>

      <template #popup>
        <div class="popup vertical tight">
          <div class="level tight wrap">
            <header>Unconfirmed:</header>
            <aside>&ldquo;{{ guess || "N/A" }}&rdquo;</aside>
          </div>

          <SHButton
            v-if="can('work_sites:update')"
            color="primary"
            :to="{
              name: 'WorkSitesView',
              params: {
                customerId: workSite.customer_id
              },
              query: {
                query: guess
              }
            }"
          >
            Search existing sites
          </SHButton>

          <SHButton
            v-if="can('work_sites:create')"
            color="success"
            :to="{
              name: 'WorkSiteCreate',
              params: {
                customerId: workSite.customer_id
              },
              query: {
                tbdDescription: guess
              }
            }"
          >
            Create new site
          </SHButton>
        </div>
      </template>
    </SHDropdown>
  </article>
</template>

<style lang="scss" scoped>
article.tbd-work-site-link {
  .popup {
    padding: 0.5em;
    border-radius: var(--border-radius);
    background: var(--color-surface-0);
    box-shadow: var(--box-shadow);
    font-size: 1rem;

    header {
      color: var(--color-surface-400);
    }
    aside {
      font-weight: 600;
    }
  }
}
</style>
