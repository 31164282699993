<script setup lang="ts">
import { computed } from "vue";
import { formatDate, formatDateRelative } from "@/formatters";
import { faPen } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import DeleteWithConfirm from "./DeleteWithConfirm.vue";
import SHButton from "./SHButton.vue";

const {
  iconFgColor = "default",
  iconBgColor = "default",
  relative,
  start = new Date(),
  end
} = defineProps<{
  start?: Date | string | null;
  end?: Date | string | null;
  iconFgColor?: string;
  iconBgColor?: string;
  relative?: boolean;
  editable?: boolean;
}>();

defineEmits<{
  (e: "edit"): void;
  (e: "delete"): void;
}>();

const timeStr = computed(() => {
  if (relative) {
    return formatDateRelative(start);
  }
  const formatString = "hh:mma";
  if (start && end) {
    return `${formatDate(start, formatString)}-${formatDate(
      end,
      formatString
    )}`;
  } else {
    return formatDate(start, formatString);
  }
});

const dateStr = computed(() => formatDate(start, "E MMM d"));
</script>

<template>
  <article class="timeline-entry">
    <header :style="{ backgroundColor: iconBgColor, color: iconFgColor }">
      <slot name="icon" />
    </header>

    <caption v-if="start">
      <span class="number time">{{ timeStr }}</span>
      <span v-if="!relative" class="date">{{ dateStr }}</span>
    </caption>

    <main>
      <slot />
    </main>

    <aside v-if="$slots.detail" class="detail">
      <slot name="detail" />
    </aside>

    <aside v-if="editable" class="editable">
      <slot name="actions">
        <SHButton square color="primary" @click.stop="$emit('edit')">
          <FontAwesomeIcon :icon="faPen" fixed-width />
        </SHButton>
        <DeleteWithConfirm
          color="danger"
          :enabled="true"
          @delete="$emit('delete')"
        />
      </slot>
    </aside>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;
article.timeline-entry {
  display: grid;
  position: relative;
  grid-template-columns: 2em 1fr;
  align-items: baseline;
  gap: 0.25em;
  grid-template-areas:
    "icon date"
    ". summary "
    ". comments";

  @include bp.laptop {
    grid-template-columns: max-content max-content 1fr;
    grid-template-areas:
      "icon date summary"
      ". comments comments";
    column-gap: 0.5em;
    row-gap: 1em;

    > main {
      padding-left: 1em;
    }
    > aside.editable {
      top: 0 !important;
    }
  }

  > header {
    grid-area: icon;
    border-radius: 50%;
    width: 1.875em;
    height: 1.875em;
    color: var(--color-surface-0);
    background-color: var(--color-primary);

    text-align: center;
    :deep(svg) {
      vertical-align: middle;
      display: inline-block;
    }
  }

  > main {
    font-size: 0.875em;
    grid-area: summary;
    font-weight: 300;
    color: var(--color-surface-500);

    :deep(a) {
      font-weight: 600;
    }
  }

  > aside.detail {
    grid-area: comments;
    border-radius: var(--border-radius);
    background: var(--color-surface-100);
    padding: 0.5em 1em;
    font-size: 0.875em;
    position: relative;
  }

  > aside.editable {
    display: none;
    position: absolute;
    top: 1em;
    right: 0em;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0.25em;
    background: transparent;
  }

  &:hover {
    .detail {
      background: var(--color-primary-opacity-15);
    }
    .editable {
      display: flex;
    }
  }

  > caption {
    // border: thin solid orchid;
    grid-area: date;
    text-align: left;
    vertical-align: top;
    .time {
      color: var(--color-surface-900);
      font-weight: 500;
      margin-right: 0.25em;
    }
    .date {
      color: var(--color-surface-500);
      font-size: 0.875em;
      font-weight: 300;
    }
  }
}
</style>
