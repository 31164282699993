import { watch, type MaybeRef, ref } from "vue";

import { type JSONContent, useEditor } from "@tiptap/vue-3";

import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";
import History from "@tiptap/extension-history";
import DropCursor from "@tiptap/extension-dropcursor";
import GapCursor from "@tiptap/extension-gapcursor";

import { ClientKey } from "@/providerKeys";
import { injectStrict } from "@/lib/helpers";

import { Node as FileLinkNode } from "@/components/TextEditor/FileLinkNode.vue";
import UserMention from "@/components/TextEditor/mentions/UserMention";
import TicketMention from "@/components/TextEditor/mentions/TicketMention";
import WorkOrderMention from "@/components/TextEditor/mentions/WorkOrderMention";
import ReplaceSymbols from "@/components/TextEditor/inputRules/ReplaceSymbols";

/**
 * This function is wrapping the useEditor function provided by TipTap 3
 * It sets up a basic editor instance with the relevant extensions.
 */
export function useTipTap(value: MaybeRef<JSONContent | null | undefined>) {
  const client = injectStrict(ClientKey);

  const _value = ref(value);

  const editor = useEditor({
    extensions: [
      Document,
      Text,
      Paragraph,
      History,
      DropCursor,
      GapCursor,

      // Custom Extensions
      UserMention(client),
      TicketMention(client),
      WorkOrderMention(client),
      FileLinkNode,
      ReplaceSymbols
    ],
    editorProps: {
      handleDOMEvents: {
        drop: (_, e) => {
          // Disable default drop behavior because we will override it in other components.
          e.preventDefault();
        }
      }
    }
  });

  watch(_value, (val = null) => {
    const isSame =
      JSON.stringify(editor.value?.getJSON()) === JSON.stringify(val);

    if (isSame) {
      return;
    }

    editor.value?.commands.setContent(val, false);
  });

  watch(editor, () => {
    if (!editor.value) {
      return;
    }

    if (_value.value) {
      editor.value.commands.setContent(_value.value);
    }

    editor.value.on("update", ({ editor }) => {
      _value.value = editor.isEmpty ? null : editor.getJSON();
    });
  });

  return editor;
}
