<script setup lang="ts">
import SHTable from "@/components/SHTable.vue";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";
// import { faChevronRight } from "@fortawesome/sharp-light-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import QueryFilter from "@/components/QueryFilter.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { useFacets } from "@/composables/useFacets";
import type { OrderSet } from "@/composables/useQueryStringFilters";
import { Order_By, type Price_Books_Order_By } from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";

const filterViewMode = ref<CollectionView>(CollectionView.Table);

const orderByFilters: OrderSet<Price_Books_Order_By> = {
  defaultValue: "name",
  defaultOrderBy: Order_By.Asc,
  options: [
    {
      id: "name",
      title: "Name/Title",
      asc: [{ title: Order_By.Asc }],
      desc: [{ title: Order_By.Desc }]
    },
    {
      id: "author",
      title: "Author",
      asc: [{ author: { id: Order_By.Asc } }],
      desc: [{ author: { id: Order_By.Desc } }]
    },
    {
      id: "created_at",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "updated_at",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const {
  facetModels,
  resetFacets,
  searchString,
  sortOrder,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets([], orderByFilters);

const query = graphql(/* GraphQL */ `
  query PriceBooksView(
    $where: price_books_bool_exp
    $orderBy: [price_books_order_by!]
  ) {
    price_books(where: $where, order_by: $orderBy) {
      id
      title
      created_at
      author {
        id
      }
      product_prices_aggregate {
        aggregate {
          count(distinct: true, columns: product_id)
        }
      }
      customers_aggregate {
        aggregate {
          count
        }
      }
    }
    price_books_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

const { data, error, fetching } = useQuery({
  query,
  variables: computed(() => ({
    where: {
      deleted_at: { _is_null: true },
      title: {
        _ilike: searchString.value ? `%${searchString.value}%` : undefined
      }
    },
    orderBy: sortOrder.value
  })),
  context: { additionalTypenames: ["price_books"] }
});

const priceBooks = computed(() => data.value?.price_books ?? []);
const priceBookCount = computed(
  () => data.value?.price_books_aggregate.aggregate?.count || 0
);
</script>

<template>
  <article class="vertical xmargin">
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="priceBookCount"
      clear-all-filters
      no-calendar-view
      no-card-view
      no-table-view
      placeholder="Search by Name..."
      searchable
      @reset:facets="resetFacets"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Pricebook Filter</template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <template v-else>
      <SHTable
        :rows="priceBooks"
        clickable
        empty-message="No pricebooks found."
        @row:click="
          $router.push({
            name: 'PriceBookDetail',
            params: { priceBookId: $event.id }
          })
        "
      >
        <template #header>
          <th>Pricebook Name</th>
          <th style="text-align: right">Customers</th>
          <th style="text-align: right">Products</th>
          <!-- <th></th> -->
        </template>

        <template v-if="!error" #row="{ row }">
          <td>
            {{ row.title }}
          </td>
          <td style="text-align: right">
            {{ row.customers_aggregate.aggregate?.count || 0 }}
          </td>
          <td style="text-align: right">
            {{ row.product_prices_aggregate.aggregate?.count || 0 }}
          </td>
        </template>

        <template #footer>
          <SHTableRowCount :count="priceBookCount" label="pricebook" />
        </template>
      </SHTable>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.price-books-view {
  section {
    padding: 0 0.5em;
  }
}
</style>
