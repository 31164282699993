<script setup lang="ts">
import type { CreateToastFn, SHToastOptions } from "@/composables/useToaster";
import { useLogger } from "@/logger";
import { CreateToastKey } from "@/providerKeys";
import { useLocalStorage } from "@vueuse/core";
import { v4 } from "uuid";
import { onUnmounted, provide, ref } from "vue";
import SHToastCard from "./SHToastCard.vue";

const { log } = useLogger("SHToastCatcher"); // eslint-disable-line @typescript-eslint/no-unused-vars
const catcherEl = ref<HTMLElement>();

const timers = ref<number[]>([]);

type ToastEntry = {
  id: string;
  timerId: number;
  options: SHToastOptions;
};

// Serializable toasts that are persisted in localStorage
const persistedToasts = useLocalStorage<ToastEntry[]>("toasts", []);

// All toasts are stored in memory
const toasts = ref([...persistedToasts.value]);

const remove = (id: string) => {
  persistedToasts.value = persistedToasts.value.filter(e => e.id !== id);
  toasts.value = toasts.value.filter(e => e.id !== id);
};

const createToast: CreateToastFn = (options: SHToastOptions) => {
  const id = v4();
  log("creating", { id, options });
  // TODO: configure defaults for missing items in e.detail
  const lifetimeMS = options.lifetimeMS ?? 3000;
  options.requiresInteraction =
    options.theme === "danger" || options.requiresInteraction;
  // build removal timer
  let timerId = 0;
  if (!options.requiresInteraction) {
    timerId = window.setTimeout(() => remove(id), lifetimeMS);
    timers.value.push(timerId);
  }

  const entry: ToastEntry = {
    id,
    timerId,
    options
  };

  if (!("component" in options)) {
    persistedToasts.value.push(entry);
  }

  toasts.value.push(entry);

  return {
    close: () => remove(id)
  };
};

provide(CreateToastKey, createToast);

onUnmounted(() => {
  timers.value.map(t => window.clearTimeout(t));
  timers.value = [];
});
</script>

<template>
  <div ref="catcherEl" class="toast-catcher">
    <slot />
    <!-- display below the slot to ensure we're in a new stacking context-->
    <TransitionGroup
      v-if="toasts.length"
      name="toast"
      tag="div"
      class="column top-right"
    >
      <SHToastCard
        v-for="(s, i) of toasts"
        :id="s.id"
        :key="s.id"
        :data-index="i"
        v-bind="s.options"
        @toast:remove="remove($event)"
      />
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints" as bp;
div.toast-catcher {
  .column {
    padding: 0;
    position: fixed;
    z-index: 1;
    min-width: 20vw;
    grid-template-rows: max-content;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    align-content: flex-start;
    justify-items: stretch;

    @include bp.tablet {
      max-width: 50vw;
    }
    @include bp.desktop {
      max-width: 35vw;
    }

    &.top-right {
      background: transparent;
      top: 1em;
      right: 1em;
      @include bp.phone-only {
        align-content: flex-end;
        gap: 0;
        left: 0;
        right: 0;
        top: 50vh;
        min-height: 40vh;
      }
    }
  }
}
</style>
