<script async setup lang="ts">
import MediaUploadCard from "@/components/MediaUploadCard.vue";
import SHLightBox from "@/components/SHLightBox.vue";
import SHNote from "@/components/SHNote.vue";
import type {
  MediaObjectOrUploadTask,
  MediaUploadTask
} from "@/composables/useUpload";
import { graphql } from "@/generated";
import type { Media_Uploads_Bool_Exp } from "@/generated/graphql";
import type { UploadedMedia } from "@/types";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

const { viewMode = "md", ...props } = defineProps<{
  mediaUploads?: MediaObjectOrUploadTask[];
  noControls?: boolean;
  hideEmptyMessage?: boolean;
  userId?: string;
  customerId?: string;
  expenseLogId?: string;
  invoiceId?: string;
  productId?: string;
  ticketCustomFormEntryId?: string;
  ticketId?: string;
  travelLogId?: string;
  workLogId?: string;
  workOrderId?: string;
  workSiteId?: string;
  viewMode?: "sm" | "md" | "lg";
  isSsr?: boolean;
}>();

const emit = defineEmits<{
  (e: "upload:cancelled", upload: MediaUploadTask): void;
  (e: "upload:deleted", upload: UploadedMedia): void;
}>();

const relationshipExpressions = computed<Media_Uploads_Bool_Exp[]>(() => {
  const expressions: Media_Uploads_Bool_Exp[] = [];

  /**
   * Loop through all the props and create a relationship expression
   * based on provided foreign keys
   */
  for (const [_key, value] of Object.entries(props)) {
    // Typescript fails to infer the type of _key as keyof typeof props
    // so we have to cast it
    const key = _key as keyof typeof props;

    if (!value || typeof value !== "string") {
      continue;
    }

    switch (key) {
      case "ticketId":
        expressions.push(
          { ticket_id: { _eq: value } },
          { work_log: { ticket_id: { _eq: value } } },
          { travel_log: { ticket_id: { _eq: value } } },
          { expense_log: { ticket_id: { _eq: value } } },
          { ticket_custom_form_entry: { ticket_id: { _eq: value } } }
        );
        break;
      case "workSiteId":
        expressions.push(
          { work_site_id: { _eq: value } },
          { ticket: { work_site_id: { _eq: value } } },
          { work_log: { ticket: { work_site_id: { _eq: value } } } },
          { travel_log: { ticket: { work_site_id: { _eq: value } } } },
          { expense_log: { ticket: { work_site_id: { _eq: value } } } },
          {
            ticket_custom_form_entry: {
              ticket: { work_site_id: { _eq: value } }
            }
          }
        );
        break;

      // case "userId":
      //   break;

      // case "invoiceId":
      //   break;

      // case "productId":
      //   break;

      // case "workOrderId":
      //   break;

      // case "customerId":
      //   break;

      case "workLogId":
        expressions.push({ work_log_id: { _eq: value } });
        break;

      // case "travelLogId":
      //   break;

      // case "expenseLogId":
      //   break;

      case "ticketCustomFormEntryId":
        expressions.push({ ticket_custom_form_entry_id: { _eq: value } });
        break;

      // case "workSiteId":
      //   break;

      default:
        console.warn(`Unhandled prop ${key}`);
    }
  }

  return expressions;
});

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query MediaUploadsView($where: media_uploads_bool_exp!) {
      media_uploads(where: $where) {
        id
        ...MediaUploadCard
        ...SHLightbox
      }
    }
  `),
  context: {
    additionalTypenames: ["media_uploads"]
  },
  variables: computed(() => ({
    where: {
      deleted_at: { _is_null: true },
      _or: relationshipExpressions.value
    }
  })),
  pause: computed(() => !!props.mediaUploads || props.isSsr)
});

const allUploads = computed<MediaObjectOrUploadTask[]>(
  () => props.mediaUploads ?? data.value?.media_uploads ?? []
);

const updateCaption = ({ id, caption }: { id: string; caption: string }) => {
  const media = allUploads.value.find(u => u.id === id);
  if (media) media.caption = caption;
};

const lightbox = ref<InstanceType<typeof SHLightBox>>();
</script>

<template>
  <article class="media-uploads-view">
    <main v-if="allUploads.length" :class="{ [viewMode]: true }">
      <TransitionGroup name="fade">
        <MediaUploadCard
          v-for="(upload, i) in allUploads"
          :key="upload?.id || '?'"
          :media-upload="upload"
          :thumbnail-size="viewMode"
          :is-ssr="isSsr"
          :no-controls="noControls"
          @upload:deleted="emit('upload:deleted', $event)"
          @upload:cancelled="emit('upload:cancelled', $event)"
          @upload:caption="updateCaption"
          @image:clicked="lightbox?.open(i)"
        />
      </TransitionGroup>
    </main>

    <SHNote v-else-if="error" theme="danger">{{ error?.message }}</SHNote>

    <slot v-else-if="!hideEmptyMessage" name="empty">
      <SHNote theme="warning" class="no-uploads">
        No media uploads found.
      </SHNote>
    </slot>

    <SHLightBox
      v-if="!isSsr && allUploads?.length"
      ref="lightbox"
      :media-uploads="allUploads"
    />
  </article>
</template>

<style lang="scss" scoped>
.media-uploads-view {
  main {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;

    :deep(.sh-image) {
      max-height: 12em;
    }
  }

  .no-uploads {
    padding: 0.25rem;
    width: 100%;
  }
}
</style>
