<script async setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type {
  Feature_Flags,
  Org_Xref_Feature_Flags
} from "@/generated/graphql";
import { humanizeEnum } from "@/lib/helpers";
import type { ListOption } from "@/types";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import SHBadge from "./SHBadge.vue";
import SHNote from "./SHNote.vue";
import SHToggle from "./SHToggle.vue";

const { createToast } = useToaster();

/*
This query for some reason sets the type of `data` to `any`. Manual response types
used below to work around the codegen failing to generate the right
typescript bindings for this query.
*/

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query FeatureFlagEditor {
      hfns_org_get_enabled_flags(order_by: { feature_flag: asc }) {
        feature_flag
      }
      feature_flags(order_by: [{ value: asc }]) {
        value
      }
    }
  `),
  context: {
    additionalTypenames: ["feature_flags", "org_xref_feature_flags"]
  }
});

const enabledFeatures = computed<string[]>(() =>
  data.value?.hfns_org_get_enabled_flags.map(
    (ff: Org_Xref_Feature_Flags) => ff.feature_flag
  )
);
const allFeatureFlags = computed(() =>
  (data.value?.feature_flags || []).map(
    (ff: Feature_Flags): ListOption => ({
      id: ff.value,
      label: ff.value,
      value: ff.value
    })
  )
);
const { executeMutation: enableFeature } = useMutation(
  graphql(/* GraphQL */ `
    mutation EnableFeature($flag: String!) {
      hfns_org_enable_feature_flag(args: { _flag: $flag }) {
        feature_flag
      }
    }
  `)
);
const { executeMutation: disableFeature } = useMutation(
  graphql(/* GraphQL */ `
    mutation DisableFeature($flag: String!) {
      hfns_org_disable_feature_flag(args: { _flag: $flag }) {
        feature_flag
      }
    }
  `)
);

const isMutating = ref(false);
const toggleFeature = async (flag: string) => {
  isMutating.value = true;
  if (enabledFeatures.value?.includes(flag)) {
    const { data, error } = await disableFeature({ flag });
    if (error) {
      createToast({
        title: "Flag not disabled.",
        message: error.message,
        theme: "danger"
      });
    } else if (data) {
      createToast({
        message: `Flag ${data.hfns_org_disable_feature_flag.at(0)?.feature_flag} disabled.`,
        theme: "success"
      });
    }
  } else {
    const { data, error } = await enableFeature({ flag });
    if (error) {
      createToast({
        title: "Flag not enabled.",
        message: error.message,
        theme: "danger"
      });
    } else if (data) {
      createToast({
        message: `Feature ${data.hfns_org_enable_feature_flag.at(0)?.feature_flag} enabled.`,
        theme: "success"
      });
    }
  }
  isMutating.value = false;
};
</script>

<template>
  <article class="feature-flag-editor">
    <h2>
      Feature Flags
      <SHBadge color="var(--color-danger)">STAFF ONLY</SHBadge>
    </h2>
    <p>
      Only Spearhead admins may toggle flags for organizations. All toggles are
      applied immediately.
    </p>
    <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
    <div v-else class="list">
      <div v-for="ff of allFeatureFlags" :key="ff.id" class="flag">
        <span>
          <SHBadge color="var(--color-primary)">
            {{ humanizeEnum(ff.label || "unknown feature") }}
          </SHBadge>
        </span>
        <div class="level tight">
          DISABLED
          <SHToggle
            :disabled="isMutating"
            :model-value="enabledFeatures?.includes(ff.id)"
            @update:model-value="toggleFeature(ff.id)"
          />
          ENABLED
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.feature-flag-editor {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  border: thin solid var(--color-danger-down-200);
  padding: var(--padding);
  background: var(--color-surface-100);
  h2 {
    margin: 0;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    .flag {
      display: grid;
      grid-template-columns: 1fr min-content;
    }
  }
}
</style>
