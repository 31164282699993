import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlus, faUser, faUsers } from "@fortawesome/sharp-light-svg-icons";

import SHButton from "@/components/SHButton.vue";
import ModuleHeader from "@/components/ModuleHeader.vue";
import CustomerContactsView from "@/views/CustomerContactsView.vue";
import CustomerContactCreate from "@/views/CustomerContactCreate.vue";
import CustomerContactEdit from "@/views/CustomerContactEdit.vue";
import CustomerContactDetail from "@/views/CustomerContactDetail.vue";

export const contactRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "CustomerContactsView",
    components: {
      default: CustomerContactsView,
      header: h(
        ModuleHeader,
        { icon: faUsers },
        {
          default: () => "Customer Contacts",
          nav: () =>
            h(
              SHButton,
              {
                to: { name: "CustomerContactCreate" },
                color: "primary",
                size: "sm"
              },
              () => [
                h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                h(FontAwesomeIcon, { icon: faUser, fixedWidth: true })
              ]
            )
        }
      )
    },
    props: true
  },
  {
    path: "new",
    components: {
      default: CustomerContactCreate,
      header: h(ModuleHeader, { icon: faUser }, () => "Create New Contact")
    },
    name: "CustomerContactCreate",
    props: true
  },
  {
    path: ":customerContactId",
    components: {
      default: CustomerContactDetail,
      header: h(ModuleHeader, { icon: faUser }, () => "Customer Contact")
    },
    name: "CustomerContactDetail",
    props: true
  },
  {
    path: ":customerContactId/edit",
    components: {
      default: CustomerContactEdit,
      header: h(ModuleHeader, { icon: faUser }, () => "Edit Customer Contact")
    },
    name: "CustomerContactEdit",
    props: true
  }
];
