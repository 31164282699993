<script lang="ts">
export type ProductFormUpdates = {
  product: Maybe<Products_Set_Input>;
};

export type ProductFormInserts = {
  product: Products_Insert_Input;
};
</script>

<script setup lang="ts">
import {
  Product_Types_Enum,
  type Products_Insert_Input,
  type Products_Set_Input
} from "@/generated/graphql";

import { ref, watch } from "vue";

import { graphql, useFragment, type FragmentType } from "@/generated";

import { useLogger } from "@/logger";

import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHTextarea from "@/components/SHTextarea.vue";
import type { Maybe } from "graphql/jsutils/Maybe";
import { v4 } from "uuid";

const { log } = useLogger("ProductForm"); // eslint-disable-line @typescript-eslint/no-unused-vars

const fragment = graphql(/* GraphQL */ `
  fragment ProductForm on products {
    id
    catalog_id

    title
    description
    product_type
  }
`);

const {
  updates = { product: null },
  inserts = { product: null },
  ...props
} = defineProps<{
  catalogId: string;
  product?: FragmentType<typeof fragment>;
  updates?: ProductFormUpdates;
  inserts?: ProductFormInserts;
}>();

const emit = defineEmits<{
  (e: "update:updates", value: ProductFormUpdates): void;
  (e: "update:inserts", value: ProductFormInserts): void;
}>();

const product = useFragment(fragment, props.product);

type ProductForm = {
  id: string;
  catalogId?: string;
  title?: string;
  description?: string | null;
  product_type?: Product_Types_Enum;
};

const form = ref<ProductForm>({
  id: product?.id ?? v4(),
  catalogId: props.catalogId,

  title: product?.title,
  description: product?.description ?? undefined,
  product_type: product?.product_type ?? Product_Types_Enum.Service
});

watch(
  form,
  () => {
    emit("update:updates", {
      ...updates,

      product: {
        ...updates.product,
        title: form.value.title,
        description: form.value.description
      }
    });

    emit("update:inserts", {
      ...inserts,

      product: {
        id: form.value.id,
        catalog_id: form.value.catalogId,
        product_type: form.value.product_type,
        title: form.value.title,
        description: form.value.description
      }
    });
  },
  { deep: true }
);
</script>

<template>
  <article class="product-form vertical">
    <section class="vertical">
      <SHField label="Product Title">
        <SHInput
          :model-value="form.title"
          @update:model-value="form.title = $event"
        />
      </SHField>
      <SHField label="Description">
        <SHTextarea
          :rows="1"
          :model-value="form.description"
          @update:model-value="form.description = $event"
        />
      </SHField>
    </section>
  </article>
</template>

<style lang="scss" scoped>
.product-form {
  header {
    span {
      font-size: var(--font-size-xs);
    }
  }
}
</style>
