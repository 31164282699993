<script lang="ts">
import component from "./FileLinkNode.vue";
import { Node as TipTapNode } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import SHBadge from "../SHBadge.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPaperclipVertical } from "@fortawesome/sharp-light-svg-icons";

export const Node = TipTapNode.create({
  name: "fileLink",
  group: "inline",
  selectable: false,
  atom: true,
  isolating: true,
  inline: true,
  allowGapCursor: true,

  addAttributes() {
    return {
      label: {
        default: "File",
        parseHTML(el) {
          return {
            label: el.getAttribute("data-label")
          };
        }
      },
      url: {
        default: "",
        parseHTML: el => {
          return {
            url: el.getAttribute("data-url")
          };
        },
        renderHTML: attributes => ({
          "data-label": attributes.label,
          "data-url": attributes.url
        })
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-file-link]`
      }
    ];
  },

  renderHTML({ node }) {
    return ["a", { href: node.attrs.url }, node.attrs.label];
  },

  renderText({ node }) {
    return `${node.attrs.label}`;
  },

  addNodeView() {
    return VueNodeViewRenderer(component);
  }
});
</script>

<script setup lang="ts">
import { NodeViewWrapper, type NodeViewProps } from "@tiptap/vue-3";

const props = defineProps<{
  node: NodeViewProps["node"];
}>();

const { url, label } = props.node.attrs;
</script>

<template>
  <NodeViewWrapper class="file-link-node" as="span">
    <SHBadge color="var(--color-primary)" class="file-link">
      <a :href="url">
        <FontAwesomeIcon :icon="faPaperclipVertical" />
        {{ label }}
      </a>
    </SHBadge>
  </NodeViewWrapper>
</template>

<style lang="scss" scoped>
.file-link {
  display: inline;
}
</style>
