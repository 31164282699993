<script setup lang="ts">
import type { SetOrganizationAgentPriceMutationVariables } from "@/generated/graphql";

import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import { formatUSD } from "@/formatters";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { IsMobileKey, OrganizationKey } from "@/providerKeys";
import { faBadgeDollar } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { endOfDay, isEqual, sub } from "date-fns";
import { computed, reactive } from "vue";

import AgentActivityChart from "@/components/AgentActivityChart.vue";
import AppLink from "@/components/AppLink.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHDollarInput from "@/components/SHDollarInput.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";

const { agentId } = defineProps<{
  agentId: string;
}>();

const { can } = useRole();
const { createToast } = useToaster();
const organization = injectStrict(OrganizationKey);

const {
  data: agentData,
  fetching: agentFetching,
  error: agentError
} = useQuery({
  query: graphql(/* GraphQL */ `
    query OrganizationAgentInfo(
      $where: organization_agents_bool_exp!
      $start: timestamptz
      $end: timestamptz
    ) {
      organization_agents(where: $where) {
        agent {
          id
          email
          fname
          lname
          city
          state
          phone_number
          first_seen_at
          last_seen_at
        }
        combined_logs_aggregate(where: { deleted_at: { _is_null: true } }) {
          aggregate {
            count
          }
        }
        ...AgentActivityChart
      }
    }
  `),
  variables: computed(() => ({
    where: {
      agent_id: { _eq: agentId }
    },
    start: sub(new Date(), { days: 365 }),
    end: endOfDay(new Date())
  }))
});

const orgAgent = computed(() => agentData.value?.organization_agents.at(0));
const agent = computed(() => orgAgent.value?.agent);
const isMobile = injectStrict(IsMobileKey);

const recentLogCount = computed(
  () => orgAgent.value?.combined_logs_aggregate.aggregate?.count || 0
);

const {
  data: pricesData,
  fetching: pricesFetching,
  error: pricesError
} = useQuery({
  query: graphql(/* GraphQL */ `
    query AgentInfoPrices($agentId: String!) {
      users_by_pk(id: $agentId) {
        organization_agents {
          organization {
            default_agent_day_rate_cents
          }
        }

        agent_prices(order_by: { valid_from: desc }) {
          valid_from
          day_rate_cents
          hourly_rate_cents
          created_at
        }
      }
    }
  `),
  variables: {
    agentId
  },
  pause: computed(() => !can("agent_prices:view"))
});

const defaultPrice = computed(
  () =>
    pricesData.value?.users_by_pk?.organization_agents.at(0)?.organization
      .default_agent_day_rate_cents
);
const agentPrices = computed(
  () => pricesData.value?.users_by_pk?.agent_prices ?? []
);

const { executeMutation: archive } = useMutation(
  graphql(/* GraphQL */ `
    mutation ArchiveAgentByPk($agent_id: String!, $organization_name: String!) {
      update_organization_agents_by_pk(
        pk_columns: {
          agent_id: $agent_id
          organization_name: $organization_name
        }
        _set: { deleted_at: "now()" }
      ) {
        agent_id
        deleted_at
        organization_name
      }
    }
  `)
);

async function onArchiveAgent() {
  const { data: mutationData, error: mutationError } = await archive({
    agent_id: agentId,
    organization_name: organization.value?.short_name || "unknown"
  });
  if (mutationError) {
    createToast({
      title: `Unable to archive agent ${agentId}.`,
      message: mutationError.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  }

  if (mutationData) {
    createToast({
      message: `Agent ${agentId} archived.`,
      theme: "success"
    });
  }
}

const form = reactive<SetOrganizationAgentPriceMutationVariables["agentPrice"]>(
  {
    agent_id: agentId
  }
);

const dayRate = computed<number>({
  get: () =>
    form.day_rate_cents ?? agentPrices.value.at(0)?.day_rate_cents ?? 0,
  set: (cents: number) => (form.day_rate_cents = cents)
});

const validFrom = computed({
  get() {
    if (form.valid_from) {
      return new Date(form.valid_from);
    }

    if (agentPrices.value.at(0)?.valid_from) {
      return new Date(agentPrices.value[0].valid_from);
    }

    return new Date();
  },
  set(val) {
    form.valid_from = val;
  }
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation SetOrganizationAgentPrice(
      $agentPrice: organization_agent_prices_insert_input!
    ) {
      insert_organization_agent_prices_one(object: $agentPrice) {
        id
        agent {
          id
        }
      }
    }
  `)
);

const onSubmit = async () => {
  const { data, error } = await executeMutation({ agentPrice: form });

  if (error) {
    createToast({
      title: "Unable to update the agent price.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
    console.error(error);
  }

  if (data) {
    createToast({
      message: "Agent price updated.",
      theme: "success"
    });
  }
};
</script>
<template>
  <SHSpinner v-if="agentFetching || pricesFetching" />
  <SHNote v-else-if="agentError || pricesError" theme="danger">
    {{ agentError }} || {{ pricesError }}
  </SHNote>
  <article v-else-if="agent" class="vertical">
    <div class="level-cols loose">
      <SHField label="First Login">
        <span v-if="agent.first_seen_at">
          <SHInlineDate :d="agent.first_seen_at" />
        </span>
        <span v-else>never</span>
      </SHField>

      <SHField label="Last Seen">
        <span v-if="agent.last_seen_at">
          <SHInlineDate :d="agent.last_seen_at" />
        </span>
        <span v-else>never</span>
      </SHField>

      <SHField label="Location">
        <span v-if="agent.city || agent.state">
          {{ agent.city }}
          <span v-if="agent.city && agent.state">,</span>
          {{ agent.state }}
        </span>
        <span v-else>-</span>
      </SHField>

      <SHField label="Phone">
        <AppLink v-if="agent.phone_number" :href="`tel:${agent.phone_number}`">
          {{ agent.phone_number }}
        </AppLink>
        <span v-else>-</span>
      </SHField>

      <SHField label="Email">
        <AppLink v-if="agent.email" :href="`mailto:${agent.email}`">
          {{ agent.email }}
        </AppLink>
        <span v-else>-</span>
      </SHField>

      <SHField label="Logs in last 30 days">
        <h2>{{ recentLogCount }}</h2>
      </SHField>
    </div>

    <AgentActivityChart
      v-if="orgAgent && !isMobile"
      :orgAgent="orgAgent"
      :vertical="isMobile"
    />

    <div v-if="can('agent_prices:view')" class="vertical">
      <h3>Agent Pricing</h3>
      <SHNote theme="info">
        Below you can see this agent's price overrides from your organization's
        default of
        {{ formatUSD(defaultPrice) }}/day.
      </SHNote>

      <SHField label="Day Rate">
        <SHDollarInput v-model="dayRate" />
      </SHField>
      <SHField label="Valid From" block>
        <SHDatePicker v-model="validFrom" auto-apply />
      </SHField>
      <SHButton
        v-if="
          dayRate !== agentPrices.at(0)?.day_rate_cents ||
          (agentPrices.at(0)?.valid_from &&
            !isEqual(validFrom, new Date(agentPrices[0].valid_from)))
        "
        color="primary"
        @click="onSubmit"
      >
        SAVE
      </SHButton>

      <div class="vertical tight">
        <TimeLineEntry v-for="p of agentPrices" :key="p.valid_from">
          <template #icon>
            <FontAwesomeIcon :icon="faBadgeDollar" />
          </template>

          <div class="level tight wrap">
            <span>day rate set to</span>
            <SHBadge color="var(--color-surface-900)">
              {{ formatUSD(p.day_rate_cents) }} / day
            </SHBadge>
            <span>valid from</span>
            <SHBadge color="var(--color-surface-600)">
              <SHInlineDate :d="p.valid_from" />
            </SHBadge>
          </div>
        </TimeLineEntry>
      </div>
    </div>

    <!-- TODO: Implement logic archived agents -->
    <!-- v-if="can('organization_agents:archive')" instead of v-if="false"-->
    <SHButton v-if="false" style="margin-top: 1em" @click="onArchiveAgent">
      Archive Agent
    </SHButton>
  </article>

  <SHNote v-else theme="warning">Unknown Agent</SHNote>
</template>
