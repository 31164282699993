import * as Sentry from "@sentry/vue";

// const { VITE_ENV } = import.meta.env;

export const useLogger = (prefix = "") => {
  return {
    log: (...args: unknown[]): void => {
      // if (VITE_ENV !== "production") {
      console.log(`[SPEARHEAD${prefix ? ":" + prefix : ""}]`, ...args);
      // }
    },
    error: (...args: unknown[]): void => {
      Sentry.captureException(new Error(args.join(" ")));
      console.error(`[SPEARHEAD${prefix ? ":" + prefix : ""}]`, ...args);
    },
    warn: (...args: unknown[]): void => {
      console.warn(`[SPEARHEAD${prefix ? ":" + prefix : ""}]`, ...args);
    }
  };
};
