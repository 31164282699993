import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

export function useTimezone() {
  const currentUser = injectStrict(CurrentUserKey);

  const { data: orgData } = useQuery({
    query: graphql(/* GraphQL */ `
      query UseTimezone_Organization($organizationId: String!) {
        organizations_by_pk(id: $organizationId) {
          timezone_history_entries(
            where: { valid_from: { _lte: "now" } }
            order_by: { valid_from: desc }
            limit: 1
          ) {
            timezone_name
          }
        }
      }
    `),
    variables: {
      organizationId:
        currentUser.value.organization_agents.at(0)?.organization.id ??
        "unknown organization"
    }
  });

  const timezoneName = ref<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const orgTimezoneName = computed(
    () =>
      orgData?.value?.organizations_by_pk?.timezone_history_entries[0]
        ?.timezone_name
  );

  return {
    timezoneName,
    orgTimezoneName
  };
}
