import { ref, type MaybeRef, computed, type Ref, watchEffect } from "vue";

import Fuse from "fuse.js";
import { useLogger } from "@/logger";

const { log } = useLogger("useFuse"); // eslint-disable-line @typescript-eslint/no-unused-vars

export function useFuse<T extends Record<string, unknown>>(
  search: MaybeRef<string | undefined | null>,
  items: MaybeRef<T[]>,
  options?: MaybeRef<Fuse.IFuseOptions<T>>,
  index?: MaybeRef<Fuse.FuseIndex<T>>
) {
  const _search = ref(search);
  const _items = ref(items) as Ref<T[]>;
  const _options = ref(options);
  const _index = ref(index);

  const fuse = ref<Fuse<T>>(
    new Fuse(_items.value, _options.value, _index.value)
  );

  watchEffect(() => {
    fuse.value = new Fuse(_items.value, _options.value, _index.value);
  });

  const result = computed(() =>
    _search.value
      ? fuse.value.search(_search.value).map(r => r.item)
      : _items.value
  );

  return {
    fuse,
    result
  };
}
