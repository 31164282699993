import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";
import {
  faPlus,
  faFileInvoiceDollar
} from "@fortawesome/sharp-light-svg-icons";
import SHButton from "@/components/SHButton.vue";
import ModuleHeader from "@/components/ModuleHeader.vue";
import InvoicesView from "@/views/InvoicesView.vue";
import InvoiceDetail from "@/views/InvoiceDetail.vue";
import InvoiceCreate from "@/views/InvoiceCreate.vue";
import InvoiceEdit from "@/views/InvoiceEdit.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export const invoiceRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "InvoicesView",
    components: {
      default: InvoicesView,
      header: h(
        ModuleHeader,
        { icon: faFileInvoiceDollar },
        {
          default: () => "Invoices",
          nav: () =>
            h(
              SHButton,
              {
                to: { name: "InvoiceCreate" },
                color: "primary",
                size: "sm"
              },
              () =>
                h("div", [
                  h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                  h(FontAwesomeIcon, {
                    icon: faFileInvoiceDollar,
                    fixedWidth: true
                  })
                ])
            )
        }
      )
    }
  },
  {
    path: ":invoiceId",
    name: "InvoiceDetail",
    props: true,
    components: {
      default: InvoiceDetail,
      header: h(
        ModuleHeader,
        { icon: faFileInvoiceDollar, class: "noprint" },
        () => "Invoice Detail"
      )
    }
  },
  {
    path: ":invoiceId/edit",
    name: "InvoiceEdit",
    props: true,
    components: {
      default: InvoiceEdit,
      header: h(
        ModuleHeader,
        { icon: faFileInvoiceDollar },
        () => "Invoice Detail"
      )
    }
  },
  {
    path: "new",
    name: "InvoiceCreate",
    components: {
      default: InvoiceCreate,
      header: h(
        ModuleHeader,
        { icon: faFileInvoiceDollar },
        () => "Create Invoice"
      )
    }
  }
];
