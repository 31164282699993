<script setup lang="ts">
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";

const user = injectStrict(CurrentUserKey);

const TRACKING_CODE_URL = "https://js-na1.hs-scripts.com/22445745.js";

const initHubspotTrackingCode = () => {
  // Check to see if tracking code exists
  if (document.querySelector(`script[src="${TRACKING_CODE_URL}"]`)) {
    return;
  }

  const script = document.createElement("script");
  script.src = TRACKING_CODE_URL;
  script.async = true;
  document.head.appendChild(script);
};

/*
 * HubSpot tracking
 * https://developers.hubspot.com/docs/api/events/tracking-code
 * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 */
window.hsConversationsSettings = {
  loadImmediately: false
};

window.hsConversationsOnReady = [
  () => {
    // Remove the widget when it is closed so that the floating button doesn't show
    window.HubSpotConversations?.on("widgetClosed", () => {
      window.HubSpotConversations?.widget.remove();
    });
  }
];

const _hsq = (window._hsq = window._hsq || []);
_hsq.push([
  "identify",
  {
    email: user.value?.email,
    username: user.value?.full_name,
    id: user.value?.id
  }
]);

initHubspotTrackingCode();
</script>

<template>
  <div class="hubspot-provider" />
</template>
