<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 307.88 415.41">
    <path
      fill="var(--left-side)"
      d="m12.35,257.74l11.43-30.01,2.15.56-2.62,27.08,83.47,127.47s-3.77-150.08-3.77-150.08c-.24-8.23-4.78-15.23-10.08-21.2-5.97-6.74-12.56-13-18.82-19.47,0,0-41.27-42.63-41.27-42.63l-13.37-57.7,109.23,62.56c4.7,2.63,8.26,10.15,8.52,15.24.79,15.99-2.43,37.04-4.36,49.62l-.08.54-2.22-.17c0-12.08-.21-44.27-1.22-48.14l-.02-.08v-.08c-.73-6.95-6.2-10.81-6.61-11.09l-89.12-48.8,8.98,33.18s38.8,40.4,38.8,40.4c7.42,7.6,15.2,15.08,22.22,23.07,5.98,6.8,9.79,15.71,10.02,24.79,1.01,38.75,1.76,77.64,2.38,117.38.22,11.45.4,22.91.58,33.99.12,6.53.1,12.93.07,20.35-.01,3.46-.03,3.19-.03,7.03l-.23,3.86L12.35,257.74Z"
    />
    <path
      fill="var(--right-side)"
      d="m307.81,47.66c-.07-.51-.15-1.02-.25-1.5-.15-.84-.22-1.28-.22-1.28l-40.57-11.89-19.7-5.73-20.58-6.02-6.09-1.75-14.85-4.34-38.04-11.46-13.56-3.7-51.61,15.15-41.52,12.11-19.7,5.73L.55,44.89s-.07.44-.22,1.28c-.11.47-.18.98-.26,1.5-.04.15-.04.29-.07.44v134.48L8.97,50.68,153.94,9.88v286.89l19.72-49.36,5.69-17.22c0-6.13-1.2-13.83-2.92-24.88-1.97-12.88-3.1-26.01-2.74-38.96.15-5,3.76-12.11,8.06-14.52l.04-.04,4.05-1.86,75.23-34.44,16.82-7.7-8.39,32.8-8.43,8.83-31.56,33.02-20.65,21.6c-6.82,7.15-10.76,16.53-11.02,26.38-1.13,39.62-1.79,79.24-2.41,118.93-.22,11.49-.4,22.95-.58,34.44-.18,9.74-.04,19.52-.04,31.63l25.58-39.69,6.09-9.45,34.59-53.67,28.46-44.18,6.09-9.45,2.23-3.47-2.23-5.84-6.09-15.98-3.06-8.06c-2.55-6.75-1.42-14.41,3.06-20.14l6.09-7.59,4.12-5.14,4.81-6.01,3.33-4.16V48.1c-.04-.15-.04-.29-.07-.44Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  max-height: 15vh;
  max-width: 15vw;
  --left-side: var(--color-secondary);
  --right-side: var(--color-surface-900);
}
</style>
