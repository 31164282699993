import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { CreateToastKey } from "@/providerKeys";
import { type Component } from "vue";

const { log } = useLogger("useToaster"); // eslint-disable-line @typescript-eslint/no-unused-vars

// various details that effect how a toast is shown, they travel in the `detail` attribute of a `ToastEvent`
export type SHToastOptions = {
  theme:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "loading";
  message: string;
  title?: string;
  component?: Component;
  placement?: "top-right"; // not actually used yet
  lifetimeMS?: number;
  requiresInteraction?: boolean; // ignores liftime
  notClosable?: boolean;
};

export type CreateToastFn = (options: SHToastOptions) => { close: () => void };

export const useToaster = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const injectedCreate = injectStrict(CreateToastKey);
  return {
    /* ignored first argument is for using this as an event handler such as @click or @on-foo */
    createToast: injectedCreate
  };
};
