<script async setup lang="ts">
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import {
  useUserRoleChoice,
  type UserRoleChoice
} from "@/composables/useUserRoleChoice";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { faUserPlus, faUserSlash } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import DeleteWithConfirm from "./DeleteWithConfirm.vue";
import SHButton from "./SHButton.vue";
import SHField from "./SHField.vue";
import SHInput from "./SHInput.vue";
import SHNote from "./SHNote.vue";
import SHRoleChooser from "./SHRoleChooser.vue";
import SHSpinner from "./SHSpinner.vue";

const props = defineProps<{
  agentId: string;
}>();

const router = useRouter();
const { createToast } = useToaster();
const currentUser = injectStrict(CurrentUserKey);

const { can } = useRole();

if (!can("users:update_others") && !can("users:block_only")) {
  router.back();
}

const { data } = await useQuery({
  query: graphql(/* GraphQL */ `
    query GetCurrentUserSettings($agentId: String!) {
      users_by_pk(id: $agentId) {
        id
        fname
        lname
        email
        phone_number
        city
        state
        created_at
        updated_at
        deleted_at

        organization_agents {
          user_roles {
            user_role {
              ...UserRoleChoice
            }
          }
        }
      }
    }
  `),
  variables: {
    agentId: props.agentId
  }
});

const user = computed(() => {
  return data.value?.users_by_pk;
});

const { executeMutation: blockUser } = useMutation(
  graphql(/* GraphQL */ `
    mutation BlockUser($agentId: String!, $block: Boolean!) {
      blockOrgAgent(agentId: $agentId, block: $block) {
        __typename
      }
    }
  `)
);

const { executeMutation: update } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateOrganizationAgent(
      $agent: AgentInput!
      $roles: [RoleInput!]!
      $blockAgent: Boolean!
    ) {
      updateOrgAgent(agent: $agent, roles: $roles, blockAgent: $blockAgent) {
        userAdded
        membershipAdded
        rolesAdded
        agent {
          id
          full_name
          email
        }
        password
        organization_name
      }
    }
  `)
);

const form = reactive<{
  id: string;
  fname: string;
  lname: string;
  email: string;
  phone_number: string;
  city: string;
  state: string;
}>({
  id: user.value?.id || "unknown id",
  fname: user.value?.fname || "",
  lname: user.value?.lname || "",
  email: user.value?.email || "",
  phone_number: user.value?.phone_number || "",
  city: user.value?.city || "",
  state: user.value?.state || ""
});
const selectedRole = ref<UserRoleChoice | undefined>(
  useUserRoleChoice(
    user.value?.organization_agents.at(0)?.user_roles.at(0)?.user_role
  ) || undefined
);
const isValid = computed(() => !!form.fname && !!form.email);
const isLoading = ref(false);

async function onSubmit() {
  if (!selectedRole.value || !isValid.value) return;
  isLoading.value = true;
  const { data, error } = await update({
    agent: form,
    roles: [
      {
        id: selectedRole.value.id,
        label: selectedRole.value.label,
        auth0_id: selectedRole.value?.auth0_id
      }
    ],
    blockAgent: false
  });
  isLoading.value = false;

  if (error) {
    createToast({
      title: `Unable to update the profile.`,
      message: error.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  }

  if (data) {
    createToast({
      message: `Profile updated.`,
      theme: "success"
    });
    router.go(-1);
  }
}

function onCancel() {
  isLoading.value = false;
  router.push({
    name: "AgentDetail",
    params: { agentId: props.agentId }
  });
}

async function onBlockConfirmed(isBlocked: boolean) {
  if (!user.value) {
    throw new Error("User not found.");
  }

  isLoading.value = true;

  if (isBlocked) {
    console.log("blocking user", user.value?.id);
  } else {
    console.log("unblocking user", user.value?.id);
  }

  const { data, error } = await blockUser({
    agentId: user.value.id,
    block: isBlocked
  });

  isLoading.value = false;

  if (error) {
    createToast({
      title: `The agent was not ${isBlocked ? "blocked" : "unblocked"}.`,
      message: error.message || "An error occurred.",
      theme: "danger",
      requiresInteraction: true
    });
  }

  if (data) {
    createToast({
      message: `Agent ${isBlocked ? "blocked" : "unblocked"}.`,
      theme: "success"
    });
    router.go(-1);
  }
}
</script>

<template>
  <article class="agent-edit xmargin">
    <section class="vertical">
      <h2>General Information</h2>
      <SHNote
        v-show="isLoading"
        theme="primary"
        class="level tight"
        style="padding: 1em"
      >
        <SHSpinner />
        Updating user. This can take up to a minute to complete.
      </SHNote>

      <div class="vertical loose">
        <span>
          Here you can update agent names, roles, and their contact information.
        </span>
        <SHField label="First Name">
          <SHInput
            v-model="form.fname"
            :disabled="isLoading || !can('users:update_others')"
          />
        </SHField>
        <SHField label="Last Name">
          <SHInput
            v-model="form.lname"
            :disabled="isLoading || !can('users:update_others')"
          />
        </SHField>
        <SHField label="Role">
          <SHRoleChooser
            v-model="selectedRole"
            :disabled="
              isLoading ||
              !can('users:update_others') ||
              user?.id === currentUser.id
            "
          />
        </SHField>

        <SHField label="Email" block>
          <div class="vertical tight">
            <SHInput
              v-model="form.email"
              type="email"
              :disabled="isLoading || !can('users:update_others')"
            />
            <SHNote theme="warning">
              Updating this user's email does not require confirmation. The user
              will need to login using the newly set email, and will receive
              notifications and password resets at that address.
            </SHNote>
          </div>
        </SHField>
        <SHField label="City">
          <SHInput
            v-model="form.city"
            :disabled="isLoading || !can('users:update_others')"
          />
        </SHField>
        <SHField label="State">
          <SHInput
            v-model="form.state"
            :disabled="isLoading || !can('users:update_others')"
          />
        </SHField>
        <SHField label="Phone Number">
          <SHInput
            v-model="form.phone_number"
            :disabled="isLoading || !can('users:update_others')"
          />
        </SHField>
      </div>

      <UndoConfirm
        :confirm-enabled="isValid && !isLoading"
        @undo="onCancel"
        @confirm="onSubmit"
      />

      <section class="danger-zone">
        <SHNote
          v-if="user && user.deleted_at === null"
          theme="danger"
          class="spread"
        >
          <div class="vertical tight">
            <h2>Block User</h2>
            <span>
              Blocking this user will remove their access from Spearhead&trade;.
              All records they have created will remain. This action is
              reversable.
            </span>
          </div>
          <div class="button">
            <DeleteWithConfirm
              :enabled="
                user.id !== currentUser.id &&
                !isLoading &&
                (can('users:update_others') || can('users:block_only'))
              "
              :square="false"
              @delete="onBlockConfirmed(true)"
            >
              <template #buttonIcon>
                <div class="level tight">
                  <FontAwesomeIcon :icon="faUserSlash" fixed-width />
                  Block User
                </div>
              </template>
              <template #question>
                Are you sure you want to block access for {{ user?.fname }}
              </template>
            </DeleteWithConfirm>
          </div>
        </SHNote>

        <SHNote
          v-if="user && user.deleted_at !== null"
          theme="warning"
          class="spread"
        >
          <div class="vertical tight">
            <h2>Unblock User</h2>
            <span>
              Unblocking this user will reenable their access from
              Spearhead&trade;. This action is reversable.
            </span>
          </div>
          <div class="button">
            <SHButton
              color="success"
              :disabled="isLoading || !can('users:update_others')"
              @click="onBlockConfirmed(false)"
            >
              <div class="level tight">
                <FontAwesomeIcon :icon="faUserPlus" fixed-width />
                Unblock User
              </div>
            </SHButton>
          </div>
        </SHNote>
      </section>
    </section>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;
.spread {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;

  .button {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @include bp.tablet {
    flex-direction: row;
    .button {
      flex-grow: 1;
    }
  }
}
.danger-zone {
  margin-top: 2em;
}
</style>
