<script setup lang="ts">
import { useRole } from "@/composables/useRole";
import {
  useRoute,
  type LocationQuery,
  type RouteLocationNamedRaw
} from "vue-router";
import SHButton from "./SHButton.vue";
import SHSpinner from "./SHSpinner.vue";

export type RouterTab = {
  title: string;
  to: RouteLocationNamedRaw;
  allowedPermissions?: string[];
};

const {
  tabs,
  queryStringMatch = "",
  replace = true
} = defineProps<{
  tabs?: RouterTab[];
  exactMatch?: boolean;
  queryStringMatch?:
    | string
    | ((to: RouteLocationNamedRaw, query: LocationQuery) => boolean);
  replace?: boolean;
  noRouter?: boolean;
}>();

const route = useRoute();

const matchesQuery = (to: RouteLocationNamedRaw) =>
  typeof queryStringMatch === "function"
    ? queryStringMatch(to, route.query)
    : route.query[queryStringMatch] === to.query?.[queryStringMatch];

const { can } = useRole();

const hasPermission = (tab: RouterTab): boolean =>
  !tab.allowedPermissions?.length || tab.allowedPermissions.some(p => can(p));
</script>

<template>
  <article class="vertical">
    <main>
      <slot name="beforeTabs"></slot>
      <div class="tab-wrapper">
        <slot name="startOfRow" />
        <div class="tabs">
          <template v-for="(tab, idx) of tabs || []" :key="idx">
            <RouterLink
              v-if="hasPermission(tab)"
              :to="tab.to"
              custom
              :replace="replace"
            >
              <template #default="{ isActive, isExactActive, navigate }">
                <SHButton
                  :class="{
                    active:
                      (exactMatch ? isExactActive : isActive) &&
                      matchesQuery(tab.to)
                  }"
                  @click="navigate"
                >
                  {{ tab.title }}
                </SHButton>
              </template>
            </RouterLink>
          </template>
        </div>
        <slot name="endOfRow" />
      </div>
      <slot name="afterTabs"></slot>
    </main>
    <router-view v-if="!noRouter" v-slot="{ Component }">
      <Suspense>
        <template #fallback>
          <SHSpinner />
        </template>
        <component :is="Component" v-bind="($props, $attrs)" />
      </Suspense>
    </router-view>
  </article>
</template>

<style lang="scss" scoped>
.tab-wrapper {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr min-content;

  > * {
    padding-bottom: 0.5em;
  }

  .tabs {
    overflow-x: auto;
    scrollbar-gutter: stable;

    display: flex;
    gap: 0.5em;
    flex-wrap: nowrap;

    :deep(.sh-btn) {
      border: none;
      color: var(--color-surface-500);
      background: var(--color-surface-100);

      &.active {
        color: var(--color-surface-900);
        background: var(--color-secondary);
      }

      &:focus:not(.active) {
        color: var(--color-surface-900);
        background: var(--color-surface-300);
      }

      &:not(.active):hover {
        background: var(--color-surface-200);
      }
    }
  }
}
</style>
