<script setup lang="ts">
import { computed } from "vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import AppLink from "@/components/AppLink.vue";
import UserAvatar from "./UserAvatar.vue";

const UserLinkFragment = graphql(/* GraphQL */ `
  fragment UserLink on users {
    id
    fname
    lname
  }
`);

const props = defineProps<{
  user?: FragmentType<typeof UserLinkFragment> | null;
  link?: boolean;
  noColor?: boolean;
  avatarOnly?: boolean;
  avatarSize?: (typeof UserAvatar)["props"]["size"];
}>();

const user = computed(() => useFragment(UserLinkFragment, props.user));

const fullName = computed(() => {
  if (user.value) {
    return `${user.value.fname}${
      user.value?.lname ? " " + user.value.lname : ""
    }`;
  }
  return "?";
});
</script>

<template>
  <span class="user-link">
    <AppLink
      v-if="user"
      :to="{
        name: 'AgentDetail',
        params: {
          agentId: user.id
        }
      }"
    >
      <span v-if="link">{{ fullName }}</span>
      <UserAvatar
        v-else-if="avatarOnly"
        :no-color="noColor"
        :seed-string="fullName"
        :size="avatarSize"
        class="avatarOnly"
      />
      <div v-else class="pill">
        <UserAvatar
          v-if="!link"
          :no-color="noColor"
          :seed-string="fullName"
          :size="avatarSize || 'small'"
          style="border-top-left-radius: 2px; border-bottom-left-radius: 2px"
        />
        <span>
          {{ fullName }}
        </span>
      </div>
    </AppLink>
  </span>
</template>

<style lang="scss" scoped>
.user-link {
  :deep(.app-link) {
    text-decoration: none;
    color: var(--color-surface-900);
  }

  .avatarOnly {
    border-radius: 0.25em;
    transition: all 0.2s ease-in-out;
    box-shadow: var(--box-shadow);
    &:hover {
      :deep(rect) {
        fill: var(--color-primary);
      }
    }
  }
  .pill {
    vertical-align: middle;
    background: var(--color-surface-0);
    display: inline-flex;
    gap: 0.25em;
    align-items: center;
    border: thin solid var(--color-surface-200);
    border-radius: 4px;

    > span {
      font-size: 0.875em;
      padding-right: 0.25em;
    }
  }
}
</style>
