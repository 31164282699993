<script setup lang="ts">
import { computed, watch, ref } from "vue";
import { graphql, type FragmentType, useFragment } from "@/generated";
import SHField from "@/components/SHField.vue";
import { v4 } from "uuid";
import SHInput from "@/components/SHInput.vue";
import type {
  Customer_Contacts_Insert_Input,
  Customer_Contacts_Set_Input
} from "@/generated/graphql";
import { useLogger } from "@/logger";

const { log } = useLogger("CustomerContactForm"); // eslint-disable-line @typescript-eslint/no-unused-vars

const customerContactFragment = graphql(/* GraphQL */ `
  fragment CustomerContactForm on customer_contacts {
    id
    fname
    lname
    job_title
    email
    phone_office
    phone_mobile
    phone_fax
  }
`);

const props = defineProps<{
  customerContact?: FragmentType<typeof customerContactFragment>;
}>();

const emit = defineEmits<{
  (e: "update:updates", value: Customer_Contacts_Set_Input | null): void;
  (e: "update:inserts", value: Customer_Contacts_Insert_Input | null): void;
}>();

const customerContact = computed(() =>
  useFragment(customerContactFragment, props.customerContact)
);

const form = ref<{
  id: string;
  fname?: string;
  lname?: string;
  jobTitle?: string;
  email?: string;
  phoneOffice?: string;
  phoneMobile?: string;
  phoneFax?: string;
}>({
  id: v4()
});

const isValid = computed(() => {
  return (
    form.value.fname ||
    customerContact.value?.fname ||
    form.value.lname ||
    customerContact.value?.lname
  );
});

const updates = computed<Customer_Contacts_Set_Input | null>(() => {
  if (!isValid.value) {
    return null;
  }

  return {
    fname: form.value.fname,
    lname: form.value.lname,
    job_title: form.value.jobTitle,
    email: form.value.email,
    phone_office: form.value.phoneOffice,
    phone_mobile: form.value.phoneMobile,
    phone_fax: form.value.phoneFax
  };
});

const inserts = computed<Customer_Contacts_Insert_Input | null>(() => {
  if (!isValid.value) {
    return null;
  }

  return {
    fname: form.value.fname,
    lname: form.value.lname,
    job_title: form.value.jobTitle,
    email: form.value.email,
    phone_office: form.value.phoneOffice,
    phone_mobile: form.value.phoneMobile,
    phone_fax: form.value.phoneFax
  };
});

watch(
  form,
  () => {
    emit("update:inserts", inserts.value);
    emit("update:updates", updates.value);
  },
  { deep: true }
);
</script>

<template>
  <article class="customer-contact-form vertical">
    <SHField label="First Name">
      <SHInput
        :model-value="form.fname ?? customerContact?.fname ?? ''"
        @update:model-value="form.fname = $event"
      />
    </SHField>

    <SHField label="Last Name">
      <SHInput
        :model-value="form.lname ?? customerContact?.lname ?? ''"
        @update:model-value="form.lname = $event"
      />
    </SHField>

    <SHField label="Email">
      <SHInput
        type="email"
        :model-value="form.email ?? customerContact?.email ?? ''"
        @update:model-value="form.email = $event"
      />
    </SHField>

    <SHField label="Job Title">
      <SHInput
        :model-value="form.jobTitle ?? customerContact?.job_title ?? ''"
        @update:model-value="form.jobTitle = $event"
      />
    </SHField>

    <SHField label="Work Phone">
      <SHInput
        type="tel"
        :model-value="form.phoneOffice ?? customerContact?.phone_office ?? ''"
        @update:model-value="form.phoneOffice = $event"
      />
    </SHField>

    <SHField label="Mobile Phone">
      <SHInput
        type="tel"
        :model-value="form.phoneMobile ?? customerContact?.phone_mobile ?? ''"
        @update:model-value="form.phoneMobile = $event"
      />
    </SHField>

    <SHField label="Fax">
      <SHInput
        type="tel"
        :model-value="form.phoneFax ?? customerContact?.phone_fax ?? ''"
        @update:model-value="form.phoneFax = $event"
      />
    </SHField>
  </article>
</template>
