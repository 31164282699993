<script setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { Order_By } from "@/generated/graphql";
import { faTimes } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { computed, reactive, ref } from "vue";

import SHButton from "@/components/SHButton.vue";
import SHCatalogChooser from "@/components/SHCatalogChooser.vue";
import SHField from "@/components/SHField.vue";
import SHNote from "@/components/SHNote.vue";
import SHProductChooser from "@/components/SHProductChooser.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import UserLink from "@/components/UserLink.vue";
import type { CatalogChoice } from "@/composables/useCatalogChoice";
import type { ProductChoice } from "@/composables/useProductChoice";
import DeleteWithConfirm from "./DeleteWithConfirm.vue";
import SHInlineDate from "./SHInlineDate.vue";

const { customFormDefinitionId } = defineProps<{
  customFormDefinitionId: string;
}>();

const { createToast } = useToaster();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomFormProductBindings(
      $where: products_xref_custom_forms_bool_exp!
      $orderBy: [products_xref_custom_forms_order_by!]
      $limit: Int
      $offset: Int
      $customFormDefinitionId: uuid!
    ) {
      products_xref_custom_forms(
        where: $where
        order_by: $orderBy
        limit: $limit
        offset: $offset
      ) {
        author {
          id
          fname
          ...UserLink
        }
        created_at
        is_required
        id
        product {
          ...ProductCard
          title
          catalog {
            title
          }
        }
      }
      custom_form_definitions_by_pk(id: $customFormDefinitionId) {
        title
      }
    }
  `),
  variables: computed(() => ({
    where: {
      custom_form_definition_id: { _eq: customFormDefinitionId }
    },
    orderBy: [
      { product: { catalog: { title: Order_By.Asc } } },
      { product: { title: Order_By.Asc } }
    ],
    customFormDefinitionId
  })),
  context: { additionalTypenames: ["products_xref_custom_forms"] }
});
const showForm = ref(false);
const bindingForm = reactive<{
  catalog?: CatalogChoice;
  product?: ProductChoice;
  isRequired?: boolean;
}>({});
const bindings = computed(() => data.value?.products_xref_custom_forms || []);
const formDef = computed(() => data.value?.custom_form_definitions_by_pk);

const { executeMutation: insertBinding } = useMutation(
  graphql(/* GraphQL */ `
    mutation BindCustomFormToProduct(
      $binding: products_xref_custom_forms_insert_input!
    ) {
      insert_products_xref_custom_forms_one(object: $binding) {
        id
        custom_form_definition {
          title
        }
      }
    }
  `)
);

const onConfirm = async () => {
  console.log("will set", { bindingForm });
  const { data, error } = await insertBinding({
    binding: {
      product_id: bindingForm.product?.id,
      custom_form_definition_id: customFormDefinitionId,
      is_required: bindingForm.isRequired
    }
  });
  if (error) {
    createToast({
      title: "Unable to create the form binding.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else if (data) {
    createToast({
      message: "Form binding created.",
      theme: "success"
    });
    showForm.value = false;
  }
};

const { executeMutation: updateBinding } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateProductCustomFormBinding(
      $productXrefCustomFormId: uuid!
      $updates: products_xref_custom_forms_set_input!
    ) {
      update_products_xref_custom_forms_by_pk(
        pk_columns: { id: $productXrefCustomFormId }
        _set: $updates
      ) {
        id
      }
    }
  `)
);

const { executeMutation: deleteBinding } = useMutation(
  graphql(/* GraphQL */ `
    mutation DeleteProductCustomFormBinding($productXrefCustomFormId: uuid!) {
      delete_products_xref_custom_forms_by_pk(id: $productXrefCustomFormId) {
        id
      }
    }
  `)
);

const archive = async (row: (typeof bindings.value)[number]) => {
  const { data, error } = await deleteBinding({
    productXrefCustomFormId: row.id
  });
  if (error) {
    createToast({
      title: `Unable to remove the binding on ${row.product.title}.`,
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else if (data) {
    createToast({
      message: `${row.product.title} binding removed.`,
      theme: "success"
    });
  }
};

const toggleRequired = async (row: (typeof bindings.value)[number]) => {
  console.log("toggle", row.id);

  const { data, error } = await updateBinding({
    updates: {
      is_required: !row.is_required
    },
    productXrefCustomFormId: row.id
  });
  if (error) {
    createToast({
      title: "Did not toggle required.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else if (data) {
    createToast({
      message: `Toggled required on ${row.product.title}.`,
      theme: "success"
    });
  }
};
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else class="vertical loose">
    <SHButton @click="showForm = true">Add to Product</SHButton>
    <div v-show="showForm" class="binding-form">
      <h2>
        Add
        <span style="color: var(--color-primary)">
          {{ formDef?.title || "Custom Form" }}
        </span>
        to Product
      </h2>
      <div class="level-cols loose">
        <SHField label="Catalog">
          <SHCatalogChooser v-model="bindingForm.catalog" />
        </SHField>
        <SHField label="Product">
          <SHProductChooser
            v-model="bindingForm.product"
            :disabled="!bindingForm.catalog"
            :catalog-id="bindingForm.catalog?.id"
          />
        </SHField>
        <SHField label="Form is">
          <div class="level loose wrap">
            <label class="level tight">
              <input
                v-model="bindingForm.isRequired"
                :checked="!!bindingForm.isRequired"
                type="radio"
                :value="true"
              />
              Required
            </label>
            <label class="level tight">
              <input
                v-model="bindingForm.isRequired"
                :checked="!bindingForm.isRequired"
                type="radio"
                :value="false"
              />
              Optional
            </label>
          </div>
        </SHField>
      </div>
      <UndoConfirm
        :confirm-enabled="!!bindingForm.catalog && !!bindingForm.product"
        @confirm="onConfirm"
        @undo="showForm = false"
      />
    </div>

    <SHNote theme="info">
      This page allows associating this custom form with various products in
      your catalogs. You can make a form required or optional when an agent is
      on a ticket for a given product below.
    </SHNote>

    <SHTable
      fluid
      :columns="['Catalog', 'Product', 'Required', 'Who', 'Date', 'Actions']"
      :rows="bindings"
    >
      <template #row="{ row }">
        <td>{{ row.product.catalog.title }}</td>
        <td>{{ row.product.title }}</td>
        <td>
          <input
            type="checkbox"
            :checked="row.is_required"
            @change="toggleRequired(row)"
          />
        </td>
        <td>
          <UserLink :user="row.author" />
        </td>
        <td><SHInlineDate :d="row.created_at" /></td>
        <td>
          <DeleteWithConfirm
            enabled
            background-color="danger"
            @delete="archive(row)"
          >
            <template #buttonIcon>
              <FontAwesomeIcon :icon="faTimes" />
            </template>
            <template #question>
              Are you sure you want to remove the binding on
              {{ row.product.title }}?
            </template>
          </DeleteWithConfirm>
        </td>
      </template>
    </SHTable>
  </article>
</template>

<style lang="scss" scoped>
.binding-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border: thin solid var(--color-surface-300);

  columns: 2;
  padding: var(--padding);
  border-radius: var(--border-radius);

  > input[type="checkbox"] {
    accent-color: var(--color-primary);
  }
}
</style>
