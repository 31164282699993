<script setup lang="ts">
import { computed, toRefs, watch, shallowRef } from "vue";
import type { Editor } from "@tiptap/vue-3";
import { type JSONContent, EditorContent } from "@tiptap/vue-3";

import { useTipTap } from "@/composables/useTipTap";

const props = defineProps<{
  modelValue: JSONContent | null | undefined;
  text?: string | null | undefined;
  editor?: Editor;
  editable?: boolean;
  inline?: boolean;
  rows?: number;
}>();

const { editable } = toRefs(props);

const emit = defineEmits<{
  (e: "update:model-value", value: JSONContent | null | undefined): void;
  (e: "update:text", value: string): void;
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val);
  }
});

// Use the prop if provided, else create our own Editor instance
const editor = props.editor ? shallowRef(props.editor) : useTipTap(modelValue);

/**
 * If you are going to provide an Editor instance via props to this component
 * you must make sure that the component does not render until that editor instance exists.
 *
 * Otherwise this component will run setup() while the prop is undefined and
 * instantiate its own editor.
 *
 * This can be achieved simply by using v-if="editor" e.g.
 *
 *  <SHTextEditor
 *    v-if="editor"
 *    :editor="editor"
 *  />
 *
 *
 * Don't ask why, but editor instantiation inside useEditor() is async
 */
watch(
  () => props.editor,
  editorProp => {
    if (editorProp && editorProp !== editor.value) {
      console.warn(
        'Editor was propped but initalized as undefined. You are probably missing a v-if="editor" condition.'
      );

      /**
       * Attempt to swap out this component's editor for the propped editor instance
       */
      editor.value = editorProp;
    }
  }
);

// Extend the editor inside a watch statement because it may be undefined during setup()
// Don't ask why, but its instantiation is async
watch(editor, () => {
  if (!editor.value) {
    return;
  }

  // We can extend the Editor instance here. For example, we will add another way to model
  // the editor content as text.
  editor.value.on("update", ({ editor }) => {
    emit("update:text", editor.getText());
  });

  editor.value?.setEditable(editable.value);
});

// Wire up reactivity for the editor's "editable" state
watch(editable, val => editor.value?.setEditable(val));

const rowsCss = computed(() => `${(props.rows || 8) * 1.5}em`);
</script>

<template>
  <EditorContent
    class="sh-editor"
    :class="{ editable, inline }"
    :editor="editor"
  />
</template>

<style lang="scss" scoped>
.sh-editor {
  :deep(.ProseMirror) {
    a {
      color: var(--color-primary-500);
    }

    p {
      margin: 0;
    }
  }

  &.editable {
    :deep(.ProseMirror) {
      padding: 0.25em;
    }
  }

  &.editable {
    background: var(--color-surface-100);
    border-radius: var(--border-radius);
    border: solid 1px var(--color-surface-200);

    &.inline {
      :deep(.ProseMirror) {
        min-height: 2em;
      }
    }

    :deep(.ProseMirror) {
      min-height: v-bind(rowsCss);
    }
  }
}
</style>
