<script setup lang="ts">
import { graphql } from "@/generated";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import ProductForm, {
  type ProductFormUpdates
} from "@/components/ProductForm.vue";
import SHButton from "@/components/SHButton.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { useLogger } from "@/logger";

const { log } = useLogger("ProductEdit"); // eslint-disable-line @typescript-eslint/no-unused-vars

const router = useRouter();
const { createToast } = useToaster();

const { productId, catalogId } = defineProps<{
  catalogId: string;
  productId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query ProductForm($id: uuid!) {
      products_by_pk(id: $id) {
        ...ProductForm
      }
    }
  `),
  variables: { id: productId }
});

const product = computed(() => data.value?.products_by_pk);

const updateMutation = graphql(/* GraphQL */ `
  mutation ProductEdit($productId: uuid!, $product: products_set_input) {
    update_products_by_pk(pk_columns: { id: $productId }, _set: $product) {
      id
    }
  }
`);

const { executeMutation: updateProduct } = useMutation(updateMutation);

const updates = ref<ProductFormUpdates>({
  product: null
});

async function onSubmit() {
  if (!updates.value) {
    return;
  }

  const { data: updateProductData, error: updateProductError } =
    await updateProduct({
      productId,
      product: updates.value.product
    });

  if (updateProductError) {
    createToast({
      title: `Unable to edit the product.`,
      message: updateProductError.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  }

  if (updateProductData) {
    createToast({
      message: `Product edited.`,
      theme: "success"
    });
    router.replace({
      name: "CatalogDetail"
    });
  }
}

const isValid = computed(() => {
  return !!updates.value.product;
});

async function onArchiveProduct() {
  const { data: updateProductResult, error: updateProductError } =
    await updateProduct({
      productId,
      product: {
        deleted_at: "now()"
      }
    });

  if (updateProductError) {
    createToast({
      title: "Unable to archive the product.",
      message: updateProductError.message || "Unknown error.",
      theme: "danger"
    });
  }

  if (updateProductResult) {
    createToast({
      message: "Product archived.",
      theme: "success"
    });
    router.push({
      name: "CatalogDetail"
    });
  }
}

watch(data, console.log);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="data && product" class="product-edit vertical">
    <section class="vertical">
      <ProductForm
        v-model:updates="updates"
        :options="data"
        :catalog-id="catalogId"
        :product="product"
      />
      <div class="level-spread">
        <SHButton color="danger" @click="onArchiveProduct">Archive</SHButton>
        <UndoConfirm
          class="level-spread"
          :confirm-enabled="isValid"
          @confirm="onSubmit"
          @undo="$router.back"
        />
      </div>
    </section>
  </article>
</template>
