<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import DeleteWithConfirm from "@/components/DeleteWithConfirm.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UserLink from "@/components/UserLink.vue";
import { useRole } from "@/composables/useRole";
import { formatBytes } from "@/formatters";
import { graphql } from "@/generated";
import type { ReportsTableFragment } from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { useSubscription } from "@urql/vue";
import { differenceInMinutes } from "date-fns";
import { computed } from "vue";

const STALE_MINUTES_TIMEOUT = 1;
const { can } = useRole();
const currentUser = injectStrict(CurrentUserKey);
const props = defineProps<{
  row: ReportsTableFragment;
}>();

const shouldSubscribe = computed(
  () => isStale(props.row.updated_at) || !!props.row.url
);

const { data: subscriptionData } = useSubscription(
  {
    query: graphql(/* GraphQL */ `
      subscription ReportsTableRow($id: uuid!) {
        report_uploads_by_pk(id: $id) {
          id
          url
          content_size
        }
      }
    `),
    variables: computed(() => ({
      id: props.row.id
    })),
    pause: shouldSubscribe
  },
  (data, res) => {
    return res;
  }
);

const emit = defineEmits<{
  (e: "delete", value: ReportsTableFragment): void;
}>();

const url = computed(
  () => subscriptionData.value?.report_uploads_by_pk?.url ?? props.row.url
);

const bytes = computed(
  () =>
    subscriptionData.value?.report_uploads_by_pk?.content_size ??
    props.row.content_size
);

function isStale(d: string) {
  return differenceInMinutes(new Date(), new Date(d)) >= STALE_MINUTES_TIMEOUT;
}

const canDelete = computed(
  () =>
    can("report_uploads:manage_others") ||
    (can("report_uploads:manage_self") &&
      props.row.author_id === currentUser.value.id)
);
</script>

<template>
  <tr>
    <td><SHInlineDate :d="row.updated_at" format="long_time" /></td>
    <td>{{ row.title }}</td>
    <td><UserLink :user="row.author" /></td>
    <td>
      <AppLink v-if="url" :href="url" :download="row.filename">
        Download ({{ formatBytes(bytes) }})
      </AppLink>
      <span v-else-if="isStale(row.updated_at)">
        Error
        <AppLink
          v-if="row.report_type === 'TicketReport'"
          :to="{
            name: 'TicketReportForm',
            params: { ticketId: row.report_params.ticketId },
            query: {
              ticketCustomFormEntryIds:
                row.report_params.ticketCustomFormEntryIds
            }
          }"
        >
          Regenerate
        </AppLink>
        <AppLink
          v-if="row.report_type === 'ServiceReport'"
          :to="{
            name: 'CreateServiceReportFromAgent',
            params: {
              agentId: row.report_params.userId
            },
            query: {
              customerId: row.report_params.customerId,
              startDate: row.report_params.startDate,
              endDate: row.report_params.endDate,
              includeMedia: row.report_params.includeMedia
            }
          }"
        >
          Regenerate
        </AppLink>
      </span>
      <span v-else class="level tight">
        <SHSpinner size="sm" />
        Generating...
      </span>
    </td>
    <td>
      <DeleteWithConfirm
        :enabled="canDelete"
        size="sm"
        @delete="emit('delete', row)"
      />
    </td>
  </tr>
</template>

<style lang="scss" scoped></style>
