<script async setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import SHLogo from "@/components/SHLogo.vue";
import SHWordMark from "@/components/SHWordMark.vue";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey, OrganizationKey } from "@/providerKeys";
import { faCheck } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import TermsOfServiceHTML from "../../../public/legal/terms_of_service.html?raw";

const { disabled } = defineProps<{
  disabled?: boolean;
}>();

const currentUser = injectStrict(CurrentUserKey);
const organization = injectStrict(OrganizationKey);
const checked = ref(false);

const { data, fetching, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query EULAChecker($agentId: String!, $orgName: String!) {
      agent: organization_agents_by_pk(
        agent_id: $agentId
        organization_name: $orgName
      ) {
        eula_accepted_at
        agent {
          id
        }
      }
    }
  `),
  variables: computed(() => ({
    agentId: currentUser.value?.id ?? "unknown user",
    orgName: organization.value?.short_name ?? "unknown org"
  })),
  pause: computed(() => disabled)
});

const eulaAccepted = computed(
  () => currentUser.value.isSupport || !!data.value?.agent?.eula_accepted_at
);

if (error.value) {
  console.error(error.value);
}

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation AcceptEULA {
      accept_eula {
        agent {
          id
        }
      }
    }
  `)
);

const agreeToEULA = async () => {
  const { data, error } = await executeMutation({});
  console.log("OK", data, error);
};
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <article class="EULAGuard">
    <div v-if="disabled || (!error && !fetching && eulaAccepted)">
      <slot />
    </div>

    <div v-else class="prompt xmargin">
      <div class="level tight">
        <div class="level tight">
          <SHLogo class="shlogo" />
          <SHWordMark />
        </div>
      </div>

      <h1>License Agreement (EULA)</h1>

      <div class="license" v-html="TermsOfServiceHTML" />

      <div class="level loose">
        <div class="vertical loose">
          <label>
            <input v-model="checked" type="checkbox" />
            I have read the License Agreement and agree to its terms.
          </label>
          <SHButton color="success" :disabled="!checked" @click="agreeToEULA">
            <FontAwesomeIcon :icon="faCheck" />
            Agree
          </SHButton>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.EULAGuard {
  .license {
    border-top: 2px solid var(--color-surface-600);
    border-bottom: 2px solid var(--color-surface-600);
    padding: 1em;
    max-height: 55vh;
    overflow: auto;
    max-width: 8.5in;
  }
  .prompt {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin: 1em;

    .shlogo {
      max-width: 3em;
      max-height: 3em;
    }
  }
}
</style>
