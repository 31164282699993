<script async setup lang="ts">
import AgentShiftCard from "@/components/AgentShiftCard.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const props = defineProps<{
  shiftHash: string;
}>();

const {
  data: shiftData,
  fetching: shiftFetching,
  error: shiftError
} = await useQuery({
  query: graphql(/* GraphQL */ `
    query AgentShiftDetail($shiftHash: String!) {
      agent_shifts(where: { hash: { _eq: $shiftHash } }, limit: 1) {
        ...AgentShiftCard
      }
    }
  `),
  variables: computed(() => ({ shiftHash: props.shiftHash }))
});

const shift = computed(() => shiftData.value?.agent_shifts.at(0));
</script>

<template>
  <SHSpinner v-if="shiftFetching" />
  <SHNote v-else-if="shiftError" theme="danger">
    <p>Error loading shift: {{ shiftError.message }}</p>
  </SHNote>
  <article v-else class="agent-shift-detail">
    <h1>Agent Shift Detail</h1>
    <AgentShiftCard
      v-if="shift"
      :shift="shift"
      @deleted="$router.replace({ name: 'AgentDetailShiftsView' })"
      @updated="
        $router.replace({
          name: 'AgentShiftDetail',
          params: { shiftHash: $event }
        })
      "
    />
  </article>
</template>

<style lang="scss" scoped>
.agent-shift-detail {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>
