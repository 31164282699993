<script setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import PriceBookForm, {
  type PriceBookFormUpdates
} from "@/components/PriceBookForm.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";

const router = useRouter();
const { createToast } = useToaster();

const { priceBookId } = defineProps<{
  priceBookId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query PriceBookEdit($priceBookId: uuid!) {
      price_books_by_pk(id: $priceBookId) {
        id
        title

        ...PriceBookForm
      }
    }
  `),
  variables: {
    priceBookId
  }
});

const updateMutation = graphql(/* GraphQL */ `
  mutation PriceBookEditUpdate(
    $priceBookId: uuid!
    $priceBook: price_books_set_input!
  ) {
    update_price_books_by_pk(
      pk_columns: { id: $priceBookId }
      _set: $priceBook
    ) {
      id
    }
  }
`);

const { executeMutation: update } = useMutation(updateMutation);

const priceBook = computed(() => data.value?.price_books_by_pk);

const variables = ref<PriceBookFormUpdates | null>(null);

async function onSubmit() {
  if (variables.value) {
    const { data, error } = await update({
      priceBookId,
      priceBook: variables.value
    });

    if (error) {
      createToast({
        message: error.message || "Unknown error.",
        theme: "danger",
        lifetimeMS: 5000,
        title: "Pricebook not updated."
      });
    }

    if (data) {
      createToast({
        message: "Pricebook updated.",
        theme: "success"
      });
      router.push({
        name: "PriceBookDetail"
      });
    }
  }
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>
  <article v-else-if="data" class="vertical xmargin">
    <section>
      <PriceBookForm
        v-if="data && priceBook"
        v-model:updates="variables"
        :price-book="priceBook"
        @submit="onSubmit"
      />
    </section>

    <section>
      <UndoConfirm
        :confirm-enabled="true"
        @confirm="onSubmit()"
        @undo="router.push({ name: 'PriceBookDetail' })"
      />
    </section>
  </article>
</template>
