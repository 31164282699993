import { createApp } from "vue";

import "./assets/scss/index.scss";

import { config } from "@fortawesome/fontawesome-svg-core"; // Disabled for SSR reasons
config.autoAddCss = false;

import App from "./App.vue";

const app = createApp(App);
app.mount("#app");
