import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";
import DashboardView from "@/views/DashboardView.vue";

import ModuleHeader from "@/components/ModuleHeader.vue";
import { faCircleUser } from "@fortawesome/sharp-light-svg-icons";

export const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "Dashboard",
    components: {
      default: h(DashboardView, { class: "xmargin" }),
      header: h(ModuleHeader, { icon: faCircleUser }, () => "Dashboard")
    }
  }
];
