<script setup lang="ts">
import { ref } from "vue";

const { initialValue: modelValue = false } = defineProps<{
  initialValue?: boolean;
}>();

const on = ref(modelValue);

function toggle() {
  on.value = !on.value;
}

function enable() {
  on.value = true;
}

function disable() {
  on.value = false;
}
</script>

<template>
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <slot
    v-bind="{
      on,
      toggle,
      enable,
      disable
    }"
  ></slot>
</template>
