import type { RouteRecordRaw } from "vue-router";
import organizationRoutes from "./organizationRoutes";

import ModuleHeader from "@/components/ModuleHeader.vue";
import ChangeLog from "@/views/ChangeLog.vue";
import LogoutView from "@/views/LogoutView.vue";
import ThemeDump from "@/views/ThemeDump.vue";
import { faSparkles } from "@fortawesome/sharp-light-svg-icons";
import { h } from "vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/changelog",
    name: "ChangeLog",
    components: {
      default: ChangeLog,
      header: h(ModuleHeader, { icon: faSparkles }, () => "Change Log")
    }
  },
  {
    path: "/theme",
    name: "ThemeDump",
    component: ThemeDump
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutView
  },
  {
    path: "/",
    children: organizationRoutes
  }
];
