<template>
  <div class="sidebar-divider"></div>
</template>

<style lang="scss" scoped>
div.sidebar-divider {
  background: radial-gradient(
    var(--color-secondary) 10%,
    var(--color-secondary-opacity-50) 60%
  );
  height: 1px;
  width: 100%;
}
</style>
