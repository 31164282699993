<script lang="ts">
import component from "./TicketLinkNode.vue";
import { Node as TipTapNode } from "@tiptap/core";
import { VueNodeViewRenderer, mergeAttributes } from "@tiptap/vue-3";

export const Node = TipTapNode.create({
  name: "ticketLink",
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      ticket: {
        default: null,
        parseHTML: el => {
          const ticket = el.getAttribute("data-ticket");

          return {
            user: ticket ? JSON.parse(ticket) : null
          };
        },
        renderHTML: attributes => ({
          "data-ticket": JSON.stringify(attributes.user)
        })
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-ticket]`
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  renderText({ node }) {
    return `!${node.attrs.ticket.ref}`;
  },

  addNodeView() {
    return VueNodeViewRenderer(component);
  }
});
</script>

<script setup lang="ts">
import { NodeViewWrapper, type NodeViewProps } from "@tiptap/vue-3";
import TicketLink from "@/components/TicketLink.vue";

const props = defineProps<{
  node: NodeViewProps["node"];
}>();

const ticket = props.node.attrs.ticket;
</script>

<template>
  <NodeViewWrapper class="ticket-link-node" as="div">
    <TicketLink v-if="ticket" :ticket="ticket" prefix="!" />
  </NodeViewWrapper>
</template>

<style lang="scss">
.ticket-link-node {
  display: inline;
}
</style>
