<script setup lang="ts">
import AgentSummary from "@/components/Dashboards/AgentSummary.vue";
import UserLink from "@/components/UserLink.vue";
import {
  Order_By,
  type Interfaces_Daily_Logs_Summary_Bool_Exp,
  type Organization_Agents_Bool_Exp
} from "@/generated/graphql";
import { startOfToday, subDays } from "date-fns";
import SHInlineDate from "../SHInlineDate.vue";
import SHPill from "../SHPill.vue";

const DAYS_BACK = 7; // how far back to look from today
const OVERWORK_HOUR_THRESHOLD = 16; // red warnings

const activityWhere: Interfaces_Daily_Logs_Summary_Bool_Exp = {
  day_started_at: {
    _gte: subDays(startOfToday(), DAYS_BACK)
  },
  total_hours: {
    _gte: OVERWORK_HOUR_THRESHOLD
  }
};

const agentsWhere: Organization_Agents_Bool_Exp = {
  shifts: {
    total_hours: { _gte: OVERWORK_HOUR_THRESHOLD },
    started_at: {
      _gte: subDays(startOfToday(), DAYS_BACK)
    }
  }
};
</script>

<template>
  <AgentSummary
    :orderBy="[{ agent: { full_name: Order_By.Asc } }]"
    :agentsWhere="agentsWhere"
    :activityWhere="activityWhere"
  >
    Overloaded Agents
    <template #perAgent="{ agent, activity }">
      <div class="level tight wrap">
        <UserLink :user="agent" />
        has over worked recent days
        <div class="level tight wrap">
          <template v-for="d of activity" :key="d.day">
            <SHPill class="number" color="var(--color-danger)">
              <template #left>
                <SHInlineDate :d="d.day_started_at" format="month_name_day" />
              </template>
              <template #right>
                {{
                  new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2
                  }).format(d.total_hours ?? 0)
                }}
                hr
              </template>
            </SHPill>
          </template>
        </div>
      </div>
    </template>
    <template #none>
      <div>There are no overloaded agents.</div>
    </template>
  </AgentSummary>
</template>
