<script lang="ts">
export interface WorkSiteChoice extends ListOption {
  title: string;
}
</script>

<script setup lang="ts">
import { graphql } from "@/generated/gql";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import type { ListOption } from "@/types";
import SHNote from "./SHNote.vue";

const { customerId = "", includeHomeBase } = defineProps<{
  customerId?: string; // disabled if we don't know
  includeHomeBase?: boolean;
  modelValue?: WorkSiteChoice | null;
  multiple?: WorkSiteChoice[];
}>();

defineEmits<{
  (e: "update:modelValue", value: WorkSiteChoice): void;
  (e: "update:multiple", value: WorkSiteChoice[]): void;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query SHWorkSiteChooser($customerId: uuid!) {
      work_sites(
        order_by: [{ title: asc }]
        where: {
          customer_id: { _eq: $customerId }
          title: { _neq: "TBD" }
          deleted_at: { _is_null: true }
        }
      ) {
        id
        title
      }
      tbdWorkSites: work_sites(
        limit: 1
        where: {
          customer: { id: { _eq: $customerId } }
          title: { _eq: "TBD" }
          deleted_at: { _is_null: true }
        }
      ) {
        id
        title
      }
    }
  `),
  variables: computed(() => ({
    customerId
  })),
  pause: computed(() => !customerId)
});

const HOME_BASE_ID = "home_base";
const homeBaseWorkSite = { id: HOME_BASE_ID, label: "Home Base" };

const workSites = computed<WorkSiteChoice[]>(() => {
  const workSiteList = data.value?.work_sites ?? [];
  let allOptions = [];
  const tbd = data.value?.tbdWorkSites ?? [];
  if (includeHomeBase) {
    allOptions = [
      homeBaseWorkSite,
      ...tbd,
      ...workSiteList
    ] as WorkSiteChoice[];
  } else {
    allOptions = [...tbd, ...workSiteList] as WorkSiteChoice[];
  }
  return allOptions;
});
</script>
<template>
  <SHNote v-if="error" theme="danger">{{ error }}</SHNote>
  <main v-else>
    <ComboBox
      enable-fuse
      :fuse-options="{
        keys: ['title'],
        threshold: 0.5
      }"
      :disabled="!customerId || !workSites"
      :options="workSites"
      :limit="100"
      :loading="fetching"
      mobile-header="Choose Worksite"
      placeholder="Choose Worksite..."
      :multiple="multiple"
      :model-value="modelValue"
      @update:multiple="$emit('update:multiple', $event as WorkSiteChoice[])"
      @update:model-value="$emit('update:modelValue', $event as WorkSiteChoice)"
    />
  </main>
</template>
