<script setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import { injectStrict } from "@/lib/helpers";
import { DisableFormKey } from "@/providerKeys";

const {
  confirmEnabled = false,
  hideUndo = false,
  confirmText = "Submit",
  undoText = "Cancel"
} = defineProps<{
  confirmEnabled?: boolean | null;
  hideUndo?: boolean;
  confirmText?: string;
  undoText?: string;
}>();

defineEmits<{
  (e: "undo"): void;
  (e: "confirm"): void;
}>();

const injectedDisabled = injectStrict(DisableFormKey);
</script>

<template>
  <article class="noprint level-end tight">
    <SHButton v-if="!hideUndo" class="undo" @click="$emit('undo')">
      <slot name="undo">{{ undoText }}</slot>
    </SHButton>

    <SHButton
      class="confirm"
      :disabled="injectedDisabled || !confirmEnabled"
      color="primary"
      @click="$emit('confirm')"
    >
      <slot name="confirm">{{ confirmText }}</slot>
    </SHButton>
  </article>
</template>
