<script setup lang="ts">
import type { DataSet } from "@incytestudios/ddv";
import { BarChart } from "@incytestudios/ddv";
import { computed } from "vue";

export type ActivityPoint = {
  day: Date;
  work_hours: number;
  travel_hours: number;
  ticket_ids: string[];
};

const props = defineProps<{
  points: ActivityPoint[];
}>();

const dataSets = computed<DataSet[]>(() => [
  {
    title: "Labor",
    points: props.points?.map(p => ({
      x: p.day,
      y: p.work_hours
    })),
    yAxisLabel: "hours",
    xAxisLabel: "date"
  },
  {
    title: "Travel",
    points: props.points?.map(p => ({
      x: p.day,
      y: p.travel_hours
    })),
    yAxisLabel: "hours",
    xAxisLabel: "date"
  }
]);
</script>

<template>
  <BarChart
    :data-sets="dataSets"
    :allow-full-screen="false"
    enable-x-panning
    enable-x-zooming
    stack-series
  />
</template>
