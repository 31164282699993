const IMGPROXY_PUBLIC_URL = import.meta.env.SSR
  ? process.env.IMGPROXY_PUBLIC_URL
  : import.meta.env.VITE_IMGPROXY_PUBLIC_URL ??
    window.config.IMGPROXY_PUBLIC_URL;

export function useImage() {
  return {
    // Read https://docs.imgproxy.net/usage/processing before editing these functions
    default(url: string) {
      return getProxyUrl(url, "q:75");
    },
    thumbnail(url: string) {
      return getProxyUrl(url, "q:65/rs:auto:512");
    },
    reportThumbnail(url: string) {
      return getProxyUrl(url, "q:38/rs:auto:256");
    },
    lightbox(url: string, responsive: boolean = false) {
      if (responsive) {
        const phoneImage = getProxyUrl(url, "q:50/s:430");
        const phoneLandscapeImage = getProxyUrl(url, "q:50/s:600");
        const tabletImage = getProxyUrl(url, "q:75/s:768");
        const laptopImage = getProxyUrl(url, "q:75/s:992");
        const desktopImage = getProxyUrl(url, "q:75/s:1400");
        return `${phoneImage} 430w, ${phoneLandscapeImage} 600w, ${tabletImage} 768w, ${laptopImage} 992w, ${desktopImage} 1450w`;
      } else {
        return getProxyUrl(url, "q:75/s:430");
      }
    }
  };
}

export type ImageType = keyof ReturnType<typeof useImage>;

function getProxyUrl(url: string, optionsUri: string) {
  const encodedUrl = btoa(url);
  return `${IMGPROXY_PUBLIC_URL}/_/${optionsUri}/${encodedUrl}.webp`;
}
