import ModuleHeader from "@/components/ModuleHeader.vue";
import ReportsView from "@/views/ReportsView.vue";
import { faFolderBookmark } from "@fortawesome/sharp-light-svg-icons";
import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";

export const reportRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "ReportsView",
    components: {
      default: h(ReportsView, { class: "xmargin" }),
      header: h(
        ModuleHeader,
        { icon: faFolderBookmark },
        { default: () => "Reports" }
      )
    }
  }
];
