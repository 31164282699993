<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { getCurrentInstance } from "vue";

import { routes } from "@/router";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

Sentry.vueRouterInstrumentation(router);

const app = getCurrentInstance()?.appContext.app;
app?.use(router);
</script>

<template>
  <div>
    <slot></slot>
  </div>
</template>
