<script setup lang="ts">
import { useImage, type ImageType } from "@/composables/useImage";

const {
  type = "default",
  proxyRequest = true,
  responsive = false,
  src = ""
} = defineProps<{
  alt: string;
  proxyRequest?: boolean;
  src: string;
  responsive?: boolean;
  type?: ImageType;
}>();

const imgproxy = useImage();
</script>

<template>
  <img
    v-if="responsive"
    class="sh-image"
    :class="[type]"
    :srcset="responsive && proxyRequest ? imgproxy[type](src, responsive) : ''"
    :alt="alt"
  />
  <img
    v-else
    class="sh-image"
    :class="[type]"
    :src="proxyRequest ? imgproxy[type](src) : src"
    :alt="alt"
  />
</template>

<style lang="scss" scoped>
.sh-image {
  user-select: none;
  &.thumbnail {
    img {
      max-width: 40em;
    }
  }
  background-image: url("/img/mark.png");
  background-size: 48%;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 12em;
  min-width: 8em;
}
</style>
