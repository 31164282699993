import { graphql } from "@/generated";

import { Node } from "../WorkOrderLinkNode.vue";

import defineMention from "./defineMention";

const query = graphql(/* GraphQL */ `
  query WorkOrderSuggestion($searchText: String!) {
    items: work_orders(
      where: { author: { fname: { _ilike: $searchText } } }
      limit: 5
    ) {
      id
      ref
      ...WorkOrderLink
    }
  }
`);

export default defineMention({
  char: "#",
  query,
  getAttrs: i => ({ workOrder: i }),
  getLabel: i => i.ref,
  node: Node
});
