<script lang="ts">
export type UnifiedTBD = {
  __typename: string;
  id: string;
  updated_at: string;
  guess?: string;
  to_work_site_id?: string;
  from_work_site_id?: string;
};

export type TBDRow = { id: string; guess: string; tbds: UnifiedTBD[] };
</script>

<script setup lang="ts">
import { useFuse } from "@/composables/useFuse";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

import SHBadge from "@/components/SHBadge.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "./SHTableRowCount.vue";

const props = defineProps<{
  modelValue: TBDRow[];
  customerId: string;
  query?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", selectedGuesses: TBDRow[]): void;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query TBDMatcher($customerId: uuid!) {
      tickets(
        where: {
          work_site_unconfirmed: { _is_null: false }
          work_order: { customer_id: { _eq: $customerId } }
          deleted_at: { _is_null: true }
        }
        order_by: [{ updated_at: desc }]
      ) {
        id
        updated_at
        ...TicketLink
        guess: work_site_unconfirmed
      }

      tlOrigins: travel_logs(
        where: {
          ticket: { work_order: { customer_id: { _eq: $customerId } } }
          from_work_site_unconfirmed: { _is_null: false }
          deleted_at: { _is_null: true }
        }
        order_by: [{ updated_at: desc }]
      ) {
        id
        updated_at
        from_work_site_id
        guess: from_work_site_unconfirmed
      }

      tlDestinations: travel_logs(
        where: {
          ticket: { work_order: { customer_id: { _eq: $customerId } } }
          to_work_site_unconfirmed: { _is_null: false }
          deleted_at: { _is_null: true }
        }
        order_by: [{ updated_at: desc }]
      ) {
        id
        updated_at
        to_work_site_id
        guess: to_work_site_unconfirmed
      }
    }
  `),
  variables: computed(() => ({ customerId: props.customerId })),
  context: { additionalTypenames: ["tickets", "travel_logs"] }
});

const unifiedTBDs = computed<TBDRow[]>(() => {
  const everything = [
    ...(data.value?.tickets || []),
    ...(data.value?.tlOrigins || []),
    ...(data.value?.tlDestinations || [])
  ] as UnifiedTBD[];

  const grouped = everything.reduce<Record<string, UnifiedTBD[]>>(
    (acc, tbd) => {
      const lowered = (tbd.guess || "NO GUESS").toLowerCase();
      if (!acc[lowered]) {
        acc[lowered] = [];
      }
      acc[lowered].push(tbd);
      return acc;
    },
    {}
  );

  return Object.keys(grouped)
    .sort()
    .map(guess => ({
      id: guess.toLowerCase(),
      guess: guess.toLowerCase(),
      tbds: grouped[guess]
    }));
});

const selectedGuesses = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value)
});

// this exists to keep state on top of whatever the query string says
const queryBuffer = ref<string | null>(null);
const form = computed({
  get: () => queryBuffer.value ?? props.query,
  set: value => (queryBuffer.value = value || "")
});

const { result } = useFuse(form, unifiedTBDs, {
  threshold: 0.5,
  keys: ["guess"]
});
</script>

<template>
  <article class="tbd-matcher vertical loose">
    <h3>Unconfirmed Worksites</h3>

    <SHNote v-if="unifiedTBDs.length" theme="warning">
      These are work sites that were originally chosen as TBD on tickets and/or
      travel logs. By selecting them below and submitting the form,
      <strong>this worksite</strong>
      will replace
      <strong>TBD</strong>
      on the relevant logs and tickets.
    </SHNote>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>

    <div v-else class="vertical tight">
      <SHField label="Search unconfirmed worksites:">
        <SHInput v-model="form" />
      </SHField>

      <SHTable
        :rows="result"
        fluid
        clickable
        empty-message="No unconfirmed worksites found for this customer."
        @row:click="
          r =>
            selectedGuesses.find(sg => sg.id === r.id)
              ? (selectedGuesses = selectedGuesses.filter(sg => sg.id !== r.id))
              : selectedGuesses.push(r)
        "
      >
        <template #header>
          <th width="10%">Replace?</th>
          <th>Original Guess</th>
        </template>

        <template #row="{ row }">
          <td>
            <input v-model="selectedGuesses" type="checkbox" :value="row" />
          </td>
          <td class="level-spread loose">
            {{ row.guess }}
            <SHBadge class="number">{{ row.tbds.length }}</SHBadge>
          </td>
        </template>

        <template #footer>
          <SHTableRowCount
            :count="result.length"
            label="unconfirmed worksite"
          />
        </template>
      </SHTable>
    </div>
  </article>
</template>

<style lang="scss" scoped></style>
