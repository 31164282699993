<script setup lang="ts">
import { useRole } from "@/composables/useRole";
import { injectStrict } from "@/lib/helpers";
import { bottomNavigationOrder } from "@/mainNavigation";
import { CurrentUserKey, DarkModeKey, OrganizationKey } from "@/providerKeys";
import { ref } from "vue";

import AppLink from "@/components/AppLink.vue";
import ThemeChooser from "@/components/ThemeChooser.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import DynamicRoleSwitcher from "@/components/MainNavigation/DynamicRoleSwitcher.vue";
import OrgLogo from "@/components/OrgLogo.vue";
import UserLink from "@/components/UserLink.vue";
import { useFeatureFlags } from "@/composables/useFeatureFlags";
import { useHubSpotChat } from "@/composables/useHubSpotChat";
import type { NavigationNode } from "@/types";
import {
  faCircleQuestion,
  faRefresh
} from "@fortawesome/sharp-light-svg-icons";
import { useRouter } from "vue-router";
import DarkModeToggle from "../DarkModeToggle.vue";
import SHButton from "../SHButton.vue";
import SHSpinner from "../SHSpinner.vue";
import UserAvatar from "../UserAvatar.vue";

const { can } = useRole();
const { has } = useFeatureFlags();
const currentUser = injectStrict(CurrentUserKey);
const router = useRouter();
const { open: openChatWidget, loading: chatLoading } = useHubSpotChat();

defineProps<{
  showUpdateButton: boolean;
}>();

const emit = defineEmits<{
  (e: "update-app"): void;
}>();

function goToAgentDetails() {
  isOpen.value = !isOpen.value;
  router.push({
    name: "AgentDetail",
    params: { agentId: currentUser.value?.id }
  });
}
const isDark = injectStrict(DarkModeKey);
const currentOrg = injectStrict(OrganizationKey);
const isOpen = ref(false);

const subMenuOpen = ref<number | undefined>();

const toggleMainMenu = () => {
  subMenuOpen.value = undefined;
  isOpen.value = !isOpen.value;
};

const toggleSub = (itemIndex: number) => {
  isOpen.value = false;
  subMenuOpen.value = subMenuOpen.value === itemIndex ? undefined : itemIndex;
};

const mainIconProps = {
  size: "xl",
  fixedWidth: true
} as const;

const hasPermission = (node: NavigationNode): boolean =>
  (!node.allowedPermissions?.length ||
    node.allowedPermissions.some(p => can(p))) &&
  (!node.allowedWithFeatureFlags?.length ||
    node.allowedWithFeatureFlags.some(f => has(f)));
</script>

<template>
  <article class="bottom-navigation">
    <nav
      class="main-nav"
      :class="{ isOpen, subMenuOpen: subMenuOpen !== undefined }"
    >
      <AppLink :active="subMenuOpen === 0" @click.prevent="toggleSub(0)">
        <FontAwesomeIcon
          v-if="bottomNavigationOrder[0].icon"
          :icon="bottomNavigationOrder[0].icon"
          v-bind="mainIconProps"
        />
      </AppLink>

      <AppLink :active="subMenuOpen === 1" @click.prevent="toggleSub(1)">
        <FontAwesomeIcon
          v-if="bottomNavigationOrder[1].icon"
          :icon="bottomNavigationOrder[1].icon"
          v-bind="mainIconProps"
        />
      </AppLink>

      <a class="middle" @click="toggleMainMenu">
        <OrgLogo :organization="currentOrg" :dark="isDark" square />
      </a>

      <AppLink :active="subMenuOpen === 2" @click.prevent="toggleSub(2)">
        <FontAwesomeIcon
          v-if="bottomNavigationOrder[2].icon"
          :icon="bottomNavigationOrder[2].icon"
          v-bind="mainIconProps"
        />
      </AppLink>

      <AppLink :active="subMenuOpen === 3" @click.prevent="toggleSub(3)">
        <FontAwesomeIcon
          v-if="bottomNavigationOrder[3].icon"
          :icon="bottomNavigationOrder[3].icon"
          v-bind="mainIconProps"
        />
      </AppLink>
    </nav>

    <nav
      class="sub-nav"
      :class="{
        isOpen: subMenuOpen !== undefined,
        isRight: subMenuOpen !== undefined && [3, 2].includes(subMenuOpen)
      }"
    >
      <template
        v-if="subMenuOpen !== undefined && bottomNavigationOrder[subMenuOpen]"
      >
        <TransitionGroup name="list-vertical">
          <template
            v-for="(opt, i) of bottomNavigationOrder[subMenuOpen].children"
          >
            <div
              v-if="hasPermission(opt)"
              :key="i"
              :style="{
                gridRowStart: i < 4 ? 2 : 1
              }"
            >
              <router-link
                :to="opt?.to || '/'"
                inactive-class="inactive"
                active-class="active"
                class="sub"
                @click="subMenuOpen = undefined"
              >
                <FontAwesomeIcon v-if="opt.icon" :icon="opt.icon" />
                <span>{{ opt.abbreviation || opt.title }}</span>
              </router-link>
            </div>
          </template>
        </TransitionGroup>
      </template>
    </nav>

    <article class="nav-modal" :class="{ isOpen }">
      <div class="main-menu">
        <section v-if="showUpdateButton">
          <SHButton
            color="success"
            size="sm"
            style="grid-column: 2; max-width: max-content"
            @click="emit('update-app')"
          >
            <div class="level tight">
              <FontAwesomeIcon :icon="faRefresh" />
              <span>Update</span>
            </div>
          </SHButton>
        </section>
        <template v-for="(i, idx) of bottomNavigationOrder" :key="idx">
          <section v-if="i.children">
            <UserAvatar
              v-if="i.avatar"
              :seed-string="currentUser?.fname + ' ' + currentUser?.lname"
              initials-color="white"
              size="small"
              class="avatar-rounded"
              @click="goToAgentDetails"
            />
            <FontAwesomeIcon v-else-if="i.icon" :icon="i.icon" fixed-width />
            <UserLink
              v-if="i.title === 'You'"
              :user="currentUser"
              @click="isOpen = false"
            />
            <h1 v-else>
              {{ i.title }}
            </h1>
            <template v-for="(c, idx2) of i.children">
              <AppLink
                v-if="hasPermission(c)"
                :key="idx2"
                :to="c.to"
                @click="isOpen = false"
              >
                {{ c.title }}
              </AppLink>
            </template>
          </section>
        </template>

        <footer>
          <ThemeChooser />
          <DarkModeToggle />
          <div>
            <AppLink @click="openChatWidget">
              <div class="level-center tight">
                <FontAwesomeIcon
                  v-if="!chatLoading"
                  color="var(--color-surface-900)"
                  :icon="faCircleQuestion"
                />
                <SHSpinner v-else size="xs" />
                <span>Support Desk</span>
              </div>
            </AppLink>
            <DynamicRoleSwitcher />
          </div>
        </footer>
      </div>
    </article>
  </article>
</template>

<style lang="scss" scoped>
$barHeight: 10vh;
$subHeight: 27vh;

.bottom-navigation {
  position: fixed;
  bottom: 0;
  min-width: 100%;
}
.sub-nav {
  position: absolute;
  pointer-events: none; // pass through pointer events
  top: -$barHeight;
  background: transparent;
  height: $subHeight;
  min-width: 100%;
  transition: top 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 50;
  gap: 0.5em;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0.5em;

  &.isOpen {
    top: calc(0vh - $barHeight - $subHeight);
  }
  &.isRight {
    direction: rtl;
  }
  div {
    pointer-events: all;
    box-shadow: 0 0.5em 2em 0.25em var(--color-surface-200);

    a.sub {
      width: 6.25em;
      height: 6.25em;
      font-size: 0.75em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      text-decoration: none;

      border: 4px solid var(--color-surface-900);
      border-radius: var(--border-radius);
      background: var(--color-surface-900);
      color: var(--color-surface-0);

      svg {
        font-size: 1.75em;
      }

      &.active {
        border: 4px solid var(--color-surface-900);
        background: var(--color-surface-0);
        color: var(--color-surface-900);
      }
      span {
        font-weight: 600;
      }
    }
  }
}

.main-nav {
  position: absolute;
  bottom: 0;
  height: $barHeight;
  z-index: 100;
  min-width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 2em 1em;
  background: var(--org-header-bg, var(--color-surface-0));

  :deep(.sh-btn) {
    background: transparent;
    border: none;
  }

  &:not(.subMenuOpen) {
    box-shadow: 0 0 4em 0.75em var(--color-surface-600-opacity-15);
  }

  .middle {
    height: 16vw;
    width: 24vw;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 20vw;
      max-height: $barHeight - 5vh;
    }
  }
}

.nav-modal {
  position: fixed;
  transition: all 0.25s cubic-bezier(0.87, 0, 0.13, 1);
  background-color: var(--color-surface-100);
  height: calc(
    100dvh - $barHeight + 2px
  ); // extra px for high density screens where the `vh` calc leaves sub-pixel gaps
  top: calc(100dvh - $barHeight);
  width: 100vw;
  overflow-y: scroll;

  &.isOpen {
    top: 0;
    z-index: 10; // this is set to 10 to make sure the pop up appears above the main content
  }
}

.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 2em 1em;
  min-height: 100%;

  > section {
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 2em 1fr;
    width: 60vw;
    gap: 0.5em;

    > svg {
      color: var(--color-primary);
    }

    > h1 {
      font-size: 0.9em;
      font-weight: 300;
      color: var(--color-surface-400);
    }

    > :deep(.app-link) {
      font-size: 1em;
      text-decoration: none;
      color: var(--color-surface-500);
      grid-column-start: 2;

      &.active {
        color: var(--color-surface-900);
      }

      &:hover {
        // wouldn't think you need to set this for a touchscreen, but here we are
        color: var(--color-surface-500);
      }
    }
  }

  > footer {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 60vw;
  }
}
</style>
