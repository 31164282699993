<script setup lang="ts">
import { dueDateOptions, pivotOptions } from "@/components/Invoices/types";
import SHField from "@/components/SHField.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { Invoice_Pivot_Options_Enum } from "@/generated/graphql";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref, watch } from "vue";
import TimeSheetSettings from "./TimeSheetSettings.vue";

const { createToast } = useToaster();

const props = defineProps<{
  customerId: string;
}>();

const {
  data: customerData,
  error,
  fetching
} = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomerSettings($customerId: uuid!) {
      customers_by_pk(id: $customerId) {
        id
        invoice_pivot
        invoice_terms
      }
    }
  `),
  variables: computed(() => ({
    customerId: props.customerId
  }))
});

const customer = computed(() => customerData.value?.customers_by_pk);

const { executeMutation: updateCustomer } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateCustomerSettings(
      $customerId: uuid!
      $_set: customers_set_input!
    ) {
      update_customers_by_pk(pk_columns: { id: $customerId }, _set: $_set) {
        id
      }
    }
  `)
);

const form = ref({
  invoiceTerms: customer.value?.invoice_terms ?? 30,
  invoicePivot: customer.value?.invoice_pivot ?? Invoice_Pivot_Options_Enum.None
});

watch(customer, () => {
  if (customer.value) {
    form.value.invoiceTerms = customer.value?.invoice_terms;
    form.value.invoicePivot = customer.value?.invoice_pivot;
  }
});

async function onConfirm() {
  const { data: updateResult, error: updateError } = await updateCustomer({
    customerId: props.customerId,
    _set: {
      invoice_pivot: form.value.invoicePivot,
      invoice_terms: form.value.invoiceTerms
    }
  });

  if (updateError) {
    createToast({
      title: `Unable to update the customer settings.`,
      message: updateError.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  } else if (updateResult) {
    createToast({
      message: `Customer settings updated.`,
      theme: "success"
    });
  }
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="customer" class="customer-settings vertical">
    <h1>Customer Settings</h1>
    <h2>Time Sheet Settings</h2>
    <TimeSheetSettings :customer-id="customerId" />

    <h2>Customer Invoice Defaults</h2>
    <div>
      <SHField label="Invoice Terms" />
      <template v-for="o in dueDateOptions" :key="o.value">
        <label class="level tight">
          <input v-model="form.invoiceTerms" type="radio" :value="o.value" />
          <span>{{ o.label }}</span>
        </label>
      </template>
    </div>
    <div>
      <SHField label="Invoice Line Item Grouping" />
      <template v-for="o in pivotOptions" :key="o.value">
        <label class="level tight">
          <input v-model="form.invoicePivot" type="radio" :value="o.value" />
          <span>{{ o.label }}</span>
        </label>
      </template>
    </div>
    <footer>
      <UndoConfirm
        confirm-enabled
        @confirm="onConfirm"
        @undo="$router.back()"
      />
    </footer>
  </article>
</template>
