<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHField from "@/components/SHField.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTimeInput from "@/components/SHTimeInput/SHTimeInput.vue";
import type { TimeSliderRange } from "@/components/SHTimeInput/SHTimeSlider.vue";
import SHTextEditorWithToolbar from "@/components/TextEditor/SHTextEditorWithToolbar.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useConflictingLogs } from "@/composables/useConflictingLogs";
import { useTipTap } from "@/composables/useTipTap";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import type { UploadedMedia } from "@/types";
import { faPersonDigging, faTruck } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { JSONContent } from "@tiptap/vue-3";
import { useMutation, useQuery } from "@urql/vue";
import { add, isAfter, startOfHour, sub } from "date-fns";
import { v4 } from "uuid";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();
const currentUser = injectStrict(CurrentUserKey);

const { ticketId, workLogId } = defineProps<{
  ticketId: string; // we should always be under a ticket
  workLogId?: string; // set when editing an existing worklog
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query WorkLogForm(
      $fetchWorkLog: Boolean!
      $currentUserId: String!
      $workLogId: uuid!
      $oldestRelevantLog: timestamptz!
    ) {
      last_log: combined_logs(
        where: {
          author_id: { _eq: $currentUserId }
          ended_at: { _gte: $oldestRelevantLog }
          deleted_at: { _is_null: true }
        }
        limit: 1
        order_by: { ended_at: desc_nulls_last }
      ) {
        ended_at
      }

      work_logs_by_pk(id: $workLogId) @include(if: $fetchWorkLog) {
        id
        notes
        notesj
        started_at
        ended_at
        deleted_at
        author_id

        media_uploads(
          where: { deleted_at: { _is_null: true } }
          order_by: [{ created_at: asc }]
        ) {
          id
          ...MediaUploadCard
          ...SHLightbox
        }
      }
    }
  `),
  variables: computed(() => ({
    workLogId: workLogId ?? "",
    currentUserId: currentUser.value.id,
    fetchWorkLog: !!workLogId,
    oldestRelevantLog: sub(new Date(), { hours: 12 })
  })),
  pause: computed(() => !ticketId || !currentUser)
});

const form = ref<{
  id?: string;
  notes?: string;
  notesj?: JSONContent | null;
  mediaUploads?: UploadedMedia[];
  started_at?: Date;
  ended_at?: Date;
}>({
  id: workLogId ? undefined : v4(),
  started_at: undefined,
  ended_at: undefined,
  mediaUploads: data.value?.work_logs_by_pk?.media_uploads ?? []
});

const lastLog = computed(() => data.value?.last_log.at(0));

const startTime = computed({
  get() {
    if (form.value.started_at) return form.value.started_at; // they already changed the form
    if (workLog.value?.started_at) return new Date(workLog.value.started_at); // they didn't change the form, we're editing

    return lastLog.value?.ended_at
      ? new Date(lastLog.value.ended_at)
      : startOfHour(new Date());
  },
  set(value) {
    form.value.started_at = value;
  }
});

const endTime = computed({
  get() {
    return (
      form.value?.ended_at ||
      (workLog.value?.ended_at
        ? new Date(workLog.value?.ended_at)
        : add(startTime.value, { minutes: 30 }))
    );
  },
  set(value) {
    form.value.ended_at = value;
  }
});

const currentLogRange = computed<TimeSliderRange>(() => {
  return {
    start: startTime.value,
    end: endTime.value,
    theme: "primary"
  };
});

const workLog = computed(() => data.value?.work_logs_by_pk);

const UpdateWorkLogMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkLog($workLogId: uuid!, $workLog: work_logs_set_input) {
    update_work_logs_by_pk(pk_columns: { id: $workLogId }, _set: $workLog) {
      id
      ticket_id
    }
  }
`);

const InsertWorkLogMutation = graphql(/* GraphQL */ `
  mutation InsertWorkLog($workLog: work_logs_insert_input!) {
    insert_work_logs_one(object: $workLog) {
      id
      ticket_id
    }
  }
`);

const { executeMutation: update } = useMutation(UpdateWorkLogMutation);

async function onUpdate() {
  if (!workLogId) {
    throw new ReferenceError("No work log to update.");
  }

  const { data, error } = await update({
    workLogId,
    workLog: {
      notesj: form.value.notesj,
      started_at: startTime.value,
      ended_at: endTime.value
    }
  });

  if (error || !data?.update_work_logs_by_pk) {
    createToast({
      title: "Unable to save the work log.",
      message: error?.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (data.update_work_logs_by_pk) {
    createToast({
      message: `Work log saved.`,
      theme: "success"
    });
    router.go(-1);
  }
}

const { executeMutation: insert } = useMutation(InsertWorkLogMutation);

async function onInsert() {
  const { data, error } = await insert({
    workLog: {
      id: form.value.id,
      notesj: form.value.notesj,
      started_at: startTime.value,
      ended_at: endTime.value,
      ticket_id: ticketId
    }
  });

  if (error) {
    createToast({
      title: "Unable to create the work log.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (data) {
    createToast({
      message: "Work log created.",
      theme: "success"
    });
    router.go(-1);
  }
}

function onConfirm() {
  if (workLogId) {
    return onUpdate();
  }
  return onInsert();
}

const notes = computed<JSONContent | null | undefined>({
  get() {
    return form.value.notesj ?? workLog.value?.notesj;
  },
  set(val) {
    form.value.notesj = val;
  }
});

const editor = useTipTap(notes);

const hasInvalidTimeFrame = computed(
  () =>
    startTime.value && endTime.value && isAfter(startTime.value, endTime.value)
);

const conflictingAuthorId = computed(
  () => workLog.value?.author_id ?? currentUser.value.id
);

const {
  isConflicting,
  conflictingRanges,
  fetching: conflictingLogsFetching,
  activeConflicts
} = useConflictingLogs(
  conflictingAuthorId,
  workLogId,
  startTime,
  endTime,
  fetching
);

const isValid = computed(
  () => !hasInvalidTimeFrame.value && !isConflicting.value
);
</script>

<template>
  <article>
    <div class="level-spread wrap loose">
      <h3>
        <template v-if="!workLogId">New</template>
        <template v-else>Edit</template>
        Work Log
      </h3>
    </div>
    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>
    <main v-else class="vertical loose">
      <SHField label="Start Date" block>
        <SHDatePicker
          v-model="startTime"
          auto-apply
          position="left"
          class="vdp"
          enable-time-picker
          format="MMM dd, yyyy"
        />
      </SHField>

      <SHField label="Start Time" style="flex-grow: 1">
        <SHTimeInput
          v-model="startTime"
          :minute-resolution="15"
          :ranges="[...conflictingRanges, currentLogRange]"
        >
          <template #toolTip>
            <AppLink
              v-if="activeConflicts.length"
              :to="{
                name: activeConflicts[0].work_log_id
                  ? 'WorkLogEdit'
                  : 'TravelLogEdit',
                params: {
                  ticketId: activeConflicts[0].ticket_id,
                  travelLogId: activeConflicts[0].travel_log_id,
                  workLogId: activeConflicts[0].work_log_id
                }
              }"
            >
              Edit log

              <FontAwesomeIcon
                v-if="activeConflicts[0].work_log_id"
                :icon="faPersonDigging"
              />

              <FontAwesomeIcon
                v-if="activeConflicts[0].travel_log_id"
                :icon="faTruck"
              />
            </AppLink>
          </template>
        </SHTimeInput>
      </SHField>

      <SHField label="End Date" block>
        <SHDatePicker
          v-model="endTime"
          auto-apply
          position="left"
          class="vdp"
          enable-time-picker
          format="MMM dd, yyyy"
        />
      </SHField>

      <SHField label="End Time" style="flex-grow: 1">
        <SHTimeInput
          v-model="endTime"
          :minute-resolution="15"
          :ranges="[currentLogRange, ...conflictingRanges]"
        >
          <template #toolTip>
            <AppLink
              v-if="activeConflicts.length"
              :to="{
                name: activeConflicts[0].work_log_id
                  ? 'WorkLogEdit'
                  : 'TravelLogEdit',
                params: {
                  ticketId: activeConflicts[0].ticket_id,
                  travelLogId: activeConflicts[0].travel_log_id,
                  workLogId: activeConflicts[0].work_log_id
                }
              }"
            >
              Edit log

              <FontAwesomeIcon
                v-if="activeConflicts[0].work_log_id"
                :icon="faPersonDigging"
              />

              <FontAwesomeIcon
                v-if="activeConflicts[0].travel_log_id"
                :icon="faTruck"
              />
            </AppLink>
          </template>
        </SHTimeInput>
      </SHField>

      <div>
        <SHField label="Notes" @click="editor?.commands.focus()" />
        <div v-if="editor" class="vertical">
          <SHTextEditorWithToolbar
            v-model="notes"
            v-model:media-uploads="form.mediaUploads"
            :context="{ work_log_id: form.id ?? workLogId }"
            :editor="editor"
            :file-path="`work_logs/${form.id ?? workLogId}`"
            editable
          />
        </div>
      </div>

      <SHNote
        v-show="hasInvalidTimeFrame"
        theme="warning"
        style="text-align: left"
      >
        The end time must be later than the start time.
      </SHNote>

      <UndoConfirm
        :confirm-enabled="isValid && !conflictingLogsFetching"
        style="margin-bottom: 0"
        @undo="$router.back"
        @confirm="onConfirm"
      />
    </main>
  </article>
</template>

<style lang="scss">
.upload-list {
  > .upload {
    .image-preview {
      padding: var(--padding);
      border: thin solid var(--color-surface-400);
      border-radius: var(--border-radius);
      > img {
        height: 4em;
        width: 4em;
      }
    }
  }
}
</style>
