<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHImage from "@/components/SHImage.vue";
import type {
  MediaObjectOrUploadTask,
  MediaUploadTask
} from "@/composables/useUpload";
import { graphql, useFragment, type FragmentType } from "@/generated";
import type { UploadedMedia } from "@/types";
import { faCircleInfo } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation } from "@urql/vue";
import { computed, ref } from "vue";
import DeleteWithConfirm from "./DeleteWithConfirm.vue";
import SHButton from "./SHButton.vue";
import SHFileThumbnail from "./SHFileThumbnail.vue";

const fragment = graphql(/* GraphQL */ `
  fragment MediaUploadCard on media_uploads {
    id
    orig_filename
    content_type
    content_size
    url
    caption

    author {
      full_name
      ...UserLink
    }
  }
`);

const props = defineProps<{
  mediaUpload: FragmentType<typeof fragment> | MediaObjectOrUploadTask;
  thumbnailSize?: "sm" | "md" | "lg";
  isSsr?: boolean;
  noControls?: boolean;
}>();

const emit = defineEmits<{
  (e: "image:clicked", value: MediaObjectOrUploadTask): void;
  (e: "upload:cancelled", upload: MediaUploadTask): void;
  (e: "upload:deleted", upload: UploadedMedia): void;
  (e: "upload:caption", value: { id: string; caption: string }): void;
}>();

const { executeMutation: deleteMediaUpload } = useMutation(
  graphql(/* GraphQL */ `
    mutation MediaUploadCard_Delete($mediaUploadId: uuid!) {
      update_media_uploads_by_pk(
        pk_columns: { id: $mediaUploadId }
        _set: { deleted_at: "now()" }
      ) {
        id
      }
    }
  `)
);

const { executeMutation: addOrEditCaption } = useMutation(
  graphql(/* GraphQL */ `
    mutation MediaUploadCard_Caption($mediaUploadId: uuid!, $caption: String) {
      update_media_uploads_by_pk(
        pk_columns: { id: $mediaUploadId }
        _set: { caption: $caption }
      ) {
        id
      }
    }
  `)
);

const mediaUpload = computed(() => {
  if (props.mediaUpload && "progress" in props.mediaUpload) {
    return props.mediaUpload;
  }

  return useFragment(fragment, props.mediaUpload);
});
const storedUrl = computed(() => {
  if (props.mediaUpload && !("progress" in props.mediaUpload)) {
    return useFragment(fragment, props.mediaUpload).url;
  }
  return "";
});

const isImage = computed(() =>
  mediaUpload.value?.content_type?.startsWith("image/")
);
const useObjectUrl = (file: File) => URL.createObjectURL(file);

// const fakeProgress = ref(0);
const progress = computed(() => {
  // turn this on and the input range in the template to debug the .progress class
  // return fakeProgress.value;
  if (mediaUpload.value && "progress" in mediaUpload.value) {
    const task = mediaUpload.value;
    return task.progress;
  }
  return undefined;
});
const thumbSrc = computed(() =>
  mediaUpload.value && "progress" in mediaUpload.value
    ? useObjectUrl(mediaUpload.value.file)
    : mediaUpload.value.url
);
const altText = computed(() => {
  if (mediaUpload.value && "progress" in mediaUpload.value) {
    return mediaUpload.value.file.name;
  } else {
    return mediaUpload.value.orig_filename;
  }
});
const onDeleteOrCancel = () => {
  if (mediaUpload.value && "progress" in mediaUpload.value) {
    mediaUpload.value.abort();
    emit("upload:cancelled", mediaUpload.value);
  } else {
    deleteMediaUpload({ mediaUploadId: mediaUpload.value.id });
    emit("upload:deleted", mediaUpload.value);
  }
};

const onAddOrEditCaption = async () => {
  const caption = window.prompt(
    "Add or Edit Caption",
    mediaUpload.value.caption || ""
  );
  if (caption === null) return;

  await addOrEditCaption({ mediaUploadId: mediaUpload.value.id, caption });
  emit("upload:caption", { id: mediaUpload.value.id, caption });
};
</script>

<template>
  <article class="media-upload-card">
    <template v-if="isSsr">
      <AppLink :href="ref(storedUrl).value">
        <SHImage
          v-if="isImage"
          :src="thumbSrc"
          :alt="altText"
          :size="thumbnailSize"
          class="avoid-break"
          type="reportThumbnail"
          proxy-request
        />
        <SHFileThumbnail
          v-else
          :src="thumbSrc"
          :content-type="mediaUpload.content_type"
          :alt="altText"
        />
      </AppLink>
      <span>{{ mediaUpload.caption }}</span>
    </template>

    <template v-else>
      <div :class="{ progress: !!progress }">
        <SHImage
          v-if="isImage"
          type="thumbnail"
          :src="thumbSrc"
          :alt="altText"
          :proxy-request="mediaUpload && !('progress' in mediaUpload)"
          :class="{ progress: !!progress }"
          @click="$emit('image:clicked', mediaUpload)"
        />

        <SHFileThumbnail
          v-else
          :src="thumbSrc"
          :content-type="mediaUpload.content_type"
          :alt="altText"
          :class="{
            progress: !!progress
          }"
        />
      </div>
      <!-- dev tool
      <div class="progress" :style="{ height: '100px' }">nothing</div>
      <input
        v-model="fakeProgress"
        type="range"
        :min="0"
        :max="1"
        :step="0.01"
      /> -->
      <aside class="actions">
        <SHButton
          v-if="!!mediaUpload.caption"
          color="primary"
          size="md"
          square
          @click.stop="onAddOrEditCaption"
        >
          <FontAwesomeIcon :icon="faCircleInfo" fixed-width />
        </SHButton>
        <DeleteWithConfirm :enabled="true" @delete="onDeleteOrCancel" />
      </aside>
      <aside class="footer">
        <SHButton
          v-if="!mediaUpload.caption"
          color="primary"
          fullWidth
          class="action"
          @click.stop="onAddOrEditCaption"
        >
          + Description
        </SHButton>
        <div v-else class="caption">{{ mediaUpload.caption }}</div>
      </aside>
    </template>
  </article>
</template>

<style lang="scss" scoped>
@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.media-upload-card {
  position: relative;

  header {
    text-wrap: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
  }

  :deep(.thumbnail) {
    max-height: 75%;
    border: thin solid var(--color-surface-200);
    background-color: var(--color-surface-100);
    padding: 0.375em;
    border-radius: var(--border-radius);
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: var(--color-primary-down-200);
      background: var(--color-primary-down-200);
    }
    &.active {
      border-color: var(--color-info);
    }
  }

  .progress {
    --progress: v-bind(progress);
    --num: calc(var(--progress, 0) * 100);
    position: relative;
    transition: --num 0.2s cubic-bezier(0.58, 0.25, 1, 0.51);
    counter-reset: num var(--num);

    background: linear-gradient(
      38deg,
      var(--color-success) calc(1% * var(--num) - 2px),
      var(--color-surface-200) calc(1% * var(--num) + 10px)
    );

    &::after {
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 16px;
      text-align: right;
      content: counter(num) "%";
      text-shadow:
        1px 1px 2px var(--color-secondary),
        0 0 1em var(--color-primary),
        0 0 0.2em var(--color-primary);

      color: white;
      font-weight: 500;
      font-family: var(--font-family-monospace);
      font-size: 2.5em;
    }
  }

  aside.actions {
    position: absolute;
    top: 0.5em;
    right: 0.5em;

    @media (min-width: 1024px) {
      display: none;
    }
    @media (max-width: 767px) {
      display: block;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0.5em;
    right: 0.5em;

    .action {
      bottom: 0.5em;
    }

    .caption {
      background-color: white;
      color: black;
      border: 0.1em solid var(--color-primary);

      bottom: 0.5em;
      padding: 0.2em 0.5em;

      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      width: 100%;
      overflow: hidden;
    }
  }

  @media (min-width: 768px) {
    &:hover {
      aside {
        display: block;
      }
    }
  }
}
</style>
