<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import {
  useFloating,
  autoUpdate,
  flip,
  shift,
  offset,
  type Placement,
  type ShiftOptions,
  type Strategy
} from "@floating-ui/vue";
import {
  onClickOutside,
  useElementHover,
  type UseElementHoverOptions
} from "@vueuse/core";
import { useLogger } from "@/logger";

const { log } = useLogger("SHDropdown"); // eslint-disable-line @typescript-eslint/no-unused-vars

const {
  disabled = false,
  placement = "bottom-start",
  strategy = "absolute",
  shift: _shift = null,
  offset: _offset = 0,
  flip: _flip = true,
  // disableToggle = false,
  openOnHover = false
} = defineProps<{
  disabled?: boolean;
  placement?: Placement;
  strategy?: Strategy;
  shift?: ShiftOptions;
  offset?: number;
  flip?: boolean;
  matchWidth?: boolean;
  // disableToggle?: boolean;
  openOnHover?: boolean;
  teleportToBody?: boolean;
}>();

const emit = defineEmits(["open", "close"]);

const anchor = ref<HTMLDivElement>();
const floating = ref<HTMLDivElement>();
const isToggled = ref<boolean>();
const isOpen = computed(
  () =>
    !isForceClosed.value &&
    (isToggled.value || (openOnHover && isHovering.value))
);
const isForceClosed = ref<boolean>(); // internal use

const hoverOptions = reactive<UseElementHoverOptions>({
  delayEnter: 35,
  delayLeave: 200
});
// these don't seem to handle the transitioning v-if .floating element well so they are rebound on close
let isHoveringAnchor = useElementHover(anchor, hoverOptions);
let isHoveringFloating = useElementHover(floating, hoverOptions);
const isHovering = computed(
  () =>
    !disabled &&
    !isForceClosed.value &&
    !isToggled.value &&
    (isHoveringAnchor.value || isHoveringFloating.value)
);

const toggle = () => {
  if (disabled) return;
  if (isToggled.value) {
    close();
  }
  open();
};

const open = () => {
  if (disabled) return;
  emit("open");
  isToggled.value = true;
};

const close = () => {
  if (disabled) return;
  emit("close");
  isForceClosed.value = true;
  setTimeout(() => {
    isToggled.value = false;
    isForceClosed.value = false;
    // rebind listeners
    isHoveringAnchor = useElementHover(anchor, hoverOptions);
    isHoveringFloating = useElementHover(floating, hoverOptions);
  }, 0);
};

onClickOutside(floating, e => {
  if (anchor.value) {
    if (e.composedPath().includes(anchor.value)) {
      return;
    }

    return close();
  }

  close();
});

const middleware = computed(() => {
  const middleware = [];

  if (_shift) {
    middleware.push(shift(_shift));
  }

  if (_offset) {
    middleware.push(offset(_offset));
  }

  if (_flip) {
    middleware.push(flip());
  }

  return middleware;
});

const {
  floatingStyles,
  isPositioned,
  placement: renderedPlacement
} = useFloating(anchor, floating, {
  open: false,
  placement: placement,
  middleware: middleware,
  strategy: strategy,
  transform: true,
  whileElementsMounted: autoUpdate
});

defineExpose({
  open,
  close,
  isOpen
});
</script>

<template>
  <section class="sh-dropdown" :class="{ isOpen, disabled }">
    <div
      ref="anchor"
      class="anchor"
      :class="{ empty: !$slots.default }"
      @click.stop=""
    >
      <slot
        v-bind="{
          toggle,
          open,
          close,
          isHovering,
          isOpen: isOpen,
          renderedPlacement
        }"
      />
    </div>
    <Teleport :disabled="!teleportToBody" to="body">
      <transition name="opacity">
        <div
          v-if="isOpen"
          ref="floating"
          :style="isPositioned ? floatingStyles : {}"
          class="floating"
          :class="{
            'match-width': matchWidth,
            isToggled: isToggled,
            isHovering,
            [renderedPlacement]: true
          }"
        >
          <slot
            name="popup"
            v-bind="{
              toggle,
              open,
              close,
              isHovering,
              isOpen: !isForceClosed && isToggled
            }"
          />
        </div>
      </transition>
    </Teleport>
  </section>
</template>

<style scoped lang="scss">
section.sh-dropdown {
  display: inline;
  position: relative;

  .anchor.empty {
    height: 100%;
    width: 100%;
  }

  &.disabled {
    .anchor {
      cursor: not-allowed;
    }
  }
  .floating {
    // https://floating-ui.com/docs/computePosition#initial-layout
    position: absolute;
    min-width: 12em;
    max-width: 100%;
    max-height: 25vh;
    overflow-y: auto;
    top: 0;
    left: 0;

    background: var(--color-surface-100);
    z-index: 200;
    box-shadow: var(--box-shadow);

    &.match-width {
      width: 100%;
    }
  }
}
</style>
