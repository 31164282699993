<script setup lang="ts">
import {
  useQueryStringFilters,
  type OrderSet
} from "@/composables/useQueryStringFilters";
import { formatDateRelative } from "@/formatters";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

import AppLink from "@/components/AppLink.vue";
import OrderBy from "@/components/OrderBy.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import {
  Order_By,
  type Custom_Form_Definitions_Order_By
} from "@/generated/graphql";

const query = graphql(/* GraphQL */ `
  query CustomForms(
    $where: custom_form_definitions_bool_exp
    $order_by: [custom_form_definitions_order_by!]
  ) {
    custom_form_definitions(where: $where, order_by: $order_by) {
      id
      title
      created_at
      updated_at
      deleted_at
      component_name

      products_xref_custom_forms_aggregate {
        aggregate {
          count
        }
      }

      products_xref_custom_forms {
        product {
          id
          title
        }
      }
    }
  }
`);

const forms = computed(() => data.value?.custom_form_definitions);

const formCount = computed(() => forms.value?.length || 0);

const { field, direction } = useQueryStringFilters({
  field: "title",
  direction: Order_By.Asc
});

const orderSet: OrderSet<Custom_Form_Definitions_Order_By> = {
  defaultValue: "title",
  options: [
    {
      id: "title",
      title: "Title",
      asc: [{ title: Order_By.Asc }],
      desc: [{ title: Order_By.Desc }]
    },
    {
      id: "updated",
      title: "Last Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    },
    {
      id: "created",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    }
  ]
};

const { data, fetching, error } = useQuery({
  query,
  variables: computed(() => {
    const order =
      orderSet.options.find(
        o => typeof field.value === "string" && o.id === field.value
      ) ||
      orderSet.options.find(o => o.id === orderSet.defaultValue) ||
      orderSet.options[0];
    const dir =
      typeof direction.value === "string" && direction.value === Order_By.Asc
        ? Order_By.Asc
        : Order_By.Desc;
    const order_by = Array.isArray(order[dir])
      ? (order[dir] as Custom_Form_Definitions_Order_By[])
      : [order[dir] as Custom_Form_Definitions_Order_By];
    return {
      where: {
        deleted_at: { _is_null: true }
      },
      order_by
    };
  }),
  context: { additionalTypenames: ["custom_form_definitions"] }
});
</script>
<template>
  <article class="vertical loose">
    <div class="level-spread">
      <h2>Forms</h2>
      <OrderBy
        :order-set="orderSet"
        :direction="typeof direction === 'string' ? direction : Order_By.Asc"
        :field="
          typeof field === 'string'
            ? field
            : orderSet.options.find(o => o.id === orderSet.defaultValue)?.id ||
              'title'
        "
        @update:order-field="field = $event"
        @update:order-direction="direction = $event"
      />
    </div>

    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <SHTable v-else fluid :rows="forms" empty-message="No forms found.">
      <template #header>
        <th style="min-width: 12em">Form</th>
        <th>Component</th>
        <th style="min-width: 4em">Products</th>
        <th>Updated</th>
      </template>
      <template #row="{ row }">
        <td>
          <AppLink
            :to="{
              name: 'OrganizationCustomFormDetail',
              params: { customFormDefinitionId: row.id }
            }"
          >
            {{ row.title }}
          </AppLink>
        </td>
        <td>
          <code>{{ row.component_name }}</code>
        </td>
        <td>
          {{ row.products_xref_custom_forms_aggregate.aggregate?.count || 0 }}
        </td>
        <td>{{ formatDateRelative(row.updated_at) }}</td>
      </template>
      <template #footer>
        <SHTableRowCount :count="formCount" label="form" />
      </template>
    </SHTable>
  </article>
</template>

<style lang="scss" scoped></style>
