<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import QueryFilter from "@/components/QueryFilter.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { useFacets, type FacetComboOption } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { graphql, useFragment } from "@/generated";
import {
  Order_By,
  type Work_Sites_Bool_Exp,
  type Work_Sites_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { DATE_FILTER_PRESETS, type DateRangePreset } from "@/lib/datetime";
import { injectStrict } from "@/lib/helpers";
import { BreakPointsKey } from "@/providerKeys";
import { faCheck, faInfoCircle } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { useIntersectionObserver } from "@vueuse/core";
import { computed, ref } from "vue";

const breakpoints = injectStrict(BreakPointsKey);
const recordsPerPage = ref(100);
const limit = ref(recordsPerPage.value);

const props = defineProps<{
  customerId: string;
}>();

const buildDatePredicates = (dates: [Date, Date]) => {
  return {
    _or: [
      { created_at: { _gte: dates[0], _lte: dates[1] } },
      { updated_at: { _gte: dates[0], _lte: dates[1] } }
    ]
  };
};

const dateRangeFilters: Facet<Work_Sites_Bool_Exp> = {
  defaultValue: "all",
  hideOnMobile: true,
  label: "Date Created or Updated",
  modelName: "dateRange",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...DATE_FILTER_PRESETS.map((preset: DateRangePreset) => ({
      id: preset.id,
      title: preset.title,
      predicate: buildDatePredicates(preset.dates)
    }))
  ]
};

const orderByFilters: OrderSet<Work_Sites_Order_By> = {
  defaultValue: "title",
  defaultOrderBy: Order_By.Asc,
  options: [
    {
      id: "title",
      title: "Worksite Name",
      asc: [{ title: Order_By.Asc }],
      desc: [{ title: Order_By.Desc }]
    },
    {
      id: "tickets_aggregate",
      title: "# of Tickets",
      asc: [{ tickets_aggregate: { count: Order_By.Asc } }],
      desc: [{ tickets_aggregate: { count: Order_By.Desc } }]
    },
    {
      id: "created_at",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "updated_at",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const {
  facetModels,
  filterWhereClause,
  resetFacets,
  searchString,
  sortOrder,
  updateFacet,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets([dateRangeFilters], orderByFilters);

const fragment = graphql(/* GraphQL */ `
  fragment WorkSitesView on work_sites {
    author {
      id
      full_name
      ...UserLink
    }
    title
    created_at
    deleted_at
    updated_at
    data
    id
    tickets {
      combined_logs {
        author_id
        author {
          full_name
        }
      }
    }
    tickets_aggregate {
      aggregate {
        count
      }
    }
  }
`);

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query WorkSitesView_Search(
      $query: String
      $where: work_sites_bool_exp!
      $orderBy: [work_sites_order_by!]
      $limit: Int
    ) {
      search_work_sites(
        args: { _query: $query }
        where: $where
        order_by: $orderBy
        limit: $limit
      ) {
        ...WorkSitesView
      }

      search_work_sites_aggregate(args: { _query: $query }, where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    orderBy: sortOrder.value,
    query: searchString.value,
    limit: 100,
    where: {
      _and: [
        props.customerId ? { customer_id: { _eq: props.customerId } } : {},
        ...(filterWhereClause.value ?? [])
      ]
    }
  })),
  context: { additionalTypenames: ["work_sites", "search_work_sites"] },
  pause: computed(() => !filterWhereClause.value)
});

const workSites = computed(
  () => useFragment(fragment, data.value?.search_work_sites) ?? []
);

const workSiteCount = computed(
  () => data.value?.search_work_sites_aggregate.aggregate?.count || 0
);

const total = computed(
  () => data.value?.search_work_sites_aggregate.aggregate?.count || 0
);

const scrollMarker = ref();
const loadMore = () => {
  if (limit.value <= total.value) limit.value += recordsPerPage.value;
};

useIntersectionObserver(scrollMarker, ([{ isIntersecting }]) => {
  if (isIntersecting) loadMore();
});
</script>

<template>
  <article class="work-sites-view vertical">
    <QueryFilter
      :default-collection-view="CollectionView.Table"
      :facets="[dateRangeFilters]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="workSiteCount"
      clear-all-filters
      no-calendar-view
      no-card-view
      no-table-view
      placeholder="Search by Worksite Name..."
      searchable
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event as FacetComboOption)"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    />

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error.message }}
    </SHNote>

    <template v-else>
      <SHTable
        :rows="workSites"
        clickable
        empty-message="No worksites found."
        @row:click="
          $router.push({
            name: 'WorkSiteDetail',
            params: {
              workSiteId: $event.id
            }
          })
        "
      >
        <template #header>
          <th>Name</th>
          <th>Cost Center</th>
          <th v-if="breakpoints.includes('tablet')">Tickets</th>
          <th v-if="breakpoints.includes('desktop')" width="100">
            <SHDropdown open-on-hover>
              <span class="level tight">
                Archived
                <FontAwesomeIcon :icon="faInfoCircle" class="info-icon" />
              </span>

              <template #popup>
                <SHNote theme="info">
                  Archived worksites are not available for selection when
                  creating tickets.
                </SHNote>
              </template>
            </SHDropdown>
          </th>
        </template>

        <template #row="{ row }">
          <td>
            <AppLink
              class="work-site-link"
              :to="{
                name: 'WorkSiteDetail',
                params: { workSiteId: row.id }
              }"
            >
              {{ row.title }}
            </AppLink>
          </td>
          <td>
            {{ row.data?.cost_center ?? "" }}
          </td>
          <td v-if="breakpoints.includes('tablet')">
            {{ row.tickets_aggregate.aggregate?.count }}
          </td>
          <td v-if="breakpoints.includes('tablet')" align="center">
            <span v-if="row.deleted_at !== null">
              <FontAwesomeIcon
                :icon="faCheck"
                size="xl"
                fixed-width
                style="color: var(--color-surface-900)"
              />
            </span>
            <span v-else></span>
          </td>
        </template>

        <template #footer>
          <SHTableRowCount :count="workSiteCount" label="worksite" />
        </template>
      </SHTable>
    </template>

    <section v-if="!fetching && limit < total" ref="scrollMarker" />
  </article>
</template>

<style lang="scss" scoped>
.work-sites-view {
  :deep(.app-link) {
    text-decoration: none;
  }

  .info-icon {
    cursor: pointer;
  }
}
</style>
