<script setup lang="ts">
import { graphql, type FragmentType, useFragment } from "@/generated";
import { formatDate, formatUSD } from "@/formatters";
import { computed, reactive } from "vue";

import AppLink from "./AppLink.vue";
import SHDollarInput from "@/components/SHDollarInput.vue";
import SHInput from "@/components/SHInput.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import UseToggle from "./UseToggle.vue";
import { startOfDay } from "date-fns";
import SHField from "./SHField.vue";
import SHInlineDate from "./SHInlineDate.vue";

const fragment = graphql(/* GraphQL */ `
  fragment AgentPriceEditor on price_book_agent_prices {
    id
    agent_id
    created_at
    day_rate_cents
    hourly_rate_cents
    valid_from
  }
`);
const props = defineProps<{
  price?: FragmentType<typeof fragment>;
  defaultPriceCents?: number;
}>();
defineEmits<{
  (e: "confirm", cents: number, valid_from: string): void;
}>();

const price = computed(() => ({
  ...useFragment(fragment, props.price)
}));
const form = reactive<{
  cents?: number;
  valid_from?: Date;
}>({});

const cents = computed({
  get: () =>
    form.cents || price.value.day_rate_cents || props.defaultPriceCents || 0,
  set: (cents: number) => (form.cents = cents)
});
const validFrom = computed({
  get: () => {
    const fmt = "yyyy-MM-dd'T'HH:mm"; //   "2017-06-01T08:30"
    if (form.valid_from) {
      return formatDate(form.valid_from, fmt);
    } else if (price.value.valid_from) {
      return formatDate(price.value.valid_from, fmt);
    } else {
      return formatDate(startOfDay(new Date()), fmt);
    }
  },
  set: (dateStr: string) => (form.valid_from = new Date(dateStr))
});
</script>

<template>
  <UseToggle v-slot="{ on, enable, disable }">
    <div :class="{ alignLeft: !on }">
      <div v-if="!on">
        <AppLink @click.prevent="enable">
          <span
            v-if="price.day_rate_cents"
            class="number"
            style="font-weight: 600; font-size: 1.15em"
          >
            {{ formatUSD(price.day_rate_cents) }}
          </span>
          <span v-else>set price</span>
        </AppLink>
        <span v-if="price.day_rate_cents">
          since
          <SHInlineDate :d="price.valid_from" />
        </span>
      </div>

      <div v-else style="padding: 0.25em" class="form">
        <SHField label="Day Rate" style="grid-area: price">
          <SHDollarInput v-model="cents" />
        </SHField>

        <SHField label="Starting" style="grid-area: date">
          <SHInput
            v-model="validFrom"
            type="datetime-local"
            :clearable="false"
          />
        </SHField>

        <UndoConfirm
          :confirm-enabled="cents > 0 && !!validFrom"
          style="grid-area: buttons; margin: 0"
          @confirm="
            $emit('confirm', cents, validFrom);
            disable();
          "
          @undo="disable"
        />
      </div>
    </div>
  </UseToggle>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;
.alignLeft {
  text-align: left;
}
.form {
  display: grid;
  gap: 0.5em;
  align-items: end;
  grid-template-columns: 1fr;
  grid-template-areas:
    "price"
    "date"
    "buttons";

  @include bp.phone-landscape {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "price date"
      "buttons buttons";
  }

  @include bp.desktop {
    grid-template-columns: 1fr 1fr min-content;
    grid-template-areas: "price date buttons";
  }
}
</style>
