<script setup lang="ts">
import { useRole } from "@/composables/useRole";
import type { IconDefinition } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { RouteLocationRaw } from "vue-router";
import AppLink from "./AppLink.vue";

export type MenuEntry = {
  id: string;
  icon?: IconDefinition;
  disabled?: boolean;
  disabledReason?: string;
  label: string;
  onClick?: (e: Event) => void;
  to?: RouteLocationRaw;
  allowedPermissions?: string[];
  isDanger?: boolean;
};
defineProps<{ items: MenuEntry[] }>();
defineEmits<{
  (e: "click", value: MenuEntry): void;
}>();
const { can } = useRole();
const hasPermission = (m: MenuEntry): boolean =>
  !m.allowedPermissions?.length || m.allowedPermissions.some(p => can(p));
</script>

<template>
  <article class="sh-menu">
    <header v-if="$slots.default">
      <slot />
    </header>
    <template v-for="i of items" :key="i.label">
      <AppLink
        v-if="hasPermission(i)"
        :disabled="i.disabled"
        :title="i.disabledReason"
        :to="i.to"
        class="menu-link level tight"
        :class="{ isDanger: i.isDanger, disabled: i.disabled }"
        @click.prevent="
          if (!i.disabled) {
            i.onClick?.($event);
            $emit('click', i);
          }
        "
      >
        <FontAwesomeIcon
          v-if="i.icon"
          color="var(--color-secondary)"
          :icon="i.icon"
          fixed-width
        />
        {{ i.label }}
      </AppLink>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.sh-menu {
  border-radius: var(--border-radius);

  :deep(.menu-link) {
    width: 100%;
    padding: 0.5em 1em;
    color: var(--color-surface-500);
    text-decoration: none;

    &.isDanger {
      // outline: thin solid var(--color-danger);
      background: var(--color-danger-opacity-15);

      &:hover:not(.disabled) {
        background: var(--color-danger-opacity-30);
      }
    }

    &.disabled {
      color: var(--color-surface-300);
      text-decoration: none;
      background: var(--color-surface-200);
    }

    &:hover:not(.disabled) {
      color: var(--color-surface-900) !important;
      text-decoration: none;
      background: var(--color-primary-opacity-15);
    }

    &.active:not(.disabled) {
      background: var(--color-secondary);
      color: var(--color-surface-900) !important;
    }
  }

  header {
    font-size: 1.125em;
    padding: 0.25em 0.5em;
  }
}
</style>
