import type { TimeSliderRange } from "@/components/SHTimeInput/SHTimeSlider.vue";

import { computed, ref, type MaybeRef } from "vue";
import { startOfDay, endOfDay, isAfter } from "date-fns";
import { useQuery } from "@urql/vue";
import { debouncedRef } from "@vueuse/core";
import { isWithinIntervalExclusive } from "@/lib/datetime";

import { graphql } from "@/generated";

export function useConflictingLogs(
  userId: MaybeRef<string>,
  logId: MaybeRef<string | undefined>,
  startTime: MaybeRef<Date>,
  endTime: MaybeRef<Date>,
  pause?: MaybeRef<boolean>
) {
  const _userId = ref(userId);
  const _logId = ref(logId);
  const _startTime = ref(startTime);
  const _endTime = ref(endTime);
  const _pause = ref(pause ?? false);

  const hasInvalidTimeFrame = computed(
    () =>
      _startTime.value &&
      _endTime.value &&
      isAfter(_startTime.value, _endTime.value)
  );

  const { data, fetching } = useQuery({
    query: graphql(/* GraphQL */ `
      query ConflictingLogs($where: combined_logs_bool_exp!) {
        combined_logs(where: $where, order_by: { started_at: asc }) {
          id
          work_log_id
          travel_log_id
          ended_at
          started_at
          ticket_id
          ticket {
            ref
          }
        }
      }
    `),
    variables: debouncedRef(
      computed(() => ({
        where: {
          author_id: { _eq: _userId.value },
          deleted_at: { _is_null: true },
          id: _logId.value ? { _neq: _logId.value } : {},
          _and: [
            {
              _or: [
                {
                  ended_at: {
                    _gte: startOfDay(_startTime.value),
                    _lte: endOfDay(_endTime.value)
                  }
                },
                {
                  started_at: {
                    _gte: startOfDay(_startTime.value),
                    _lte: endOfDay(_endTime.value)
                  }
                }
              ]
            }
          ]
        }
      })),
      1000
    ),
    pause: computed(() => hasInvalidTimeFrame.value || _pause.value),
    context: {
      additionalTypenames: ["combined_logs", "work_logs", "travel_logs"]
    }
  });

  const conflictingLogs = computed(() => {
    return data.value?.combined_logs || [];
  });

  const activeConflicts = computed(() => {
    return conflictingLogs.value.filter(log => {
      const interval = {
        start: new Date(log.started_at as string),
        end: new Date(log.ended_at as string)
      };

      return (
        isWithinIntervalExclusive(_startTime.value, interval) ||
        isWithinIntervalExclusive(_endTime.value, interval)
      );
    });
  });

  const isConflicting = computed(() => {
    const selectedInterval = {
      start: _startTime.value,
      end: _endTime.value
    };

    return conflictingLogs.value.some(log => {
      if (!log.started_at || !log.ended_at) return false;

      return (
        isWithinIntervalExclusive(log.started_at, selectedInterval) ||
        isWithinIntervalExclusive(log.ended_at, selectedInterval)
      );
    });
  });

  const conflictingRanges = computed<TimeSliderRange[]>(() =>
    conflictingLogs.value.map(log => ({
      start: new Date(log.started_at as string),
      end: new Date(log.ended_at as string),
      theme: log.work_log_id ? "primary" : "secondary"
    }))
  );

  return {
    conflictingLogs,
    activeConflicts,
    fetching,
    isConflicting,
    conflictingRanges
  };
}
