<script async setup lang="ts">
import { graphql, useFragment, type FragmentType } from "@/generated";
import { faEllipsisVertical } from "@fortawesome/sharp-light-svg-icons";
import {
  endOfDay,
  format,
  isAfter,
  isSameDay,
  parseISO,
  startOfDay
} from "date-fns";

import SHField from "@/components/SHField.vue";
import SHTimeInput from "@/components/SHTimeInput/SHTimeInput.vue";
import type { TimeSliderRange } from "@/components/SHTimeInput/SHTimeSlider.vue";
import SHTimeSlider from "@/components/SHTimeInput/SHTimeSlider.vue";
import TicketLink from "@/components/TicketLink.vue";
import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import type { AgentShiftCardFragment } from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { CombinedError } from "@urql/vue";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import AppLink from "./AppLink.vue";
import SHButton from "./SHButton.vue";
import SHDatePicker from "./SHDatePicker.vue";
import SHDropdown from "./SHDropdown.vue";
import SHMenu from "./SHMenu.vue";
import SHNote from "./SHNote.vue";
import UndoConfirm from "./UndoConfirm.vue";

const { createToast } = useToaster();
const currentUser = injectStrict(CurrentUserKey);
const { can } = useRole();

const { executeMutation: executeCreate } = useMutation(
  graphql(/* GraphQL */ `
    mutation AgentShiftCard_CreateShift(
      $object: agent_shift_overrides_insert_input!
    ) {
      insert_agent_shift_overrides_one(object: $object) {
        hash
      }
    }
  `)
);

const { executeMutation: executeUpdate } = useMutation(
  graphql(/* GraphQL */ `
    mutation AgentShiftCard_UpdateShift(
      $id: uuid!
      $set: agent_shift_overrides_set_input!
    ) {
      update_agent_shift_overrides_by_pk(pk_columns: { id: $id }, _set: $set) {
        hash
      }
    }
  `)
);

const { executeMutation: executeDelete } = useMutation(
  graphql(/* GraphQL */ `
    mutation AgentShiftCard_DeleteShift($id: uuid!) {
      delete_agent_shift_overrides_by_pk(id: $id) {
        id
      }
    }
  `)
);

const fragment = graphql(/* GraphQL */ `
  fragment AgentShiftCard on agent_shifts {
    override_id
    hash

    agent_id
    agent {
      agent {
        ...UserLink
      }
    }

    started_at
    ended_at

    logs(where: { deleted_at: { _is_null: true } }) {
      id
      work_log_id
      travel_log_id
      started_at
      ended_at

      ticket {
        id
        ...TicketLink
      }
    }
  }
`);

const { shift: propsShift } = defineProps<{
  shift: FragmentType<typeof fragment>;
}>();

const emit = defineEmits<{
  (e: "updated", hash: string): void;
  (e: "deleted"): void;
}>();

const mode = ref<"view" | "edit">("view");
const canEdit = computed(
  () =>
    can("agent_shifts:manage_others") ||
    (can("agent_shifts:manage_self") &&
      currentUser.value.id === shift.value.agent_id)
);
const shift = computed(
  () =>
    useFragment(fragment, propsShift) as AgentShiftCardFragment & {
      started_at: string;
      ended_at: string;
    }
);

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query AgentShiftCard_Logs($logsWhere: combined_logs_bool_exp!) {
      combined_logs(where: $logsWhere) {
        id
        started_at
        ended_at
        work_log_id

        ticket {
          id
          ...TicketLink
        }
      }
    }
  `),
  variables: {
    logsWhere: {
      author_id: { _eq: shift.value.agent_id },
      shifts: {
        hash: {
          _neq: shift.value.hash
        }
      },
      _or: [
        {
          started_at: {
            _gte: startOfDay(parseISO(shift.value.started_at)),
            _lt: endOfDay(parseISO(shift.value.ended_at))
          }
        },
        {
          ended_at: {
            _gte: startOfDay(parseISO(shift.value.started_at)),
            _lt: endOfDay(parseISO(shift.value.ended_at))
          }
        }
      ]
    }
  }
});

const logs = computed(() => [
  ...(shift.value.logs ?? []),
  ...(data.value?.combined_logs ?? [])
]);

const logRanges = computed<TimeSliderRange[]>(() =>
  logs.value.map(l => ({
    start: parseISO(l.started_at as string),
    end: parseISO(l.ended_at as string),
    theme: l.work_log_id ? "primary" : "secondary"
  }))
);

const viewRanges = computed<(TimeSliderRange & { isShift?: true })[]>(() => [
  {
    start: parseISO(shift.value.started_at),
    end: parseISO(shift.value.ended_at),
    theme: "success",
    isShift: true
  },
  ...logRanges.value
]);

const keyTimes = computed(() => [
  {
    label: format(parseISO(shift.value.started_at), "hh:mm a"),
    value: parseISO(shift.value.started_at)
  },
  {
    label: format(parseISO(shift.value.ended_at), "hh:mm a"),
    value: parseISO(shift.value.ended_at)
  }
]);

const newStartTime = ref(parseISO(shift.value.started_at));
const newEndTime = ref(parseISO(shift.value.ended_at));

const formRanges = computed<(TimeSliderRange & { isShift?: true })[]>(() => [
  {
    start: newStartTime.value,
    end: newEndTime.value,
    theme: "success",
    isShift: true
  },
  ...logRanges.value
]);

const formKeyTimes = computed(() => [
  {
    label: format(newStartTime.value, "hh:mm a"),
    value: newStartTime.value
  },
  {
    label: format(newEndTime.value, "hh:mm a"),
    value: newEndTime.value
  }
]);

async function updateShift() {
  let shiftHash: string | null | undefined;
  let error: CombinedError | undefined;

  if (!shift.value.override_id) {
    const newOverride = await executeCreate({
      object: {
        agent_id: shift.value.agent_id,
        started_at: newStartTime.value,
        ended_at: newEndTime.value
      }
    });

    shiftHash = newOverride.data?.insert_agent_shift_overrides_one?.hash;
    error = newOverride.error;
  } else {
    const updatedOverride = await executeUpdate({
      id: shift.value.override_id,
      set: {
        started_at: newStartTime.value,
        ended_at: newEndTime.value
      }
    });

    shiftHash = updatedOverride.data?.update_agent_shift_overrides_by_pk?.hash;
    error = updatedOverride.error;
  }

  if (error || !shiftHash) {
    createToast({
      title: "Unable to update the shift.",
      message: error?.message || "Unknown error.",
      theme: "danger"
    });

    return;
  } else {
    createToast({
      message: "Shift updated.",
      theme: "success"
    });
  }

  emit("updated", shiftHash);
  mode.value = "view";
}

async function deleteShift() {
  if (!confirm("Are you sure you want to delete this shift?")) {
    return;
  }

  if (!shift.value.override_id) {
    throw new Error("Shift does not have an override ID");
  }

  const { error } = await executeDelete({ id: shift.value.override_id });

  if (error) {
    createToast({
      title: "Unable to delete the shift.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else {
    createToast({
      message: "Shift deleted.",
      theme: "success"
    });
  }

  emit("deleted");
}
</script>

<template>
  <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else-if="shift" class="agent-shift-card">
    <header class="vertical tight">
      <div class="level-spread">
        <h3>
          <AppLink
            :to="{
              name: 'AgentShiftDetail',
              params: { agentId: shift.agent_id, shiftHash: shift.hash }
            }"
          >
            {{ format(new Date(shift.started_at), "EEEE, MMM d") }}
          </AppLink>
        </h3>
        <SHDropdown>
          <template #default="{ toggle }">
            <SHButton @click="toggle">
              <FontAwesomeIcon :icon="faEllipsisVertical" />
            </SHButton>
          </template>
          <template #popup="{ close }">
            <SHMenu
              v-if="canEdit"
              :items="[
                {
                  id: 'edit',
                  label: 'Edit',
                  onClick: () => {
                    mode = 'edit';
                    close();
                  }
                },
                {
                  id: 'delete',
                  label: 'Delete',
                  isDanger: true,
                  disabled: !shift.override_id,
                  onClick: () => {
                    deleteShift();
                    close();
                  }
                }
              ]"
            />
          </template>
        </SHDropdown>
      </div>
    </header>
    <section>
      <div class="level-spread loose wrap">
        <SHField label="Affected Tickets">
          <div class="level tight wrap">
            <template
              v-for="ticket in logs
                .map(l => l.ticket)
                .filter(
                  (t, i, arr) => arr.findIndex(t2 => t2?.id === t?.id) === i
                )"
              :key="ticket?.id"
            >
              <TicketLink
                v-if="ticket"
                style="--background-color: var(--color-surface-300)"
                :ticket="ticket"
              />
            </template>
          </div>
        </SHField>
      </div>
    </section>
    <footer v-if="mode === 'edit'" class="vertical loose">
      <div class="vertical loose">
        <SHField block label="New Shift Start">
          <div class="vertical">
            <SHDatePicker v-model="newStartTime" auto-apply />
            <SHTimeInput
              v-model="newStartTime"
              :minute-resolution="15"
              :ranges="formRanges"
              :key-times="formKeyTimes"
            >
              <template #range="{ range, style }">
                <div
                  class="range"
                  :class="{
                    [range.theme ?? 'danger']: true,
                    shift: range.isShift
                  }"
                  :style="style"
                ></div>
              </template>
            </SHTimeInput>
          </div>
        </SHField>
        <SHField block label="New Shift End">
          <div class="vertical">
            <SHDatePicker v-model="newEndTime" auto-apply />
            <SHTimeInput
              v-model="newEndTime"
              :minute-resolution="15"
              :ranges="formRanges"
              :key-times="formKeyTimes"
            >
              <template #range="{ range, style }">
                <div
                  class="range"
                  :class="{
                    [range.theme ?? 'danger']: true,
                    shift: range.isShift
                  }"
                  :style="style"
                ></div>
              </template>
            </SHTimeInput>
          </div>
        </SHField>
      </div>
      <div>
        <UndoConfirm
          :confirm-enabled="isAfter(newEndTime, newStartTime)"
          @confirm="updateShift"
          @undo="mode = 'view'"
        >
          <template #confirm>
            <span>Save</span>
          </template>
          <template #undo>
            <span>Cancel</span>
          </template>
        </UndoConfirm>
      </div>
    </footer>
    <footer v-else class="vertical tight" style="margin-bottom: 1em">
      <div class="time-slider-list">
        <SHTimeSlider
          readonly
          :model-value="startOfDay(parseISO(shift.started_at))"
          :ranges="viewRanges"
          :keyTimes="keyTimes"
        >
          <template #range="{ range, style }">
            <div
              class="range"
              :class="{
                [range.theme ?? 'danger']: true,
                shift: range.isShift
              }"
              :style="style"
            ></div>
          </template>
        </SHTimeSlider>
        <template
          v-if="
            !isSameDay(parseISO(shift.started_at), parseISO(shift.ended_at))
          "
        >
          <SHTimeSlider
            readonly
            :model-value="startOfDay(parseISO(shift.ended_at))"
            :ranges="viewRanges"
            :keyTimes="keyTimes"
          >
            <template #range="{ range, style }">
              <div
                class="range"
                :class="{
                  [range.theme ?? 'danger']: true,
                  shift: range.isShift
                }"
                :style="style"
              ></div>
            </template>
          </SHTimeSlider>
        </template>
      </div>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.agent-shift-card {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--color-surface-300);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  > section,
  > footer,
  > header {
    padding: 1em;
  }

  > header {
    background-color: var(--color-surface-100);
    border-bottom: thin solid var(--color-surface-300);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  > footer {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .range.shift {
    height: 4px;
    background-color: var(--color-success);
    border-radius: var(--border-radius);
    top: -0.5em;
  }

  .time-slider-list {
    // you need a little extra gap for time sliders because of shadow DOM
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
}
</style>
