<script setup lang="ts">
import CustomerForm from "@/components/CustomerForm.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type { Customers_Set_Input } from "@/generated/graphql";
import { useMutation, useQuery } from "@urql/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();

const props = defineProps<{
  customerId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomerEdit($id: uuid!) {
      customers_by_pk(id: $id) {
        ...CustomerForm
      }

      ...CustomerFormOptions
    }
  `),
  variables: {
    id: props.customerId
  }
});

const updates = ref<Customers_Set_Input>();

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateCustomer($id: uuid!, $updates: customers_set_input!) {
      update_customers_by_pk(pk_columns: { id: $id }, _set: $updates) {
        id
      }
    }
  `)
);

async function onSubmit() {
  if (updates.value) {
    const { data, error } = await executeMutation({
      id: props.customerId,
      updates: updates.value
    });

    if (data) {
      createToast({
        message: "Customer updated.",
        theme: "success"
      });

      router.push({
        name: "CustomerDashboard"
      });
    }

    if (error) {
      createToast({
        title: "Unable to update the customer.",
        message: error.message || "Unknown error.",
        theme: "danger"
      });
    }
  }
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else class="vertical xmargin">
    <CustomerForm
      v-if="data && data.customers_by_pk"
      v-model:updates="updates"
      :customer="data.customers_by_pk"
      :options="data"
    />
    <UndoConfirm
      :confirm-enabled="!!updates"
      @confirm="onSubmit"
      @undo="$router.back"
    />
  </article>
</template>
