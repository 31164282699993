<script setup lang="ts">
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import UndoConfirmVue from "@/components/UndoConfirm.vue";
import { graphql } from "@/generated";

import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import { useToaster } from "@/composables/useToaster";
import type { JSONContent } from "@tiptap/vue-3";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();

const { catalogId = "" } = defineProps<{
  catalogId?: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query CatalogForm($catalogId: uuid!) {
      catalogs_by_pk(id: $catalogId) {
        title
        notesj
      }
    }
  `),
  variables: {
    catalogId
  },
  pause: !catalogId
});

const catalog = computed(() => data.value?.catalogs_by_pk);

const insertCatalog = graphql(/* GraphQL */ `
  mutation InsertCatalog($object: catalogs_insert_input!) {
    insert_catalogs_one(object: $object) {
      id
    }
  }
`);

const { executeMutation: insert } = useMutation(insertCatalog);

const updateCatalog = graphql(/* GraphQL */ `
  mutation UpdateCatalog($id: uuid!, $object: catalogs_set_input) {
    update_catalogs_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`);

const { executeMutation: update } = useMutation(updateCatalog);

async function onSubmit() {
  if (catalogId) {
    const { data, error } = await update({
      id: catalogId,
      object: form.value
    });

    if (error) {
      createToast({
        title: "Unable to update the catalog.",
        message: error.message || "Unknown error.",
        theme: "danger",
        lifetimeMS: 5000
      });
    }

    if (data) {
      createToast({
        message: "Catalog updated.",
        theme: "success"
      });
      router.replace({
        name: "CatalogDetail",
        params: {
          catalogId: data.update_catalogs_by_pk?.id
        }
      });
    }
  } else {
    const { data, error } = await insert({
      object: form.value
    });

    if (error) {
      createToast({
        title: "Unable to create the catalog.",
        message: error.message || "Unknown error.",
        theme: "danger",
        lifetimeMS: 5000
      });
    } else if (data) {
      createToast({
        message: "Catalog created.",
        theme: "success"
      });
      router.replace({
        name: "CatalogDetail",
        params: {
          catalogId: data.insert_catalogs_one?.id
        }
      });
    }
  }
}

const form = ref<{
  title?: string;
  notesj?: JSONContent | null;
}>({});

const isValid = computed(() => (catalogId ? true : !!form.value.title));
</script>

<template>
  <SHSpinner v-if="fetching && catalogId" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="data || !catalogId" class="catalog-form vertical">
    <section class="vertical xmargin">
      <SHField label="Title">
        <SHInput
          autofocus
          :model-value="form.title ?? catalog?.title"
          @update:model-value="form.title = $event"
        />
      </SHField>
      <SHField label="Notes">
        <SHTextEditor
          editable
          :model-value="form.notesj ?? catalog?.notesj"
          @update:model-value="form.notesj = $event"
        />
      </SHField>
      <UndoConfirmVue
        :confirm-enabled="isValid"
        @confirm="onSubmit"
        @undo="$router.back"
      />
    </section>
  </article>
</template>

<style lang="scss" scoped>
.catalog-form {
  section {
    padding: 0 0.5em;
  }
}
</style>
