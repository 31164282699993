<script async setup lang="ts">
import QueryFilter from "@/components/QueryFilter.vue";
import SHButton from "@/components/SHButton.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import ToBeDetermined from "@/components/ToBeDetermined.vue";
import UserLink from "@/components/UserLink.vue";
import WorkOrderStatusBadge from "@/components/WorkOrderStatusBadge.vue";
import { useFacets, type FacetComboOption } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { graphql } from "@/generated";
import type {
  Tickets_Bool_Exp,
  Work_Orders_Order_By
} from "@/generated/graphql";
import {
  Lifecycle_Statuses_Enum,
  Order_By,
  Work_Order_Types_Enum,
  type Work_Orders_Bool_Exp
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import {
  CURRENT_DATE_PRESETS,
  YEAR_BACK,
  type DateRangePreset
} from "@/lib/datetime";
import { injectStrict } from "@/lib/helpers";
import { getColor, getIcon } from "@/lib/lifecycleStatus";
import { BreakPointsKey } from "@/providerKeys";
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

const props = defineProps<{
  customerId?: string;
}>();

const breakpoints = injectStrict(BreakPointsKey);
const filterViewMode = ref<CollectionView>(CollectionView.Table);

const { data: optionsData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query WorkOrderOptions {
      customers(order_by: { title: asc }) {
        id
        title
      }

      users(order_by: { full_name: asc }) {
        id
        full_name
      }
    }
  `)
});

const customers = computed(() => optionsData.value?.customers ?? []);
const users = computed(() => optionsData.value?.users ?? []);

const statusFilter: Facet<Work_Orders_Bool_Exp> = {
  modelName: "workOrderStatus",
  label: "Status",
  defaultValue: "all",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    {
      id: "pending",
      title: "Pending",
      predicate: {
        lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Pending }
      }
    },
    {
      id: "open",
      title: "Open",
      predicate: {
        lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Open }
      }
    },
    {
      id: "closed",
      title: "Closed",
      predicate: {
        lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Closed }
      }
    }
  ]
};

const orderByFilters: OrderSet<Work_Orders_Order_By> = {
  defaultValue: "updated",
  options: [
    {
      id: "created",
      title: "Created (Open)",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "start",
      title: "Start Date",
      asc: [{ service_date_start: Order_By.Asc }],
      desc: [{ service_date_start: Order_By.Desc }]
    },
    {
      id: "ref",
      title: "Work Order ID",
      asc: [{ ref: Order_By.Asc }],
      desc: [{ ref: Order_By.Desc }]
    },
    {
      id: "author",
      title: "Author",
      asc: [{ author: { full_name: Order_By.Asc } }],
      desc: [{ author: { full_name: Order_By.Desc } }]
    },
    {
      id: "updated",
      title: "Updated Date",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    },
    {
      id: "end",
      title: "End Date",
      asc: [{ service_date_end: Order_By.Asc }],
      desc: [{ service_date_end: Order_By.Desc }]
    },

    {
      id: "customer",
      title: "Customer",
      asc: [{ customer: { title: Order_By.Asc } }],
      desc: [{ customer: { title: Order_By.Desc } }]
    }
  ]
};

const authorFilters: Facet<Work_Orders_Bool_Exp> = {
  defaultValue: "All",
  label: "Author",
  modelName: "authors",
  filters: [
    {
      id: "All",
      title: "All",
      predicate: {}
    },
    ...users.value.map(
      (user: { id: string; full_name?: string | null | undefined }) => {
        return {
          id: user.id,
          title: `${user.full_name}`,
          predicate: {
            author_id: { _eq: user.id }
          }
        };
      }
    )
  ]
};

const customerFilters: Facet<Work_Orders_Bool_Exp> = {
  defaultValue: "All",
  hideOnMobile: true,
  label: "Customer",
  modelName: "customerId",
  filters: [
    {
      id: "All",
      title: "All",
      predicate: {}
    },
    ...customers.value.map((cust: { id: string; title: string }) => {
      return {
        id: cust.id,
        title: cust.title,
        predicate: {
          customer_id: { _eq: cust.id }
        }
      };
    })
  ]
};

function buildDatePredicates(dates: [Date, Date]) {
  return {
    _or: [
      { created_at: { _gte: dates[0], _lte: dates[1] } },
      { service_date_end: { _gte: dates[0], _lte: dates[1] } },
      { service_date_start: { _gte: dates[0], _lte: dates[1] } },
      { updated_at: { _gte: dates[0], _lte: dates[1] } }
    ]
  };
}

const PRESETS = [...CURRENT_DATE_PRESETS, ...YEAR_BACK];
const dateRangeFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "all",
  hideOnMobile: true,
  label: "Dates",
  modelName: "dateRange",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...PRESETS.map((preset: DateRangePreset) => ({
      id: preset.id,
      title: preset.title,
      predicate: buildDatePredicates(preset.dates)
    }))
  ]
};

const activeFacets = props.customerId
  ? [authorFilters, dateRangeFilters, statusFilter]
  : [authorFilters, customerFilters, dateRangeFilters, statusFilter];

const {
  facetModels,
  filterWhereClause,
  resetFacets,
  searchString,
  sortOrder,
  updateFacet,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets(activeFacets, orderByFilters);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query WorkOrdersView(
      $where: work_orders_bool_exp!
      $order_by: [work_orders_order_by!]
    ) {
      work_orders(where: $where, order_by: $order_by) {
        author_id
        customer_id
        id
        ref
        service_date_start
        service_date_end
        updated_at

        lifecycle_status
        ...WorkOrderStatus

        author {
          ...UserLink
        }

        customer {
          title
          abbreviation
        }
      }

      work_orders_aggregate(where: $where) {
        aggregate {
          count
        }
      }

      customers(order_by: { title: asc }) {
        id
        title
      }

      users(order_by: { full_name: asc }) {
        id
        full_name
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        { customer_id: { _eq: props.customerId } },
        { work_order_type: { _eq: Work_Order_Types_Enum.Dedicated } },
        ...filterWhereClause.value,
        { ref: { _ilike: `${searchString.value}%` } }
      ]
    },
    order_by: sortOrder.value
  })),
  context: { additionalTypenames: ["work_orders", "users", "customers"] },
  pause: computed(() => !filterWhereClause.value)
});

const workOrders = computed(() => data.value?.work_orders ?? []);
const workOrderCount = computed(
  () => data.value?.work_orders_aggregate.aggregate?.count || 0
);
</script>

<template>
  <article
    v-if="customers && users"
    class="vertical loose"
    :class="[props.customerId === undefined ? 'xmargin' : '']"
  >
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="activeFacets"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="workOrderCount"
      clear-all-filters
      no-card-view
      placeholder="Search by Order..."
      searchable
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event as FacetComboOption)"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Work Order Filter</template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <template v-else-if="filterViewMode === CollectionView.Table">
      <SHTable fluid :rows="workOrders" empty-message="No work orders found.">
        <template #header>
          <th>Order</th>
          <th style="width: 50%">Customer</th>
          <th v-if="breakpoints.includes('tablet')">Status</th>
          <th v-if="breakpoints.includes('tablet')">Date Range</th>
          <th v-if="breakpoints.includes('desktop')">Author</th>
        </template>

        <template #row="{ row }">
          <td class="level tight">
            <SHButton
              class="number"
              @click="
                $router.push({
                  name: 'WorkOrderDetail',
                  params: {
                    workOrderId: row.id
                  }
                })
              "
            >
              {{ row.ref }}
            </SHButton>
            <FontAwesomeIcon
              v-if="!breakpoints.includes('tablet')"
              :icon="getIcon(row.lifecycle_status)"
              :color="getColor(row.lifecycle_status)"
            />
          </td>
          <td class="truncate">
            <span v-if="breakpoints.includes('tablet')">
              {{ row.customer.title }}
            </span>
            <span v-else>{{ row.customer.abbreviation }}</span>
          </td>

          <td v-if="breakpoints.includes('tablet')">
            <WorkOrderStatusBadge :work-order="row" />
          </td>

          <td
            v-if="breakpoints.includes('tablet')"
            class="number level tight"
            style="font-weight: 300"
          >
            <span>
              <SHInlineDate :d="row.service_date_start" />
            </span>
            <FontAwesomeIcon
              :icon="faArrowRight"
              style="color: var(--color-primary)"
            />
            <span>
              <SHInlineDate :d="row.service_date_end" />
            </span>
          </td>
          <td v-if="breakpoints.includes('desktop')">
            <UserLink :user="row.author" />
          </td>
        </template>

        <template #footer>
          <SHTableRowCount :count="workOrderCount" label="work order" />
        </template>
      </SHTable>
    </template>
    <template v-else-if="filterViewMode === CollectionView.Calendar">
      <ToBeDetermined>Calendar View</ToBeDetermined>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.truncate {
  max-width: 10em;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
