<script setup lang="ts">
import type { MediaUploadContext } from "@/types";
import type { Editor } from "@tiptap/vue-3";
import type { MediaUploadTask } from "@/composables/useUpload";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCamera,
  faImage,
  faPaperclip
} from "@fortawesome/sharp-light-svg-icons";
import SHMediaUploader from "@/components/SHMediaUploader.vue";
import type { UploadedMedia } from "@/types";

defineProps<{
  editor: Editor;
  filePath: string;
  context?: MediaUploadContext;
}>();
const emit = defineEmits<{
  (e: "upload:queued", value: MediaUploadTask): void;
  (e: "upload:finished", value: UploadedMedia): void;
}>();
</script>

<template>
  <article class="editor-toolbar level tight">
    <SHMediaUploader
      multiple
      accept="image/*"
      :path="filePath"
      capture="environment"
      :context="context"
      @upload:queued="emit('upload:queued', $event)"
      @upload:finished="emit('upload:finished', $event)"
    >
      <FontAwesomeIcon :icon="faCamera" />
    </SHMediaUploader>

    <SHMediaUploader
      multiple
      accept="image/*"
      :path="filePath"
      :context="context"
      @upload:queued="emit('upload:queued', $event)"
      @upload:finished="emit('upload:finished', $event)"
    >
      <FontAwesomeIcon :icon="faImage" />
    </SHMediaUploader>

    <SHMediaUploader
      multiple
      accept="*"
      :path="filePath"
      :context="context"
      @upload:queued="emit('upload:queued', $event)"
      @upload:finished="emit('upload:finished', $event)"
    >
      <FontAwesomeIcon :icon="faPaperclip" />
    </SHMediaUploader>
  </article>
</template>

<style lang="scss" scoped>
@media print {
  .editor-toolbar {
    display: none;
  }
}
</style>
