import type { RouteRecordRaw } from "vue-router";
import ModuleHeader from "@/components/ModuleHeader.vue";
import SHButton from "@/components/SHButton.vue";
import { h } from "vue";
import {
  faPlus,
  faClipboardListCheck,
  faFileInvoiceDollar
} from "@fortawesome/sharp-light-svg-icons";
import { faCalendar } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import InvoicesView from "@/views/InvoicesView.vue";
import InvoiceCreate from "@/views/InvoiceCreate.vue";

export const invoiceRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "CustomerInvoicesView",
    components: {
      default: InvoicesView,
      header: h(
        ModuleHeader,
        { icon: faClipboardListCheck },
        {
          default: () => "Customer Invoices",
          nav: () =>
            h(
              SHButton,
              {
                color: "primary",
                to: { name: "CustomerInvoiceCreate" },
                size: "sm"
              },
              () => [
                h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                h(FontAwesomeIcon, {
                  icon: faFileInvoiceDollar,
                  fixedWidth: true
                })
              ]
            )
        }
      )
    },
    props: {
      default(route) {
        return {
          ...route.params,
          noMargin: true
        };
      }
    }
  },
  {
    path: "new",
    name: "CustomerInvoiceCreate",
    components: {
      default: InvoiceCreate,
      header: h(ModuleHeader, { icon: faCalendar }, () => "Create New Invoice")
    },
    props: {
      default(route) {
        return {
          ...route.params,
          noMargin: true
        };
      }
    }
  }
];
