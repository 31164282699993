<script async setup lang="ts">
import { graphql } from "@/generated";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { useMutation, useQuery } from "@urql/vue";
import { v4 } from "uuid";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import ComboBox from "@/components/ComboBox/ComboBox.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHField from "@/components/SHField.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";

import SHDollarInput from "@/components/SHDollarInput.vue";
import SHNote from "@/components/SHNote.vue";
import SHToggle from "@/components/SHToggle.vue";
import SHTextEditorWithToolbar from "@/components/TextEditor/SHTextEditorWithToolbar.vue";
import { useTipTap } from "@/composables/useTipTap";
import { useToaster } from "@/composables/useToaster";
import type { Expense_Types_Enum } from "@/generated/graphql";
import type { UploadedMedia } from "@/types";
import type { JSONContent } from "@tiptap/vue-3";
import { isFuture } from "date-fns";

const router = useRouter();
const { createToast } = useToaster();

const { ticketId, expenseLogId } = defineProps<{
  ticketId: string;
  expenseLogId?: string;
}>();

defineEmits<{
  (
    e: "expense-log:create",
    value: ResultOf<typeof InsertExpenseLogMutation>
  ): void;
  (
    e: "expense-log:update",
    value: ResultOf<typeof UpdateExpenseLogMutation>
  ): void;
  (e: "expense-log:cancel"): void;
}>();

const { data: expenseLogData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query ExpenseLogFormExpenseLog($expenseLogId: uuid!) {
      expense_logs_by_pk(id: $expenseLogId) {
        id
        notes
        notesj
        expensed_at
        deleted_at
        expense_type
        cents_recorded
        is_external

        media_uploads(
          where: { deleted_at: { _is_null: true } }
          order_by: [{ created_at: asc }]
        ) {
          id
          ...MediaUploadCard
          ...SHLightbox
        }
      }
    }
  `),
  variables: {
    expenseLogId: expenseLogId ?? ""
  },
  pause: !expenseLogId
});

const expenseLogFormOptionsQuery = graphql(/* GraphQL */ `
  query ExpenseLogFormOptions {
    expense_types {
      id: value
      label: value
    }
  }
`);

const { data: expenseLogOptionData } = useQuery({
  query: expenseLogFormOptionsQuery
});

const expenseLog = computed(() => expenseLogData.value?.expense_logs_by_pk);

const expenseTypes = computed(() =>
  expenseLogOptionData.value?.expense_types
    ? expenseLogOptionData.value.expense_types.map(et => ({
        ...et,
        label:
          et.label[0].toLocaleUpperCase() +
          et.label.substring(1).toLocaleLowerCase()
      }))
    : []
);

const displayExpenseType = computed(
  () =>
    expenseTypes.value.find(e => e.id === form.value.expense_type) ??
    expenseTypes.value.find(e => e.id === expenseLog.value?.expense_type)
);

const form = ref<{
  id?: string;
  notesj?: JSONContent | null;
  expensed_at?: Date;
  expense_type?: Expense_Types_Enum;
  cents_recorded?: number | null;
  mediaUploads: UploadedMedia[];
  is_external?: boolean;
}>({
  id: expenseLog?.value?.id ?? v4(),
  notesj: expenseLog?.value?.notesj ?? undefined,
  expensed_at: expenseLog.value?.expensed_at
    ? new Date(expenseLog.value?.expensed_at)
    : new Date(),
  expense_type: expenseLog.value?.expense_type ?? undefined,
  cents_recorded: expenseLog.value?.cents_recorded ?? undefined,
  mediaUploads: expenseLog.value?.media_uploads ?? [],
  is_external: expenseLog.value?.is_external ?? false
});

const UpdateExpenseLogMutation = graphql(/* GraphQL */ `
  mutation UpdateExpenseLog(
    $expenseLogId: uuid!
    $expenseLog: expense_logs_set_input
  ) {
    update_expense_logs_by_pk(
      pk_columns: { id: $expenseLogId }
      _set: $expenseLog
    ) {
      id
    }
  }
`);

const InsertExpenseLogMutation = graphql(/* GraphQL */ `
  mutation InsertExpenseLog($expenseLog: expense_logs_insert_input!) {
    insert_expense_logs_one(object: $expenseLog) {
      id
      ticket_id
    }
  }
`);

const { executeMutation: update } = useMutation(UpdateExpenseLogMutation);

async function onUpdate() {
  if (!expenseLogId) {
    throw new ReferenceError("No expense log to update.");
  }

  const { data, error } = await update({
    expenseLogId: expenseLogId,
    expenseLog: {
      expense_type: form.value.expense_type,
      notesj: form.value.notesj,
      expensed_at: form.value.expensed_at,
      cents_recorded: form.value.cents_recorded,
      is_external: form.value.is_external
    }
  });

  if (error) {
    createToast({
      title: "Unable to save the expense.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (data) {
    createToast({
      message: `Expense updated.`,
      theme: "success"
    });
    router.go(-1);
  }
}

const { executeMutation: insert } = useMutation(InsertExpenseLogMutation);

async function onInsert() {
  const { data, error } = await insert({
    expenseLog: {
      ticket_id: ticketId,
      id: form.value.id,
      notesj: form.value.notesj,
      expensed_at: form.value.expensed_at,
      expense_type: form.value.expense_type,
      cents_recorded: form.value.cents_recorded,
      is_external: form.value.is_external
    }
  });

  if (error) {
    createToast({
      title: "Unable to add the expense.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (data) {
    createToast({
      message: `Expense added.`,
      theme: "success"
    });
    router.go(-1);
  }
}

function onConfirm() {
  if (expenseLogId) {
    return onUpdate();
  }
  return onInsert();
}

const isValid = computed(
  () =>
    !!form.value.expense_type &&
    !!form.value.cents_recorded &&
    !!form.value.expensed_at &&
    !isFuture(form.value.expensed_at) &&
    (form.value.is_external ? form.value.mediaUploads.length > 0 : true)
);

const notes = computed<JSONContent | null | undefined>({
  get() {
    return expenseLog.value?.notesj || form.value.notesj;
  },
  set(val) {
    form.value.notesj = val;
  }
});

const editor = useTipTap(notes);
</script>

<template>
  <article>
    <main class="vertical loose">
      <SHField
        label="Expense Type"
        block
        required
        :requirement-satisfied="!!form.expense_type"
      >
        <ComboBox
          :model-value="displayExpenseType"
          :options="expenseTypes"
          mobile-header="Expense Type"
          @update:model-value="
            form.expense_type = $event?.id as Expense_Types_Enum
          "
        />
      </SHField>

      <SHField label="Include in Reports">
        <div class="level tight">
          No
          <SHToggle v-model="form.is_external" />
          Yes
        </div>
      </SHField>

      <SHField
        label="Amount in Dollars"
        block
        required
        :requirement-satisfied="!!form.cents_recorded"
      >
        <SHDollarInput
          :model-value="form.cents_recorded ?? expenseLog?.cents_recorded"
          placeholder="0.00"
          @keydown="$event.key === 'Enter' && onConfirm()"
          @update:model-value="form.cents_recorded = $event"
        />
      </SHField>

      <SHField
        label="Date"
        block
        required
        :requirement-satisfied="!isFuture(form.expensed_at as Date)"
      >
        <SHDatePicker
          :model-value="form.expensed_at"
          auto-apply
          position="left"
          class="vdp"
          enable-time-picker
          format="MMM dd, yyyy"
          @update:model-value="form.expensed_at = $event as Date"
        />
      </SHField>

      <SHField
        label="Notes & Media"
        block
        required
        :requirement-satisfied="
          !form.is_external || form.mediaUploads.length > 0
        "
      >
        <SHTextEditorWithToolbar
          v-model="notes"
          v-model:media-uploads="form.mediaUploads"
          :context="{ expense_log_id: form.id ?? expenseLogId }"
          label="Notes"
          :editor="editor"
          :file-path="`expense_logs/${expenseLog?.id ?? form.id}`"
          editable
        />
      </SHField>

      <SHNote
        v-if="form.is_external && form.mediaUploads.length < 1"
        theme="warning"
      >
        Expenses billed to the customer must have at least 1 image attached
        showing the charge.
      </SHNote>

      <UndoConfirm
        :confirm-enabled="isValid"
        @undo="$router.back"
        @confirm="onConfirm"
      />
    </main>
  </article>
</template>
