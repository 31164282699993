<script setup lang="ts" generic="T extends object">
import { useEnumOptions } from "@/composables/useEnumOptions";
import { useLogger } from "@/logger";
import { computed } from "vue";
import ComboBox from "./ComboBox/ComboBox.vue";

const { log } = useLogger("SHEnumChooser");

const props = defineProps<{
  options: T;
  mobileHeader: string;
}>();

const modelValue = defineModel<string | null | undefined>({ default: null });

const comboOptions = useEnumOptions(props.options);

const comboOption = computed({
  get() {
    log(
      "comboOption get",
      modelValue.value,
      comboOptions.value.find(opt => opt.id === modelValue.value)
    );
    return comboOptions.value.find(opt => opt.id === modelValue.value);
  },
  set(val) {
    modelValue.value = val?.id ?? null;
  }
});
</script>

<template>
  <ComboBox
    v-model="comboOption"
    :options="comboOptions"
    :mobile-header="mobileHeader"
  >
    <template #option="{ option }">
      <slot name="option" :option="option" />
    </template>

    <template #selected-option="{ option }">
      <slot name="selected-option" :option="option" />
    </template>
  </ComboBox>
</template>

<style lang="scss" scoped></style>
