import { formatDate } from "@/formatters";
import { useLogger } from "./../logger";
import { clamp } from "@vueuse/core";
import {
  differenceInMinutes,
  differenceInSeconds,
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfWeek,
  endOfYesterday,
  format,
  intervalToDuration,
  isAfter,
  isBefore,
  isSameDay,
  parse as dfParse,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYesterday,
  subMonths,
  subQuarters,
  subWeeks,
  type Interval,
  add,
  lastDayOfMonth,
  addQuarters,
  startOfDay,
  endOfDay
} from "date-fns";

const { log } = useLogger("lib/datetime"); // eslint-disable-line @typescript-eslint/no-unused-vars

export type TimeOfDay = {
  hours: number;
  minutes: number;
};

export const dayMinutesToTOD = (
  minutesInDay: number,
  minuteResolution = 1
): TimeOfDay => ({
  hours: Math.floor((minutesInDay * minuteResolution) / 60), // 0-23
  minutes: (minutesInDay * minuteResolution) % 60 // 0-59
});

export const TODToDayMinutes = (time: TimeOfDay): number =>
  (time.hours ?? 0) * 60 + (time.minutes ?? 0);

export const timeOfDayFromText = (text: string): TimeOfDay => {
  const parsed = dfParse(text, "HH:mm", 0);
  return timeOfDayFromDate(parsed);
};

export const timeOfDayFromDate = (d: Date | undefined): TimeOfDay => {
  return {
    hours: d?.getHours() ?? 12,
    minutes: d?.getMinutes() ?? 0
  };
};

export const combineDateTimeOfDay = (d: Date, t: TimeOfDay): Date => {
  const newD = new Date(d);
  newD.setHours(t.hours);
  newD.setMinutes(t.minutes);
  newD.setSeconds(0, 0);
  return newD;
};

export function progressInInterval(start: Date, end: Date): number {
  const numerator = differenceInSeconds(start, new Date());
  const denominator = differenceInSeconds(start, end);
  return clamp(numerator / denominator, 0, 1);
}

export function getPriorMonth(numOfMonthsBack: number): Date {
  return subMonths(startOfToday(), numOfMonthsBack);
}

export function createTitleForPriorMonth(numOfMonthsBack: number): string {
  return `${format(startOfMonth(getPriorMonth(numOfMonthsBack)), "MMMM yyyy")}`;
}

export function createShortTitle(numOfMonthsBack: number): string {
  return `${format(startOfMonth(getPriorMonth(numOfMonthsBack)), "MMM. yyyy")}`;
}
/**
 * Check whether a date is intersecting a date range
 * while excluding intersections at the exact start and end point
 */
export function isWithinIntervalExclusive<DateType extends Date>(
  date: string | number | DateType,
  interval: Interval
): boolean {
  const _date = new Date(date);
  return isAfter(_date, interval.start) && isBefore(_date, interval.end);
}

export function formatDateRange(
  start: string | number | Date,
  end: string | number | Date
) {
  const timeFormat = "hh:mma";
  const dateFormat = `MM/dd, ${timeFormat}`;
  return isSameDay(new Date(start), new Date(end))
    ? `${formatDate(start, dateFormat)} - ${formatDate(end, timeFormat)}`
    : `${formatDate(start, dateFormat)} - ${formatDate(end, dateFormat)}`;
}

export function formatInterval(interval: Interval) {
  const formatter = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumIntegerDigits: 2
  });

  const { hours, minutes = 0 } = intervalToDuration(interval);

  return hours
    ? `${hours}h ${formatter.format(minutes)}m`
    : `${formatter.format(minutes)}m`;
}

export function datesWithinThreshold(
  start: string,
  end: string,
  threshold = 30
) {
  return (
    Math.abs(differenceInMinutes(parseISO(end), parseISO(start))) <= threshold
  );
}

export type DateRangePreset = {
  id: string;
  title: string;
  title_short?: string;
  dates: [Date, Date];
};

export const LAST_MONTH: DateRangePreset = {
  id: "last_month",
  title: "Last Month",
  dates: [startOfMonth(getPriorMonth(1)), endOfMonth(getPriorMonth(1))]
};

export const LAST_QUARTER: DateRangePreset = {
  id: "last_quarter",
  title: "Last Quarter",
  dates: [
    subQuarters(startOfQuarter(startOfToday()), 1),
    startOfQuarter(startOfToday())
  ]
};

export const LAST_WEEK: DateRangePreset = {
  id: "last_week",
  title: "Last Week",
  dates: [subWeeks(startOfWeek(startOfToday()), 1), startOfWeek(startOfToday())]
};

export const NEXT_MONTH: DateRangePreset = {
  id: "next_month",
  title: "Next Month",
  dates: [
    startOfMonth(add(startOfToday(), { months: 1 })),
    lastDayOfMonth(add(startOfToday(), { months: 1 }))
  ]
};

export const NEXT_QUARTER: DateRangePreset = {
  id: "next_quarter",
  title: "Next Quarter",
  dates: [
    startOfQuarter(addQuarters(startOfToday(), 1)),
    endOfQuarter(addQuarters(startOfToday(), 1))
  ]
};

export const NEXT_WEEK: DateRangePreset = {
  id: "next_week",
  title: "Next Week",
  dates: [
    startOfWeek(add(startOfToday(), { weeks: 1 })),
    endOfWeek(add(startOfToday(), { weeks: 1 }))
  ]
};

export const THIS_MONTH: DateRangePreset = {
  id: "this_month",
  title: "This Month",
  dates: [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
};

export const THIS_QUARTER: DateRangePreset = {
  id: "this_quarter",
  title: "This Quarter",
  dates: [startOfQuarter(startOfToday()), endOfQuarter(startOfToday())]
};

export const THIS_WEEK: DateRangePreset = {
  id: "this_week",
  title: "This Week",
  dates: [startOfWeek(startOfToday()), endOfWeek(startOfToday())]
};

export const TODAY: DateRangePreset = {
  id: "today",
  title: "Today",
  dates: [startOfToday(), endOfToday()]
};

export const TOMORROW: DateRangePreset = {
  id: "tomorrow",
  title: "Tomorrow",
  dates: [
    startOfDay(add(startOfToday(), { days: 1 })),
    endOfDay(add(startOfToday(), { days: 1 }))
  ]
};

export const YESTERDAY: DateRangePreset = {
  id: "yesterday",
  title: "Yesterday",
  dates: [startOfYesterday(), endOfYesterday()]
};

// create date presets for a range of previous months
const rangeOfDates = (start: number, end: number) => {
  const arrayOfMonths = [];
  const START_OF_RANGE = start;
  const END_OF_RANGE = end;
  for (let m: number = START_OF_RANGE; m < END_OF_RANGE; m++) {
    const newPreset: DateRangePreset = {
      id: `${m}_months`,
      title: createTitleForPriorMonth(m),
      title_short: createShortTitle(m),
      dates: [startOfMonth(getPriorMonth(m)), endOfMonth(getPriorMonth(m))]
    };
    arrayOfMonths.push(newPreset);
  }
  return arrayOfMonths;
};

export const FUTURE_DATE_PRESETS = [
  TOMORROW,
  NEXT_WEEK,
  NEXT_MONTH,
  NEXT_QUARTER
];

export const CURRENT_DATE_PRESETS = [
  TODAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_QUARTER
];

export const PAST_DATE_PRESETS = [
  YESTERDAY,
  LAST_WEEK,
  LAST_MONTH,
  LAST_QUARTER
];

export const SIX_MONTHS_BACK = [...rangeOfDates(2, 8)];

export const YEAR_BACK = [...rangeOfDates(2, 13)];

export const DATE_FILTER_PRESETS = [
  ...CURRENT_DATE_PRESETS,
  ...PAST_DATE_PRESETS,
  ...SIX_MONTHS_BACK
];
