<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHBadge from "@/components/SHBadge.vue";
import UserLink from "@/components/UserLink.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  faBarcodeRead,
  faChevronUp,
  faNoteSticky,
  faUsers
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useTimeAgo } from "@vueuse/core";
import { ref } from "vue";
import SHTextEditor from "./TextEditor/SHTextEditor.vue";
// import SHTextEditor from "./TextEditor/SHTextEditor.vue";

const fragment = graphql(/* GraphQL */ `
  fragment PriceBookCard on price_books {
    id
    title
    created_at
    author {
      ...UserLink
    }
    notesj

    product_prices_aggregate(
      where: {
        product: {
          deleted_at: { _is_null: true }
          catalog: { deleted_at: { _is_null: true } }
        }
      }
    ) {
      aggregate {
        count(distinct: true, columns: product_id)
      }
    }

    customers_aggregate(where: { deleted_at: { _is_null: true } }) {
      aggregate {
        count
      }
    }
  }
`);

const props = defineProps<{
  priceBook: FragmentType<typeof fragment>;
}>();

const showDetails = ref(false);
const priceBook = useFragment(fragment, props.priceBook);

const createdAtAgo = useTimeAgo(priceBook.created_at);
</script>

<template>
  <article class="price-book-card vertical">
    <header class="level-spread loose">
      <h1>{{ priceBook.title }}</h1>
      <div class="level tight">
        <SHBadge class="level tight number">
          <FontAwesomeIcon :icon="faUsers" />
          <span>
            {{ priceBook.customers_aggregate.aggregate?.count }}
          </span>
        </SHBadge>
        <SHBadge class="level tight number">
          <FontAwesomeIcon :icon="faBarcodeRead" />
          <span>
            {{ priceBook.product_prices_aggregate.aggregate?.count }}
          </span>
        </SHBadge>
      </div>
    </header>

    <div class="level-spread">
      <div class="level tight">
        <span>Created On:</span>
        <span>{{ createdAtAgo }}</span>
      </div>

      <div class="level tight">
        <span>Created By:</span>
        <UserLink v-if="priceBook.author" :user="priceBook.author" />
      </div>
    </div>

    <section v-if="showDetails" class="details vertical tight">
      <FontAwesomeIcon :icon="faNoteSticky" />
      <div v-if="priceBook.notesj">
        <SHTextEditor v-model="priceBook.notesj" :editable="false" />
      </div>
      <div v-else>No notes provided.</div>
    </section>
    <footer class="level-center">
      <AppLink @click="showDetails = !showDetails">
        <FontAwesomeIcon
          class="chevron"
          :icon="faChevronUp"
          :rotation="showDetails ? undefined : 180"
          style="transition: transform 150ms ease-in-out"
          size="sm"
        />
      </AppLink>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.price-book-card {
  padding: var(--padding);
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--color-surface-100);

  header {
    h1 {
      font-size: var(--font-size-md);
    }
  }

  .details {
    padding-top: 0.5em;
    display: grid;
    grid-template-columns: 1em 1fr;

    svg {
      padding-top: 0.25em;
      color: var(--color-secondary);
    }

    p {
      margin: 0;
      vertical-align: text-top;

      &.empty {
        color: var(--color-surface-300);
      }
    }
  }

  footer {
    font-size: var(--font-size-xs);
  }
}
</style>
