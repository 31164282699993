<script setup lang="ts">
import SHInput from "../SHInput.vue";
import { computed } from "vue";
import type { InvoiceForm } from "./types";
import SHDollarInput from "@/components/SHDollarInput.vue";
import SHTextarea from "../SHTextarea.vue";

const props = defineProps<{
  form: InvoiceForm["lineItems"][number];
}>();

const emit = defineEmits<{
  (e: "update:form", value: InvoiceForm["lineItems"][number]): void;
}>();

const form = computed({
  get() {
    return props.form;
  },
  set(val) {
    emit("update:form", val);
  }
});
</script>

<template>
  <tr class="line-item-form">
    <td>
      <SHInput v-model="form.label" compact />
    </td>
    <td>
      <SHTextarea v-model="form.description" auto-resize compact />
    </td>
    <td>
      <SHDollarInput
        :model-value="form.centsInvoiced ?? form.centsCalculated"
        compact
        align-right
        @update:model-value="form.centsInvoiced = $event"
      />
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.line-item-form {
  td {
    vertical-align: bottom;
  }
}
</style>
