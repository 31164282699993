import { getHash } from "@/lib/seedableRandom";

const POSSIBLE_COLORS = 32; // should be a clean divisor of 360, but 360 was chosen for how many divisors it has so... have fun
export function colorFromSeed(
  seed: string,
  isDark: boolean,
  alpha: number = 1
) {
  const hashVal = getHash(seed); // 0..1
  const bucket = Math.round(hashVal * POSSIBLE_COLORS);

  const hue = Math.round(bucket * (360 / POSSIBLE_COLORS));
  const saturation = isDark ? "50%" : "50%";
  const lightness = isDark ? "40%" : "35%";
  const color = `hsl(${hue}, ${saturation}, ${lightness}, ${alpha})`;

  return color;
}
