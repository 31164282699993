<script setup lang="ts">
import { useTipTap } from "@/composables/useTipTap";
import type {
  MediaObjectOrUploadTask,
  MediaUploadTask
} from "@/composables/useUpload";
import { useLogger } from "@/logger";
import type { MediaUploadContext, UploadedMedia } from "@/types";
import MediaUploadsView from "@/views/MediaUploadsView.vue";
import type { Editor, JSONContent } from "@tiptap/vue-3";
import { useVModel } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import SHEditorToolbar from "./SHEditorToolbar.vue";
import SHTextEditor from "./SHTextEditor.vue";

const { log } = useLogger("SHTextEditorWithToolbar");

const props = defineProps<{
  filePath: string;
  context?: MediaUploadContext;
  editor?: Editor;
  modelValue?: JSONContent | null;
  mediaUploads?: UploadedMedia[];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: JSONContent | null | undefined): void;
  (e: "update:media-uploads", value: UploadedMedia[]): void;
}>();

const model = useVModel(props, "modelValue", emit);
const createdEditor = useTipTap(model);
const editor = computed(() => props.editor ?? createdEditor.value);

// const allUploads = shallowRef<MediaObjectOrUploadTask[]>([...(props.mediaUploads ?? [])]);
const allUploads = ref<MediaObjectOrUploadTask[]>([
  ...(props.mediaUploads ?? [])
]);

const ignoreIncomingChanges = ref(false);

watch(
  () => props.mediaUploads,
  newVal => {
    if (!ignoreIncomingChanges.value) {
      allUploads.value = [...(newVal ?? [])];
    }
    ignoreIncomingChanges.value = false;
  }
);

const _mediaUploads = computed<MediaObjectOrUploadTask[]>({
  get: () => props.mediaUploads ?? [],
  set: uploads => {
    log("setting mediaUploads in writable to", uploads);
    ignoreIncomingChanges.value = true;
    emit("update:media-uploads", uploads.filter(isUploaded));
  }
});

const onUploadFinished = (e: UploadedMedia) => {
  console.log("upload finished", e);
  const origIdx = allUploads.value.findIndex(u => u.id === e.id);
  allUploads.value.splice(origIdx, 1, e);
  _mediaUploads.value = [..._mediaUploads.value, e];
};

const onCancelled = (e: MediaUploadTask) => {
  allUploads.value = allUploads.value.filter(u => u.id !== e.id);
};

const onDeleted = (e: UploadedMedia) => {
  allUploads.value = allUploads.value.filter(u => u.id !== e.id);
  _mediaUploads.value = _mediaUploads.value.filter(u => u.id !== e.id);
};

function isUploaded(u: MediaObjectOrUploadTask): u is UploadedMedia {
  return !("progress" in u);
}
</script>

<template>
  <article v-if="filePath" class="vertical tight">
    <SHEditorToolbar
      v-if="editor"
      :editor="editor"
      v-bind="{ filePath, context }"
      @upload:finished="onUploadFinished($event)"
      @upload:queued="(allUploads as MediaObjectOrUploadTask[]).push($event)"
    />
    <SHTextEditor
      v-if="editor"
      v-model="model"
      editable
      :editor="editor"
      class="text-editor"
    />
    <MediaUploadsView
      hide-empty-message
      :media-uploads="allUploads as MediaObjectOrUploadTask[]"
      @upload:cancelled="onCancelled"
      @upload:deleted="onDeleted"
    />
  </article>
</template>

<style lang="scss" scoped>
.text-editor {
  overflow-y: auto;
}
</style>
