<script lang="ts"></script>

<script setup lang="ts" generic="R extends TimeSliderRange">
import { computed, ref, type StyleValue } from "vue";
import {
  differenceInMinutes,
  startOfDay,
  type Interval,
  endOfDay,
  clamp as clampDate,
  addHours,
  isWithinInterval
} from "date-fns";
import { useElementSize } from "@vueuse/core";
import { autoUpdate, offset, shift, useFloating } from "@floating-ui/vue";

import { isWithinIntervalExclusive } from "@/lib/datetime";

import {
  faBan,
  faCheckCircle,
  faInfoCircle,
  faMoon,
  faSunBright,
  faSunrise,
  faSunset,
  faWarning,
  type IconDefinition
} from "@fortawesome/sharp-light-svg-icons";
import { useLogger } from "@/logger";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { formatDate } from "@/formatters";

export type KeyTime = {
  value: Date;
  label: string;
};

export interface TimeSliderRange extends Interval {
  theme?: "primary" | "secondary" | "info" | "success" | "warning" | "danger";
}

const themeOrder = [
  "danger",
  "warning",
  "info",
  "success",
  "primary",
  "secondary"
];

const { log } = useLogger("SHTimeSlider"); // eslint-disable-line @typescript-eslint/no-unused-vars

const inputEl = ref<HTMLInputElement>();
const { width: inputWidth } = useElementSize(inputEl);

const toolTipEl = ref<HTMLLabelElement>();
const sliderThumbEl = ref<HTMLDivElement>();

const { x: toolTipPositionX, y: toolTipPositionY } = useFloating(
  sliderThumbEl,
  toolTipEl,
  {
    open: true,
    transform: true,
    placement: "top",
    strategy: "absolute",
    middleware: [offset(5), shift()],
    whileElementsMounted: autoUpdate
  }
);

const {
  minuteResolution = 1,
  ranges = [],
  ...props
} = defineProps<{
  compact?: boolean;
  keyTimes?: KeyTime[];
  readonly?: boolean;
  modelValue: Date;
  minuteResolution?: number;
  ranges?: R[];
}>();

const emit = defineEmits<{
  (e: "update:model-value", date: Date): void;
}>();

const defaultKeyTimes = computed<KeyTime[]>(() => [
  { value: addHours(startOfDay(props.modelValue), 7), label: "7am" },
  { value: addHours(startOfDay(props.modelValue), 12), label: "noon" },
  { value: addHours(startOfDay(props.modelValue), 17), label: "5pm" }
]);

const keyTimes = computed(() => {
  return props.keyTimes ?? defaultKeyTimes.value;
});

const clampedRanges = computed(() => {
  const boundaries = {
    start: startOfDay(props.modelValue),
    end: endOfDay(props.modelValue)
  };
  return ranges.map(r => ({
    ...r,
    start: clampDate(r.start, boundaries),
    end: clampDate(r.end, boundaries)
  }));
});

const intersectingRanges = computed(() =>
  clampedRanges.value.filter(range => {
    return isWithinIntervalExclusive(props.modelValue, range);
  })
);

const sortedRanges = computed(() => {
  return clampedRanges.value.slice().sort((a, b) => {
    return (
      themeOrder.indexOf(a.theme || "primary") -
      themeOrder.indexOf(b.theme || "primary")
    );
  });
});

const filteredKeyTimes = computed(() => {
  return keyTimes.value.filter(kt =>
    isWithinInterval(kt.value, {
      start: startOfDay(props.modelValue),
      end: endOfDay(props.modelValue)
    })
  );
});

const minutesValue = computed({
  get() {
    return differenceInMinutes(props.modelValue, startOfDay(props.modelValue));
  },
  set(val) {
    const newDate = new Date(props.modelValue);
    const hours = Math.floor(val / 60);
    const minutes = val % 60;
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    emit("update:model-value", newDate);
  }
});

const labelIcon = computed((): IconDefinition => {
  const date = new Date(props.modelValue);
  const hour = date.getHours();
  const theme = intersectingRanges.value[0]?.theme;
  switch (true) {
    case intersectingRanges.value.length > 0:
    case theme === "danger":
      return faBan;
    case theme === "warning":
      return faWarning;
    case theme === "info":
      return faInfoCircle;
    case theme === "success":
      return faCheckCircle;
    case hour >= 22:
      return faMoon;
    case hour >= 20:
      return faSunset;
    case hour >= 10:
      return faSunBright;
    case hour >= 8:
      return faSunrise;
    default:
      return faMoon;
  }
});

function getMinutesPositionPx(minutes: number): number {
  return (minutes / (24 * 60 - minuteResolution)) * inputWidth.value;
}

const THUMB_OFFSET_WIDTH = 8;
const sliderThumbPositionX = computed(() => {
  return getMinutesPositionPx(minutesValue.value) - THUMB_OFFSET_WIDTH + "px";
});

function computeRangeStyle(
  range: (typeof sortedRanges.value)[number]
): StyleValue {
  return {
    position: "absolute",
    left:
      getMinutesPositionPx(
        differenceInMinutes(range.start, startOfDay(props.modelValue))
      ) + "px",
    width:
      getMinutesPositionPx(differenceInMinutes(range.end, range.start)) + "px"
  };
}
</script>

<template>
  <article class="sh-time-slider" :class="{ readonly, compact }">
    <div
      ref="sliderThumbEl"
      :style="{
        left: getMinutesPositionPx(minutesValue) + 'px'
      }"
      class="psuedo-thumb"
    ></div>
    <div class="track-background"></div>

    <template v-for="(range, idx) in sortedRanges" :key="idx">
      <slot
        name="range"
        v-bind="{
          range,
          idx,
          ranges: sortedRanges,
          style: computeRangeStyle(range)
        }"
      >
        <div
          class="range"
          :class="[
            range.theme,
            { intersecting: intersectingRanges.includes(range) }
          ]"
          :style="computeRangeStyle(range)"
        />
      </slot>
    </template>

    <div class="slider-container">
      <!-- slider thumb tooltip -->

      <!-- slider track -->
      <input
        ref="inputEl"
        v-model="minutesValue"
        type="range"
        :min="0"
        :max="24 * 60 - minuteResolution"
        :step="minuteResolution"
        list="keyTimes"
        :class="{
          [intersectingRanges.at(0)?.theme ?? 'primary']: true
        }"
      />

      <label
        ref="toolTipEl"
        :style="{
          left: toolTipPositionX + 'px',
          top: toolTipPositionY + 'px'
        }"
        class="tooltip"
        :class="{
          show: intersectingRanges.length,
          [intersectingRanges.at(0)?.theme ?? 'primary']: true
        }"
      >
        <FontAwesomeIcon
          :icon="labelIcon"
          size="xl"
          fixed-width
          :color="
            intersectingRanges[0]?.theme === 'danger'
              ? 'var(--color-danger)'
              : 'var(--color-surface-900)'
          "
        />
        <span class="time">
          {{ formatDate(modelValue, "hh:mm aa") }}
        </span>
        <slot name="toolTip" v-bind="{ modelValue }"></slot>
      </label>

      <datalist id="keyTimes">
        <template v-for="keyTime in filteredKeyTimes" :key="keyTime.label">
          <option
            :value="keyTime.value"
            :style="{
              position: 'absolute',
              left:
                getMinutesPositionPx(
                  differenceInMinutes(keyTime.value, startOfDay(modelValue))
                ) + 'px',
              transform: 'translateX(-50%)'
            }"
          >
            <span
              :style="{
                position: 'absolute',
                left:
                  getMinutesPositionPx(
                    differenceInMinutes(keyTime.value, startOfDay(modelValue))
                  ) + 'px',
                transform: 'translateX(-50%)'
              }"
            >
              {{ keyTime.label }}
            </span>
          </option>
        </template>
      </datalist>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints" as bp;

.sh-time-slider {
  --input-height: 38px;

  position: relative;
  user-select: none;
  height: var(--input-height);

  &.readonly {
    .tooltip {
      display: none;
    }

    input[type="range"] {
      &::-webkit-slider-thumb {
        display: none;
      }
    }
  }

  &.compact {
    --input-height: 24px;
  }

  .track-background {
    outline: thin solid var(--color-surface-300);
    background: repeating-linear-gradient(
      135deg,
      var(--color-surface-50) 5px,
      var(--color-surface-50) 10px,
      var(--color-surface-100) 10px,
      var(--color-surface-100) 20px
    );
    border-radius: 5px;
    display: flex;
    height: var(--input-height);
    position: absolute;
    width: 100%;
    z-index: -2;
  }

  .slider-container {
    background: none;
    height: var(--input-height);
  }

  .show-conflict-thumb {
    background-color: var(--color-danger);
  }

  datalist {
    position: relative;
    display: block;

    option {
      color: var(--color-surface-600);
      min-height: unset;
      min-width: max-content;
      position: absolute;
      top: -0.25em;
      font-size: 0.75em;
    }
  }

  input[type="range"] {
    appearance: none;
    background-color: transparent;
    touch-action: none;
    width: 100%;
    z-index: 2;
    height: var(--input-height);

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      // By default on Chrome, the thumb is positioned wrong
      position: absolute;
      left: v-bind(sliderThumbPositionX);
      top: 0;

      appearance: none;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 40%;
      border-radius: 5px;
      width: 24px;
      height: var(--input-height);
      cursor: grab;
      transition: background-color 0.2s ease-in-out;
      background-color: var(--color-surface-300);
      background-image: url("/img/grip-dots-vertical-sharp-light.svg");
      transform: translateX(-3px);

      z-index: 3;

      &:hover {
        background-color: var(--color-surface-200);
      }

      &:active {
        background-color: var(--color-surface-200);
        cursor: grabbing;
      }
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: var(--input-height);
      border-radius: 5px;
      background: none;

      &:active {
        border-color: var(--color-primary);
      }
      &:focus {
        border-color: var(--color-primary);
      }
    }

    &.danger {
      &::-webkit-slider-runnable-track {
        border-color: var(--color-danger);
      }
    }
  }

  .psuedo-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    z-index: 1000;
  }

  .tooltip {
    display: flex;
    align-items: center;
    gap: 0.5em;
    border-radius: 5px;
    font-family: var(--font-family-primary);
    font-size: 1.25em;

    height: 2.5rem;
    z-index: 100;
    position: absolute;
    width: max-content;
    top: 0;
    left: 0;

    white-space: nowrap;
    min-width: 5em;
    text-align: center;
    padding: 0.25em 0.5em;

    background-color: var(--color-surface-200);

    visibility: hidden;
    &.show {
      visibility: visible;
    }

    .time {
      color: var(--color-surface-900);
      font-family: var(--font-family-monospace);
    }

    &.danger {
      box-shadow: inset 0 0 0 1px var(--color-danger);
    }
  }

  :deep(.app-link) {
    color: var(--color-primary);

    &:hover {
      color: var(--color-secondary);
    }
  }

  input:active + .tooltip {
    visibility: visible;
  }
}
</style>

<style lang="scss">
.sh-time-slider {
  .range {
    height: var(--input-height);
    position: absolute;
    background-color: var(--color-surface-300-opacity-30);
    z-index: 3;
    transition: box-shadow 0.2s ease-in-out;

    &.intersecting {
      box-shadow: inset 0 0 0 1px var(--color-danger);
    }

    &.danger {
      background-color: var(--color-danger-opacity-50);
    }

    &.warning {
      background-color: var(--color-warning-opacity-30);
    }

    &.info {
      background-color: var(--color-info-opacity-30);
    }

    &.primary {
      background-color: var(--color-primary-opacity-30);
    }

    &.secondary {
      background-color: var(--color-secondary-opacity-30);
    }

    &.success {
      background-color: var(--color-success-opacity-30);
    }
  }
}
</style>
