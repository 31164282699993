import { graphql } from "@/generated";

import { Node } from "../UserLinkNode.vue";

import defineMention from "./defineMention";

const query = graphql(/* GraphQL */ `
  query UserSuggestion($searchText: String!) {
    items: users(where: { fname: { _ilike: $searchText } }, limit: 5) {
      fname
      ...UserLink
    }
  }
`);

export default defineMention({
  char: " ", // TODO: Re-enable when @mentions are ready to trigger notifications
  // char: "@",
  query,
  getAttrs: i => ({ user: i }),
  getLabel: i => i.fname,
  node: Node
});
