<script setup lang="ts">
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import LogActivityChart from "@/components/charts/LogActivityChart.vue";
import { useDDVActivitySeries } from "@/composables/useDDVActivitySeries";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const props = defineProps<{
  customerId: string;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomerDashboard($customerId: uuid!) {
      ticket_activity(
        where: { ticket: { work_order: { customer_id: { _eq: $customerId } } } }
      ) {
        work_log_id
        travel_log_id
        started_at
        ended_at
        ticket_id
      }
    }
  `),
  variables: computed(() => ({
    customerId: props.customerId
  }))
});

const { series } = useDDVActivitySeries(
  computed(() =>
    data.value?.ticket_activity.map(a => ({
      started_at: a.started_at,
      ended_at: a.ended_at,
      work_log_id: a.work_log_id,
      travel_log_id: a.travel_log_id,
      ticket_id: a.ticket_id
    }))
  )
);
</script>
<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else>
    <LogActivityChart :points="series" />
  </article>
</template>
