<script setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { toArrayLiteral } from "@/lib/strings";
import { useMutation, useQuery } from "@urql/vue";
import { computed, inject, ref } from "vue";
import { useRouter } from "vue-router";

import SHAgentChooser from "@/components/SHAgentChooser.vue";
import { useAgentChoice, type AgentChoice } from "@/composables/useAgentChoice";

import SHBadge from "@/components/SHBadge.vue";
import SHCustomerChooser from "@/components/SHCustomerChooser.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHNote from "@/components/SHNote.vue";
import SHProductChooser from "@/components/SHProductChooser.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHWorkSiteChooser, {
  type WorkSiteChoice
} from "@/components/SHWorkSiteChooser.vue";
import SHTextEditorWithToolbar from "@/components/TextEditor/SHTextEditorWithToolbar.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import type { CustomerChoice } from "@/composables/useCustomerChoice";
import type { ProductChoice } from "@/composables/useProductChoice";
import { useRole } from "@/composables/useRole";
import { formatPostgres } from "@/formatters";
import { CurrentUserKey } from "@/providerKeys";
import type { JSONContent } from "@tiptap/vue-3";
import { startOfDay } from "date-fns";
import { v4 } from "uuid";

const router = useRouter();
const { createToast } = useToaster();
const currentUser = inject(CurrentUserKey, null);

const { ticketId, workOrderId, ...props } = defineProps<{
  ticketId?: string;
  noMargin?: boolean;
  customerId?: string;
  workOrderId?: string;
  workSiteId?: string;
  productId?: string;
}>();

const form = ref<{
  id?: string;
  agent?: AgentChoice | null | undefined;
  customer?: CustomerChoice;
  product?: ProductChoice | null;
  workSite?: WorkSiteChoice | null;
  workSiteUnconfirmed?: string | null;
  serviceDateStart?: string | null;
  requestingParty?: string;
  notes?: JSONContent | null | undefined;
}>({
  id: v4()
  // serviceDateStart: startOfDay(new Date()).toISOString()
});

const customerId = computed(
  () =>
    props.customerId ||
    form.value.customer?.id ||
    workOrder.value?.customer?.id ||
    ticket.value?.work_order?.customer?.id
);

const pageTitle = computed(() => {
  if (ticketId) {
    return `Editing Ticket`;
  }
  return `Create ${workOrder.value ? "Work Order" : "Calloff"} Ticket`;
});

const { data: customerData } = useQuery({
  query: graphql(/* GraphQL */ `
    query TicketForm_Customer($customerId: uuid!) {
      customers_by_pk(id: $customerId) {
        id
        title
        price_book_id
      }
    }
  `),
  variables: computed(() => ({
    customerId: props.customerId ?? "unknown id"
  })),
  pause: computed(() => !props.customerId)
});

const {
  data: ticketData,
  fetching: ticketFetching,
  error: ticketError
} = useQuery({
  query: graphql(/* GraphQL */ `
    query TicketEdit($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        id
        ref
        notesj
        manually_scheduled_at
        ticket_status
        review_status

        ticket_agents {
          agent {
            id
            ...AgentChoice
          }
        }

        work_site {
          id
          title
        }
        work_site_unconfirmed

        product {
          id
          title
        }

        work_order {
          customer {
            id
            title
            price_book_id
          }
        }

        date_range {
          first_work
        }
      }
    }
  `),
  variables: {
    ticketId: ticketId || ""
  },
  pause: computed(() => !ticketId)
});
const ticket = computed(() => ticketData.value?.tickets_by_pk);
const ticketHasEntries = computed(
  () =>
    ticket.value?.date_range?.first_work !== null &&
    ticket.value?.date_range?.first_work !== undefined
);
const isEditing = computed(() => !!ticketId);

const { can } = useRole();

const customer = computed({
  get: () =>
    form.value.customer ||
    customerData.value?.customers_by_pk ||
    workOrder.value?.customer ||
    ticket.value?.work_order.customer,
  set: value => (form.value.customer = value)
});

const product = computed({
  get: () =>
    form.value.product === null
      ? null
      : form.value.product || ticket.value?.product,
  set: value => (form.value.product = value)
});

const workSite = computed({
  get: () =>
    form.value.workSite === null
      ? null
      : form.value.workSite || ticket.value?.work_site,
  set: value => (form.value.workSite = value)
});

const workSiteUnconfirmed = computed({
  get: () => {
    if (workSite.value?.title === "TBD") {
      return (
        form.value.workSiteUnconfirmed ?? ticket.value?.work_site_unconfirmed
      );
    }
    return null;
  },
  set: value => (form.value.workSiteUnconfirmed = value)
});

const notes = computed({
  get: () => form.value.notes ?? ticket.value?.notesj,
  set: value => (form.value.notes = value)
});

const agent = computed({
  get: () =>
    form.value.agent ??
    useAgentChoice(ticket.value?.ticket_agents.at(0)?.agent),
  set: value => (form.value.agent = value)
});

const manuallyScheduledAt = computed({
  get: () =>
    form.value.serviceDateStart ??
    ticket.value?.manually_scheduled_at ??
    startOfDay(new Date()).toISOString(),
  set: value =>
    (form.value.serviceDateStart =
      value || startOfDay(new Date()).toISOString())
});

// TODO: (housecleaning) this logic for calloff for me/you, or dedicated is poorly
// encapsulated, need to enshrine it elsewhere...

// have product, have worksite
// if no work order: (it's a calloff)
//   if can calloff_tickets:create_others
//     must have a user choice
//   else
//     can't have a user choice
// else:
//   if can dedicated_tickets:create_others
//     must have a user choice
//   else
//     can't have a user choice

const isValid = computed(() => {
  return (
    !!product.value &&
    !!workSite.value &&
    (!workOrder.value && can("calloff_tickets:create_others")
      ? !!agent.value
      : true) &&
    (workSite.value?.title === "TBD" ? !!workSiteUnconfirmed.value : true)
  );
});

const {
  data: workOrderData,
  fetching: workOrderFetching,
  error: workOrderError
} = useQuery({
  query: graphql(/* GraphQL */ `
    query TicketFormWorkOrder($workOrderId: uuid!) {
      work_orders_by_pk(id: $workOrderId) {
        id
        ref
        requesting_party
        service_date_start
        service_date_end
        work_order_type
        lifecycle_status

        customer {
          id
          title
          price_book_id
        }
      }
    }
  `),
  variables: {
    workOrderId: workOrderId || ""
  },
  pause: computed(() => !workOrderId)
});
const workOrder = computed(() => workOrderData.value?.work_orders_by_pk);

const { executeMutation: createCalloffTicket } = useMutation(
  graphql(/* GraphQL */ `
    mutation CreateCalloffTicket($args: create_calloff_ticket_args!) {
      ticket: create_calloff_ticket(args: $args) {
        id
        ref
      }
    }
  `)
);

const { executeMutation: createDedicatedTicket } = useMutation(
  graphql(/* GraphQL */ `
    mutation CreateDedicatedTicket($args: create_dedicated_ticket_args!) {
      ticket: create_dedicated_ticket(args: $args) {
        id
        ref
      }
    }
  `)
);

const { executeMutation: updateTicket } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateTicket(
      $ticketId: uuid!
      $ticketSetInput: tickets_set_input!
      $assignAgent: Boolean!
      $ticketAgent: ticket_agents_insert_input!
    ) {
      ticket: update_tickets_by_pk(
        pk_columns: { id: $ticketId }
        _set: $ticketSetInput
      ) {
        id
        ref
        updated_at
      }
      delete_ticket_agents(where: { ticket_id: { _eq: $ticketId } })
        @include(if: $assignAgent) {
        returning {
          id
        }
      }
      insert_ticket_agents_one(object: $ticketAgent)
        @include(if: $assignAgent) {
        agent {
          id
        }
      }
    }
  `)
);

async function onSubmit() {
  let result;
  if (isEditing.value) {
    result = await updateTicket({
      ticketId: ticketId || "",
      ticketSetInput: {
        product_id: product.value?.id,
        work_site_id: workSite.value?.id,
        work_site_unconfirmed: workSiteUnconfirmed.value,
        notesj: form.value.notes,
        manually_scheduled_at: formatPostgres(manuallyScheduledAt.value)
      },
      assignAgent:
        agent.value?.id !== ticket.value?.ticket_agents.at(0)?.agent.id,
      ticketAgent: {
        agent_id: agent.value?.id,
        ticket_id: ticketId || "unknown ticket"
      }
    });
  } else if (form.value.workSite && form.value.product) {
    if (!!workOrder.value) {
      result = await createDedicatedTicket({
        args: {
          _work_order_id: workOrderId,
          _product_id: form.value.product.id,
          _assigned_agent_ids: toArrayLiteral([currentUser?.value.id]),
          _ticket_notes: form.value.notes || null,
          _work_site_id: form.value.workSite.id,
          _work_site_unconfirmed: workSiteUnconfirmed.value
        }
      });
    } else {
      result = await createCalloffTicket({
        args: {
          _customer_id: customerId.value,
          _product_id: form.value.product.id,
          _assigned_agent_ids: toArrayLiteral([
            agent.value?.id ?? currentUser?.value.id
          ]),
          _requesting_party: form.value.requestingParty || "",
          _service_date_start: formatPostgres(manuallyScheduledAt.value),
          _ticket_notes: form.value.notes || null,
          _work_site_id: form.value.workSite.id,
          _work_site_unconfirmed: workSiteUnconfirmed.value
        }
      });
    }
  }

  if (result?.error) {
    createToast({
      title: `Ticket not ${isEditing.value ? "updated" : "created"}.`,
      message: result.error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (!result?.data?.ticket) {
    createToast({
      title: `Unable to ${isEditing.value ? "update" : "create"} the ticket.`,
      message: "An internal error occurred.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (result.data) {
    createToast({
      message: `Ticket ${result.data?.ticket?.ref} ${
        isEditing.value ? "updated" : "created"
      }.`,
      theme: "success"
    });
    router.replace({
      name: "TicketDetail",
      params: {
        ticketId: result.data.ticket?.id
      }
    });
  }
}
</script>

<template>
  <article class="ticket-form" :class="{ xmargin: !noMargin }">
    <SHSpinner v-if="workOrderFetching || ticketFetching" />
    <SHNote v-else-if="workOrderError || ticketError" theme="danger">
      {{ workOrderError }}
      {{ ticketError }}
    </SHNote>
    <section v-else class="vertical">
      <header class="vertical">
        <h2 class="level tight">
          {{ pageTitle }}
          <SHBadge v-if="isEditing" color="var(--color-secondary)">
            {{ ticket?.ref }}
          </SHBadge>
        </h2>
        <SHNote v-if="!isEditing" theme="warning">
          <template v-if="workOrder">
            Dedicated agents for a customer with an active work order can add
            new tickets during the coverage period.
          </template>
          <template v-else>
            Calloff tickets must be created by supervisors, and do not require a
            work order.
          </template>
        </SHNote>
      </header>

      <main class="level-cols loose" style="align-items: center">
        <SHField v-if="!workOrder && !$props.customerId" label="Customer">
          <SHCustomerChooser
            :disabled="isEditing"
            :model-value="customer"
            @update:model-value="
              $event => {
                customer = $event;
                // worksite and product both depend on customer
                workSite = null;
                product = null;
              }
            "
          />
        </SHField>

        <SHField label="Product / Task">
          <SHProductChooser
            v-model="product"
            :price-book-id="customer?.price_book_id"
            :disabled="
              workOrderFetching ||
              ticketFetching ||
              (!customer && !workOrder) ||
              ticketHasEntries
            "
          />
        </SHField>

        <SHField label="Worksite">
          <SHWorkSiteChooser
            v-model="workSite"
            :customer-id="customerId"
            :disabled="!customer"
          />
        </SHField>
        <SHField
          v-if="workSite?.title === 'TBD'"
          label="Worksite Name (best guess)"
        >
          <SHInput v-model="workSiteUnconfirmed" />
        </SHField>

        <SHField v-if="!workOrder" label="Assigned Agent">
          <SHAgentChooser
            v-model="agent"
            :disabled="!can('ticket_agents:delete')"
            :self-at-top="!!workOrder"
            :context-customer-id="customerId"
          />
        </SHField>

        <SHField v-if="!workOrder" label="Service Date" block>
          <SHDatePicker
            v-model="manuallyScheduledAt"
            auto-apply
            class="vdp"
            format="MMM dd, yyyy"
          />
        </SHField>
      </main>

      <footer class="vertical">
        <SHField label="Ticket Notes/Instructions" block>
          <SHTextEditorWithToolbar
            v-model="notes"
            :context="{ ticket_id: form.id ?? ticketId }"
            file-path="ticket-notes"
            :rows="6"
          />
        </SHField>

        <UndoConfirm
          :confirm-enabled="isValid"
          @undo="$router.go(-1)"
          @confirm="onSubmit"
        >
          <template #confirm>
            <span v-if="isEditing">Update</span>
            <span v-else>Create</span>
            Ticket
          </template>
        </UndoConfirm>
      </footer>
    </section>
  </article>
</template>

<style lang="scss" scoped></style>

<style lang="scss">
.work-site-combobox :has(> .tbd-option) {
  border: none !important;
  background-image: var(--border-image);
}

.dp__input_wrap input {
  max-height: 34px;
}
</style>
