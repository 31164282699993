<script async setup lang="ts">
import { computed, getCurrentInstance, provide, ref } from "vue";
import type { OrganizationProviderQuery } from "@/generated/graphql";
import { useLocalStorage } from "@vueuse/core";
import { SPEARHEAD_DOMAIN } from "@/constants";
import { useLogger } from "@/logger";
import { AuthClientKey, RoleKey } from "@/providerKeys";
import * as Sentry from "@sentry/vue";

import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { createAuth0 } from "@auth0/auth0-vue";
import { sleep } from "@/lib/helpers";

const {
  VITE_AUTH0_DOMAIN = window.config.AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID = window.config.AUTH0_CLIENT_ID
} = import.meta.env;

const { organization } = defineProps<{
  organization?: OrganizationProviderQuery["organizations"][number];
}>();

const { log } = useLogger("AuthProvider"); // eslint-disable-line @typescript-eslint/no-unused-vars

const auth0Error = computed(() => {
  const params = new URLSearchParams(window.location.search);
  const error = params.get("error");
  const errorDescription = params.get("error_description");
  if (error && errorDescription) {
    return { error, errorDescription };
  }
  return {};
});

const auth0Plugin = createAuth0({
  domain: VITE_AUTH0_DOMAIN,
  clientId: VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: "spearhead-hasura",
    organization: organization?.id
  },
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  useRefreshTokensFallback: true
});

const app = getCurrentInstance()?.appContext.app;

if (!app) {
  throw new Error("Could not get app instance.");
}

app.use(auth0Plugin);

const authClient = app.config.globalProperties.$auth0;
const { isAuthenticated, isLoading, user } = authClient;

while (isLoading.value) {
  await sleep(100);
}

if (isAuthenticated.value) {
  log("User is authenticated", user.value?.sub);

  Sentry.setUser({
    id: user.value?.sub,
    email: user.value?.email,
    username: user.value?.name,
    organization_name: user.value?.[SPEARHEAD_DOMAIN]?.org_name
  });
} else {
  const path = window.location.pathname + window.location.search;
  log("User is not authenticated, redirecting to login", path);

  authClient.loginWithRedirect({
    appState: {
      target: path
    }
  });
}

provide(AuthClientKey, authClient);

// Only the SHRoleChooser can set the role in localstorage
// and is for developer use only.
const selectedRole = useLocalStorage("selectedRole", "");
const role = ref(
  selectedRole.value
    ? selectedRole
    : user.value?.[SPEARHEAD_DOMAIN]?.default_role ?? "public"
);

provide(RoleKey, role);
</script>

<template>
  <div>
    <SHSpinner v-if="isLoading" />
    <SHNote v-else-if="auth0Error.error" theme="danger">
      <h1>{{ auth0Error.error }}</h1>
      <p>{{ auth0Error.errorDescription }}</p>
    </SHNote>
    <slot
      v-else-if="!isLoading && isAuthenticated"
      :auth-client="authClient"
      :role-name="role"
    />
  </div>
</template>
