import type { RouteRecordRaw } from "vue-router";
import CustomerAgentsView from "@/views/CustomerAgentsView.vue";
import ModuleHeader from "@/components/ModuleHeader.vue";
import { h } from "vue";
import { faUsers } from "@fortawesome/sharp-light-svg-icons";

export const agentRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "CustomerAgentsView",
    components: {
      default: CustomerAgentsView,
      header: h(
        ModuleHeader,
        { icon: faUsers },
        {
          default: () => "Customer Dedicated Agents"
        }
      )
    },
    props: true
  }
];
