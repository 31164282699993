<script setup lang="ts">
import type { Editor, JSONContent } from "@tiptap/vue-3";
import { computed } from "vue";
import { generateHTML } from "@tiptap/html";

import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";
import History from "@tiptap/extension-history";
import DropCursor from "@tiptap/extension-dropcursor";
import GapCursor from "@tiptap/extension-gapcursor";

import { ClientKey } from "@/providerKeys";
import { injectStrict } from "@/lib/helpers";

import { Node as FileLinkNode } from "@/components/TextEditor/FileLinkNode.vue";
import UserMention from "@/components/TextEditor/mentions/UserMention";
import TicketMention from "@/components/TextEditor/mentions/TicketMention";
import WorkOrderMention from "@/components/TextEditor/mentions/WorkOrderMention";
import ReplaceSymbols from "@/components/TextEditor/inputRules/ReplaceSymbols";

const props = defineProps<{
  modelValue: JSONContent | null | undefined;
  text?: string | null | undefined;
  editor?: Editor;
  inline?: boolean;
  rows?: number;
}>();

const emit = defineEmits<{
  (e: "update:model-value", value: JSONContent | null | undefined): void;
  (e: "update:text", value: string): void;
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val);
  }
});

const client = injectStrict(ClientKey);
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <p
    v-if="modelValue"
    class="sh-editor"
    v-html="
      generateHTML(modelValue, [
        Document,
        Text,
        Paragraph,
        History,
        DropCursor,
        GapCursor,

        // Custom Extensions
        UserMention(client),
        TicketMention(client),
        WorkOrderMention(client),
        FileLinkNode,
        ReplaceSymbols
      ])
    "
  />
</template>

<style lang="scss" scoped>
.sh-editor {
  :deep(.ProseMirror) {
    a {
      color: var(--color-primary-500);
    }

    p {
      margin: 0;
    }

    img {
      max-width: 100%;
    }
  }
}
</style>
