import { faCog } from "@fortawesome/sharp-light-svg-icons";
import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";

import CustomFormEntries from "@/components/CustomFormEntries.vue";
import CustomFormLoader from "@/components/CustomFormLoader.vue";
import CustomFormProductBindings from "@/components/CustomFormProductBindings.vue";
import FeatureFlagEditor from "@/components/FeatureFlagEditor.vue";
import ModuleHeader from "@/components/ModuleHeader.vue";
import OrganizationCustomFormDetail from "@/views/OrganizationCustomFormDetail.vue";
import OrganizationCustomFormsView from "@/views/OrganizationCustomFormsView.vue";
import OrganizationSettingsGeneral from "@/views/OrganizationSettingsGeneral.vue";
import OrganizationSettingsInvoices from "@/views/OrganizationSettingsInvoices.vue";
import OrganizationSettingsView from "@/views/OrganizationSettingsView.vue";
import TimeSheetSettings from "@/views/TimeSheetSettings.vue";

export const orgSettingRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    props: true,
    components: {
      default: OrganizationSettingsView,
      header: h(ModuleHeader, { icon: faCog }, () => "Organization Settings")
    },
    name: "OrganizationSettingsView",
    redirect: {
      name: "OrganizationSettingsGeneral"
    },
    children: [
      {
        path: "settings",
        name: "OrganizationSettingsGeneral",
        components: {
          default: OrganizationSettingsGeneral
        }
      },
      {
        path: "forms",
        name: "OrganizationCustomFormsView",
        components: {
          default: OrganizationCustomFormsView,
          header: h(ModuleHeader, { icon: faCog }, () => "Custom Forms")
        }
      },
      {
        path: "form/:customFormDefinitionId",
        name: "OrganizationCustomFormDetail",
        components: {
          default: OrganizationCustomFormDetail,
          header: h(ModuleHeader, { icon: faCog }, () => "Custom Form")
        },
        props: true,
        redirect: { name: "CustomFormEntries" },
        children: [
          {
            path: "entries",
            name: "CustomFormEntries",
            components: {
              default: CustomFormEntries
            },
            props: true
          },
          {
            path: "preview",
            name: "CustomFormPreview",
            components: {
              default: CustomFormLoader
            },
            props: true
          },
          {
            path: "bindings",
            name: "CustomFormProductBindings",
            components: {
              default: CustomFormProductBindings
            },
            props: true
          }
        ]
      },
      {
        path: "invoices",
        name: "OrganizationSettingsInvoices",
        components: {
          default: OrganizationSettingsInvoices,
          header: h(ModuleHeader, { icon: faCog }, () => "Invoices")
        }
      },
      {
        path: "time-sheets",
        name: "OrganizationSettingsTimeSheets",
        components: {
          default: TimeSheetSettings,
          header: h(ModuleHeader, { icon: faCog }, () => "Time Sheets")
        }
      },
      {
        path: "feature_flags",
        name: "OrganizationSettingsFeatureFlags",
        components: {
          default: FeatureFlagEditor,
          header: h(ModuleHeader, { icon: faCog }, () => "Feature Flags")
        }
      }
    ]
  }
];
