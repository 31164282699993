import type { Lifecycle_Statuses_Enum } from "@/generated/graphql";
import {
  faAlarmExclamation,
  faCheckDouble,
  faClockFourThirty,
  faEllipsis,
  faInterrobang
} from "@fortawesome/sharp-light-svg-icons";

export const getColor = (status: Lifecycle_Statuses_Enum) => {
  switch (status) {
    case "PENDING":
      return "var(--color-primary)";
    case "OPEN":
      return "var(--color-success)";
    case "EXPIRING":
      return "var(--color-warning)";
    case "GRACE":
      return "var(--color-warning)";
    case "CLOSED":
      return "var(--color-surface-400)";
  }
  return "var(--color-info)";
};

export const getIcon = (status: Lifecycle_Statuses_Enum) => {
  switch (status) {
    case "PENDING":
      return faEllipsis;
    case "OPEN":
      return faClockFourThirty;
    case "EXPIRING":
      return faAlarmExclamation;
    case "GRACE":
      return faAlarmExclamation;
    case "CLOSED":
      return faCheckDouble;
  }
  return faInterrobang;
};
