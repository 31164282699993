<script setup lang="ts">
import { computed } from "vue";
import { graphql, type FragmentType, useFragment } from "@/generated";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SHBadge from "@/components/SHBadge.vue";
import { getColor, getIcon } from "@/lib/lifecycleStatus";

const fragment = graphql(/* GraphQL */ `
  fragment WorkOrderStatus on work_orders {
    lifecycle_status
  }
`);

const props = defineProps<{
  workOrder: FragmentType<typeof fragment>;
}>();

const workOrder = computed(() => useFragment(fragment, props.workOrder));

const statusText = computed(() => {
  switch (workOrder.value.lifecycle_status) {
    case "PENDING":
      return "Pending";
    case "OPEN":
      return "Open";
    case "EXPIRING":
      return "Expiring";
    case "GRACE":
      return "Grace";
    case "CLOSED":
      return "Closed";
  }
  return "UNKNOWN";
});
</script>

<template>
  <SHBadge
    :color="getColor(workOrder.lifecycle_status)"
    class="level tight"
    style="width: max-content"
  >
    <FontAwesomeIcon :icon="getIcon(workOrder.lifecycle_status)" />
    {{ statusText }}
  </SHBadge>
</template>
