<script setup lang="ts">
import { computed } from "vue";
import { graphql, type FragmentType, useFragment } from "@/generated";
import {
  faBan,
  faComment,
  faCheck,
  faQuestion
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import UserLink from "@/components/UserLink.vue";
import { Ticket_Review_Event_Types_Enum } from "@/generated/graphql";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import { faArrowLeftToLine } from "@fortawesome/sharp-light-svg-icons";
import { faArrowRightToLine } from "@fortawesome/sharp-light-svg-icons";

const ReviewEventCardFragment = graphql(/* GraphQL */ `
  fragment ReviewEventCard on ticket_review_events {
    id
    ticket_id
    created_at
    notesj
    ticket_review_event_type

    author {
      ...UserLink
    }
    desired_reviewer {
      ...UserLink
    }

    ticket {
      ref
      work_order {
        customer {
          ...CustomerLink
        }
      }

      ...TicketLink
    }
  }
`);

const props = defineProps<{
  reviewEvent: Partial<FragmentType<typeof ReviewEventCardFragment>>;
  relative?: boolean;
}>();

const reviewEvent = computed(() =>
  useFragment(ReviewEventCardFragment, props.reviewEvent)
);

const reviewEventStatus = computed(() => {
  return reviewEvent.value.ticket_review_event_type;
});

const eventIcon = computed(() => {
  switch (reviewEventStatus.value) {
    case Ticket_Review_Event_Types_Enum.Accept:
      return faCheck;
    case Ticket_Review_Event_Types_Enum.Reject:
      return faArrowLeftToLine;
    case Ticket_Review_Event_Types_Enum.Comment:
      return faComment;
    case Ticket_Review_Event_Types_Enum.Request:
      return faArrowRightToLine;
    case Ticket_Review_Event_Types_Enum.Cancel:
      return faBan;
  }
  return faQuestion;
});

const iconBgColor = computed(() => {
  switch (reviewEventStatus.value) {
    case Ticket_Review_Event_Types_Enum.Accept:
      return "var(--color-success)";
    case Ticket_Review_Event_Types_Enum.Reject:
      return "var(--color-danger)";
    case Ticket_Review_Event_Types_Enum.Comment:
      return "var(--color-primary)";
    case Ticket_Review_Event_Types_Enum.Request:
      return "var(--color-info)";
    case Ticket_Review_Event_Types_Enum.Cancel:
      return "var(--color-warning)";
  }
  return "var(--color-surface-500)";
});
</script>

<template>
  <TimeLineEntry
    class="review-card"
    :icon-bg-color="iconBgColor"
    :start="reviewEvent.created_at"
    :relative="relative"
  >
    <template #icon>
      <FontAwesomeIcon :icon="eventIcon" fixed-width />
    </template>

    <span v-if="reviewEventStatus === Ticket_Review_Event_Types_Enum.Comment">
      <UserLink :user="reviewEvent.author" link />
      commented
    </span>

    <span
      v-else-if="
        reviewEvent.ticket_review_event_type ===
        Ticket_Review_Event_Types_Enum.Request
      "
    >
      <UserLink :user="reviewEvent.author" link />
      requested
      <UserLink :user="reviewEvent.desired_reviewer" link />
      to review
    </span>

    <span
      v-else-if="
        reviewEvent.ticket_review_event_type ===
        Ticket_Review_Event_Types_Enum.Accept
      "
    >
      <UserLink :user="reviewEvent.author" link />
      approved and closed
    </span>

    <span
      v-else-if="
        reviewEvent.ticket_review_event_type ===
        Ticket_Review_Event_Types_Enum.Reject
      "
    >
      <UserLink :user="reviewEvent.author" link />
      rejected and reopened
    </span>

    <span
      v-else-if="
        reviewEvent.ticket_review_event_type ===
        Ticket_Review_Event_Types_Enum.Cancel
      "
      class="level tight wrap"
    >
      <UserLink :user="reviewEvent.author" link />
      canceled the request
    </span>

    <template v-if="reviewEvent.notesj?.content[0]?.content" #detail>
      <SHTextEditor :model-value="reviewEvent.notesj" />
    </template>
  </TimeLineEntry>
</template>
