import type { InvoiceForm } from "@/components/Invoices/types";
import { graphql } from "@/generated";
import { Invoice_Pivot_Options_Enum } from "@/generated/graphql";
import type { Invoices } from "@/generated/graphql";
import type { DeepPartial } from "@/types";
import type { ClientHandle } from "@urql/vue";
import { format } from "date-fns";
import { v4 } from "uuid";

export function makeForm(query: DeepPartial<Invoices>): InvoiceForm {
  return {
    settings: {
      customerId: null,
      includeExpenseLogs: true,
      includeWorkLogs: true,
      pivotOption: Invoice_Pivot_Options_Enum.None,
      dateRange: null
    },
    invoice: {
      id: query.id ?? v4(),
      subject: query.subject,
      poNumber: query.po_number,
      issueDate: new Date(),
      dueIn: query.due_in,
      notesj: query.notesj
    },
    lineItems:
      query.invoice_line_items?.map(lineItem => ({
        id: lineItem.id ?? v4(),
        label: lineItem.label ?? "",
        description: lineItem.description ?? "",
        details:
          (lineItem.details as unknown as Record<string, string>) ?? null,
        centsCalculated: lineItem.cents_calculated ?? 0,
        centsInvoiced: lineItem.cents_invoiced
      })) ?? []
  };
}

export function createInvoice(
  client: ClientHandle,
  customerId: string,
  dateRange: [Date, Date]
) {
  const { executeMutation } = client.useMutation(
    graphql(/* GraphQL */ `
      mutation CreateInvoice($args: create_invoice_for_endeavor_args!) {
        create_invoice_for_endeavor(args: $args) {
          id
        }
      }
    `)
  );

  return executeMutation({
    args: {
      _customer_id: customerId,
      _due_in: 30,
      _issue_date: new Date().toISOString(),
      _start_date: format(dateRange[0], "MM/dd/yyyy"),
      _end_date: format(dateRange[1], "MM/dd/yyyy")
    }
  });
}
