<script setup lang="ts">
import { faDollarSign } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { formatUSD } from "@/formatters";
import { graphql, type FragmentType, useFragment } from "@/generated";
import { humanizeEnum } from "@/lib/helpers";

import MediaUploadsView from "@/views/MediaUploadsView.vue";
import SHPill from "@/components/SHPill.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import UserLink from "@/components/UserLink.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";

const fragment = graphql(/* GraphQL */ `
  fragment ExpenseLogCard on expense_logs {
    id
    ticket_id
    expensed_at
    notesj
    expense_type
    cents_recorded

    author {
      ...UserLink
    }

    media_uploads(
      where: { deleted_at: { _is_null: true } }
      order_by: [{ created_at: asc }]
    ) {
      id
      ...MediaUploadCard
      ...SHLightbox
    }
  }
`);

const props = defineProps<{
  editable?: boolean;
  expenseLog: FragmentType<typeof fragment>;
}>();

const expenseLog = useFragment(fragment, props.expenseLog);
</script>

<template>
  <TimeLineEntry
    :start="expenseLog.expensed_at"
    icon-fg-color="var(--color-secondary-up-300)"
    icon-bg-color="var(--color-secondary-down-300)"
    :editable="editable"
    @edit="
      $router.push({
        name: 'ExpenseLogDetail',
        params: {
          ticketId: expenseLog.ticket_id,
          expenseLogId: expenseLog.id
        }
      })
    "
  >
    <template #icon>
      <FontAwesomeIcon :icon="faDollarSign" />
    </template>

    <template #default>
      <UserLink :user="expenseLog.author" link />
      logged a {{ humanizeEnum(expenseLog.expense_type) }} expense.
    </template>

    <template #detail>
      <article class="vertical">
        <SHTextEditor v-if="expenseLog" :model-value="expenseLog.notesj" />
        <MediaUploadsView
          :media-uploads="expenseLog.media_uploads ?? []"
          hide-empty-message
          no-controls
        />

        <div>
          <SHPill color="var(--color-secondary)">
            <template #left>
              {{ humanizeEnum(expenseLog.expense_type) }}
            </template>
            <template #right>
              {{ formatUSD(expenseLog.cents_recorded) }}
            </template>
          </SHPill>
        </div>
      </article>
    </template>
  </TimeLineEntry>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints" as bp;

.log {
  border-radius: var(--border-radius);
  background: var(--color-surface-100);

  &:hover {
    border-color: var(--color-primary);
    background-color: var(--color-primary-opacity-15);
  }

  header {
    font-family: var(--font-family-monospace);
    padding: 0.6em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: aqua;
  }
  .log-notes {
    font-size: 0.875em;
    padding: 0 1em;
    margin: 0;
  }
  footer {
    font-size: 0.875em;
    padding: 1em;
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
