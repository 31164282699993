<script setup lang="ts">
import LogActivityChart from "@/components/charts/LogActivityChart.vue";
import RouterTabs from "@/components/RouterTabs.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import type { MenuEntry } from "@/components/SHMenu.vue";
import SHMenu from "@/components/SHMenu.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UserLink from "@/components/UserLink.vue";
import { useDDVActivitySeries } from "@/composables/useDDVActivitySeries";

import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import { formatDate } from "@/formatters";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { BreakPointsKey } from "@/providerKeys";
import {
  faBan,
  faEllipsisVertical,
  faOilWell,
  faPen,
  faTrashCan,
  faTrashCanArrowUp,
  faTrashCanSlash
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

const { workSiteId } = defineProps<{
  workSiteId: string;
}>();

const breakpoints = injectStrict(BreakPointsKey);
const router = useRouter();
const { can } = useRole();
const { createToast } = useToaster();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query WorkSiteDetail($workSiteId: uuid!) {
      work_sites_by_pk(id: $workSiteId) {
        author {
          full_name
          ...UserLink
        }
        created_at
        id
        title
        data
        deleted_at
        customer_id
      }

      ticket_activity(
        where: {
          ticket: { work_site_id: { _eq: $workSiteId } }
          ended_at: { _is_null: false }
          _or: [
            { work_log_id: { _is_null: false } }
            { travel_log_id: { _is_null: false } }
          ]
        }
      ) {
        work_log_id
        travel_log_id
        started_at
        ended_at
        ticket_id
      }
    }
  `),
  variables: computed(() => ({
    workSiteId: workSiteId || "unknown work_site_id"
  }))
});

const workSite = computed(() => data.value?.work_sites_by_pk);
const tabs = computed(() => [
  {
    title: "Tickets",
    to: {
      name: "WorkSiteTicketsView",
      params: { workSiteId: workSiteId }
    }
  },
  {
    title: "Media",
    to: {
      name: "WorkSiteMediaView",
      params: { workSiteId: workSiteId }
    }
  },
  {
    title: "Forms",
    to: {
      name: "WorkSiteFormsView",
      params: { workSiteId: workSiteId }
    }
  }
]);

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation ArchiveWorkSite($id: uuid!, $deleted_at: timestamptz) {
      update_work_sites_by_pk(
        pk_columns: { id: $id }
        _set: { deleted_at: $deleted_at }
      ) {
        id
      }
    }
  `)
);

async function onArchive() {
  if (!confirm("Are you sure you want to archive this worksite?")) {
    return;
  }

  if (!workSite.value) {
    return;
  }

  const { data: archiveData, error: archiveError } = await executeMutation({
    id: workSite.value.id,
    deleted_at: "now()"
  });

  if (archiveError) {
    createToast({
      title: "Unable to archive the worksite.",
      message: archiveError.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  } else if (archiveData) {
    createToast({
      message: "Worksite archived.",
      theme: "success"
    });
  }
}

async function onReinstate() {
  if (!workSite.value) {
    return;
  }

  const { data: reinstateData, error: reinstateError } = await executeMutation({
    id: workSite.value.id,
    deleted_at: null
  });

  if (reinstateError) {
    createToast({
      theme: "danger",
      title: "Unable to reinstate the worksite.",
      lifetimeMS: 5000,
      message: reinstateError.message || "Unkown error."
    });
  } else if (reinstateData) {
    createToast({
      theme: "success",
      message: "Worksite reinstated."
    });
  }
}

const onEdit = () => {
  router.push({
    name: "WorkSiteEdit",
    params: { workSiteId, customerId: workSite.value?.customer_id }
  });
};

const menuItems = computed<MenuEntry[]>(() => [
  {
    id: "edit",
    icon: faPen,
    label: "Edit",
    onClick: onEdit
  },
  workSite.value?.deleted_at !== null
    ? {
        id: "unarchive",
        icon: workSite.value?.deleted_at !== null ? faTrashCanArrowUp : faBan,
        label: "Reinstate",
        isDanger: false,
        onClick: onReinstate
      }
    : {
        id: "archive",
        icon:
          workSite.value?.deleted_at === null ? faTrashCan : faTrashCanSlash,
        label: "Archive",
        isDanger: true,
        onClick: onArchive
      }
]);

const { series } = useDDVActivitySeries(
  computed(() =>
    data.value?.ticket_activity.map(a => ({
      started_at: a.started_at,
      ended_at: a.ended_at,
      work_log_id: a.work_log_id,
      travel_log_id: a.travel_log_id,
      ticket_id: a.ticket_id
    }))
  )
);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>

  <article v-else class="vertical">
    <div class="vertical work-site-card">
      <!-- Row 1 -->
      <div class="level-spread">
        <!-- Row 1 - Left Side -->
        <div class="level tight">
          <FontAwesomeIcon :icon="faOilWell" />
          <h4>{{ workSite?.title }}</h4>
          <SHBadge
            v-show="workSite?.deleted_at && breakpoints.includes('tablet')"
            color="var(--color-info)"
          >
            Archived
          </SHBadge>
        </div>

        <!-- Row 1 - Right Side -->
        <div class="level tight">
          <SHDropdown
            v-if="can('work_sites:update')"
            placement="bottom-end"
            strategy="fixed"
          >
            <template #default="{ toggle }">
              <SHButton color="surface-300" square size="sm" @click="toggle">
                <FontAwesomeIcon :icon="faEllipsisVertical" fixed-width />
              </SHButton>
            </template>

            <template #popup="{ close }">
              <div class="popup">
                <SHMenu :items="menuItems" @click="() => close()" />
              </div>
            </template>
          </SHDropdown>
        </div>
      </div>

      <div
        v-if="breakpoints.includes('mobile') && !breakpoints.includes('tablet')"
      >
        <SHBadge v-if="workSite?.deleted_at" color="var(--color-info)">
          Archived
        </SHBadge>
      </div>

      <!-- Row 2 -->
      <div class="level-spread tight wrap">
        <!-- Row 2 - Left Side -->
        <div class="level tight">
          <span>Cost Center:</span>
          <span class="number">
            <strong>{{ workSite?.data?.cost_center ?? "" }}</strong>
          </span>
        </div>

        <!-- Row 2 - Right Side -->
        <div class="level tight wrap">
          <div>
            <span>Created On:</span>
            <span>
              &nbsp;
              <strong>
                {{ formatDate(workSite?.created_at, "MMMM dd, yyyy") || "" }}
              </strong>
            </span>
          </div>
          <div>
            <span>Created By:</span>
            &nbsp;
            <span v-if="workSite?.author">
              <UserLink :user="workSite?.author" />
            </span>
            <span v-else>Unknown</span>
          </div>
        </div>
      </div>
    </div>

    <LogActivityChart :points="series" />

    <RouterTabs :tabs="tabs" />
  </article>
</template>

<style lang="scss" scoped>
.work-site-card {
  padding: 1em;
  border-radius: var(--border-radius);
  background: var(--color-surface-100);
}

.archive {
  position: relative;
}

.confirm {
  background: var(--color-surface-100);
  border: var(--color-surface-200) 1px solid;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 1em;
  position: absolute;
  top: 50%;
  right: -100%;
  width: 300px;
  z-index: 100;
}
</style>
