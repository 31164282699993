<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import SHCustomerChooser from "@/components/SHCustomerChooser.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import type { CustomerChoice } from "@/composables/useCustomerChoice";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { PAST_DATE_PRESETS, YEAR_BACK } from "@/lib/datetime";
import { createInvoice } from "@/model/invoices";
import { useClientHandle, useQuery } from "@urql/vue";
import { endOfMonth, startOfMonth } from "date-fns";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();
const clientHandle = useClientHandle();

const props = defineProps<{
  noMargin?: boolean;
  customerId?: string;
}>();

const form = ref<{
  customer?: CustomerChoice;
  dateRange: [Date, Date];
}>({
  dateRange: [startOfMonth(new Date()), endOfMonth(new Date())]
});

const customerId = computed(() => props.customerId || form.value?.customer?.id);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query InvoiceCreateOptions(
      $customerId: uuid!
      $includeCustomer: Boolean!
      $startTime: timestamptz!
      $endTime: timestamptz!
    ) {
      all_time_agg: combined_logs_aggregate(
        where: {
          customer_id: { _eq: $customerId }
          invoice_id: { _is_null: true }
          deleted_at: { _is_null: true }
        }
      ) @include(if: $includeCustomer) {
        aggregate {
          count
          min {
            started_at
          }
          max {
            ended_at
            started_at
          }
        }
      }

      in_range_agg: combined_logs_aggregate(
        where: {
          customer_id: { _eq: $customerId }
          invoice_id: { _is_null: true }
          _and: [
            { started_at: { _gte: $startTime } }
            { ended_at: { _lte: $endTime } }
            { deleted_at: { _is_null: true } }
          ]
        }
      ) @include(if: $includeCustomer) {
        aggregate {
          count
          min {
            started_at
          }
          max {
            ended_at
            started_at
          }
        }
      }
    }
  `),
  variables: computed(() => ({
    customerId: customerId.value ?? "unknown customer",
    includeCustomer: !!customerId.value,
    startTime: form.value.dateRange[0],
    endTime: form.value.dateRange[1]
  }))
});

const options = computed(() => data.value);
const inRangeLogsFound = computed(
  () => data.value?.in_range_agg?.aggregate?.count || 0
);
const allTimeLogsFound = computed(
  () => data.value?.all_time_agg?.aggregate?.count || 0
);
const allTimeFirstLogDate = computed(
  () => data.value?.all_time_agg?.aggregate?.min?.started_at
);
const allTimeLastLogDate = computed(
  () =>
    data.value?.all_time_agg?.aggregate?.max?.ended_at ||
    data.value?.all_time_agg?.aggregate?.max?.started_at
);

async function onSave() {
  if (!customerId.value) {
    console.log("customer not set");
    return;
  }

  const { data, error } = await createInvoice(
    clientHandle,
    customerId.value,
    form.value.dateRange
  );

  if (data?.create_invoice_for_endeavor) {
    createToast({
      message: `Invoice created.`,
      theme: "success"
    });
    router.push({
      name: "InvoiceDetail",
      params: {
        invoiceId: data.create_invoice_for_endeavor?.id
      }
    });
  } else {
    createToast({
      title: "Unable to create the invoice.",
      message: error?.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  }
}

const mustHaveUnbilledLogs = ref(true);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error?.message }}</SHNote>
  <article
    v-else-if="options"
    class="invoice-form vertical"
    :class="{ xmargin: !noMargin, hasCustomerId: !!props.customerId }"
  >
    <section v-if="!props.customerId" style="grid-area: customer">
      <SHField label="Customer to Invoice">
        <SHCustomerChooser
          v-model="form.customer"
          :must-have-unbilled-logs="mustHaveUnbilledLogs"
        />
      </SHField>
    </section>

    <section style="grid-area: dates">
      <SHField label="Billable Period">
        <DateRangePicker
          v-model="form.dateRange"
          label="Date Range"
          :presets="[...PAST_DATE_PRESETS, ...YEAR_BACK]"
        />
      </SHField>
    </section>

    <section style="grid-area: summary">
      <label v-if="!props.customerId">
        <input v-model="mustHaveUnbilledLogs" type="checkbox" />
        Only show customers with unbilled work?
      </label>
      <SHNote
        v-if="customerId"
        :theme="inRangeLogsFound === 0 ? 'danger' : 'warning'"
      >
        <span v-if="allTimeLogsFound === 0">
          This customer has no uninvoiced billables (regardless of date range)
        </span>
        <span v-else-if="inRangeLogsFound > 0">
          Will invoice {{ inRangeLogsFound }} unbilled logs in the selected time
          range.
        </span>
        <span v-else>
          No billable logs found in your selected time range.

          <div v-if="allTimeLogsFound > 0">
            There are however, {{ allTimeLogsFound }} unbilled logs found
            between
            <em>
              <SHInlineDate :d="allTimeFirstLogDate" />
            </em>
            and
            <em>
              <SHInlineDate :d="allTimeLastLogDate" />
            </em>
            <br />
            <AppLink
              @click.prevent="
                () => {
                  if (allTimeFirstLogDate && allTimeLastLogDate) {
                    form.dateRange = [
                      startOfMonth(new Date(allTimeFirstLogDate)),
                      endOfMonth(new Date(allTimeLastLogDate))
                    ];
                  }
                }
              "
            >
              Adjust Range
            </AppLink>
          </div>
        </span>
      </SHNote>
    </section>

    <UndoConfirm
      :confirm-enabled="inRangeLogsFound > 0"
      style="grid-area: buttons"
      @confirm="onSave"
      @undo="$router.back"
    >
      <template #confirm>Create Invoice</template>
    </UndoConfirm>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;
.invoice-form {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr;
  grid-template-areas:
    "customer"
    "dates"
    "summary"
    "buttons";

  @include bp.laptop {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "customer dates"
      "summary summary"
      "buttons buttons";

    &.hasCustomerId {
      grid-template-columns: 1fr;
      grid-template-areas:
        "dates"
        "summary"
        "buttons";
    }
  }
}
</style>
