<script setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import { useToaster } from "@/composables/useToaster";
import useUpload, { type MediaUploadTask } from "@/composables/useUpload";
import { useLogger } from "@/logger";
import { UploadContextKey } from "@/providerKeys";
import type { MediaUploadContext, UploadedMedia } from "@/types";
import { faFileUpload } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { v4 } from "uuid";
import { computed, inject, ref } from "vue";

const { log, error } = useLogger("SHMediaUploader"); // eslint-disable-line @typescript-eslint/no-unused-vars
const { createToast } = useToaster();

const {
  path,
  multiple = false,
  accept = "*",
  ...props
} = defineProps<{
  path: string;
  context?: MediaUploadContext;
  disabled?: boolean;
  accept?: string;
  multiple?: boolean;
  capture?: boolean | "user" | "environment";
}>();

const emit = defineEmits<{
  (e: "upload:finished", upload: UploadedMedia): void;
  (e: "upload:queued", upload: MediaUploadTask): void;
}>();

const context = ref(props.context ?? inject(UploadContextKey));

const fileInput = ref();
const fileList = ref<FileList | null>();
const files = computed(() =>
  Array.from(fileList.value || []).map(file => ({
    original: file,
    id: v4(),
    src: URL.createObjectURL(file)
  }))
);

const { uploadStream } = useUpload();

const activeUploads = ref<
  Record<string, Awaited<ReturnType<typeof uploadStream>>>
>({}); // eslint-disable-line @typescript-eslint/no-explicit-any

const onSelectionChanged = (e: Event) => {
  fileList.value = (e.target as HTMLInputElement).files;
  files.value.forEach(async file => {
    // const key = `${path}/${file.id}`;

    try {
      const upload = await uploadStream(
        file.original,
        file.id,
        path,
        mediaUpload => emit("upload:finished", mediaUpload),
        context.value
      );
      emit("upload:queued", upload);
      activeUploads.value[file.id] = upload;
      upload.start();
    } catch (e) {
      error("couldn't start uploadStream", e);
      createToast({
        title: "Unable to upload the file.",
        message: "Could not start the upload stream. Please try again later.",
        theme: "danger"
      });
    }
  });
};
</script>

<template>
  <article class="sh-media-uploader">
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      v-bind="
        capture
          ? {
              ...$attrs,
              capture: $props.capture
            }
          : $attrs
      "
      :accept="accept"
      :multiple="multiple"
      @change="onSelectionChanged"
    />
    <SHButton color="primary" @click="fileInput.click()">
      <slot>
        <FontAwesomeIcon :icon="faFileUpload" />
      </slot>
    </SHButton>
  </article>
</template>

<style lang="scss" scoped>
.sh-media-uploader {
  display: flex;
  flex-direction: column;
  gap: 1em;
  .files {
    --preview-width: 14em;
    --preview-height: 14em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--preview-width), 1fr));
    gap: 1em;
    .local-preview {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: 1px solid var(--color-surface-200);
      padding: var(--padding);
      background: var(--color-surface-100);

      .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        grid-column: 1;
        color: var(--color-surface-900);
      }

      .size {
        text-align: right;
        grid-column: 2;
        color: var(--color-surface-600);
      }

      img {
        justify-self: center;
        grid-column: 1/-1;
        max-height: var(--preview-height);
      }

      .close {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        z-index: 1;
      }
    }
  }
}
</style>
