<script setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import SHDollarInput from "@/components/SHDollarInput.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHNote from "@/components/SHNote.vue";
import SHNumberInput from "@/components/SHNumberInput.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTextarea from "@/components/SHTextarea.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import {
  Invoice_Pivot_Options_Enum,
  Oauth_Providers_Enum,
  type Organizations_Set_Input
} from "@/generated/graphql";
import { humanizeEnum, injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { AuthClientKey, OrganizationKey } from "@/providerKeys";
import { useMutation, useQuery } from "@urql/vue";
import { computed, reactive, ref } from "vue";

const { log } = useLogger("OrganizationSettingsInvoices"); // eslint-disable-line @typescript-eslint/no-unused-vars
const { createToast } = useToaster();
const { can } = useRole();

const organization = injectStrict(OrganizationKey);
const authClient = injectStrict(AuthClientKey);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query OrganizationSettingsInvoices($id: String!) {
      organizations_by_pk(id: $id) {
        reply_to_address
        postal_address
        default_agent_day_rate_cents
        default_invoice_net_term
        default_invoice_pivot
        last_invoice_number
        invoice_template_above_line_items
        invoice_template_below_line_items

        oauth_connections {
          organization_id
          oauth_provider
        }
      }
      invoice_pivot_options(order_by: { value: asc }) {
        value
      }
    }
  `),
  variables: computed(() => ({ id: organization.value?.id || "" }))
});

const connections = computed(
  () => data.value?.organizations_by_pk?.oauth_connections ?? []
);

const { data: quickbooksData } = useQuery({
  query: graphql(/* GraphQL */ `
    query OrganizationSettingsQuickbooks {
      getQuickBooksCompany {
        __typename
        CompanyInfo {
          CompanyName
        }
      }
    }
  `),
  pause: computed(() => !hasConnection(Oauth_Providers_Enum.Quickbooks))
});

const quickbooksCompany = computed(
  () => quickbooksData.value?.getQuickBooksCompany
);

const accessToken = ref("");
authClient.getAccessTokenSilently().then(token => (accessToken.value = token));

const { executeMutation: deleteOrganizationsXrefOauth } = useMutation(
  graphql(/* GraphQL */ `
    mutation OrganizationSettingsDeleteConnection(
      $provider: oauth_providers_enum!
      $organization_id: String!
    ) {
      delete_organizations_xref_oauth_by_pk(
        oauth_provider: $provider
        organization_id: $organization_id
      ) {
        organization_id
        oauth_provider
      }
    }
  `)
);

// get current record
const org = computed(() => data.value?.organizations_by_pk);

const pivotOptions = computed(() =>
  (data.value?.invoice_pivot_options || []).map(
    ({ value }: { value: string }) => ({
      id: value,
      label: humanizeEnum(value)
    })
  )
);

// track pending updates, none at the start

const updates = reactive<Organizations_Set_Input>({});

const replyToAddress = computed({
  get() {
    return updates.reply_to_address || org.value?.reply_to_address || "";
  },
  set(val: string) {
    updates.reply_to_address = val;
  }
});
const postalAddress = computed<string | undefined>({
  get() {
    return updates.postal_address || org.value?.postal_address || undefined;
  },
  set(val: string | undefined) {
    updates.postal_address = val;
  }
});
const defaultAgentDayRateCents = computed({
  get() {
    return (
      updates.default_agent_day_rate_cents ||
      org.value?.default_agent_day_rate_cents ||
      10000
    );
  },
  set(val: number) {
    updates.default_agent_day_rate_cents = val;
  }
});
const defaultInvoiceNetTerm = computed({
  get() {
    return (
      updates.default_invoice_net_term ??
      org.value?.default_invoice_net_term ??
      30
    );
  },
  set(val: number) {
    updates.default_invoice_net_term = val;
  }
});
const defaultInvoicePivot = computed({
  get() {
    return (
      updates.default_invoice_pivot ||
      org.value?.default_invoice_pivot ||
      Invoice_Pivot_Options_Enum.None
    );
  },
  set(value: Invoice_Pivot_Options_Enum) {
    updates.default_invoice_pivot = value;
  }
});
const nextInvoiceNumber = computed({
  get() {
    return (
      (updates.last_invoice_number ?? org.value?.last_invoice_number ?? 99) + 1
    );
  },
  set(value: number) {
    // TODO, check if this number is valid
    updates.last_invoice_number = value - 1;
  }
});

const invoiceTemplateAboveLineItems = computed({
  get: () =>
    updates.invoice_template_above_line_items ||
    org.value?.invoice_template_above_line_items ||
    "",
  set: (value: string) => (updates.invoice_template_above_line_items = value)
});
const invoiceTemplateBelowLineItems = computed({
  get: () =>
    updates.invoice_template_below_line_items ||
    org.value?.invoice_template_below_line_items ||
    "",
  set: (value: string) => (updates.invoice_template_below_line_items = value)
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateOrgSettings(
      $set: organizations_set_input!
      $where: organizations_bool_exp!
    ) {
      update_organizations(where: $where, _set: $set) {
        affected_rows
      }
    }
  `)
);

async function deleteConnection(provider: Oauth_Providers_Enum) {
  if (!organization.value) {
    throw new ReferenceError("Organization is not defined");
  }
  deleteOrganizationsXrefOauth({
    organization_id: organization.value.id,
    provider
  });
}

function hasConnection(provider: Oauth_Providers_Enum) {
  return connections.value.some(
    ({ oauth_provider }) => oauth_provider === provider
  );
}

const onSubmit = async () => {
  const { data, error } = await executeMutation({
    set: updates,
    where: {
      id: { _eq: organization.value?.id }
    }
  });

  if (error) {
    createToast({
      title: "Unable to update the settings.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  }
  if (data) {
    createToast({
      message: "Settings updated.",
      theme: "success"
    });
  }
};
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="org" class="vertical">
    <h3>Invoicing</h3>
    <SHField label="Next Invoice Number">
      <SHNumberInput
        v-model="nextInvoiceNumber"
        :min="0"
        @change="console.log($event)"
      />
    </SHField>
    <SHField label="Reply To Address">
      <SHInput v-model="replyToAddress" type="email" />
    </SHField>
    <SHField label="Default Net Terms">
      <div class="level loose wrap">
        <label>
          <input v-model="defaultInvoiceNetTerm" type="radio" :value="0" />
          On Receipt
        </label>
        <label>
          <input v-model="defaultInvoiceNetTerm" type="radio" :value="30" />
          Net 30
        </label>
        <label>
          <input v-model="defaultInvoiceNetTerm" type="radio" :value="45" />
          Net 45
        </label>
        <label>
          <input v-model="defaultInvoiceNetTerm" type="radio" :value="60" />
          Net 60
        </label>
        <label>
          <input v-model="defaultInvoiceNetTerm" type="radio" :value="90" />
          Net 90
        </label>
      </div>
    </SHField>
    <SHField label="Default Agent Day Rate">
      <span>
        If not overriden on the agent or the customer, agents will be billed
        this rate per day
      </span>

      <SHDollarInput v-model="defaultAgentDayRateCents" />
    </SHField>
    <SHField label="Postal Address">
      <SHTextarea v-model="postalAddress" auto-resize />
    </SHField>
    <SHField label="Default Invoice Rollup">
      <div class="level loose wrap">
        <label v-for="opt of pivotOptions" :key="opt.id">
          <input v-model="defaultInvoicePivot" type="radio" :value="opt.id" />
          {{ humanizeEnum(opt.label) }}
        </label>
      </div>
    </SHField>
    <SHField label="Invoice Template Above Line Items">
      <SHTextarea v-model="invoiceTemplateAboveLineItems" auto-resize />
    </SHField>
    <SHField label="Invoice Template Below Line Items">
      <SHTextarea v-model="invoiceTemplateBelowLineItems" auto-resize />
    </SHField>

    <template v-if="false">
      <!-- TODO: Enable this when the quickbooks OAuth is more fleshed out -->
      <h3>Connections</h3>
      <h4>Quickbooks</h4>
      <div
        v-if="hasConnection(Oauth_Providers_Enum.Quickbooks)"
        class="vertical"
      >
        <div v-if="quickbooksCompany">
          You are connected to Quickbooks as
          {{ quickbooksCompany.CompanyInfo?.CompanyName }}
        </div>
        <div v-if="can('organization_connections:delete')">
          <SHButton
            v-if="accessToken"
            color="danger"
            @click="deleteConnection(Oauth_Providers_Enum.Quickbooks)"
          >
            Delete Quickbooks Connection
          </SHButton>
        </div>
      </div>
      <div v-else>
        <SHButton
          v-if="accessToken"
          color="primary"
          :href="`/api/connect/intuit?access_token=${accessToken}`"
        >
          <div class="level tight">
            <img
              style="width: 1.5em; height: 1.5em"
              src="@/assets/icons/quickbooks.svg"
            />
            <span>Connect to QuickBooks</span>
          </div>
        </SHButton>
      </div>
    </template>

    <UndoConfirm
      :confirm-enabled="Object.entries(updates).length > 0"
      @confirm="onSubmit"
      @undo="$router.back"
    />
  </article>
</template>

<style lang="scss" scoped></style>
