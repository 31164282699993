<script setup lang="ts">
import type { AppBuildInfo } from "./types";

import AppLink from "@/components/AppLink.vue";
import BottomNavigation from "@/components/MainNavigation/BottomNavigation.vue";
import SideBar from "@/components/MainNavigation/SideBar.vue";
import SHAlert from "@/components/SHAlert.vue";
import SHLogo from "@/components/SHLogo.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHWordMark from "@/components/SHWordMark.vue";
import SiteLayout from "@/components/SiteLayout.vue";
import { DarkModeKey, DisableFormKey, ThemeKey } from "@/providerKeys";
import AuthenticationProvider from "@/providers/AuthenticationProvider.vue";
import OrganizationProvider from "@/providers/OrganizationProvider.vue";
import EULAGuard from "@/views/EULAGuard.vue";
import { faCircleInfo, faRefresh } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDark, useLocalStorage } from "@vueuse/core";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { computed, provide, ref, watch } from "vue";
import { RouterView } from "vue-router";
import SHButton from "./components/SHButton.vue";
import SHToastCatcher from "./components/SHToastCatcher.vue";
import { useLogger } from "./logger";
import ClientProvider from "./providers/ClientProvider.vue";
import HubspotChatProvider from "./providers/HubspotChatProvider.vue";
import NotificationProvider from "./providers/NotificationProvider.vue";
import RouterProvider from "./providers/RouterProvider.vue";
import SentryProvider from "./providers/SentryProvider.vue";
import UploadQueuePlugin from "./providers/UploadQueuePlugin.vue";

const showBanner = ref(true);
const showUpdateBtn = ref(false);
const siteBuildInfo = ref<AppBuildInfo>();
const browserUnsupported = computed(
  () =>
    !navigator.userAgent.match(/chrome/i) &&
    !navigator.userAgent.match(/crios/i)
);
const hideBrowserWarning = ref(false);

const isDark = useDark({
  valueLight: "",
  valueDark: "dark",
  selector: "body"
});
const activeTheme = useLocalStorage("theme", "default");

watch(
  activeTheme,
  (newValue, oldValue) => {
    if (oldValue) {
      document.body.classList.remove(oldValue);
    }
    document.body.classList.add(newValue);
  },
  { immediate: true }
);

provide(DarkModeKey, isDark);
provide(ThemeKey, activeTheme);
provide(DisableFormKey, ref(false));

const { log } = useLogger("PwaUpdate");
const DECLINE_UPDATES_LIMIT = 5;

const { needRefresh, updateServiceWorker } = useRegisterSW({
  onRegisteredSW(swUrl) {
    log(`Service Worker registered at: ${swUrl}`);
  }
});

const onUpdateApp = () => {
  updateAttempts.value = 0;
  hideBanner();
  showUpdateBtn.value = false;
  updateServiceWorker(true);
};

const hideBanner = () => {
  showBanner.value = false;
};

const updateAttempts = useLocalStorage("updateAttempts", 0);

const onUpdateDeclined = () => {
  if (needRefresh.value && updateAttempts.value >= DECLINE_UPDATES_LIMIT) {
    onUpdateApp();
    showUpdateBtn.value = false;
  } else {
    updateAttempts.value++;
    showUpdateBtn.value = true;
  }
  hideBanner();
};

const fetchBuildInfo = async () => {
  const buildInfoUrl = "/build-info.json";
  fetch(buildInfoUrl)
    .then(r => (r.ok ? r.json() : Promise.reject("file not found")))
    .then(json => {
      siteBuildInfo.value = json;
      window.buildInfo = siteBuildInfo.value as AppBuildInfo;
    })
    .catch(reason => {
      console.log("no build info: ", reason);
    });
};

const displayMode = ref<"BROWSER" | "PWA">("BROWSER");
const isInPWA = computed(() => displayMode.value === "PWA");

window.addEventListener("DOMContentLoaded", () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    displayMode.value = "PWA";
  } else {
    displayMode.value = "BROWSER";
  }
});

fetchBuildInfo();
</script>

<template>
  <Suspense>
    <template #fallback>
      <SHSpinner />
    </template>
    <RouterProvider>
      <SHToastCatcher>
        <UploadQueuePlugin />
        <SentryProvider>
          <OrganizationProvider v-slot="{ organization }">
            <AuthenticationProvider
              v-slot="{ authClient, roleName }"
              :organization="organization"
            >
              <ClientProvider
                v-if="authClient.isAuthenticated"
                :org-from-hostname="organization"
                :auth-client="authClient"
                :role-name="roleName"
              >
                <HubspotChatProvider />
                <NotificationProvider />
                <EULAGuard :disabled="!authClient.user.value?.sub">
                  <SiteLayout>
                    <template #sidebar="{ toggle, isCollapsed }">
                      <SideBar
                        :is-collapsed="isCollapsed"
                        :show-update-button="showUpdateBtn"
                        @toggle="toggle"
                        @update-app="onUpdateApp()"
                      />
                    </template>

                    <template #default="{ isMobile }">
                      <RouterView v-slot="{ Component }" name="header">
                        <Transition name="fade" mode="out-in">
                          <component :is="Component" :is-mobile="isMobile" />
                        </Transition>
                      </RouterView>

                      <RouterView v-slot="{ Component }">
                        <Transition name="fade" mode="out-in">
                          <component :is="Component" />
                        </Transition>
                      </RouterView>
                    </template>

                    <template #bottomNav>
                      <BottomNavigation
                        :show-update-button="showUpdateBtn"
                        @update-app="onUpdateApp()"
                      />
                    </template>
                    <template #footer>
                      <footer
                        class="level-spread loose"
                        style="align-items: end"
                      >
                        <div>
                          <div class="branding">
                            <SHLogo class="mark" />
                            <SHWordMark class="word" />
                          </div>
                          <div class="copy">
                            <strong>Spearhead &trade;</strong>
                            copyright
                            <strong>Incyte Energy Solutions</strong>
                            &copy; 2023-2024. All rights reserved.
                            <br />
                            <span class="build number">
                              <template v-if="siteBuildInfo?.BUILD_ID">
                                {{ siteBuildInfo?.BUILD_ID }}
                              </template>
                              <template v-else>
                                {{ siteBuildInfo?.GIT_COMMIT }} ({{
                                  siteBuildInfo?.GIT_BRANCH
                                }})
                              </template>
                              &bullet;
                              <em>
                                {{ siteBuildInfo?.BUILD_TIME }}
                              </em>
                              &bullet;
                              <span>{{ isInPWA ? "PWA" : "WEB" }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="level tight">
                          <AppLink to="/changelog">Change Log</AppLink>
                          <AppLink
                            href="/legal/terms_of_service.html"
                            target="_blank"
                          >
                            Terms of Service
                          </AppLink>
                          <AppLink href="/legal/privacy.html" target="_blank">
                            Privacy Policy
                          </AppLink>
                        </div>
                      </footer>
                    </template>
                  </SiteLayout>
                </EULAGuard>
              </ClientProvider>

              <div v-else>not logged in</div>
            </AuthenticationProvider>
          </OrganizationProvider>
        </SentryProvider>

        <!-- Browser Suggestion Alert -->
        <SHAlert
          :visible="browserUnsupported && !hideBrowserWarning"
          @hide-alert="hideBrowserWarning = true"
        >
          <template #message>
            For the best experience, please use Google Chrome.
          </template>
          <template #action>
            <div class="level tight">
              Download
              <AppLink href="https://www.google.com/chrome/" target="_blank">
                Google Chrome.
              </AppLink>
            </div>
          </template>
        </SHAlert>

        <!-- App Version Alert -->

        <SHAlert
          :visible="needRefresh && showBanner"
          @hide-alert="onUpdateDeclined"
        >
          <template #message>
            <div class="vertical">
              <div class="level tight">Spearhead update available.</div>
              <div class="level loose">
                <SHButton
                  inner-class="level tight"
                  color="primary"
                  size="sm"
                  style="width: max-content"
                  @click="
                    onUpdateDeclined();
                    $router.push({ name: 'ChangeLog' });
                  "
                >
                  <FontAwesomeIcon :icon="faCircleInfo" />
                  Show Details
                </SHButton>

                <SHButton
                  inner-class="level tight"
                  color="success"
                  size="sm"
                  style="width: max-content"
                  @click="onUpdateApp"
                >
                  <FontAwesomeIcon :icon="faRefresh" />
                  Update
                </SHButton>
              </div>
            </div>
          </template>
        </SHAlert>
      </SHToastCatcher>
    </RouterProvider>
  </Suspense>
</template>

<style lang="scss" scoped>
.branding {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 0.5em;
  .mark {
    max-height: 39px;
  }
  .word {
    max-height: 22px;
  }
}
.build {
  font-weight: 300;
  color: var(--color-surface-400);
}
.copy {
  font-size: 1em;
}
</style>
