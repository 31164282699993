<script setup lang="ts" generic="R extends TimeSliderRange">
import { formatDate } from "@/formatters";
import {
  combineDateTimeOfDay,
  isWithinIntervalExclusive,
  timeOfDayFromDate,
  timeOfDayFromText
} from "@/lib/datetime";
import { startOfToday } from "date-fns";
import { computed } from "vue";
import SHInput from "@/components/SHInput.vue";
import SHTimeSlider, {
  type KeyTime,
  type TimeSliderRange
} from "@/components/SHTimeInput/SHTimeSlider.vue";
import { useLogger } from "@/logger";

const { log } = useLogger("SHTimeInput"); // eslint-disable-line @typescript-eslint/no-unused-vars

const {
  modelValue,
  minuteResolution = 1,
  ranges = []
} = defineProps<{
  modelValue: Date;
  minuteResolution?: number;
  ranges?: R[];
  keyTimes?: KeyTime[];
}>();

const emit = defineEmits<{
  (e: "update:model-value", date: Date): void;
}>();

const textInputValue = computed(() => {
  const t = timeOfDayFromDate(modelValue);
  if (t) return formatDate(combineDateTimeOfDay(startOfToday(), t), "HH:mm");
  return "??:??";
});
const onTimeInput = (value: string | undefined) => {
  if (value) {
    const time = timeOfDayFromText(value);
    // log("time text input", time);
    if (time) {
      // log("emitting", combineDateTimeOfDay(modelValue, time));
      emit("update:model-value", combineDateTimeOfDay(modelValue, time));
    }
  }
};
const onSliderInput = (value: Date) => {
  // log("slider updated to", formatDate(value, "MMM d HH:mm"));
  emit("update:model-value", value);
};

const intersectingRanges = computed(() =>
  ranges.filter(range => {
    return isWithinIntervalExclusive(modelValue, range);
  })
);
</script>

<template>
  <main class="sh-time-input">
    <div class="level-spread loose wrap">
      <div :style="{ position: 'relative', flexGrow: 0 }">
        <SHInput
          type="time"
          :model-value="textInputValue"
          :step="minuteResolution * 60"
          class="number"
          :class="{ [intersectingRanges.at(0)?.theme ?? 'primary']: true }"
          @update:model-value="onTimeInput"
        />
      </div>
      <div :style="{ position: 'relative', flexGrow: 1 }">
        <SHTimeSlider
          :ranges="ranges"
          :key-times="keyTimes"
          :model-value="modelValue"
          :minute-resolution="minuteResolution"
          @update:model-value="onSliderInput"
        >
          <template #toolTip>
            <slot name="toolTip" v-bind="{ modelValue }" />
          </template>
          <template #range="slotProps">
            <slot name="range" v-bind="slotProps" />
          </template>
        </SHTimeSlider>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints" as bp;

main.sh-time-input {
  :deep(input[type="time"]) {
    &.danger {
      border: thin solid var(--color-danger);
    }
  }

  .wrap {
    align-items: flex-start;
    @include bp.phone-only {
      flex-direction: column;
      align-items: stretch;
    }
  }
  position: relative;
  user-select: none;
}
</style>
