<script async setup lang="ts">
import DateRangePicker from "@/components/DateRangePicker.vue";
import DocumentPreview from "@/components/DocumentPreview.vue";
import SHAgentChooser from "@/components/SHAgentChooser.vue";
import SHButton from "@/components/SHButton.vue";
import SHCustomerChooser from "@/components/SHCustomerChooser.vue";
import SHField from "@/components/SHField.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHToggle from "@/components/SHToggle.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useAgentChoice } from "@/composables/useAgentChoice";
import { useCustomerChoice } from "@/composables/useCustomerChoice";
import { useQueryStringFilters } from "@/composables/useQueryStringFilters";
import { useToaster } from "@/composables/useToaster";
import { formatDate } from "@/formatters";
import { graphql } from "@/generated";
import {
  CURRENT_DATE_PRESETS,
  PAST_DATE_PRESETS,
  SIX_MONTHS_BACK
} from "@/lib/datetime";
import ServiceReport from "@/ssrComponents/ServiceReport.vue";
import { useMutation, useQuery } from "@urql/vue";
import { endOfDay, endOfToday, startOfDay, startOfToday } from "date-fns";
import { v4 } from "uuid";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const { createToast } = useToaster();
const router = useRouter();

const props = defineProps<{
  agentId?: string;
  customerId?: string;
  workOrderId?: string;
  startDate?: string;
  endDate?: string;
  includeMedia?: boolean;
}>();

const includeMedia = ref(props.includeMedia);
const toString = (x: string | string[] | undefined) =>
  Array.isArray(x) ? x.join("") : (x ?? "");

const { agentId: _agentId, customerId: _customerId } = useQueryStringFilters({
  agentId: props.agentId,
  customerId: props.customerId
});

const { data: customerData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query ServiceReportForm_Customer($workOrderId: uuid!, $customerId: uuid!) {
      customers(
        where: {
          _or: [
            { work_orders: { id: { _eq: $workOrderId } } }
            { id: { _eq: $customerId } }
          ]
        }
      ) {
        id
        ...CustomerChoice
      }
    }
  `),
  variables: computed(() => ({
    customerId: toString(_customerId.value) || v4(),
    workOrderId: props.workOrderId || v4()
  })),
  pause: computed(() => !_customerId && !props.workOrderId)
});

const customer = ref(
  customerData.value?.customers[0]
    ? useCustomerChoice(customerData.value.customers[0])
    : null
);

const { data: agentData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query ServiceReportForm_Agent($selectedAgentId: String!) {
      selectedAgent: users_by_pk(id: $selectedAgentId) {
        ...AgentChoice
      }
    }
  `),
  variables: computed(() => ({
    selectedAgentId: toString(_agentId.value) || "unknown user"
  }))
});
const agent = ref(useAgentChoice(agentData.value?.selectedAgent));

const { data: viewData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query ServiceReportForm($workOrderId: uuid!) {
      work_orders_by_pk(id: $workOrderId) {
        service_date_start
        service_date_end
        customer {
          id
          ...CustomerLink
        }
      }
    }
  `),
  variables: computed(() => ({
    workOrderId: props.workOrderId ?? "unknown work order"
  })),
  pause: computed(() => !props.workOrderId)
});
const workOrder = ref(viewData.value?.work_orders_by_pk);

const { startDate, endDate } = useQueryStringFilters({
  startDate:
    props.startDate ||
    workOrder.value?.service_date_start ||
    startOfToday().toLocaleString(),
  endDate:
    props.endDate ||
    workOrder.value?.service_date_end ||
    endOfToday().toLocaleString()
});

const serviceReportVariables = computed(() => ({
  userId: agent.value?.id || "unknown agent id",
  customerId: customer.value?.id || "unknown customer",
  startDate: startOfDay(new Date(toString(startDate.value))),
  endDate: endOfDay(new Date(toString(endDate.value))),
  includeMedia: includeMedia.value || false
}));

const { data, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query ServiceReportForm_ReportData(
      $userId: String!
      $customerId: uuid!
      $startDate: timestamptz!
      $endDate: timestamptz!
      $includeMedia: Boolean!
    ) {
      ...ServiceReport
    }
  `),
  variables: serviceReportVariables,
  pause: computed(() => !agent.value)
});

const { executeMutation: insertServiceReport } = useMutation(
  // TODO: turn this into a simple insert into reports, then subscribe to your own reports?
  graphql(/* GraphQL */ `
    mutation CreateServiceReport($report: report_uploads_insert_input!) {
      insert_report_uploads_one(object: $report) {
        id
      }
    }
  `)
);

const isExporting = ref(false);
async function onExport() {
  isExporting.value = true;
  const title = `Service Report ${agent.value?.full_name} (${formatDate(new Date(), "yyyy-MM-dd")})`;
  console.log("exporting", { title });

  const { data, error } = await insertServiceReport({
    report: {
      title,
      report_type: "ServiceReport",
      report_params: serviceReportVariables.value
    }
  });
  console.log("inserted report as", data?.insert_report_uploads_one);
  isExporting.value = false;

  if (error) {
    createToast({
      title: "Unable to export the service report.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
    return;
  } else {
    createToast({
      message: "Please wait while the report is generated...",
      theme: "success"
    });
    router.push({ name: "ReportsView" });
  }
}
</script>

<template>
  <article class="service-report-form vertical">
    <section class="vertical loose noprint">
      <SHField v-if="!customerId && !workOrderId" block label="Customer">
        <SHCustomerChooser
          v-if="!workOrderId"
          v-model="customer"
          @update:model-value="if (!customerId) _customerId = $event?.id;"
        />
      </SHField>

      <SHField v-if="!agentId" block label="Agent">
        <SHAgentChooser
          v-model="agent"
          :dedicated-at-top-by-customer-id="customer?.id"
          self-at-top
          @update:model-value="if (!agentId) _agentId = $event?.id;"
        />
      </SHField>

      <SHField block label="Service Date(s)">
        <DateRangePicker
          auto-apply
          :model-value="[
            new Date(toString(startDate)),
            new Date(toString(endDate))
          ]"
          :presets="[
            ...CURRENT_DATE_PRESETS,
            ...PAST_DATE_PRESETS,
            ...SIX_MONTHS_BACK
          ]"
          @update:model-value="
            dates => {
              if (dates?.length === 2) {
                startDate = dates[0].toLocaleString();
                endDate = dates[1].toLocaleString();
              }
            }
          "
        >
          <template
            v-if="workOrder?.service_date_start && workOrder?.service_date_end"
            #buttons="{ setRange }"
          >
            <SHButton
              color="secondary"
              size="sm"
              @click="
                setRange(
                  workOrder?.service_date_start!,
                  workOrder?.service_date_end!
                )
              "
            >
              This work order
            </SHButton>
          </template>
        </DateRangePicker>
      </SHField>
      <SHField label="Include media on the service report?">
        <div class="level tight">
          No
          <SHToggle v-model="includeMedia" />
          Yes
        </div>
      </SHField>
    </section>

    <section class="level-end tight noprint">
      <UndoConfirm
        :confirm-enabled="!isExporting"
        @confirm="onExport"
        @undo="$router.go(-1)"
      >
        <template #confirm>
          <div class="level tight">
            <SHSpinner v-if="isExporting" size="sm" />
            <span>Export to PDF</span>
          </div>
        </template>
      </UndoConfirm>
    </section>

    <SHSpinner v-if="fetching" size="lg" />
    <DocumentPreview v-else-if="!fetching && data">
      <ServiceReport :query="data" />
    </DocumentPreview>
  </article>
</template>
