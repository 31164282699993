import type { Order_By } from "@/generated/graphql";
import { useRouteQuery } from "@vueuse/router";
import type Fuse from "fuse.js";
import type { Ref } from "vue";

export type FacetOption<T> = {
  id: string;
  title: string;
  predicate: T;
};

export type OrderOption<T> = {
  id: string;
  title: string;
  asc: T[];
  desc: T[];
};

export type Facet<T> = {
  modelName: string;
  hidden?: boolean;
  hideOnMobile?: boolean;
  label: string;
  defaultValue: string;
  fuseOptions?: Fuse.IFuseOptions<T>;
  filters: FacetOption<T>[];
};

export type OrderSet<T> = {
  defaultValue: string;
  defaultOrderBy?: Order_By;
  options: OrderOption<T>[];
};

export type QueryStringFiltersMap = Record<
  string,
  string | string[] | undefined
>;

export type QueryStringFilter = {
  key: string;
  initialValue: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type QueryStringMap = Record<string, Ref<string | string[] | undefined>>;

export const useQueryStringFilters = (filters: QueryStringFiltersMap) => {
  const map: QueryStringMap = {};

  return Object.entries(filters).reduce((prev, [k, v]) => {
    prev[k] = useRouteQuery(k, v, { mode: "replace" });
    return prev;
  }, map);
};

export const toString = (x: string | string[] | undefined) =>
  Array.isArray(x) ? x.join("") : x ?? "";
