<script setup lang="ts">
import { graphql, useFragment, type FragmentType } from "@/generated";
import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleQuestion,
  faDesktop,
  faHelmetSafety,
  faWalkieTalkie,
  faWrench
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

const fragment = graphql(/* GraphQL */ `
  fragment UserRole on user_roles {
    short_name
    auth0_id
    title
  }
`);

const {
  showLabel = true,
  size = "sm",
  ...props
} = defineProps<{
  role?: FragmentType<typeof fragment>;
  showLabel?: boolean;
  size?: SizeProp;
}>();

const loaded = computed(() => useFragment(fragment, props.role));
const stripped = computed(() => loaded.value?.short_name.split(":").at(1));
const icon = computed(() => {
  switch (stripped.value) {
    case "agent":
      return faHelmetSafety;
    case "supervisor":
      return faWalkieTalkie;
    case "back_office":
      return faDesktop;
    case "engineer":
      return faWrench;
    default:
      return faCircleQuestion;
  }
});
</script>

<template>
  <span class="sh-role-badge level tight" :class="{ [stripped || '']: true }">
    <FontAwesomeIcon :icon="icon" :size="size" fixed-width />
    <span v-if="loaded?.title && showLabel" class="label">
      {{ loaded.title }}
    </span>
  </span>
</template>

<style lang="scss" scoped>
.sh-role-badge {
  background: orchid;
  border-radius: var(--border-radius);
  width: max-content;
  font-size: 0.875em;
  font-weight: 600;
  padding: 0.25em 0.5em;

  &.agent {
    color: var(--color-secondary);
    background: var(--color-secondary-opacity-25);
  }
  &.supervisor {
    color: var(--color-primary);
    background: var(--color-primary-opacity-25);
  }
  &.back_office {
    color: var(--color-surface-400);
    background: var(--color-surface-400-opacity-25);
  }
  &.engineer {
    color: var(--color-success);
    background: var(--color-success-opacity-25);
  }

  &.reverseColor {
    background: var(--color);
    color: var(--color-surface-100);
  }
}
.noBackground {
  background: transparent;
}
</style>
