<script setup lang="ts">
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey, RoleKey } from "@/providerKeys";
import { useRole } from "@/composables/useRole";
import { useLocalStorage } from "@vueuse/core";
import SHField from "@/components/SHField.vue";
import ComboBox from "../ComboBox/ComboBox.vue";

const { allowedRoles } = useRole();
const role = injectStrict(RoleKey);
const currentUser = injectStrict(CurrentUserKey);

const selectedRole = useLocalStorage("selectedRole", "public");
const updateRole = (value: string | undefined) => {
  selectedRole.value = value ?? "unknown role"; // update local storage
  role.value = selectedRole.value; // update the global role
};
</script>

<template>
  <SHField
    v-if="currentUser.email?.endsWith('@incyteenergysolutions.com')"
    label="Role"
  >
    <ComboBox
      mobile-header="Choose Role"
      :options="allowedRoles?.map(name => ({ id: name, label: name })) || []"
      :model-value="{ id: selectedRole, label: selectedRole }"
      @update:model-value="
        r => {
          if (r?.label) updateRole(r.label);
        }
      "
    />
  </SHField>
</template>

<style lang="scss" scoped></style>
