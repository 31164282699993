import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

export async function useBillingUnits() {
  const query = await useQuery({
    query: graphql(/* GraphQL */ `
      query BillingUnits {
        billing_units {
          id: value
          value
          label
        }
      }
    `)
  });

  if (query.error.value) {
    throw query.error.value;
  }

  if (!query.data.value) {
    throw new Error("Unable to fetch billing units.");
  }

  const billingUnits = computed(() => query.data.value?.billing_units ?? []);

  return {
    billingUnits
  };
}
