<script setup lang="ts">
import { computed } from "vue";
import {
  faCircle,
  faCircleCheck,
  faExclamationCircle
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { formatDateRelative } from "@/formatters";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import AppLink from "@/components/AppLink.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { useCustomForms } from "@/composables/useCustomForms";

const { ticketId } = defineProps<{
  ticketId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomFormsView($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        ref
        ticket_status
        ...CustomFormEntries
      }
    }
  `),
  variables: computed(() => ({
    ticketId
  })),
  context: { additionalTypenames: ["products_xref_custom_forms"] }
});
const ticket = computed(() => data.value?.tickets_by_pk);
const { formBindings } = useCustomForms(ticket);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>
  <article v-else class="vertical loose custom-forms">
    <div
      v-for="{ isRequired, definition, serverValue, cache } of formBindings"
      :key="definition.id"
      class="level tight wrap"
    >
      <AppLink
        class="level tight"
        :to="{
          name: 'CustomFormDetail',
          query: {
            ticketCustomFormEntryId: serverValue?.id,
            customFormDefinitionId: definition.id
          }
        }"
      >
        <FontAwesomeIcon
          fixed-width
          :icon="
            cache.value
              ? faExclamationCircle
              : definition.entries.length
                ? faCircleCheck
                : faCircle
          "
          :style="{
            color: cache.value
              ? 'var(--color-warning)'
              : definition.entries.length
                ? 'var(--color-success)'
                : 'var(--color-surface-400)'
          }"
          size="2x"
        />
        {{ definition.title }}
      </AppLink>

      <template v-if="definition.entries.length">
        <span v-if="cache.value" class="time">
          unsaved changes from
          {{ formatDateRelative(cache.value?.updated_at) }}
        </span>
        <span v-else class="time">
          saved {{ formatDateRelative(definition.entries.at(0)?.updated_at) }}
        </span>
      </template>
      <SHBadge v-else-if="isRequired" color="var(--color-warning)">
        Required
      </SHBadge>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.custom-forms {
  .time {
    font-size: 0.875em;
    font-weight: 300;
    color: var(--color-surface-600);
  }
}
</style>
