<script lang="ts">
export type PriceBookFormUpdates = Price_Books_Set_Input;
export type PriceBookFormInserts = Price_Books_Insert_Input;
</script>

<script setup lang="ts">
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import type {
  Price_Books_Insert_Input,
  Price_Books_Set_Input
} from "@/generated/graphql";
import type { JSONContent } from "@tiptap/vue-3";
import { v4 } from "uuid";
import { computed, ref, watch } from "vue";
import SHTextEditor from "./TextEditor/SHTextEditor.vue";

const priceBookFragment = graphql(/* GraphQL */ `
  fragment PriceBookForm on price_books {
    id
    notesj
    title
  }
`);

const props = defineProps<{
  priceBook?: FragmentType<typeof priceBookFragment>;
  updates?: PriceBookFormUpdates | null;
  inserts?: PriceBookFormInserts | null;
}>();

const emit = defineEmits<{
  (e: "update:updates", value: PriceBookFormUpdates | null): void;
  (e: "update:inserts", value: PriceBookFormInserts | null): void;
}>();

const priceBook = useFragment(priceBookFragment, props.priceBook);

const form = ref<{
  id: string;
  notesj?: JSONContent | null;
  title?: string;
}>({
  id: priceBook?.id ?? v4(),
  notesj: priceBook?.notesj,
  title: priceBook?.title
});

const inserts = computed(() => {
  return {
    id: form.value.id,
    title: form.value.title,
    notesj: form.value.notesj
  } as PriceBookFormInserts;
});

const updates = computed<PriceBookFormUpdates | null>(() => {
  if (priceBook) {
    return {
      title: form.value.title,
      notesj: form.value.notesj
    };
  }

  return null;
});

watch(updates, val => emit("update:updates", val));
watch(inserts, val => emit("update:inserts", val));
</script>

<template>
  <article class="price-book-form vertical">
    <section class="vertical">
      <SHField label="Pricebook Title">
        <SHInput
          :model-value="form.title ?? priceBook?.title"
          @update:model-value="form.title = $event"
        />
      </SHField>
      <SHField label="Notes">
        <SHTextEditor v-model="form.notesj" editable />
      </SHField>
    </section>
  </article>
</template>
