<script async setup lang="ts">
import DocumentPreview from "@/components/DocumentPreview.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { formatDate, formatUSD } from "@/formatters";
import { graphql } from "@/generated";
import { humanizeEnum } from "@/lib/helpers";
import TicketReport from "@/ssrComponents/TicketReport.vue";
import { faEllipsisVertical } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { clamp } from "@vueuse/core";
import type { SortableEvent } from "sortablejs";
import { Sortable } from "sortablejs-vue3";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();

const props = defineProps<{
  ticketId: string;
  ticketCustomFormEntryIds?: string[];
}>();

const documentPreview = ref();

const { data } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TicketReportForm($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        id
        ref

        product {
          products_xref_custom_forms {
            is_required

            custom_form_definition {
              id
              title

              ticket_custom_form_entries(
                where: { ticket_id: { _eq: $ticketId } }
              ) {
                id
              }
            }
          }
        }

        expense_logs(order_by: [{ expensed_at: asc }]) {
          id
          expense_type
          expensed_at
          cents_recorded
          is_external
        }
      }
    }
  `),
  variables: computed(() => ({
    ticketId: props.ticketId
  }))
});

const ticket = computed(() => data.value?.tickets_by_pk);
// map by custom_form_definition_id
const customForms = computed(
  () =>
    ticket.value?.product.products_xref_custom_forms.reduce(
      (
        prev: Record<
          string,
          NonNullable<
            (typeof ticket.value.product.products_xref_custom_forms)[number]
          >
        >,
        cur: (typeof ticket.value.product.products_xref_custom_forms)[number]
      ) => {
        prev[cur?.custom_form_definition.id] = cur;
        return prev;
      },
      {}
    ) || {}
);
const expenses = computed(
  () =>
    ticket.value?.expense_logs.reduce(
      (
        prev: Record<
          string,
          NonNullable<(typeof ticket.value.expense_logs)[number]>
        >,
        cur: (typeof ticket.value.expense_logs)[number]
      ) => {
        prev[cur?.id] = cur;
        return prev;
      },
      {}
    ) || {}
);
const formOrder = ref(Object.keys(customForms.value as never));
const expenseOrder = ref(Object.keys(expenses.value as never));

const selectedEntries = ref<string[]>(
  props.ticketCustomFormEntryIds
    ? props.ticketCustomFormEntryIds
    : (
        data.value?.tickets_by_pk?.product.products_xref_custom_forms ?? []
      ).reduce((prev: string[], cur) => {
        const formRequired = cur?.is_required;
        const firstEntryId =
          cur.custom_form_definition?.ticket_custom_form_entries.at(0)?.id;
        if (formRequired && firstEntryId) {
          prev.push(firstEntryId);
        }
        return prev;
      }, [])
);
const selectedExpenses = ref<string[]>(
  (data.value?.tickets_by_pk?.expense_logs ?? []).reduce(
    (prev: string[], cur) => {
      if (cur?.is_external) prev.push(cur.id);
      return prev;
    },
    []
  )
);

const { data: reportData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TicketReportForm_Data($ticketId: uuid!) {
      ...TicketReport
    }
  `),
  variables: computed(() => ({
    ticketId: props.ticketId
  }))
});

const { executeMutation: insertTicketReport } = useMutation(
  // TODO: turn this into a simple insert into reports, then subscribe to your own reports?
  graphql(/* GraphQL */ `
    mutation CreateTicketReport($report: report_uploads_insert_input!) {
      insert_report_uploads_one(object: $report) {
        id
      }
    }
  `)
);

const isExporting = ref(false);
async function onExport() {
  isExporting.value = true;
  const title = `Ticket Report ${ticket.value?.ref} (${formatDate(new Date(), "yyyy-MM-dd")})`;
  console.log("exporting", { title });

  const { data, error } = await insertTicketReport({
    report: {
      title,
      report_type: "TicketReport",
      report_params: {
        ticketId: props.ticketId,
        ticketCustomFormEntryIds: selectedEntries.value,
        expenseLogIds: selectedExpenses.value
      }
    }
  });
  console.log("inserted report as", data?.insert_report_uploads_one);
  isExporting.value = false;

  if (error) {
    createToast({
      title: "Unable to export the ticket report.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
    return;
  } else {
    createToast({
      message:
        "Ticket report requested. Please wait while the report is generated...",
      theme: "success"
    });
    router.push({ name: "ReportsView" });
  }
}

const moveFormSelection = ({ oldIndex, newIndex }: SortableEvent) => {
  const oldIdx = clamp(oldIndex || 0, 0, formOrder.value.length - 1);
  const newIdx = clamp(newIndex || 0, 0, formOrder.value.length - 1);

  const newOrder = [...formOrder.value];
  // change order in main list
  // console.log("forms before", formOrder.value);
  newOrder.splice(newIdx, 0, newOrder.splice(oldIdx, 1)[0]);
  // console.log("temp order", newOrder);
  formOrder.value = newOrder;
  // console.log("forms after", formOrder.value);

  // also change order in selected items for print
  // console.log("selected entries before", selectedEntries.value);
  const newSelected: string[] = [];
  formOrder.value.reduce((out, defId) => {
    const def = customForms.value[defId];
    const entry = def.custom_form_definition.ticket_custom_form_entries.at(0);
    if (def && entry && selectedEntries.value.includes(entry.id)) {
      out.push(entry.id);
    }
    return out;
  }, newSelected);
  selectedEntries.value = newSelected;
  // console.log("NEW SELECTED", newSelected);

  // console.log("selected entries after", selectedEntries.value);
};
const moveExpenseSelection = ({ oldIndex, newIndex }: SortableEvent) => {
  const oldIdx = clamp(oldIndex || 0, 0, expenseOrder.value.length - 1);
  const newIdx = clamp(newIndex || 0, 0, expenseOrder.value.length - 1);

  const newOrder = [...expenseOrder.value];
  // change order in main list
  // console.log("expenses before", expenseOrder.value);
  newOrder.splice(newIdx, 0, newOrder.splice(oldIdx, 1)[0]);
  // console.log("temp order", newOrder);
  expenseOrder.value = newOrder;
  // console.log("forms after", expenseOrder.value);

  // also change order in selected items for print
  // console.log("selected entries before", selectedEntries.value);
  const newSelected: string[] = [];
  expenseOrder.value.reduce((out, defId) => {
    const e = expenses.value[defId];
    if (e && selectedExpenses.value.includes(e.id)) {
      out.push(e.id);
    }
    return out;
  }, newSelected);
  selectedExpenses.value = newSelected;
  // console.log("NEW SELECTED", newSelected);

  // console.log("selected entries after", selectedEntries.value);
};
</script>

<template>
  <article class="ticket-report-form vertical">
    <section>
      <SHField block label="Include Forms">
        <Sortable
          :list="formOrder.map(id => customForms?.[id]?.custom_form_definition)"
          item-key="id"
          @end="moveFormSelection"
        >
          <template #item="{ element }">
            <div class="level tight custom-form">
              <label class="level tight">
                <FontAwesomeIcon
                  fixed-width
                  :icon="faEllipsisVertical"
                  size="lg"
                />
                <input
                  v-model="selectedEntries"
                  :disabled="!element.ticket_custom_form_entries.at(0)"
                  :value="element.ticket_custom_form_entries.at(0)?.id || null"
                  type="checkbox"
                />
                {{ element.title }}
                <SHBadge
                  v-if="!element.ticket_custom_form_entries.at(0)"
                  color="var(--color-warning)"
                >
                  Not filled out
                </SHBadge>
              </label>
            </div>
          </template>
        </Sortable>
      </SHField>
      <SHField
        v-if="ticket?.expense_logs?.length"
        block
        label="Include Expenses"
      >
        <Sortable
          :list="expenseOrder.map(id => expenses?.[id])"
          item-key="id"
          @end="moveExpenseSelection"
        >
          <template #item="{ element }">
            <div class="level tight custom-form">
              <label class="level tight">
                <FontAwesomeIcon
                  fixed-width
                  :icon="faEllipsisVertical"
                  size="lg"
                />
                <input
                  v-model="selectedExpenses"
                  :value="element.id || null"
                  type="checkbox"
                />
                <SHInlineDate :d="element.expensed_at" />
                {{ humanizeEnum(element.expense_type) }}
                <strong>
                  {{ formatUSD(element.cents_recorded) }}
                </strong>
              </label>
            </div>
          </template>
        </Sortable>
      </SHField>
    </section>

    <section>
      <UndoConfirm
        :confirm-enabled="!isExporting"
        @confirm="onExport"
        @undo="$router.go(-1)"
      >
        <template #confirm>
          <div class="level tight">
            <SHSpinner v-if="isExporting" size="sm" />
            <span>Export to PDF</span>
          </div>
        </template>
      </UndoConfirm>
    </section>

    <DocumentPreview ref="documentPreview">
      <TicketReport
        v-if="reportData"
        :query="reportData"
        :selected-form-entries="selectedEntries"
        :selected-expense-logs="selectedExpenses"
      />
    </DocumentPreview>
  </article>
</template>

<style lang="scss" scoped>
.ticket-report-form {
  .custom-form {
    padding: var(--padding);
    border-radius: var(--border-radius);
    svg {
      padding: 0.25em;
      border-radius: var(--border-radius);
      color: var(--color-surface-500);
      border: thin solid var(--color-surface-500);
    }
  }
  .sortable-chosen {
    svg {
      color: var(--color-primary-up-100);
      border: thin solid var(--color-primary-up-100);
    }
    outline: thin solid var(--color-primary-up-100);
  }
}
</style>
