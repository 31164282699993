<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHField from "@/components/SHField.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import UserLink from "@/components/UserLink.vue";
import WorkOrderLink from "@/components/WorkOrder/WorkOrderLink.vue";
import { formatDate } from "@/formatters";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { Ticket_Statuses_Enum } from "@/generated/graphql";
import { faChevronUp } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
// import { BreakPointsKey } from "@/providerKeys";
// import { injectStrict } from "@/lib/helpers";
import CustomerLink from "@/components/Customer/CustomerLink.vue";
import TBDWorkSiteLink from "@/components/TBDWorkSiteLink.vue";
import { getColor } from "@/lib/ticketStatus";

const TicketCardFragment = graphql(/* GraphQL */ `
  fragment TicketCard on tickets {
    id
    ref
    notesj
    created_at
    updated_at
    deleted_at
    review_status
    ticket_status

    product {
      title
    }
    work_site {
      id
      title
      ...TBDWorkSiteLink
    }
    work_site_unconfirmed

    date_range {
      first_work
      last_work
    }

    work_order {
      ref
      customer {
        id
        title
        abbreviation
        ...CustomerLink
      }
      ...WorkOrderLink
    }
    ticket_agents {
      agent {
        ...UserLink
      }
    }
    author {
      ...UserLink
    }
  }
`);

const props = defineProps<{
  ticket: Partial<FragmentType<typeof TicketCardFragment>>;
  showWorkOrder?: boolean;
  expandable?: boolean;
  noHover?: boolean;
  reportMode?: boolean;
}>();

const emit = defineEmits<{
  (e: "click:id", value: typeof ticket.value): void;
}>();

// const breakpoints = injectStrict(BreakPointsKey);
// const isMobile = injectStrict(IsMobileKey);
const showDetails = ref(false);
const ticket = computed(() => useFragment(TicketCardFragment, props.ticket));

const workLogsDateRange = computed(() => {
  const format = (date: Date) => formatDate(date, "MMM dd, yyyy");

  const dStart = ticket.value?.date_range?.first_work
    ? new Date(ticket.value?.date_range?.first_work)
    : undefined;
  const dEnd = ticket.value?.date_range?.last_work
    ? new Date(ticket.value?.date_range?.last_work)
    : undefined;

  if (dStart && dEnd) {
    if (format(dStart) === format(dEnd)) {
      return format(dStart);
    }
    return `${format(dStart)} - ${format(dEnd)}`;
  }
  return "";
});

const statusColor = computed(() =>
  props.reportMode
    ? "var(--color-surface-400)"
    : ticket.value.deleted_at !== null
      ? "var(--color-danger)"
      : getColor(ticket.value.ticket_status)
);
</script>

<template>
  <article
    class="ticket-card"
    :class="{
      hoverEnabled: !noHover,
      isClosed: ticket.ticket_status === Ticket_Statuses_Enum.Closed,
      report: reportMode
    }"
    @click="!noHover && emit('click:id', ticket)"
  >
    <header>
      <span class="ticket_type level tight">
        {{ ticket.product.title || "?" }}
        <SHBadge :color="statusColor" class="number">{{ ticket.ref }}</SHBadge>
        <SHBadge v-if="ticket.deleted_at !== null" color="var(--color-danger)">
          ARCHIVED
        </SHBadge>
        <WorkOrderLink :work-order="ticket.work_order" :disabled="!noHover">
          {{ ticket.work_order.ref }}
        </WorkOrderLink>
      </span>
      <span class="truncate">
        <CustomerLink
          :disabled="!noHover || reportMode"
          :customer="ticket.work_order.customer"
        >
          {{
            ticket.work_order.customer.abbreviation ??
            ticket.work_order.customer.title
          }}
        </CustomerLink>

        <span style="color: var(--color-secondary)">◦</span>

        <TBDWorkSiteLink
          v-if="!reportMode"
          :disabled="!noHover || reportMode"
          :work-site="ticket.work_site"
          :guess="ticket.work_site_unconfirmed"
        />
        <AppLink v-else disabled>
          {{ ticket.work_site_unconfirmed ?? ticket.work_site.title }}
        </AppLink>
      </span>
    </header>

    <main>
      <section>
        <template v-if="workLogsDateRange">
          {{ workLogsDateRange }}
        </template>
        <template v-else>
          <span style="color: var(--color-surface-500)">No Work Added</span>
        </template>
      </section>

      <section class="assignee">
        <UserLink
          v-if="ticket.ticket_agents[0]?.agent"
          :user="ticket.ticket_agents[0]?.agent"
        />
        <div v-else style="color: var(--color-surface-500)">Unassigned</div>
      </section>
    </main>

    <footer v-if="expandable && ticket.notesj" class="vertical">
      <SHField v-show="showDetails" label="Ticket Notes/Instructions" block>
        <SHTextEditor v-model="ticket.notesj" />
      </SHField>

      <div style="text-align: center">
        <AppLink @click.prevent.stop="showDetails = !showDetails">
          <FontAwesomeIcon
            :icon="faChevronUp"
            :rotation="showDetails ? undefined : 180"
            style="transition: transform 150ms ease-in-out"
          />
        </AppLink>
      </div>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;
article.ticket-card {
  position: relative;
  font-size: 0.875em;

  &:not(.report) {
    border: thin solid var(--color-surface-200);
    background: var(--color-surface-100);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }

  &.isClosed {
    background: var(--color-surface-200-opacity-40);
    box-shadow: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 15%;
    width: 4px;
    height: 70%;
    background: v-bind(statusColor);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  header {
    display: flex;
    gap: 0.5em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.5em 1em;
    font-weight: var(--font-weight-bold);
    color: var(--color-surface-500);

    @include bp.tablet {
      flex-direction: row;
    }

    .ticket_type {
      font-weight: 900;
      color: var(--color-surface-900);

      :deep(a) {
        --link--color: var(--color-secondary);
      }
    }
    .truncate {
      display: inline-flex;
      justify-content: flex-end;
      gap: 0.25em;
      flex: 1;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  main {
    border-top: thin solid var(--color-surface-200);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0.5em 1em;

    nav {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.25em;
    }
  }

  footer {
    padding-left: 1em;
    padding-bottom: 0.5em;
  }

  &.hoverEnabled:hover {
    transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    background: var(--color-primary-opacity-15);
    cursor: pointer;
  }
}
</style>
