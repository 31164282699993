<script setup lang="ts">
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

import RouterTabs from "@/components/RouterTabs.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";

const { customFormDefinitionId } = defineProps<{
  customFormDefinitionId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query OrgCustomFormDetail($customFormDefinitionId: uuid!) {
      custom_form_definitions_by_pk(id: $customFormDefinitionId) {
        id
        title
        component_name
        created_at
        updated_at
      }
    }
  `),
  variables: { customFormDefinitionId }
});
const formDef = computed(() => data.value?.custom_form_definitions_by_pk);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>
  <article v-else-if="formDef" class="vertical">
    <header class="vertical">
      <h2>
        {{ formDef.title }}
      </h2>
      <p>
        Last updated:
        <SHInlineDate :d="formDef.updated_at" />
      </p>
    </header>

    <RouterTabs
      :tabs="[
        { title: 'Entries', to: { name: 'CustomFormEntries' } },
        { title: 'Products', to: { name: 'CustomFormProductBindings' } },
        { title: 'Preview', to: { name: 'CustomFormPreview' } }
      ]"
    />
  </article>
</template>
