<script setup lang="ts">
import type { Interval } from "date-fns";
import { format, isSameMinute } from "date-fns";

defineProps<{
  left?: Interval;
  right: Interval;
}>();

const TIME_FORMAT = "hh:mm aa";
</script>

<template>
  <article class="sh-interval-diff">
    <strong v-if="!left" class="add">
      {{ format(right.start, TIME_FORMAT) }}
    </strong>
    <template v-else-if="!isSameMinute(left.start, right.start)">
      <span class="remove">
        {{ format(left.start, TIME_FORMAT) }}
      </span>
      <strong class="add">{{ format(right.start, TIME_FORMAT) }}</strong>
    </template>
    <template v-else>
      <span>{{ format(right.start, TIME_FORMAT) }}</span>
    </template>
    <span>-</span>
    <strong v-if="!left" class="add">
      {{ format(right.end, TIME_FORMAT) }}
    </strong>
    <template v-else-if="!isSameMinute(left.end, right.end)">
      <span class="remove">
        {{ format(left.end, TIME_FORMAT) }}
      </span>
      <strong class="add">{{ format(right.end, TIME_FORMAT) }}</strong>
    </template>
    <template v-else>
      <span>{{ format(right.end, TIME_FORMAT) }}</span>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.sh-interval-diff {
  display: flex;
  align-items: center;
  gap: 0.5em;

  .remove {
    text-decoration: line-through;
    color: var(--color-danger);
  }

  .add {
    color: var(--color-success-up-200);
  }
}
</style>
