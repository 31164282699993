<script async setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { useMutation } from "@urql/vue";
import { v4 } from "uuid";
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";

import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHNote from "@/components/SHNote.vue";
import SHRoleChooser from "@/components/SHRoleChooser.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import type { UserRoleChoice } from "@/composables/useUserRoleChoice";

const router = useRouter();
const { createToast } = useToaster();

const mutation = graphql(/* GraphQL */ `
  mutation CreateOrganizationAgent($agent: AgentInput!, $roles: [RoleInput!]!) {
    createOrgAgent(agent: $agent, roles: $roles) {
      userAdded
      membershipAdded
      rolesAdded
      agent {
        id
        full_name
        email
      }
      password
      organization_name
    }
  }
`);

const form = reactive<VariablesOf<typeof mutation>>({
  agent: {
    id: `auth0|${v4().replaceAll("-", "")}`,
    fname: "",
    lname: "",
    email: "",
    phone_number: "",
    city: "",
    state: ""
  },
  roles: []
});
const selectedRole = ref<UserRoleChoice>();

const { executeMutation: insert } = useMutation(mutation);

const lastInviteInfo = ref<ResultOf<typeof mutation>["createOrgAgent"]>(null);

const isValid = computed(
  () =>
    !!form.agent.fname &&
    !!form.agent.lname &&
    !!form.agent.email &&
    !!selectedRole.value
);
const isLoading = ref(false);

async function onSubmit() {
  if (!selectedRole.value?.id || !isValid.value) return;
  isLoading.value = true;

  const { data, error } = await insert({
    agent: form.agent,
    roles: [
      {
        id: selectedRole.value.id,
        label: selectedRole.value.label,
        auth0_id: selectedRole.value?.auth0_id
      }
    ]
  });

  if (error) {
    createToast({
      title: "Unable to create the invite.",
      message: error?.message || "Unknown error.",
      theme: "danger"
    });
  } else if (data && !data.createOrgAgent?.membershipAdded) {
    // they were already a user in the tenant and a member of this org
    createToast({
      title: "Warning",
      message: `The user with email ${form.agent.email} is already a member of your organization.`,
      theme: "warning",
      lifetimeMS: 30_000,
      requiresInteraction: true
    });
  } else if (data && data.createOrgAgent?.rolesAdded) {
    lastInviteInfo.value = data.createOrgAgent;
    createToast({
      message: "Agent created.",
      theme: "success"
    });
  }

  isLoading.value = false;
}

function onCancel() {
  router.push({
    name: "AgentsView"
  });
}
</script>

<template>
  <article class="vertical xmargin">
    <div v-if="lastInviteInfo" class="vertical">
      <h1>Agent Created</h1>
      <div class="vertical">
        <span>
          We created an account for agent
          {{ lastInviteInfo.agent?.full_name }} with temporary password
        </span>
        <SHBadge
          color="var(--color-primary)"
          style="font-size: 2em; text-align: center"
        >
          {{ lastInviteInfo.password }}
        </SHBadge>
      </div>
      <SHNote theme="danger">
        This password will not be shown again! You can always send them a
        password reset link via email.
      </SHNote>
      <SHButton
        color="primary"
        @click="
          $router.push({
            name: 'AgentsView'
          })
        "
      >
        Back to Agents List
      </SHButton>
    </div>

    <template v-else>
      <h1>Agent Create Form</h1>
      <SHNote
        v-show="isLoading"
        theme="primary"
        class="level tight"
        style="padding: 1em"
      >
        <SHSpinner />
        Creating user. This can take up to a minute to complete.
      </SHNote>

      <SHField label="First Name">
        <SHInput
          :model-value="form?.agent?.fname"
          :disabled="isLoading"
          @update:model-value="form.agent.fname = $event ?? ''"
        />
      </SHField>
      <SHField label="Last Name">
        <SHInput
          :model-value="form?.agent?.lname"
          :disabled="isLoading"
          @update:model-value="form.agent.lname = $event ?? ''"
        />
      </SHField>
      <SHField label="Select Role">
        <SHRoleChooser v-model="selectedRole" :disabled="isLoading" />
      </SHField>

      <SHField label="Email">
        <SHInput
          type="email"
          :model-value="form?.agent.email"
          :disabled="isLoading"
          @update:model-value="form.agent.email = $event ?? ''"
        />
      </SHField>
      <SHField label="Phone Number">
        <SHInput
          :model-value="form?.agent?.phone_number"
          :disabled="isLoading"
          @update:model-value="form.agent.phone_number = $event ?? ''"
        />
      </SHField>
      <SHField label="City">
        <SHInput
          :model-value="form?.agent.city"
          :disabled="isLoading"
          @update:model-value="form.agent.city = $event ?? ''"
        />
      </SHField>
      <SHField label="State">
        <SHInput
          :model-value="form?.agent.state"
          :disabled="isLoading"
          @update:model-value="form.agent.state = $event ?? ''"
        />
      </SHField>

      <UndoConfirm
        :confirm-enabled="isValid && !isLoading"
        @undo="onCancel"
        @confirm="onSubmit"
      />
    </template>
  </article>
</template>

<style lang="scss" scoped></style>
