<template>
  <div class="items">
    <template v-if="items.length">
      <button
        v-for="(item, index) in items"
        :key="index"
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      >
        {{ typeof getLabel === "function" ? getLabel(item) : item }}
      </button>
    </template>
    <div v-else class="item item-empty">{{ placeholder ?? "No Results" }}</div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { ref, toRefs, watch } from "vue";

const props = defineProps<{
  items: T[];
  placeholder: string;
  command: (props: any) => void;
  getLabel?: (item: T) => string;
}>();

const { items } = toRefs(props);

const selectedIndex = ref(0);

watch(items, () => {
  selectedIndex.value = 0;
});

defineExpose({
  onKeyDown
});

function onKeyDown({ event }: { event: KeyboardEvent }) {
  if (event.key === "ArrowUp") {
    upHandler();
    return true;
  }

  if (event.key === "ArrowDown") {
    downHandler();
    return true;
  }

  if (event.key === "Enter") {
    enterHandler();
    return true;
  }

  return false;
}

function upHandler() {
  selectedIndex.value =
    (selectedIndex.value + items.value.length - 1) % items.value.length;
}

function downHandler() {
  selectedIndex.value = (selectedIndex.value + 1) % items.value.length;
}

function enterHandler() {
  selectItem(selectedIndex.value);
}

function selectItem(index: number) {
  const item = items.value[index];

  if (item) {
    props.command(item);
  }
}
</script>

<style lang="scss" scoped>
.items {
  position: relative;
  border-radius: var(--border-radius);
  background: var(--color-surface-200);
  color: var(--color-surface-800);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: var(--box-shadow);
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border: 1px solid transparent;
  color: var(--color-surface-900);

  &.is-selected {
    background-color: var(--color-secondary);
  }
}

.item-empty {
  padding: 0.25em 0.5em 0.25em 0.5em;
}
</style>
