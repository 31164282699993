<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import QueryFilter from "@/components/QueryFilter.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { useFacets } from "@/composables/useFacets";
import type { OrderSet } from "@/composables/useQueryStringFilters";
import { graphql } from "@/generated";
import { Order_By, type Customers_Order_By } from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { injectStrict } from "@/lib/helpers";
import { BreakPointsKey } from "@/providerKeys";
import { useQuery } from "@urql/vue";
import { useIntersectionObserver } from "@vueuse/core";
import { startOfDay, subDays } from "date-fns";
import { computed, ref } from "vue";

const breakpoints = injectStrict(BreakPointsKey);
const filterViewMode = ref<CollectionView>(CollectionView.Table);
const recordsPerPage = ref(100);
const limit = ref(recordsPerPage.value);
const ninetyDaysAgo = subDays(startOfDay(new Date()), 90);

const orderByFilters: OrderSet<Customers_Order_By> = {
  defaultValue: "name",
  defaultOrderBy: Order_By.Asc,
  options: [
    {
      id: "name",
      title: "Name/Title",
      asc: [{ title: Order_By.Asc }],
      desc: [{ title: Order_By.Desc }]
    },
    {
      id: "abbreviation",
      title: "Abbreviation",
      asc: [{ abbreviation: Order_By.Asc }],
      desc: [{ abbreviation: Order_By.Desc }]
    },
    {
      id: "created_at",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "updated_at",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const {
  facetModels,
  resetFacets,
  searchString,
  sortOrder,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets([], orderByFilters);

const CustomerIndexQuery = graphql(/* GraphQL */ `
  query CustomerIndex(
    $where: customers_bool_exp
    $orderBy: [customers_order_by!]
    $limit: Int
    $offset: Int
    $date: timestamptz
  ) {
    customers(
      where: $where
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      title
      abbreviation

      customer_agents_aggregate {
        aggregate {
          count
        }
      }

      work_orders {
        tickets_aggregate(
          where: { created_at: { _gte: $date }, deleted_at: { _is_null: true } }
        ) {
          aggregate {
            count
          }
        }
      }

      work_orders_aggregate(where: { service_date_end: { _gte: "now()" } }) {
        aggregate {
          count
        }
      }
    }

    customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

const { data, fetching, error } = useQuery({
  query: CustomerIndexQuery,
  variables: computed(() => ({
    where: {
      title: { _ilike: `%${searchString.value}%` }
    },
    orderBy: sortOrder.value,
    offset: 0,
    limit: limit.value,
    date: ninetyDaysAgo
  })),
  context: { additionalTypenames: ["customers"] }
});

const customers = computed(() => data.value?.customers ?? []);

const customerCount = computed(
  () => data.value?.customers_aggregate.aggregate?.count || 0
);

const scrollMarker = ref();
const loadMore = () => {
  if (limit.value <= customerCount.value) limit.value += recordsPerPage.value;
};

useIntersectionObserver(scrollMarker, ([{ isIntersecting }]) => {
  if (isIntersecting) loadMore();
});
</script>

<template>
  <article class="vertical xmargin">
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="customerCount"
      clear-all-filters
      placeholder="Search by Name/Title..."
      no-calendar-view
      no-card-view
      no-table-view
      searchable
      @reset:facets="resetFacets"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Customer Filter</template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <template v-else>
      <SHTable
        :rows="customers"
        clickable
        empty-message="No customers found."
        @row:click="
          $router.push({
            name: 'CustomerDetail',
            params: { customerId: $event.id }
          })
        "
      >
        <template #header>
          <th></th>
          <th>Name / Title</th>
          <th v-if="breakpoints.includes('desktop')">Dedicated Agents</th>
          <th v-if="breakpoints.includes('desktop')">
            Tickets in the Last 90 Days
          </th>
        </template>

        <template v-if="!error" #row="{ row }">
          <td>
            <SHBadge class="number">
              {{ row.abbreviation }}
            </SHBadge>
          </td>
          <AppLink
            :to="{ name: 'CustomerDetail', params: { customerId: row.id } }"
          >
            {{ row.title }}
          </AppLink>
          <td v-if="breakpoints.includes('desktop')">
            {{ row.customer_agents_aggregate?.aggregate?.count }}
          </td>
          <td v-if="breakpoints.includes('desktop')">
            {{
              row.work_orders.reduce(
                (acc, wo) =>
                  acc + (wo?.tickets_aggregate?.aggregate?.count ?? 0),
                0
              )
            }}
          </td>
        </template>
        <template #footer>
          <SHTableRowCount :count="customerCount" label="customer" />
        </template>
      </SHTable>
    </template>
  </article>
</template>
