<script setup lang="ts">
import { useLogger } from "@/logger";
import * as Sentry from "@sentry/vue";
import { getCurrentInstance } from "vue";
const instance = getCurrentInstance();
// import router from "@/router";

const {
  VITE_SENTRY_DSN = window.config.SENTRY_DSN,
  VITE_ENV = window.config.ENV
} = import.meta.env;

const { log } = useLogger("SentryProvider"); // eslint-disable-line @typescript-eslint/no-unused-vars

Sentry.init({
  enabled: VITE_ENV !== "development",
  app: instance?.appContext.app,
  dsn: VITE_SENTRY_DSN,
  environment: VITE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/ies\.energy/,
        /^https:\/\/spearheadops\.com/
      ]
      // routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: VITE_ENV !== "development" ? 1 : 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: VITE_ENV !== "development" ? 1 : 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
</script>

<template>
  <section><slot /></section>
</template>
