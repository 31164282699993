<script setup lang="ts">
import { computed } from "vue";
import { dueDateOptions, type InvoiceForm } from "./types";
import SHDatePicker from "../SHDatePicker.vue";
import SHField from "../SHField.vue";
import SHInput from "../SHInput.vue";
import SHTextEditor from "../TextEditor/SHTextEditor.vue";
import { formatDate } from "@/formatters";
import { add, differenceInDays } from "date-fns";

const props = defineProps<{
  form: InvoiceForm["invoice"];
}>();

const emit = defineEmits<{
  (e: "update:invoiceForm", value: InvoiceForm["invoice"]): void;
}>();

const form = computed({
  get() {
    return props.form;
  },
  set(val) {
    emit("update:invoiceForm", val);
  }
});

const dueDate = computed({
  get() {
    return add(form.value.issueDate, { days: form.value.dueIn });
  },
  set(val) {
    console.log(val);
    form.value.dueIn = differenceInDays(new Date(val), form.value.issueDate);
  }
});
</script>

<template>
  <div class="invoice-meta">
    <SHField label="Subject">
      <SHInput v-model="form.subject" />
    </SHField>
    <SHField label="PO Number">
      <SHInput v-model="form.poNumber" />
    </SHField>
    <SHField label="Issue Date" block>
      <SHDatePicker v-model="form.issueDate" />
    </SHField>
    <SHField :label="`Due on ${formatDate(dueDate, 'MM-dd-yyyy')}`">
      <template v-for="o in dueDateOptions" :key="o.value">
        <label class="level tight">
          <input v-model="form.dueIn" type="radio" :value="o.value" />
          <span>{{ o.label }}</span>
        </label>
      </template>
    </SHField>
    <SHField class="invoice-notes" label="Notes">
      <SHTextEditor v-model="form.notesj" editable />
    </SHField>
  </div>
</template>

<style lang="scss" scoped>
.invoice-meta {
  display: grid;
  width: 100%;
  gap: 1em;

  grid-template-columns: 1fr 1fr;

  .invoice-notes {
    grid-column: 1 / -1;
  }
}
</style>
