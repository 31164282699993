<script setup lang="ts">
import { useLogPermissions } from "@/composables/useLogPermissions";
import { graphql, useFragment } from "@/generated";
import { Order_By } from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { CurrentTicketKey } from "@/providerKeys";
import {
  faArrowDownFromLine,
  faCommentDollar,
  faDigging,
  faPlus,
  faTruckContainer
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery, useSubscription } from "@urql/vue";
import { computed, ref } from "vue";

import ExpenseLogCard from "@/components/ActivityView/ExpenseLogCard.vue";
import FormEvent from "@/components/ActivityView/FormEvent.vue";
import ReviewEventCard from "@/components/ActivityView/ReviewEventCard.vue";
import TravelLogCard from "@/components/ActivityView/TravelLogCard.vue";
import WorkLogCard from "@/components/ActivityView/WorkLogCard.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import SHMenu from "@/components/SHMenu.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import TimeLine from "@/components/TimeLine.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import UserLink from "@/components/UserLink.vue";

const { filter = "", ticketId } = defineProps<{
  filter?: string;
  ticketId: string;
}>();

const fragment = graphql(/* GraphQL */ `
  fragment TicketActivity on ticket_activity {
    started_at
    ended_at

    author {
      id
      ...UserLink
    }

    work_log_id
    work_log {
      ...WorkLogCard
    }

    travel_log_id
    travel_log {
      ...TravelLogCard
    }

    expense_log_id
    expense_log {
      ...ExpenseLogCard
    }

    ticket_custom_form_entry_id
    ticket_custom_form_entry {
      ...FormEvent
    }

    ticket_review_event_id
    ticket_review_event {
      ticket_review_event_type
      ...ReviewEventCard
    }
  }
`);

const showRelativeDates = ref(false);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query TicketActivityView(
      $where: ticket_activity_bool_exp
      $order_by: [ticket_activity_order_by!]
    ) {
      # for showing the log
      ticket_activity(where: $where, order_by: $order_by) {
        ...TicketActivity
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        { ticket_id: { _eq: ticketId } },
        filter === "logs" ? { work_log_id: { _is_null: false } } : {},
        filter === "travel" ? { travel_log_id: { _is_null: false } } : {},
        filter === "expenses" ? { expense_log_id: { _is_null: false } } : {},
        filter === "reviews"
          ? { ticket_review_event_id: { _is_null: false } }
          : {}
      ]
    },
    order_by: [{ started_at: Order_By.Asc }, { ended_at: Order_By.Asc }]
  })),
  context: {
    additionalTypenames: [
      "ticket_activity",
      "work_logs",
      "travel_logs",
      "expense_logs",
      "ticket_review_events",
      "custom_form_entries"
    ]
  }
});

const { data: subscriptionData } = useSubscription(
  {
    query: graphql(/* GraphQL */ `
      subscription TicketActivityViewSubscription(
        $where: ticket_activity_bool_exp
        $order_by: [ticket_activity_order_by!]
      ) {
        ticket_activity(where: $where, order_by: $order_by) {
          ...TicketActivity
        }
      }
    `),
    variables: computed(() => ({
      where: {
        _and: [
          { ticket_id: { _eq: ticketId } },
          filter === "logs" ? { work_log_id: { _is_null: false } } : {},
          filter === "travel" ? { travel_log_id: { _is_null: false } } : {},
          filter === "expenses" ? { expense_log_id: { _is_null: false } } : {},
          filter === "reviews"
            ? { ticket_review_event_id: { _is_null: false } }
            : {}
        ]
      },
      order_by: [{ started_at: Order_By.Asc }, { ended_at: Order_By.Asc }]
    }))
  },
  (data, res) => {
    return res;
  }
);

const activityLogs = computed(() =>
  useFragment(
    fragment,
    subscriptionData.value?.ticket_activity ?? data.value?.ticket_activity ?? []
  )
);

const currentTicket = injectStrict(CurrentTicketKey);
const { canAddLogsOnTicket, canEditLog } = useLogPermissions(currentTicket);

const plusMenuItems = computed(() => [
  {
    id: "work",
    icon: faDigging,
    label: "Labor",
    to: { name: "WorkLogCreate", params: { ticketId } }
  },
  {
    id: "travel",
    icon: faTruckContainer,
    label: "Mileage",
    to: { name: "TravelLogCreate", params: { ticketId } }
  },
  {
    id: "expense",
    icon: faCommentDollar,
    label: "Expense",
    to: { name: "ExpenseLogCreate", params: { ticketId } }
  }
]);

const { executeMutation: deleteWorkLog } = useMutation(
  graphql(/* GraphQL */ `
    mutation DeleteWorkLogCard($workLogId: uuid!) {
      update_work_logs(
        where: { id: { _eq: $workLogId } }
        _set: { deleted_at: "now" }
      ) {
        returning {
          id
        }
      }
    }
  `)
);

const { executeMutation: deleteTravelLog } = useMutation(
  graphql(/* GraphQL */ `
    mutation DeleteTravelLogCard($travelLogId: uuid!) {
      update_travel_logs(
        where: { id: { _eq: $travelLogId } }
        _set: { deleted_at: "now" }
      ) {
        returning {
          id
        }
      }
    }
  `)
);

const { executeMutation: deleteExpenseLog } = useMutation(
  graphql(/* GraphQL */ `
    mutation DeleteExpenseLogCard($expenseLogId: uuid!) {
      update_expense_logs(
        where: { id: { _eq: $expenseLogId } }
        _set: { deleted_at: "now" }
      ) {
        returning {
          id
        }
      }
    }
  `)
);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>
  <article v-else class="ticket-activity-view">
    <nav class="level tight">
      <SHDropdown :disabled="!canAddLogsOnTicket" placement="right-start">
        <template #default="{ toggle }">
          <SHButton
            size="lg"
            color="primary"
            class="level"
            :disabled="!canAddLogsOnTicket"
            @click="toggle"
          >
            <FontAwesomeIcon :icon="faPlus" fixed-width />
          </SHButton>
        </template>

        <template #popup>
          <SHMenu :items="plusMenuItems" />
        </template>
      </SHDropdown>
    </nav>

    <main>
      <TimeLine>
        <!-- Manually placed timeline "event" for when this ticket was created -->
        <TimeLineEntry
          v-if="!filter"
          :relative="showRelativeDates"
          icon-fg-color="var(--color-surface-400)"
          icon-bg-color="var(--color-surface-100)"
          :start="currentTicket?.created_at"
        >
          <template #icon>
            <FontAwesomeIcon :icon="faArrowDownFromLine" fixed-width />
          </template>

          <UserLink :user="currentTicket?.author" link />
          created ticket.
        </TimeLineEntry>

        <TransitionGroup name="list">
          <template
            v-for="log of activityLogs"
            :key="
              log.work_log_id ||
              log.travel_log_id ||
              log.expense_log_id ||
              log.ticket_review_event_id ||
              log.ticket_custom_form_entry_id
            "
          >
            <WorkLogCard
              v-if="log.work_log"
              :relative="showRelativeDates"
              :work-log="log.work_log"
              :editable="canEditLog(log.author?.id)"
              @delete="deleteWorkLog({ workLogId: log.work_log_id! })"
            />
            <TravelLogCard
              v-else-if="log.travel_log"
              :travel-log="log.travel_log"
              :editable="canEditLog(log.author?.id)"
              @delete="deleteTravelLog({ travelLogId: log.travel_log_id! })"
            />
            <ExpenseLogCard
              v-else-if="log.expense_log"
              :expense-log="log.expense_log"
              :editable="canEditLog(log.author?.id)"
              @delete="deleteExpenseLog({ expenseLogId: log.expense_log_id! })"
            />
            <FormEvent
              v-else-if="log.ticket_custom_form_entry"
              :event="log.ticket_custom_form_entry"
            />
            <ReviewEventCard
              v-else-if="log.ticket_review_event"
              :reviewEvent="log.ticket_review_event"
            />
          </template>
        </TransitionGroup>
      </TimeLine>
    </main>
  </article>
</template>

<style lang="scss" scoped>
article.ticket-activity-view {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  nav {
    position: sticky;
    top: 0.875em;
    z-index: 101;
  }
  section.day-container {
    display: flex;
    flex-direction: column;
    position: relative;

    header {
      position: sticky;
      top: 0;
      color: var(--color-surface-400);
      background: var(--color-surface-50);
      font-weight: 300;
      font-size: 1.125em;
      padding-top: 0.875em;
      padding-bottom: 0.75em;
      text-align: right;
      z-index: 100;
    }

    main {
      display: flex;
      flex-direction: column;
      gap: 1.5em;

      .details {
        background: red;
        // display: inline-flex;
        // justify-content: flex-start;
        // align-items: baseline;
        // gap: 0.25em;
      }
    }
  }
}
</style>
