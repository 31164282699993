import { computed, type Ref, type MaybeRefOrGetter, toValue } from "vue";
import { Ticket_Statuses_Enum } from "@/generated/graphql";
import { useSubscription } from "@urql/vue";
import type {
  UseTicketAggregatesAllSubscription,
  UseTicketAggregatesByAgentSubscription
} from "@/generated/graphql";
import { graphql } from "@/generated";

const queryAll = graphql(/* GraphQL */ `
  subscription UseTicketAggregatesAll {
    totals: ticket_aggregates_all {
      ticket_status
      total
      calloff_total
      dedicated_total
    }
  }
`);

const queryByAgent = graphql(/* GraphQL */ `
  subscription UseTicketAggregatesByAgent {
    totals: ticket_aggregates_by_agent {
      ticket_status
      assigned_agent_id
      total
      calloff_total
      dedicated_total
    }
  }
`);

export type TicketTotalsByStatus = Partial<
  Record<Ticket_Statuses_Enum, number>
>;

export type TicketTotalsByType = {
  all: TicketTotalsByStatus;
  calloff: TicketTotalsByStatus;
  dedicated: TicketTotalsByStatus;
};

const buildResult = (
  data: Ref<
    | UseTicketAggregatesAllSubscription
    | UseTicketAggregatesByAgentSubscription
    | undefined
  >
) =>
  computed<TicketTotalsByType>(() => {
    const out: TicketTotalsByType = { all: {}, calloff: {}, dedicated: {} };
    Object.values(Ticket_Statuses_Enum).forEach(v => {
      out.all[v] =
        (data.value?.totals || []).find(t => v === t.ticket_status)?.total ?? 0;
    });
    Object.values(Ticket_Statuses_Enum).forEach(v => {
      out.calloff[v] =
        (data.value?.totals || []).find(t => v === t.ticket_status)
          ?.calloff_total ?? 0;
    });
    Object.values(Ticket_Statuses_Enum).forEach(v => {
      out.dedicated[v] =
        (data.value?.totals || []).find(t => v === t.ticket_status)
          ?.dedicated_total ?? 0;
    });
    // console.log("computed table is now", out);
    return out;
  });

export const useTicketAggregates = (viewAll: MaybeRefOrGetter<boolean>) => {
  const { data: dataAll, error: errorAll } =
    useSubscription<UseTicketAggregatesAllSubscription>({
      query: queryAll,
      context: { additionalTypenames: ["tickets"] },
      pause: computed(() => !toValue(viewAll))
    });
  const { data: dataByAgent, error: errorByAgent } =
    useSubscription<UseTicketAggregatesByAgentSubscription>({
      query: queryByAgent,
      context: { additionalTypenames: ["tickets"] },
      pause: computed(() => toValue(viewAll))
    });

  const data = computed(() =>
    toValue(viewAll) ? dataAll.value : dataByAgent.value
  );

  const result = buildResult(data);
  return {
    data,
    error: computed(() =>
      toValue(viewAll) ? errorAll.value : errorByAgent.value
    ),
    totalInReview: computed(() => result.value.all.IN_REVIEW),
    totalClosed: computed(() => result.value.all.CLOSED),
    totalActiveTickets: computed(() => {
      return (result.value.all.OPEN ?? 0) + (result.value.all.IN_PROGRESS ?? 0);
    }),
    callOffInReview: computed(() => result.value.calloff.IN_REVIEW),
    callOffClosed: computed(() => result.value.calloff.CLOSED),
    callOffActiveTickets: computed(() => {
      return (
        (result.value.calloff.OPEN ?? 0) +
        (result.value.calloff.IN_PROGRESS ?? 0)
      );
    }),
    dedicatedInReview: computed(() => result.value.dedicated.IN_REVIEW),
    dedicatedClosed: computed(() => result.value.dedicated.CLOSED),
    dedicatedActiveTickets: computed(() => {
      return (
        (result.value.dedicated.OPEN ?? 0) +
        (result.value.dedicated.IN_PROGRESS ?? 0)
      );
    })
  };
};
