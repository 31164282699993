<script async setup lang="ts">
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHField from "@/components/SHField.vue";
import SHIntervalDiff from "@/components/SHIntervalDiff.vue";
import SHTimeSlider from "@/components/SHTimeInput/SHTimeSlider.vue";
import TicketLink from "@/components/TicketLink.vue";
import UserLink from "@/components/UserLink.vue";
import type { AgentShiftSuggestion } from "@/composables/useShiftSuggestions";
import { useToaster } from "@/composables/useToaster";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { DisableFormKey } from "@/providerKeys";
import { format, isSameDay, startOfDay } from "date-fns";

const { log } = useLogger("AgentShiftsView"); // eslint-disable-line @typescript-eslint/no-unused-vars
const { createToast } = useToaster();

defineProps<{
  suggestion: AgentShiftSuggestion;
}>();

const emit = defineEmits<{
  (e: "accept", suggestion: AgentShiftSuggestion): void;
}>();

const formDisabled = injectStrict(DisableFormKey);

async function handleAcceptSuggestion(suggestion: AgentShiftSuggestion) {
  log("Accepting suggestion", suggestion);
  formDisabled.value = true;

  const { error } = await suggestion.execute();

  if (error) {
    createToast({
      title: "Unable to update the shift.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else {
    createToast({
      message: "Shift updated.",
      theme: "success"
    });
  }

  emit("accept", suggestion);
  formDisabled.value = false;
}
</script>

<template>
  <article class="suggestion-card">
    <header class="vertical tight">
      <div class="level-spread">
        <div class="level tight">
          <h3>
            {{ format(new Date(suggestion.newInterval.start), "EEEE, MMM d") }}
          </h3>
          <UserLink v-if="suggestion.agent" :user="suggestion.agent" />
        </div>
        <slot name="top-right">
          <SHBadge :color="'var(--color-secondary)'">
            {{ suggestion.action === "create" ? "Create" : "Adjust" }}
          </SHBadge>
        </slot>
      </div>
      <p>
        Found {{ suggestion.logs.length }}
        {{ suggestion.logs.length !== 1 ? "logs that are" : "log" }}
        <span v-if="suggestion.action === 'create'">
          not assigned to a shift. Would you like to create a new shift
        </span>
        <span
          v-else-if="['append', 'extend_right'].includes(suggestion.action)"
        >
          not captured by the existing shift. Would you like to extend the shift
          to include these logs
        </span>
        <span
          v-else-if="['prepend', 'extend_left'].includes(suggestion.action)"
        >
          not captured by the existing shift. Would you like to extend the shift
          to include these logs
        </span>
        <span>?</span>
      </p>
    </header>
    <section>
      <div class="level-spread loose wrap">
        <SHField label="Proposed changes">
          <SHIntervalDiff
            :left="suggestion.existingInterval"
            :right="suggestion.newInterval"
          />
        </SHField>
        <SHField label="Affected Tickets">
          <div class="level tight wrap">
            <template
              v-for="ticket in suggestion.logs
                .map(l => l.ticket)
                .filter(
                  (t, i, arr) => arr.findIndex(t2 => t2?.id === t?.id) === i
                )"
              :key="ticket!.id"
            >
              <TicketLink
                v-if="ticket"
                style="--background-color: var(--color-surface-300)"
                :ticket="ticket"
              />
            </template>
          </div>
        </SHField>
      </div>
    </section>
    <footer class="agent-shift-row">
      <div class="vertical">
        <SHTimeSlider
          readonly
          compact
          :model-value="startOfDay(suggestion.newInterval.start)"
          :ranges="suggestion.ranges"
          :keyTimes="suggestion.keyTimes"
        />
        <template
          v-if="
            !isSameDay(suggestion.newInterval.start, suggestion.newInterval.end)
          "
        >
          <SHField
            block
            :label="`New Shift End: ${format(
              suggestion.newInterval.end,
              'MMM d, yyyy h:mm a'
            )}`"
          >
            <SHTimeSlider
              readonly
              compact
              :model-value="startOfDay(suggestion.newInterval.end)"
              :ranges="suggestion.ranges"
              :keyTimes="suggestion.keyTimes"
            />
          </SHField>
        </template>
      </div>
      <div class="level-end">
        <SHButton
          class="suggestion-btn"
          color="primary"
          @click="handleAcceptSuggestion(suggestion)"
        >
          Accept
        </SHButton>
      </div>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.suggestion-card {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--color-surface-300);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  .suggestion-btn {
    min-width: 12ch;
  }

  > section,
  > footer,
  > header {
    padding: 1em;
  }

  > header {
    background-color: var(--color-surface-100);
    border-bottom: thin solid var(--color-surface-300);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  > footer {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .agent-shift-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
  }
}
</style>
