import { CurrentUserKey, RoleKey } from "./../providerKeys";
import { useLogger } from "@/logger";
import { computed, inject } from "vue";
import hasuraRoles from "@hasura/metadata/inherited_roles.yaml";

const { log } = useLogger("useRole"); // eslint-disable-line @typescript-eslint/no-unused-vars

export const useRole = () => {
  const role = inject(RoleKey, null); // This role is provided in the AuthenticationProvider
  const currentUser = inject(CurrentUserKey, null);

  return {
    roleName: role,
    currentUser: currentUser,
    allowedRoles: computed(() => currentUser?.value?.allowed_roles),
    permissions: computed(() =>
      (
        hasuraRoles.find(
          (r: { role_name: string }) => r.role_name === role?.value
        )?.role_set || []
      ).sort((a: string, b: string) => a > b)
    ),
    can(perm: (typeof hasuraRoles)[number]["role_set"][number]): boolean {
      const currentRole = hasuraRoles.find(
        (r: { role_name: string }) => r.role_name === role?.value
      );
      if (!currentRole) {
        log("unknown role: ", role?.value);
      } else if (currentRole) {
        return currentRole.role_set.includes(perm);
      }
      return false;
    }
  };
};
