import { humanizeEnum } from "@/lib/helpers";
import { computed } from "vue";

export function useEnumOptions<T extends object>(options: T) {
  return computed(() =>
    Object.values(options).map(val => ({
      id: val,
      label: humanizeEnum(val)
    }))
  );
}
