import SHUploadQueue from "@/components/SHUploadQueue.vue";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { ClientKey, DisableFormKey } from "@/providerKeys";
import type { MediaUploadContext, UploadedMedia } from "@/types";
import type { Client } from "@urql/core";
import { fileTypeFromBuffer } from "file-type";
import { computed, h, reactive, ref, watch, type Ref } from "vue";
import { usePreventNavigation } from "./usePreventNavigation";
import { useToaster } from "./useToaster";
const { log, error } = useLogger("useUpload"); // eslint-disable-line @typescript-eslint/no-unused-vars

/**
 * Make a request to Hasura Action to get a POST Policy
 * From AWS
 *
 * https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-HTTPPOSTConstructPolicy.html
 */
const getUploadPolicy = async (
  client: Client,
  path: string,
  key: string,
  uploadId?: string
) => {
  const { data: policyData } = await client.query(
    graphql(/* GraphQL */ `
      query GetUploadPolicy($path: String!, $key: uuid!, $uploadId: String) {
        getSignedS3PostPolicy(path: $path, key: $key, uploadId: $uploadId) {
          url
          fields
        }
      }
    `),
    {
      path,
      key,
      uploadId
    }
  );

  const policy = policyData?.getSignedS3PostPolicy;

  if (!policy) {
    throw new ReferenceError("S3 Policy is null or undefined");
  }
  return policy;
};

const startMultiPartUpload = async (
  client: Client,
  path: string,
  key: string
) => {
  const { data: uploadData } = await client.query(
    graphql(/* GraphQL */ `
      query beginS3MultiPartUpload($path: String!, $key: uuid!) {
        beginS3MultiPartUpload(path: $path, id: $key) {
          statusCode
          serverSideEncryption
          bucket
          key
          uploadId
        }
      }
    `),
    {
      path,
      key
    }
  );

  const upload = uploadData?.beginS3MultiPartUpload;

  if (!upload) {
    throw new ReferenceError("S3 Policy is null or undefined");
  }
  return upload;
};

export type MediaUploadTask = {
  id: string;
  file: File;
  content_type: string;
  s3url: string;
  xhr: XMLHttpRequest;
  progress: number;
  bytesUploaded: number;
  uploadStart: number;
  start: () => void;
  abort: () => void;
  caption?: string;
};
export type MediaObjectOrUploadTask = MediaUploadTask | UploadedMedia;

const activeUploads: Ref<MediaUploadTask[]> = ref([]);

export function useUploadQueue() {
  log("useUploadQueue invoked");
  const formsDisabled = injectStrict(DisableFormKey);
  const { createToast } = useToaster();
  const hasActiveUploads = computed(() => activeUploads.value.length > 0);

  let queueToast: ReturnType<typeof createToast> | null = null;

  // Create and remove toast when active uploads change
  watch(
    hasActiveUploads,
    val => {
      if (val) {
        queueToast = createToast({
          theme: "loading",
          title: `Uploading files`,
          message: "Leaving this page will stop the upload(s).",
          requiresInteraction: true,
          notClosable: true,
          component: () =>
            h(SHUploadQueue, { activeUploads: activeUploads.value })
        });
      } else {
        queueToast?.close();
      }
    },
    { immediate: true }
  );

  watch(hasActiveUploads, hasActive => {
    log("hasActiveUploads changed", hasActive);

    if (hasActive) {
      formsDisabled.value = true;
      return;
    }

    if (!isNavigatingAway)
      createToast({
        message: "All uploads completed.",
        theme: "success",
        lifetimeMS: 5000
      });
    formsDisabled.value = false;
  });

  let isNavigatingAway = false;

  usePreventNavigation(
    "This will stop all existing uploads. Are you sure?",
    hasActiveUploads,
    isLeaving => {
      if (!isLeaving) return;
      isNavigatingAway = true;
      queueToast?.close();
      activeUploads.value.forEach(u => u.abort?.());
      activeUploads.value.length = 0;
    }
  );

  return {
    activeUploads
  };
}

export default function useUpload() {
  const client = injectStrict(ClientKey);

  async function uploadStream(
    file: File,
    id: string,
    path: string,
    onComplete: (upload: UploadedMedia) => void,
    context?: MediaUploadContext
  ) {
    const fileExtension = file.name.split(".").pop();
    const fileName = `${id}.${fileExtension}`;

    let mimeType = file.type;
    try {
      const fileBuffer = await file.arrayBuffer();
      const fileType = await fileTypeFromBuffer(fileBuffer);

      if (fileType) {
        mimeType = fileType.mime;
      }
    } catch (err) {
      error("Error getting file type", err);
    }

    const upload = await startMultiPartUpload(client, path, fileName);

    if (!upload.uploadId) {
      activeUploads.value = activeUploads.value.filter(q => q.id === id);
      throw new Error("Unable to start multipart upload");
    }
    log("got multipart upload", upload);

    const policy = await getUploadPolicy(
      client,
      path,
      fileName,
      upload.uploadId
    );
    log("policy", policy);

    const formData = new FormData();
    Object.entries(policy.fields as Record<string, string>).forEach(
      ([key, value]) => {
        formData.append(key, value);
      }
    );
    formData.append("file", file);

    // const chunkSize = 1024 * 128;
    const item: MediaUploadTask = reactive({
      id,
      file,
      content_type: mimeType,
      s3url: "",
      xhr: new XMLHttpRequest(),
      progress: 0,
      bytesUploaded: 0,
      uploadStart: Date.now(),
      remainingTime: 0,
      start: () => xhr.send(formData),
      abort: () => {
        activeUploads.value = activeUploads.value.filter(u => u.id !== id);
        xhr.abort();
      }
    });
    const xhr = item.xhr;
    xhr.open("POST", policy.url, true);
    xhr.onloadend = async ev => {
      if (xhr.status === 0) {
        log("ignoring aborted request");
        return;
      }
      log("onloadend", ev);
      item.s3url = `${policy.url}${policy.fields.key}`;
      const { data: mediaUpload, error } = await client.mutation(
        graphql(/* GraphQL */ `
          mutation InsertMediaUpload_Stream(
            $mediaUpload: media_uploads_insert_input!
          ) {
            insert_media_uploads_one(object: $mediaUpload) {
              id
              caption
              ...MediaUploadCard
              ...SHLightbox
            }
          }
        `),
        {
          mediaUpload: {
            id: id,
            url: item.s3url,
            content_size: file.size,
            content_type: mimeType,
            orig_filename: file.name,
            ...context
          }
        }
      );
      if (activeUploads.value.every(q => q.progress >= 1))
        activeUploads.value.length = 0;
      if (error) {
        log("error binding:", error);
        throw new Error("couldn't bind media upload.");
      } else if (mediaUpload?.insert_media_uploads_one) {
        onComplete(mediaUpload.insert_media_uploads_one);
      }
    };

    xhr.upload.onprogress = ev => {
      item.progress = ev.loaded / ev.total;
      item.bytesUploaded = ev.loaded;
    };
    xhr.upload.onerror = ev => {
      error("error uploading to S3", ev);
    };

    activeUploads.value.push(item);
    return item;
  }

  return {
    uploadStream
  };
}

export type UploadResult = Awaited<
  ReturnType<ReturnType<typeof useUpload>["uploadStream"]>
>;
