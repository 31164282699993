<script setup lang="ts">
import QueryFilter from "@/components/QueryFilter.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { useFacets } from "@/composables/useFacets";
import type { OrderSet } from "@/composables/useQueryStringFilters";
import { graphql } from "@/generated";
import { Order_By, type Catalogs_Order_By } from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { faBox } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

const filterViewMode = ref<CollectionView>(CollectionView.Table);

const orderByFilters: OrderSet<Catalogs_Order_By> = {
  defaultValue: "name",
  defaultOrderBy: Order_By.Asc,
  options: [
    {
      id: "name",
      title: "Name/Title",
      asc: [{ title: Order_By.Asc }],
      desc: [{ title: Order_By.Desc }]
    },
    {
      id: "author",
      title: "Author",
      asc: [{ author: { lname: Order_By.Asc } }],
      desc: [{ author: { lname: Order_By.Desc } }]
    },
    {
      id: "created",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "updated",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const {
  facetModels,
  resetFacets,
  searchString,
  sortOrder,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets([], orderByFilters);

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query CatalogIndex(
      $where: catalogs_bool_exp!
      $orderBy: [catalogs_order_by!]
    ) {
      catalogs(where: $where, order_by: $orderBy) {
        id
        title
        author {
          full_name
        }
        products_aggregate {
          aggregate {
            count
          }
        }
      }
      catalogs_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      deleted_at: { _is_null: true },
      title: { _ilike: `%${searchString.value}%` }
    },
    orderBy: sortOrder.value
  })),
  context: { additionalTypenames: ["catalogs"] }
});

const catalogs = computed(() => data.value?.catalogs ?? []);
const catalogCount = computed(
  () => data.value?.catalogs_aggregate.aggregate?.count || 0
);
</script>

<template>
  <article class="vertical xmargin">
    <QueryFilter
      :view-mode="filterViewMode"
      :facets="[]"
      :facet-models="facetModels"
      :order-set="orderByFilters"
      :fetching="fetching"
      :result-count="catalogCount"
      clear-all-filters
      no-calendar-view
      no-card-view
      no-table-view
      searchable
      @reset:facets="resetFacets"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Catalogs Filter</template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <template v-else>
      <SHTable
        :rows="catalogs"
        clickable
        empty-message="No catalogs found."
        @row:click="
          $router.push({
            name: 'CatalogDetail',
            params: { catalogId: $event.id }
          })
        "
      >
        <template #header>
          <th>Name / Title</th>
          <th>Products</th>
        </template>

        <template v-if="!error" #row="{ row }">
          <td>
            {{ row.title }}
          </td>
          <td style="width: 6em; vertical-align: middle">
            <div class="level-end">
              <SHBadge>
                <div class="level tight number">
                  <FontAwesomeIcon :icon="faBox" />
                  <span>
                    {{ row.products_aggregate.aggregate?.count || 0 }}
                  </span>
                </div>
              </SHBadge>
            </div>
          </td>
        </template>

        <template #footer>
          <SHTableRowCount :count="catalogCount" label="catalog" />
        </template>
      </SHTable>
    </template>
  </article>
</template>
