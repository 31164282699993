import { useLogger } from "@/logger";

const { log } = useLogger("useCSV"); // eslint-disable-line @typescript-eslint/no-unused-vars

const escape = (thing: any) => `${thing}`.replace(/"/g, '""'); // eslint-disable-line @typescript-eslint/no-explicit-any

export const useCSV = () => {
  return {
    escape,
    download: (content: string, filename = "spearhead-export.csv") => {
      const blob = new Blob([content], { type: "text/csv;charset=utf-8," });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    generate: (headers: string[], rows: string[][]) =>
      rows.reduce(
        (prev, cur) => {
          prev += cur.map(item => `"${escape(item)}"`).join(",") + "\n";
          return prev;
        },
        headers.map(item => `"${escape(item)}"`).join(",") + "\n"
      )
  };
};
