import type { ActivityPoint } from "@/components/charts/LogActivityChart.vue";
import { formatDate } from "@/formatters";
import { useLogger } from "@/logger";
import { differenceInMinutes, isAfter, parseISO, startOfDay } from "date-fns";
import { computed, type MaybeRefOrGetter, toValue } from "vue";

const { log } = useLogger("useDDV"); // eslint-disable-line @typescript-eslint/no-unused-vars

export const useDDVActivitySeries = (
  points: MaybeRefOrGetter<
    | {
        work_log_id: string | null | undefined;
        travel_log_id: string | null | undefined;
        started_at: string | null | undefined;
        ended_at: string | null | undefined;
        ticket_id: string | null | undefined;
      }[]
    | undefined
  >
) => {
  const series = computed<ActivityPoint[]>(() => {
    const days = (toValue(points) || []).reduce(
      (prev: Record<string, ActivityPoint>, cur) => {
        if (!cur.started_at || !cur.ended_at) return prev;
        const [started, ended] = [
          parseISO(cur.started_at),
          parseISO(cur.ended_at)
        ];
        const hours = differenceInMinutes(ended, started) / 60;
        const day = startOfDay(started);
        const dayKey = formatDate(day, "yyyy-MM-dd");
        // log("day", dayKey, hours);

        if (prev[dayKey] === undefined) {
          prev[dayKey] = {
            day,
            travel_hours: 0,
            work_hours: 0,
            ticket_ids: []
          };
        }
        if (
          cur?.ticket_id &&
          !prev[dayKey].ticket_ids.includes(cur.ticket_id)
        ) {
          prev[dayKey].ticket_ids.push(cur.ticket_id);
        }

        if (cur?.work_log_id !== null) {
          prev[dayKey].work_hours += hours;
        } else if (cur?.travel_log_id !== null) {
          prev[dayKey].travel_hours += hours;
        }
        return prev;
      },
      {}
    );
    // console.log("days", days);
    return Object.values(days).sort((a, b) => (isAfter(a.day, b.day) ? 1 : -1));
  });

  return {
    series
  };
};
