import type { Client, TypedDocumentNode } from "@urql/vue";

import type { Exact } from "@/generated/graphql";
import type { Editor, Node, Range } from "@tiptap/core";
import { Extension } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import { Suggestion, type SuggestionOptions } from "@tiptap/suggestion";
import { VueRenderer } from "@tiptap/vue-3";

import tippy from "tippy.js";

import CommandsList from "../CommandsList.vue";

type Props = {
  title?: string;
  command: (props: { editor: Editor; range: Range }) => void;
};

export default function defineMention<
  Q extends TypedDocumentNode<
    { items: Array<T> },
    Exact<{
      searchText: string;
    }>
  >,
  T = Q extends TypedDocumentNode<
    { items: Array<infer I>; [key: string]: any },
    any
  >
    ? I
    : never
>({
  char,
  query,
  getLabel,
  getAttrs,
  node
}: {
  char: string;
  query: Q;
  getLabel: (i: T) => string;
  getAttrs: (i: T) => Record<string, unknown>;
  node: Node;
}): (client: Client) => Extension {
  return client =>
    Extension.create<{ suggestion: Partial<SuggestionOptions<Props>> }>({
      name: char,

      addOptions() {
        return {
          suggestion: {
            char: char,
            command: ({ editor, range, props }) => {
              props.command({ editor, range });
            }
          }
        };
      },

      addProseMirrorPlugins() {
        return [
          Suggestion({
            editor: this.editor,
            ...this.options.suggestion
          })
        ];
      },

      addExtensions() {
        return [node];
      }
    }).configure({
      suggestion: {
        pluginKey: new PluginKey(char),
        char: char,

        command: ({ editor, range, props }) => {
          props.command({ editor, range });
        },

        items: async ({ query: searchText }) => {
          const { data } = await client.query(query, {
            searchText: `%${searchText}%`
          });

          const items = data?.items ?? [];

          return items.map(i => ({
            title: getLabel(i),
            command: ({ editor, range }) => {
              const nodeAfter = editor.view.state.selection.$to.nodeAfter;
              const overrideSpace = nodeAfter?.text?.startsWith(" ");

              if (overrideSpace) {
                range.to += 1;
              }

              editor
                .chain()
                .focus()
                .insertContentAt(range, [
                  {
                    type: node.name,
                    attrs: getAttrs(i)
                  }
                ])
                .run();

              window.getSelection()?.collapseToEnd();
            }
          }));
        },

        render: () => {
          let component: VueRenderer;
          let popup: ReturnType<typeof tippy>;

          return {
            onStart: props => {
              component = new VueRenderer(CommandsList, {
                props: {
                  command: props.command,
                  placeholder: "User not found",
                  items: props.items,
                  getLabel(item: { title: string }) {
                    return item.title;
                  }
                },
                editor: props.editor
              });

              popup = tippy("body", {
                appendTo: () => document.body,
                getReferenceClientRect: props.clientRect as () => DOMRect,
                content: component.element as Element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "right-start",
                offset: [0, 5]
              });
            },

            onUpdate(props) {
              component.updateProps(props);

              popup.at(0)?.setProps({
                getReferenceClientRect: props.clientRect as () => DOMRect
              });
            },

            onKeyDown(props) {
              if (props.event.key === "Escape") {
                popup[0].hide();

                return true;
              }

              return component.ref?.onKeyDown(props);
            },

            onExit() {
              popup[0].destroy();
              component.destroy();
            }
          };
        }
      }
    });
}
