import { ref } from "vue";

export function useHubSpotChat() {
  const loading = ref(false);
  const open = () => {
    if (!window.HubSpotConversations) {
      throw new Error("HubSpot Conversations not found.");
    }

    loading.value = true;
    const intervalId = setInterval(() => {
      const status = window.HubSpotConversations?.widget.status();
      if (status.loaded) {
        window.HubSpotConversations?.widget.open();
        loading.value = false;
        clearInterval(intervalId);
      }
    }, 100);

    const status = window.HubSpotConversations.widget.status();

    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.HubSpotConversations.widget.load();
    }
  };

  return { open, loading };
}
