<script async setup lang="ts">
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import { faPlus } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

import CustomerLink from "@/components/Customer/CustomerLink.vue";
import QueryFilter from "@/components/QueryFilter.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import TicketCard from "@/components/TicketCard.vue";
import ToBeDetermined from "@/components/ToBeDetermined.vue";
import UserLink from "@/components/UserLink.vue";
import { useFacets } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { useRole } from "@/composables/useRole";
import { formatDateRelative } from "@/formatters";
import {
  Lifecycle_Statuses_Enum,
  Order_By,
  Review_Statuses_Enum,
  Ticket_Statuses_Enum,
  Work_Order_Types_Enum,
  type Tickets_Bool_Exp,
  type Tickets_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { DATE_FILTER_PRESETS, type DateRangePreset } from "@/lib/datetime";
import { injectStrict } from "@/lib/helpers";
import { BreakPointsKey, CurrentUserKey } from "@/providerKeys";

const { can, roleName } = useRole();
const { log } = useLogger("TicketsView"); // eslint-disable-line @typescript-eslint/no-unused-vars
const currentUser = injectStrict(CurrentUserKey);

const {
  workOrderId = "",
  customerId = "",
  workSiteId = "",
  agentId = "",
  showAddTicket = true
} = defineProps<{
  customerId?: string; // when nested under a customer
  workOrderId?: string; // when nested under a work order
  workSiteId?: string; // when nested under a work site
  agentId?: string; // when nested under an agent
  noMargin?: boolean; // turn this on when nesting the route
  showAddTicket?: boolean; // if set, an add ticket button will link there
}>();

const breakpoints = injectStrict(BreakPointsKey);
const filterViewMode = ref<CollectionView>(CollectionView.Card);

const buildDatePredicates = (dates: [Date, Date]) => {
  return {
    _or: [
      { created_at: { _gte: dates[0], _lte: dates[1] } },
      { manually_scheduled_at: { _gte: dates[0], _lte: dates[1] } },
      { updated_at: { _gte: dates[0], _lte: dates[1] } },
      {
        work_logs: {
          created_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          ended_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          updated_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          started_at: {
            _gte: dates[0],
            _lte: dates[1]
          }
        }
      },
      {
        expense_logs: {
          created_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          expensed_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          updated_at: {
            _gte: dates[0],
            _lte: dates[1]
          }
        }
      },
      {
        travel_logs: {
          arrived_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          created_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          departed_at: {
            _gte: dates[0],
            _lte: dates[1]
          },
          updated_at: {
            _gte: dates[0],
            _lte: dates[1]
          }
        }
      }
    ]
  };
};

const { data: optionsData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TicketViewOptions($currentUserId: String!) {
      customers(order_by: { title: asc }) {
        id
        title
      }
      products(order_by: { title: asc }) {
        id
        title
      }
      organization_agents(
        where: { agent_id: { _neq: $currentUserId } }
        order_by: { agent: { full_name: asc } }
      ) {
        agent {
          full_name
          id
        }
      }
    }
  `),
  variables: { currentUserId: currentUser.value.id }
});

const TicketsViewQuery = graphql(/* GraphQL */ `
  query TicketsView(
    $where: tickets_bool_exp!
    $order_by: [tickets_order_by!]
    $limit: Int!
    $offset: Int!
  ) {
    tickets(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      ref
      ticket_status
      review_status
      created_at
      deleted_at
      manually_scheduled_at
      updated_at

      author {
        id
        ...UserLink
      }

      product {
        title
        product_type
      }

      work_site {
        title
      }

      ...TicketCard

      work_order {
        work_order_type
        service_date_start
        customer {
          ...CustomerLink
        }
        customer {
          customer_agents {
            agent_id
          }
        }
      }
    }
    tickets_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

const { data: agentsData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query GetCustomerAgentsByWorkOrderId(
      $work_order_id: uuid!
      $user_id: String!
    ) {
      work_orders_by_pk(id: $work_order_id) {
        id
        customer {
          customer_agents(where: { agent_id: { _eq: $user_id } }) {
            agent {
              id
              full_name
            }
          }
        }
      }
    }
  `),
  variables: {
    work_order_id: workOrderId,
    user_id: currentUser.value.id
  },
  pause: computed(() => !currentUser.value || !workOrderId)
});

const addTicketRouteLocation = computed(() => {
  if (workOrderId) {
    return { name: "WorkOrderTicketCreate" };
  } else if (customerId) {
    return { name: "CustomerTicketCreate" };
  } else {
    return { name: "TicketCreate" };
  }
});

const { data: workOrderStatusData } = useQuery({
  query: graphql(/* GraphQL */ `
    query TicketViewWorkOrderStatus($id: uuid!) {
      work_orders_by_pk(id: $id) {
        ref
        id
        lifecycle_status
      }
    }
  `),
  variables: { id: workOrderId },
  pause: computed(() => !workOrderId)
});

const workOrderCanBeModified = computed(() => {
  return (
    workOrderStatusData.value?.work_orders_by_pk?.lifecycle_status &&
    [
      Lifecycle_Statuses_Enum.Open,
      Lifecycle_Statuses_Enum.Pending,
      Lifecycle_Statuses_Enum.Expiring
    ].includes(workOrderStatusData.value.work_orders_by_pk.lifecycle_status)
  );
});

const canAddTicket = computed(
  () =>
    !workOrderId ||
    (workOrderCanBeModified.value && userIsDedicatedToThisCustomer.value)
);

const agents = computed(() => optionsData.value?.organization_agents ?? []);
const customers = computed(() => optionsData.value?.customers ?? []);
const products = computed(() => optionsData.value?.products ?? []);
const tickets = computed(() => data.value?.tickets);
const ticketCount = computed(
  () => data.value?.tickets_aggregate?.aggregate?.count ?? 0
);
const userIsDedicatedToThisCustomer = computed(
  () => agentsData.value?.work_orders_by_pk?.customer.customer_agents.length
);

const assigneeFilters: Facet<Tickets_Bool_Exp> = {
  modelName: "assignee",
  hidden: !!agentId,
  label: "Assignee",
  defaultValue:
    !workSiteId && ["agent"].includes(roleName?.value ?? "unknown role")
      ? "myself"
      : "anyone",
  hideOnMobile: true,
  filters: [
    {
      id: "myself",
      title: "Myself",
      predicate: {
        ticket_agents: { agent_id: { _eq: currentUser.value.id } }
      }
    },
    {
      id: "anyone",
      title: "Anyone",
      predicate: {}
    },
    ...agents.value.map(oa => ({
      id: oa.agent.id,
      title: `${oa.agent.full_name}`,
      predicate: {
        ticket_agents: { agent_id: { _eq: oa.agent.id } }
      }
    }))
  ]
};

const authorFilters: Facet<Tickets_Bool_Exp> = {
  modelName: "authors",
  hideOnMobile: true,
  label: "Author",
  defaultValue: "all",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...agents.value.map(oa => ({
      id: oa.agent.id,
      title: `${oa.agent.full_name} `,
      predicate: {
        author: { id: { _eq: oa.agent.id } }
      }
    }))
  ]
};

const customerFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "all",
  hidden: customerId !== "",
  hideOnMobile: true,
  label: "Customer",
  modelName: "customers",
  fuseOptions: {
    keys: ["title", "abbreviation"],
    threshold: 0.5
  },
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...customers.value.map(cust => {
      return {
        id: cust.id,
        title: cust.title,
        predicate: {
          work_order: { customer: { id: { _eq: cust.id } } }
        }
      };
    })
  ]
};

const dateRangeFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "all",
  hideOnMobile: true,
  label: "Dates",
  modelName: "dateRange",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...DATE_FILTER_PRESETS.map((preset: DateRangePreset) => ({
      id: preset.id,
      title: preset.title,
      predicate: buildDatePredicates(preset.dates)
    }))
  ]
};

const orderByFilters: OrderSet<Tickets_Order_By> = {
  defaultValue: "updated",
  options: [
    {
      id: "ref",
      title: "Ticket ID",
      asc: [{ ref: Order_By.Asc }],
      desc: [{ ref: Order_By.Desc }]
    },
    {
      id: "created",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "updated",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const reviewStatusFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "all",
  label: "Review Status",
  modelName: "reviewStatus",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: { review_status: { _is_null: false } }
    },
    {
      id: "requested",
      title: "Requested",
      predicate: { review_status: { _eq: Review_Statuses_Enum.Requested } }
    },
    {
      id: "approved",
      title: "Approved",
      predicate: { review_status: { _eq: Review_Statuses_Enum.Accepted } }
    },
    {
      id: "rejected",
      title: "Rejected",
      predicate: { review_status: { _eq: Review_Statuses_Enum.Rejected } }
    }
  ]
};

const serviceTypeFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "all",
  hideOnMobile: true,
  label: "Service Type",
  modelName: "serviceType",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...products.value.map(prod => {
      return {
        id: prod.id,
        title: prod.title,
        predicate: {
          product: { id: { _eq: prod.id } }
        }
      };
    })
  ]
};

const TBDFilters: Facet<Tickets_Bool_Exp> = {
  modelName: "tbd",
  label: "Uses TBD Worksite",
  defaultValue: "all",
  hidden: !!workSiteId,
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    {
      id: "ticket",
      title: "TBD in Ticket",
      predicate: {
        deleted_at: { _is_null: true },
        work_site_unconfirmed: { _is_null: false }
      }
    },
    {
      id: "travel_logs",
      title: "TBD in Travel Log",
      predicate: {
        travel_logs: {
          _and: [
            { deleted_at: { _is_null: true } },
            {
              _or: [
                {
                  to_work_site_unconfirmed: { _is_null: false }
                },
                {
                  from_work_site_unconfirmed: { _is_null: false }
                }
              ]
            }
          ]
        }
      }
    }
  ]
};

const ticketArchiveFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "active",
  label: "Archived",
  modelName: "archived",
  filters: [
    {
      id: "active",
      title: "Active",
      predicate: { deleted_at: { _is_null: true } }
    },
    {
      id: "archived",
      title: "Archived",
      predicate: { deleted_at: { _is_null: false } }
    },
    {
      id: "all",
      title: "All",
      predicate: { ref: { _is_null: false } }
    }
  ]
};

const ticketStatusFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "all",
  label: "Status",
  modelName: "ticketStatus",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    {
      id: "active",
      title: "Active",
      predicate: {
        ticket_status: {
          _in: [Ticket_Statuses_Enum.Open, Ticket_Statuses_Enum.InProgress]
        }
      }
    },
    {
      id: "open",
      title: "Open",
      predicate: { ticket_status: { _eq: Ticket_Statuses_Enum.Open } }
    },
    {
      id: "inprogress",
      title: "In Progress",
      predicate: { ticket_status: { _eq: Ticket_Statuses_Enum.InProgress } }
    },
    {
      id: "review",
      title: "In Review",
      predicate: { ticket_status: { _eq: Ticket_Statuses_Enum.InReview } }
    },
    {
      id: "closed",
      title: "Closed",
      predicate: { ticket_status: { _eq: Ticket_Statuses_Enum.Closed } }
    }
  ]
};

const ticketTypeFilters: Facet<Tickets_Bool_Exp> = {
  defaultValue: "all",
  label: "Ticket Type",
  modelName: "ticketType",
  hidden: !!workOrderId,
  filters: [
    {
      id: "all",
      title: "All",
      predicate: { ref: { _is_null: false } }
    },
    {
      id: "calloff",
      title: "Calloff",
      predicate: {
        work_order: { work_order_type: { _eq: Work_Order_Types_Enum.Calloff } }
      }
    },
    {
      id: "dedicated",
      title: "Dedicated",
      predicate: {
        work_order: {
          work_order_type: { _eq: Work_Order_Types_Enum.Dedicated }
        }
      }
    }
  ]
};

const {
  facetModels,
  filterWhereClause,
  mode,
  resetFacets,
  searchString,
  sortOrder,
  updateFacet,
  updateSearch,
  updateSortDirection,
  updateSortType,
  updateViewMode
} = useFacets(
  [
    assigneeFilters,
    authorFilters,
    customerFilters,
    dateRangeFilters,
    reviewStatusFilters,
    serviceTypeFilters,
    ticketStatusFilters,
    ticketTypeFilters,
    ticketArchiveFilters,
    TBDFilters
  ],
  orderByFilters
);

const { data, fetching, error } = useQuery({
  query: TicketsViewQuery,
  variables: computed(() => ({
    where: {
      _and: [
        // this picks up when we're nested under a workOrder
        workOrderId ? { work_order_id: { _eq: workOrderId } } : {},
        // this picks up when we're nested under a customer
        customerId ? { work_order: { customer_id: { _eq: customerId } } } : {},
        // this picks up when we're nested under a work site
        workSiteId ? { work_site_id: { _eq: workSiteId } } : {},
        // this picks up when we're nested under an agent detail page
        agentId ? { ticket_agents: { agent_id: { _eq: agentId } } } : {},
        // this applies all the where clauses from TicketsFilter
        ...(filterWhereClause.value || []),
        // this adds any search text set from the filter
        { ref: { _ilike: `${searchString.value}%` } }
      ]
    },
    order_by: sortOrder.value,
    limit: 50,
    offset: 0
  })),
  context: { additionalTypenames: ["tickets"] },
  pause: computed(() => !filterWhereClause.value)
});

const onModeChange = (newValue: string): void => {
  switch (newValue) {
    case "card":
      filterViewMode.value = CollectionView.Card;
      break;
    case "calendar":
      filterViewMode.value = CollectionView.Calendar;
      break;
    case "table":
      filterViewMode.value = CollectionView.Table;
      break;
    default:
      filterViewMode.value = CollectionView.Card;
      break;
  }
  updateViewMode(filterViewMode.value);
};

mode.value
  ? (filterViewMode.value = mode.value)
  : updateViewMode(CollectionView.Card);
</script>

<template>
  <article class="vertical" :class="{ xmargin: !noMargin }">
    <SHNote
      v-if="!userIsDedicatedToThisCustomer && workOrderId"
      theme="warning"
    >
      You cannot add tickets to this work order because you are not a dedicated
      agent of this customer.
    </SHNote>
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[
        assigneeFilters,
        authorFilters,
        customerFilters,
        dateRangeFilters,
        reviewStatusFilters,
        serviceTypeFilters,
        ticketStatusFilters,
        ticketTypeFilters,
        ticketArchiveFilters,
        TBDFilters
      ]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="ticketCount"
      clear-all-filters
      placeholder="Search by Ticket..."
      searchable
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event)"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
      @update:view-mode="onModeChange($event)"
    >
      <template v-if="showAddTicket" #leftOfSearch>
        <SHButton
          v-if="
            can('calloff_tickets:create_self') ||
            can('calloff_tickets:create_others') ||
            (workOrderId && can('tickets_dedicated:create'))
          "
          color="primary"
          class="level tight"
          :to="addTicketRouteLocation"
          :disabled="!canAddTicket"
        >
          <FontAwesomeIcon :icon="faPlus" />
          Add Ticket
        </SHButton>
      </template>
      <template #mobile-header>Ticket Filter</template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <main v-else class="vertical loose">
      <template v-if="filterViewMode === CollectionView.Card">
        <TransitionGroup v-if="tickets?.length" name="list">
          <TicketCard
            v-for="t in tickets"
            :key="t.id"
            :ticket="t"
            show-work-order
            @click:id="
              $router.push({ name: 'TicketDetail', params: { ticketId: t.id } })
            "
          />
        </TransitionGroup>
        <SHNote v-else theme="warning" class="no-tickets">
          No tickets found.
        </SHNote>
      </template>
      <template v-else-if="filterViewMode === CollectionView.Table">
        <SHTable
          :rows="tickets || []"
          fluid
          clickable
          empty-message="No tickets found."
          @row:click="
            $router.push({
              name: 'TicketDetail',
              params: {
                ticketId: $event.id
              }
            })
          "
        >
          <template #header>
            <th>Ticket</th>
            <th style="width: 35%">Product</th>
            <th>Customer</th>
            <th v-if="breakpoints.includes('tablet')">Worksite</th>
            <th v-if="breakpoints.includes('tablet')">Created</th>
            <th v-if="breakpoints.includes('desktop')">Author</th>
          </template>

          <template #row="{ row }">
            <td>
              <SHBadge class="number">#{{ row.ref }}</SHBadge>
            </td>
            <td class="truncate">
              {{ row.product.title || "?" }}
            </td>
            <td>
              <CustomerLink
                :customer="row.work_order.customer"
                :abbr="!breakpoints.includes('desktop')"
                hide-icon
              />
            </td>
            <td v-if="breakpoints.includes('tablet')">
              {{ row.work_site.title }}
            </td>
            <td v-if="breakpoints.includes('tablet')">
              {{ formatDateRelative(row.created_at) }}
            </td>
            <td v-if="breakpoints.includes('desktop')">
              <UserLink :user="row.author" />
            </td>
          </template>

          <template #footer>
            <SHTableRowCount :count="ticketCount" label="ticket" />
          </template>
        </SHTable>
      </template>
      <template v-else-if="filterViewMode === CollectionView.Calendar">
        <ToBeDetermined>Ticket Calendar View</ToBeDetermined>
      </template>
    </main>
  </article>
</template>

<style lang="scss" scoped>
.truncate {
  max-width: 10em;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.no-tickets {
  padding: 0.25rem;
  width: 100%;
}
</style>
