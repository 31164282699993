<script setup lang="ts">
import { graphql, type FragmentType, useFragment } from "@/generated";
import { useLogger } from "@/logger";
import { v4 } from "uuid";
import { ref, watch, computed } from "vue";
import { TBD_TITLE } from "@/constants";
import type { Work_Sites_Insert_Input } from "@/generated/graphql";
import type { Work_Sites_Set_Input } from "@/generated/graphql";
import SHInput from "@/components/SHInput.vue";
import SHField from "@/components/SHField.vue";

const { log } = useLogger("WorkSiteForm"); // eslint-disable-line @typescript-eslint/no-unused-vars

const fragment = graphql(/* GraphQL */ `
  fragment WorkSiteForm on work_sites {
    id
    title
    data
  }
`);

const props = defineProps<{
  customerId: string;
  workSite?: FragmentType<typeof fragment>;
}>();

const emit = defineEmits<{
  (e: "update:updates", value: Work_Sites_Set_Input | null): void;
  (e: "update:inserts", value: Work_Sites_Insert_Input | null): void;
}>();

const workSite = computed(() => useFragment(fragment, props.workSite));

const form = ref<{
  id: string;
  title?: string;
  data: Record<string, unknown>;
}>({
  id: v4(),
  data: {}
});

const isValid = computed(() => {
  return (
    workSite.value?.title.toLowerCase() !== TBD_TITLE.toLowerCase() &&
    form.value?.title?.toLowerCase() !== TBD_TITLE.toLowerCase() &&
    (form.value.title || workSite.value?.title)
  );
});

const inserts = computed<Work_Sites_Insert_Input | null>(() => {
  if (!isValid.value) {
    return null;
  }

  return {
    id: form.value.id,
    title: form.value.title,
    data: {
      ...workSite.value?.data,
      ...form.value.data
    }
  };
});

const updates = computed<Work_Sites_Set_Input | null>(() => {
  if (!isValid.value) {
    return null;
  }

  return {
    title: form.value.title,
    data: {
      ...workSite.value?.data,
      ...form.value.data
    }
  };
});

watch(
  form,
  () => {
    emit("update:inserts", inserts.value);
    emit("update:updates", updates.value);
  },
  { deep: true }
);
</script>

<template>
  <article>
    <div class="level-cols loose">
      <SHField label="Title">
        <SHInput
          :model-value="form.title ?? workSite?.title ?? ''"
          @update:model-value="form.title = $event.trim()"
        />
      </SHField>
      <SHField label="Cost Center">
        <SHInput
          :model-value="
            form.data.cost_center ?? workSite?.data?.cost_center ?? ''
          "
          @update:model-value="form.data.cost_center = $event"
        />
      </SHField>
    </div>
  </article>
</template>
