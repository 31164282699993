import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";
import {
  faFileSpreadsheet,
  faTrafficCone
} from "@fortawesome/sharp-light-svg-icons";

import ModuleHeader from "@/components/ModuleHeader.vue";
import WorkSiteEdit from "@/views/WorkSiteEdit.vue";
import MediaUploadsView from "@/views/MediaUploadsView.vue";
import WorkSiteDetail from "@/views/WorkSiteDetail.vue";
import WorkSiteCreate from "@/views/WorkSiteCreate.vue";
import TicketsView from "@/views/TicketsView.vue";
import CustomFormEntries from "@/components/CustomFormEntries.vue";

export const worksiteRoutes: Array<RouteRecordRaw> = [
  {
    path: "new",
    name: "WorkSiteCreate",
    components: {
      default: WorkSiteCreate,
      header: h(
        ModuleHeader,
        { icon: faTrafficCone },
        () => "Create New Worksite"
      )
    },
    props: {
      default: r => {
        return {
          ...r.params,
          ...r.query
        };
      }
    }
  },
  {
    path: ":workSiteId",
    children: [
      {
        path: "",
        name: "WorkSiteDetail",
        redirect: {
          name: "WorkSiteTicketsView"
        },
        components: {
          default: WorkSiteDetail,
          header: h(
            ModuleHeader,
            { icon: faTrafficCone },
            () => "Customer Worksite"
          )
        },
        children: [
          {
            path: "media",
            name: "WorkSiteMediaView",
            components: {
              default: h(MediaUploadsView),
              header: h(
                ModuleHeader,
                { icon: faTrafficCone },
                () => "Worksite Media"
              )
            },
            props: true
          },
          {
            path: "tickets",
            name: "WorkSiteTicketsView",
            components: {
              default: h(TicketsView, { noMargin: true }),
              header: h(
                ModuleHeader,
                { icon: faTrafficCone },
                () => "Worksite Tickets"
              )
            },
            props: {
              default: r => ({
                ...r.params,
                showAddTicket: false
              })
            }
          },
          {
            path: "forms",
            name: "WorkSiteFormsView",
            components: {
              default: h(CustomFormEntries),
              header: h(
                ModuleHeader,
                { icon: faFileSpreadsheet },
                () => "Worksite Forms"
              )
            },
            props: true
          }
        ],
        props: true
      }
    ]
  },
  {
    path: ":workSiteId/edit",
    name: "WorkSiteEdit",
    components: {
      default: WorkSiteEdit,
      header: h(ModuleHeader, { icon: faTrafficCone }, () => "Edit Worksite")
    },
    props: true
  }
];
