<script async setup lang="ts">
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { Event_Types_Enum, Work_Order_Types_Enum } from "@/generated/graphql";
import { useLogger } from "@/logger";
import { CurrentUserKey } from "@/providerKeys";
import type { JSONContent } from "@tiptap/vue-3";
import { useMutation, useQuery } from "@urql/vue";
import { add, endOfDay, lastDayOfMonth, startOfMonth } from "date-fns";
import { v4 } from "uuid";
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";

import DateRangePicker from "@/components/DateRangePicker.vue";
import SHCustomerChooser from "@/components/SHCustomerChooser.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHTextEditorWithToolbar from "@/components/TextEditor/SHTextEditorWithToolbar.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import type { CustomerChoice } from "@/composables/useCustomerChoice";
import {
  NEXT_MONTH,
  NEXT_QUARTER,
  NEXT_WEEK,
  TODAY,
  TOMORROW
} from "@/lib/datetime";
import { injectStrict } from "@/lib/helpers";

const { customerId } = defineProps<{
  customerId?: string;
}>();

const { log } = useLogger("WorkOrderCreate"); // eslint-disable-line @typescript-eslint/no-unused-vars
const router = useRouter();
const { createToast } = useToaster();

const currentUser = injectStrict(CurrentUserKey);

const { data: customerData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query WorkOrderCreate_Customer($customerId: uuid!) {
      customers_by_pk(id: $customerId) {
        id
        title
        price_book_id
      }
    }
  `),
  variables: computed(() => ({
    customerId: customerId ?? "missing customer id"
  })),
  pause: computed(() => !customerId)
});

const form = reactive<{
  id: string;
  customer?: CustomerChoice | null;
  requestingParty?: string;
  serviceDates?: [Date, Date];
  notes?: JSONContent;
}>({
  id: v4(),
  customer: customerId ? customerData.value?.customers_by_pk : undefined
});

const selectedCustomer = computed<CustomerChoice | undefined | null>({
  get: () => form.customer,
  set: value => (form.customer = value)
});
const requestingParty = computed({
  get: () => form.requestingParty,
  set: value => (form.requestingParty = value)
});
const serviceDates = computed({
  get: () =>
    form.serviceDates || [
      startOfMonth(add(new Date(), { months: 1 })),
      endOfDay(lastDayOfMonth(add(new Date(), { months: 1 })))
    ],
  set: value => (form.serviceDates = value)
});
const notes = computed({
  get: () => form.notes,
  set: value => (form.notes = value)
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation CreateWorkOrderMutation(
      $workOrder: work_orders_insert_input!
      $eventNotification: event_notifications_insert_input!
    ) {
      insert_work_orders_one(object: $workOrder) {
        id
        ref
      }

      insert_event_notifications_one(object: $eventNotification) {
        event_id
      }
    }
  `)
);

async function onSubmit() {
  const { data, error } = await executeMutation({
    workOrder: {
      id: form.id,
      ref: "",
      notesj: notes.value,
      customer_id: selectedCustomer.value?.id,
      requesting_party: requestingParty.value,
      work_order_type: Work_Order_Types_Enum.Dedicated,
      service_date_start: serviceDates.value.at(0),
      service_date_end: serviceDates.value.at(1)
    },
    eventNotification: {
      event_id: v4(),
      payload: {
        type: Event_Types_Enum.DedicatedWorkOrderCreated,
        version: 1,
        data: {
          author_id: currentUser.value.id,
          customer_id: selectedCustomer.value?.id,
          work_order_id: form.id
        }
      }
    }
  });

  if (error) {
    createToast({
      title: "Unable to create the work order.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  }

  if (data) {
    createToast({
      message: `Work order #${data?.insert_work_orders_one?.ref} created.`,
      theme: "success"
    });
    router.push({
      name: "WorkOrderDetail",
      params: { workOrderId: form.id }
    });
  }
}
</script>

<template>
  <article class="work-order-create">
    <SHField label="Customer" style="grid-area: customer">
      <SHCustomerChooser v-model="selectedCustomer" />
    </SHField>

    <SHField label="Requested By" style="grid-area: requestingParty">
      <SHInput v-model="requestingParty" />
    </SHField>

    <SHField label="Service Dates" block style="grid-area: serviceDates">
      <DateRangePicker
        v-model="serviceDates"
        :presets="[TODAY, TOMORROW, NEXT_WEEK, NEXT_MONTH, NEXT_QUARTER]"
      />
    </SHField>

    <SHField label="Instructions / Details" block style="grid-area: notes">
      <SHTextEditorWithToolbar
        v-model="notes"
        file-path="workorders"
        :context="{ work_order_id: form.id }"
        :rows="4"
      />
    </SHField>

    <UndoConfirm
      style="grid-area: buttons"
      :confirm-enabled="!!selectedCustomer"
      @confirm="onSubmit"
      @undo="$router.go(-1)"
    />
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;

.work-order-create {
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr;
  grid-template-areas:
    "customer"
    "requestingParty"
    "serviceDates"
    "notes"
    "buttons";

  @include bp.laptop {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "customer requestingParty"
      "serviceDates serviceDates"
      "notes notes"
      "buttons buttons";
  }

  @include bp.desktop {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "customer requestingParty serviceDates"
      "notes notes notes"
      "buttons buttons buttons";
  }
}
</style>
