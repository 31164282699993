<script setup lang="ts">
import CustomerForm from "@/components/CustomerForm.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type { Customers_Insert_Input } from "@/generated/graphql";
import { useMutation, useQuery } from "@urql/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomerCreate {
      ...CustomerFormOptions
    }
  `)
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation InsertCutomer($object: customers_insert_input!) {
      insert_customers_one(object: $object) {
        id
        title
      }
    }
  `)
);

const inserts = ref<Customers_Insert_Input>();

async function onSubmit() {
  if (inserts.value) {
    const { data, error } = await executeMutation({ object: inserts.value });

    if (data?.insert_customers_one?.id) {
      createToast({
        message: `Customer ${data.insert_customers_one.title} created.`,
        theme: "success"
      });

      router.replace({
        name: "CustomerDetail",
        params: { customerId: data.insert_customers_one.id }
      });
    }

    if (error) {
      createToast({
        title: "Unable to create the customer.",
        message: error.message || "Unknown error.",
        theme: "danger",
        lifetimeMS: 5000
      });
    }
  }
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="data" class="vertical xmargin">
    <CustomerForm v-if="data" v-model:inserts="inserts" :options="data" />

    <UndoConfirm
      :confirm-enabled="!!inserts"
      @undo="$router.back"
      @confirm="onSubmit"
    >
      <template #confirm>Create</template>
    </UndoConfirm>
  </article>
</template>
