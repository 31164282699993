<script async setup lang="ts">
import AgentShiftSlider from "@/components/AgentShiftSlider.vue";
import SHButton from "@/components/SHButton.vue";
import SHDatePicker from "@/components/SHDatePicker.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTimeSlider from "@/components/SHTimeInput/SHTimeSlider.vue";
import UserLink from "@/components/UserLink.vue";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import {
  addDays,
  eachDayOfInterval,
  eachHourOfInterval,
  endOfDay,
  endOfMonth,
  format,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays
} from "date-fns";
import { computed, ref } from "vue";

const { log } = useLogger("AgentShiftsView"); // eslint-disable-line @typescript-eslint/no-unused-vars

const props = defineProps<{
  agentId?: string;
}>();

const dateRange = ref<[Date, Date]>(
  props.agentId
    ? [startOfMonth(new Date()), endOfMonth(new Date())]
    : [startOfDay(new Date()), endOfDay(new Date())]
);

const selectedDay = computed<Date>({
  get: () => startOfDay(dateRange.value[0]),
  set: (value: Date) => {
    dateRange.value = [startOfDay(value), endOfDay(value)];
  }
});

const selectedMonth = computed<{
  month: number;
  year: number;
}>({
  get: () => ({
    month: dateRange.value[0].getMonth(),
    year: dateRange.value[0].getFullYear()
  }),
  set: (value: { month: number; year: number }) => {
    dateRange.value = [
      startOfMonth(new Date(value.year, value.month)),
      endOfMonth(new Date(value.year, value.month))
    ];
  }
});

const {
  data: shiftsData,
  fetching: shiftsFetching,
  error: shiftsErrors
} = await useQuery({
  query: graphql(/* GraphQL */ `
    query AgentShiftsView(
      $logsWhere: combined_logs_bool_exp!
      $agentsWhere: organization_agents_bool_exp!
      $shiftsWhere: agent_shifts_bool_exp!
    ) {
      organization_agents(
        where: $agentsWhere
        order_by: [{ agent: { full_name: asc } }]
      ) {
        agent_id
        agent {
          ...UserLink
        }

        combined_logs(
          where: $logsWhere
          order_by: [{ started_at: asc }, { ended_at: asc }]
        ) {
          id
          started_at
          ended_at
          ...AgentShiftSlider_Log
        }

        shifts(
          where: $shiftsWhere
          order_by: [{ started_at: asc }, { ended_at: asc }]
        ) {
          hash
          started_at
          ended_at
          ...AgentShiftSlider_Shift
          ...ShiftLink
        }
      }
    }
  `),
  variables: computed(() => ({
    agentsWhere: {
      agent_id: props.agentId ? { _eq: props.agentId } : {}
    },
    shiftsWhere: {
      _or: [
        { started_at: { _gte: dateRange.value[0], _lt: dateRange.value[1] } },
        { ended_at: { _gte: dateRange.value[0], _lt: dateRange.value[1] } }
      ]
    },
    logsWhere: {
      ended_at: { _is_null: false },
      deleted_at: { _is_null: true },
      _not: { shifts: {} },
      _or: [
        { started_at: { _gte: dateRange.value[0], _lt: dateRange.value[1] } },
        { ended_at: { _gte: dateRange.value[0], _lt: dateRange.value[1] } }
      ]
    }
  })),
  context: {
    additionalTypenames: ["work_logs", "travel_logs", "agent_shift_overrides"]
  }
});

const agents = computed(() => shiftsData.value?.organization_agents || []);

const days = computed(() =>
  eachDayOfInterval({ start: dateRange.value[0], end: dateRange.value[1] }).map(
    d => ({
      date: d,
      shifts: agents.value.flatMap(a =>
        a.shifts.filter(
          s =>
            isWithinInterval(parseISO(s.started_at!), {
              start: d,
              end: endOfDay(d)
            }) ||
            isWithinInterval(parseISO(s.ended_at!), {
              start: d,
              end: endOfDay(d)
            })
        )
      ),
      logs: agents.value.flatMap(a =>
        a.combined_logs.filter(
          l =>
            isWithinInterval(parseISO(l.started_at!), {
              start: d,
              end: endOfDay(d)
            }) ||
            isWithinInterval(parseISO(l.ended_at!), {
              start: d,
              end: endOfDay(d)
            })
        )
      )
    })
  )
);
</script>

<template>
  <SHSpinner v-if="shiftsFetching" />
  <SHNote v-else-if="shiftsErrors" theme="danger">
    {{ shiftsErrors.message }}
  </SHNote>
  <article v-else class="agent-shifts-view vertical loose">
    <header>
      <div v-if="!agentId" class="level tight" style="grid-column: 1/-1">
        <SHButton square @click="selectedDay = subDays(selectedDay, 1)">
          <FontAwesomeIcon :icon="faChevronLeft" />
        </SHButton>
        <SHDatePicker v-model="selectedDay" auto-apply />
        <SHButton square @click="selectedDay = addDays(selectedDay, 1)">
          <FontAwesomeIcon :icon="faChevronRight" />
        </SHButton>
      </div>
      <div v-else class="level tight" style="grid-column: 1/-1">
        <SHButton
          square
          @click="
            selectedMonth = {
              ...selectedMonth,
              month: selectedMonth.month - 1
            }
          "
        >
          <FontAwesomeIcon :icon="faChevronLeft" />
        </SHButton>
        <SHDatePicker
          v-model="selectedMonth"
          format="MMMM yyyy"
          month-picker
          auto-apply
        />
        <SHButton
          square
          @click="
            selectedMonth = {
              ...selectedMonth,
              month: selectedMonth.month + 1
            }
          "
        >
          <FontAwesomeIcon :icon="faChevronRight" />
        </SHButton>
      </div>
    </header>
    <section>
      <SHTimeSlider
        class="hide-slider"
        style="grid-column: 2"
        compact
        readonly
        :model-value="selectedDay"
        :ranges="[]"
        :key-times="
          eachHourOfInterval({
            start: startOfDay(selectedDay),
            end: endOfDay(selectedDay)
          }).map(h => ({
            label: format(h, 'haaa'),
            value: h
          }))
        "
      />
    </section>
    <template v-if="!agentId">
      <article v-for="a in agents" :key="a.agent_id" class="agent-shift-row">
        <UserLink v-if="a.agent" :user="a.agent" />
        <AgentShiftSlider
          :date="selectedDay"
          :shifts="a.shifts"
          :logs="a.combined_logs"
        />
      </article>
    </template>
    <template v-else>
      <article
        v-for="d in days"
        :key="d.date.getTime()"
        class="agent-shift-row"
      >
        <span>{{ format(d.date, "EEEE, MMM d") }}</span>
        <AgentShiftSlider :date="d.date" :shifts="d.shifts" :logs="d.logs" />
      </article>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.sh-note {
  background-color: var(--color-warning-down-500);
}

.agent-shifts-view {
  gap: 1.5em;

  header,
  section,
  footer,
  .agent-shift-row {
    display: grid;
    grid-template-columns: minmax(10em, max-content) 1fr;
    row-gap: 8em;
  }

  :deep(.sh-time-slider) {
    &.hide-slider {
      input[type="range"] {
        height: 1px;
      }

      .track-background {
        visibility: hidden;
      }
    }
  }
}
</style>
