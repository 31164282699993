<script setup lang="ts">
import { computed, ref, watch } from "vue";

const props = defineProps<{
  modelValue?: string | null;
  compact?: boolean;
  autoResize?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string | null | undefined): void;
}>();

const modelValue = computed({
  get() {
    return props.modelValue ?? undefined;
  },
  set(val) {
    emit("update:modelValue", val || null);
  }
});

const textareaEl = ref<HTMLTextAreaElement>();

watch(
  [modelValue, textareaEl],
  () => {
    // Resize the text area
    if (props.autoResize && textareaEl.value) {
      textareaEl.value.style.height = "0";
      textareaEl.value.style.height = textareaEl.value.scrollHeight + "px";
    }
  },
  { immediate: true }
);
</script>

<template>
  <article class="sh-textarea" :class="{ compact }">
    <textarea ref="textareaEl" v-model="modelValue"></textarea>
  </article>
</template>

<style lang="scss" scoped>
.sh-textarea {
  background-color: var(--color-surface-100);
  textarea {
    width: 100%;
    color: var(--color-surface-900);
    background-color: transparent;
    border: none;
    resize: none;

    // Remove browser's outline when focused
    &:focus-within {
      outline: none;
    }
  }

  border-radius: var(--border-radius);
  border-color: var(--border-color);
  border-style: var(--border-style);
  border-width: var(--border-width);

  &.compact {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
  }
}
</style>
