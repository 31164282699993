<script setup lang="ts">
import InvoiceLineItemForm from "@/components/Invoices/InvoiceLineItemForm.vue";
import InvoiceMetaForm from "@/components/Invoices/InvoiceMetaForm.vue";
import type { InvoiceForm } from "@/components/Invoices/types";
import SHButton from "@/components/SHButton.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { makeForm } from "@/model/invoices";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

const { createToast } = useToaster();
const router = useRouter();

const props = defineProps<{
  invoiceId: string;
}>();

const invoiceForm = ref<InvoiceForm>();

const { data: invoiceData } = useQuery({
  query: graphql(/* GraphQL */ `
    query InvoiceEdit($invoiceId: uuid!) {
      invoices_by_pk(id: $invoiceId) {
        id
        subject
        po_number
        due_in

        invoice_line_items(where: { deleted_at: { _is_null: true } }) {
          id
          cents_calculated
          cents_invoiced
          label
          description
          details
        }

        work_logs {
          id
        }

        expense_logs {
          id
        }
      }
    }
  `),
  variables: {
    invoiceId: props.invoiceId
  }
});

const invoice = computed(() => invoiceData.value?.invoices_by_pk);

watch(invoice, () => {
  if (!invoice.value) {
    return;
  }

  invoiceForm.value = makeForm(invoice.value);
});

const { executeMutation: updateInvoice } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateInvoice(
      $invoiceId: uuid!
      $invoice: invoices_set_input!
      $invoiceLineItemUpdates: [invoice_line_items_updates!]!
    ) {
      update_invoices_by_pk(pk_columns: { id: $invoiceId }, _set: $invoice) {
        id
      }

      update_invoice_line_items_many(updates: $invoiceLineItemUpdates) {
        returning {
          id
        }
      }
    }
  `)
);

async function onSave() {
  if (!invoice.value || !invoiceForm.value) {
    return;
  }

  const invoiceOld = makeForm(invoice.value);
  const invoiceNew = invoiceForm.value;

  const deletedLineItems = invoiceOld.lineItems.filter(
    item => !invoiceNew.lineItems.some(i => i.id === item.id)
  );

  const updatedLineItems = invoiceNew.lineItems
    // Filter out deleted items
    .filter(item => !deletedLineItems.some(i => i.id === item.id))
    .filter(item => invoiceOld.lineItems.some(i => i.id === item.id));

  const { data, error } = await updateInvoice({
    invoiceId: invoice.value.id,
    invoice: {
      subject: invoiceForm.value.invoice.subject,
      po_number: invoiceForm.value.invoice.poNumber,
      issued_at: invoiceForm.value.invoice.issueDate.toISOString(),
      due_in: invoiceForm.value.invoice.dueIn
    },
    invoiceLineItemUpdates: [
      {
        where: {
          id: {
            _in: deletedLineItems.map(item => item.id)
          }
        },
        _set: {
          deleted_at: new Date().toISOString()
        }
      },
      ...(updatedLineItems.map(item => ({
        where: {
          id: {
            _eq: item.id
          }
        },
        _set: {
          label: item.label,
          description: item.description,
          cents_invoiced: item.centsInvoiced
        }
      })) ?? [])
    ]
  });

  if (data) {
    createToast({
      message: `Invoice created.`,
      theme: "success"
    });
    router.push({
      name: "InvoiceDetail",
      params: {
        invoiceId: data.update_invoices_by_pk?.id
      }
    });
  }

  if (error) {
    createToast({
      title: "Unable to create the invoice.",
      message: error.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  }
}
</script>

<template>
  <article v-if="invoiceForm" class="invoice-form vertical xmargin">
    <main class="vertical">
      <section class="invoice-settings vertical">
        <h2>Invoice Details</h2>
        <InvoiceMetaForm v-model:form="invoiceForm.invoice" />
      </section>
      <hr />
      <section class="invoice-items vertical">
        <h2>Invoice Items</h2>
        <SHTable
          :rows="invoiceForm.lineItems"
          :columns="['Label', 'Description', 'Amount']"
          empty-message="No invoices found."
        >
          <template #body="{ rows }">
            <template v-for="(row, idx) in rows" :key="row.id">
              <InvoiceLineItemForm v-model:form="invoiceForm.lineItems[idx]" />
            </template>
          </template>

          <template #footer>
            <SHTableRowCount
              :count="invoiceForm.lineItems.length"
              label="invoice"
            />
          </template>
        </SHTable>
      </section>
    </main>

    <footer class="level-end">
      <SHButton color="primary" @click="onSave">Save Invoice</SHButton>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.invoice-form {
  h2 {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-light);
  }

  > main {
    > section {
      align-items: flex-start;
    }
  }

  .invoice-details {
    display: grid;
    width: 100%;
    gap: 1em;

    grid-template-columns: 1fr 1fr;

    .invoice-notes {
      grid-column: 1 / -1;
    }
  }

  .summary-table {
    border-collapse: collapse;

    tr {
      &:not(:last-child) {
        td {
          border: none;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid var(--color-gray-300);
        border-bottom: none;
      }
    }

    td {
      padding: 0.5em 1em;
      text-align: right;
    }

    td:first-child {
      font-weight: var(--font-weight-bold);
    }

    td:last-child {
      font-family: var(--font-family-monospace);
    }
  }
}
</style>
