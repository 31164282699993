<script setup lang="ts">
import { computed } from "vue";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import {
  useUserRoleChoice,
  type UserRoleChoice
} from "@/composables/useUserRoleChoice";

import ComboBox from "@/components/ComboBox/ComboBox.vue";
import SHNote from "@/components/SHNote.vue";
import SHRoleBadge from "@/components/SHRoleBadge.vue";

const modelValue = defineModel<UserRoleChoice>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query SHRoleChooser {
      user_roles {
        ...UserRoleChoice
      }
    }
  `)
});

const roles = computed(
  () =>
    (data.value?.user_roles.map(useUserRoleChoice) ?? []) as UserRoleChoice[]
);
</script>

<template>
  <ComboBox
    v-if="!error"
    v-model="modelValue"
    :loading="fetching"
    :options="roles"
    mobile-header="Choose your role"
    placeholder="Choose role..."
  >
    <template #selected-option="{ option, placeholder }">
      <div class="level tight">
        <SHRoleBadge v-if="option" :role="option" :show-label="false" />
        <span>{{ option?.label || placeholder }}</span>
      </div>
    </template>
    <template #option="{ option }">
      <div class="level tight">
        <SHRoleBadge :role="option" :show-label="false" />
        <span>{{ option.label }}</span>
      </div>
    </template>
  </ComboBox>
  <SHNote v-else theme="danger">{{ error }}</SHNote>
</template>
