<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import TicketBadge from "@/components/TicketBadge.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { computed } from "vue";

const fragment = graphql(/* GraphQL */ `
  fragment TicketLink on tickets {
    id
    ...TicketBadge
  }
`);

const props = defineProps<{
  ticket: FragmentType<typeof fragment>;
}>();

const ticket = computed(() => useFragment(fragment, props.ticket));
</script>

<template>
  <AppLink
    class="ticket-link"
    :to="{
      name: 'TicketDetail',
      params: {
        ticketId: ticket.id
      }
    }"
  >
    <TicketBadge :ticket="ticket" />
  </AppLink>
</template>
