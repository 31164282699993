import { graphql } from "@/generated";
import { CurrentUserKey } from "@/providerKeys";
import { useMutation } from "@urql/vue";
import { injectStrict } from "../lib/helpers";

export default function useWebpush() {
  const user = injectStrict(CurrentUserKey);

  const { executeMutation: savePushSubscription } = useMutation(
    graphql(/* GraphQL */ `
      mutation Users_Subscription($userId: String!, $subscription: String!) {
        update_users_by_pk(
          pk_columns: { id: $userId }
          _set: { subscription: $subscription }
        ) {
          id
        }
      }
    `)
  );

  async function register() {
    const { VAPID_PUBLIC_KEY = import.meta.env.VITE_VAPID_PUBLIC_KEY } =
      window.config;
    //  Request permission for notifications
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.log("Permission not granted for Notification");
      return;
    }

    const registration = await navigator.serviceWorker.ready;
    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        // Replace with your own VAPID public key
        applicationServerKey: VAPID_PUBLIC_KEY
      });
      await savePushSubscription({
        userId: user.value.id,
        subscription: JSON.stringify(subscription)
      });
    } catch (err) {
      console.log("Failed to subscribe the user: ", err);
    }

    // Listen for messages from the service worker
    navigator.serviceWorker.addEventListener("message", event => {
      console.log("Received a message from service worker:", event.data);
    });
  }

  return { register };
}
