import { Ticket_Statuses_Enum } from "@/generated/graphql";
import {
  faArrowsRotate,
  faCircleCheck,
  faHourglassStart,
  faInterrobang,
  faUserMagnifyingGlass
} from "@fortawesome/sharp-light-svg-icons";

export function getColor(status: Ticket_Statuses_Enum) {
  switch (status) {
    case Ticket_Statuses_Enum.Open:
      return "var(--color-primary)";
    case Ticket_Statuses_Enum.InProgress:
      return "var(--color-success)";
    case Ticket_Statuses_Enum.InReview:
      return "var(--color-info)";
    case Ticket_Statuses_Enum.Closed:
      return "var(--color-surface-400)";
    default:
      return "magenta";
  }
}

export function getIcon(status: Ticket_Statuses_Enum) {
  switch (status) {
    case Ticket_Statuses_Enum.Open:
      return faHourglassStart;
    case Ticket_Statuses_Enum.InProgress:
      return faArrowsRotate;
    case Ticket_Statuses_Enum.InReview:
      return faUserMagnifyingGlass;
    case Ticket_Statuses_Enum.Closed:
      return faCircleCheck;
    default:
      return faInterrobang;
  }
}
