<script setup lang="ts">
import ProductPriceEditor from "@/components/ProductPriceEditor.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHModal from "@/components/SHModal.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UseToggle from "@/components/UseToggle.vue";
import { formatUSD } from "@/formatters";
import { graphql } from "@/generated";
import {
  faBarcodeRead,
  faBox,
  faBriefcase,
  faPen
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { useWindowSize } from "@vueuse/core";
import { computed } from "vue";

const { priceBookId } = defineProps<{
  priceBookId: string;
}>();

const { data, error, fetching, executeQuery } = useQuery({
  query: graphql(/* GraphQL */ `
    query ProductPriceIndex($priceBookId: uuid!) {
      products(order_by: [{ catalog: { title: asc } }, { title: asc }]) {
        id
        title

        prices(
          limit: 1
          order_by: { valid_from: desc }
          where: {
            price_book_id: { _eq: $priceBookId }
            valid_from: { _lte: "now()" }
          }
        ) {
          id
          cents_per_unit
          billing_unit

          ...ProductPriceEditor
        }

        catalog {
          id
          title
        }
      }

      ...ProductPriceEditorOptions
    }
  `),
  variables: {
    priceBookId
  },
  context: { additionalTypenames: ["products", "product_prices"] }
});

const products = computed(() => data.value?.products ?? []);

const catalogs = computed(() =>
  products.value.reduce<
    ((typeof products)["value"][number]["catalog"] & {
      products: (typeof products)["value"];
    })[]
  >((acc, product, idx, arr) => {
    const lastProduct = arr[idx - 1];

    if (lastProduct?.catalog.id !== product.catalog.id) {
      acc.push({
        ...product.catalog,
        products: []
      });
    }

    const catalog = acc.find(p => p.id === product.catalog.id);
    catalog?.products.push(product);

    return acc;
  }, [])
);

const { width } = useWindowSize();
const isMobileMode = computed(() => width.value < 600);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="data" class="product-prices-view">
    <section v-for="catalog in catalogs" :key="catalog.id">
      <div class="catalog-row">
        <td style="padding-top: 1em" class="level-end">
          <SHBadge class="level tight">
            <FontAwesomeIcon :icon="faBriefcase" />
            <span>{{ catalog.title }}</span>
          </SHBadge>
        </td>
      </div>
      <article class="product-prices-list vertical">
        <section v-for="product in catalog.products" :key="product.id">
          <UseToggle v-slot="{ on, disable, enable }">
            <section
              class="product-price-row"
              :class="{
                open: on
              }"
            >
              <td class="level-spread">
                <div class="level tight">
                  <FontAwesomeIcon :icon="faBox" />
                  <span>{{ product.title }}</span>
                </div>
                <template v-if="!on">
                  <div v-if="!product.prices.at(0)" class="level tight">
                    <span class="no-price">No price added.</span>
                    <SHButton
                      link
                      class="level tight"
                      color="primary"
                      @click="enable"
                    >
                      <FontAwesomeIcon
                        :icon="faPen"
                        style="color: var(--surface-500)"
                      />
                    </SHButton>
                  </div>
                  <div v-else class="level tight">
                    <span>
                      {{ formatUSD(product.prices.at(0)?.cents_per_unit) }}
                    </span>

                    <SHButton
                      link
                      class="level tight"
                      color="primary"
                      @click="enable"
                    >
                      <FontAwesomeIcon
                        :icon="faPen"
                        style="color: var(--surface-500)"
                      />
                    </SHButton>
                  </div>
                </template>
                <template v-else>
                  <SHModal v-if="isMobileMode" :show="on" @close="disable">
                    <template #brand>
                      <h1 class="level tight">
                        <FontAwesomeIcon
                          style="color: var(--color-secondary)"
                          :icon="faBarcodeRead"
                        />
                        <span>{{ product.title }}</span>
                      </h1>
                    </template>
                    <div class="xmargin">
                      <ProductPriceEditor
                        :price-book-id="priceBookId"
                        :product-id="product.id"
                        :options="data"
                        :product-price="product.prices.at(0)"
                        @update:product-price="
                          executeQuery();
                          disable();
                        "
                        @cancel="disable"
                      />
                    </div>
                  </SHModal>
                  <ProductPriceEditor
                    v-else-if="on"
                    :price-book-id="priceBookId"
                    :product-id="product.id"
                    inline
                    :options="data"
                    :product-price="product.prices.at(0)"
                    @update:product-price="
                      executeQuery();
                      disable();
                    "
                    @cancel="disable"
                  />
                </template>
              </td>
            </section>
          </UseToggle>
        </section>
      </article>
    </section>
  </article>
</template>

<style lang="scss" scoped>
.product-prices-view {
  .product-price-row {
    color: var(--color-surface-600);

    > td {
      border-radius: var(--border-radius);
    }
  }

  .product-editor-row {
    > .product-price-editor {
      padding: 0.25em;
    }
  }

  .no-price {
    color: var(--color-surface-400);
    font-weight: 400;
  }
}
</style>
