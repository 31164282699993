<script setup lang="ts">
import { computed, ref } from "vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  faChevronUp,
  faNoteSticky,
  faPaperPlane
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SHBadge from "@/components/SHBadge.vue";
import SHProgress from "@/components/SHProgress.vue";
import WorkOrderStatusBadge from "@/components/WorkOrderStatusBadge.vue";
import { progressInInterval } from "@/lib/datetime";
import { Lifecycle_Statuses_Enum } from "@/generated/graphql";
import AppLink from "./AppLink.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";

const WorkOrderCardFragment = graphql(/* GraphQL */ `
  fragment WorkOrderCard on work_orders {
    id
    ref
    requesting_party
    service_date_start
    service_date_end
    lifecycle_status
    notesj

    ...WorkOrderStatus

    customer {
      title
      ...CustomerLink
    }
  }
`);

const props = defineProps<{
  workOrder: FragmentType<typeof WorkOrderCardFragment>;
}>();

const emit = defineEmits<{
  (e: "click:id", value: FragmentType<typeof WorkOrderCardFragment>): void;
}>();

const workOrder = computed(() =>
  useFragment(WorkOrderCardFragment, props.workOrder)
);

const showDetails = ref(false);

const progress = computed(() => {
  switch (workOrder.value.lifecycle_status) {
    case Lifecycle_Statuses_Enum.Pending:
      return 0;
    case Lifecycle_Statuses_Enum.Closed:
      return 1;
    default:
      if (!workOrder.value.service_date_end) {
        return 1;
      }

      const start = new Date(workOrder.value.service_date_start);
      const end = new Date(workOrder.value.service_date_end);
      return progressInInterval(start, end);
  }
});

const onCardClicked = () => {
  emit("click:id", props.workOrder);
};
</script>

<template>
  <article class="work-order-card">
    <section class="vertical tight">
      <header class="level-spread">
        <div class="level tight">
          <SHBadge class="number">{{ workOrder.ref }}</SHBadge>
          {{ workOrder.customer.title }}
        </div>
        <aside>
          <WorkOrderStatusBadge :work-order="workOrder" />
        </aside>
      </header>
      <div style="margin-top: 0.5em">
        <SHProgress :progress="progress" />
      </div>

      <section class="vertical tight" @click="onCardClicked">
        <div class="level-spread">
          <span>
            <SHInlineDate :d="workOrder.service_date_start" />
          </span>
          <span>
            <SHInlineDate
              :d="workOrder.service_date_end ?? workOrder.service_date_start"
            />
          </span>
        </div>
      </section>

      <section v-if="showDetails" class="details vertical tight">
        <FontAwesomeIcon :icon="faPaperPlane" />
        <p :class="{ empty: !workOrder.requesting_party }">
          {{ workOrder.requesting_party || "Requesting party not provided." }}
        </p>
        <FontAwesomeIcon :icon="faNoteSticky" />
        <SHTextEditor v-if="workOrder.notesj" :model-value="workOrder.notesj" />
        <div v-else>No notes provided.</div>
      </section>
    </section>

    <footer class="level-center">
      <AppLink @click="showDetails = !showDetails">
        <FontAwesomeIcon
          class="chevron"
          :icon="faChevronUp"
          :rotation="showDetails ? undefined : 180"
          style="transition: transform 150ms ease-in-out"
          size="sm"
        />
      </AppLink>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
article.work-order-card {
  padding: var(--padding);
  border-radius: var(--border-radius);
  background: var(--color-surface-100);

  header {
    aside {
      font-weight: var(--font-weight-extralight);
      color: var(--color-surface-500);
    }
  }

  section {
    --link--color: var(--color-info);
    font-size: var(--font-size-sm);
  }

  .details {
    padding-top: 0.5em;
    display: grid;
    grid-template-columns: 1em 1fr;

    svg {
      padding-top: 0.25em;
      color: var(--color-secondary);
    }

    p {
      margin: 0;
      vertical-align: text-top;

      &.empty {
        color: var(--color-surface-300);
      }
    }
  }

  footer {
    button {
      padding: 0;
    }
  }

  .chevron {
    color: var(--color-surface-500);
  }
}
</style>
