<script setup lang="ts">
import DashboardLink from "@/components/Dashboards/DashboardLink.vue";
import SHNote from "@/components/SHNote.vue";
import {
  faClipboardList,
  faClipboardListCheck,
  faUserMagnifyingGlass
} from "@fortawesome/sharp-light-svg-icons";
import { useTicketAggregates } from "@/composables/useTicketAggregates";

const props = defineProps<{
  viewAll?: boolean;
}>();
const {
  callOffActiveTickets,
  callOffInReview,
  data,
  dedicatedActiveTickets,
  dedicatedInReview,
  dedicatedClosed,
  error,
  totalActiveTickets,
  totalInReview
} = useTicketAggregates(() => props.viewAll);
</script>

<template>
  <div class="dashboard-actions">
    <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>

    <!-- ACTIONS FOR AGENT DASHBOARD -->
    <div v-if="!viewAll" class="action-buttons">
      <DashboardLink
        :to="{ name: 'TicketsView' }"
        :icon="faClipboardList"
        :number="totalActiveTickets ?? -1"
        :is-loading="!data"
        title="Active Tickets"
      />
      <DashboardLink
        :to="{ name: 'TicketsView', query: { ticketStatus: 'review' } }"
        :icon="faUserMagnifyingGlass"
        :number="totalInReview ?? -1"
        :is-loading="!data"
        title="In Review"
      />
    </div>

    <!-- ACTIONS FOR BACKOFFICE OR SUPERVISOR DASHBOARD -->
    <div v-else class="action-buttons">
      <DashboardLink
        :to="{
          name: 'TicketsView',
          query: { ticketStatus: 'active', ticketType: 'dedicated' }
        }"
        :icon="faClipboardList"
        :number="dedicatedActiveTickets ?? -1"
        :is-loading="!data"
        title="Active"
      >
        <template #suffix>
          <span class="suffix">&nbsp; Dedicated</span>
        </template>
      </DashboardLink>
      <DashboardLink
        :to="{
          name: 'TicketsView',
          query: { ticketStatus: 'active', ticketType: 'calloff' }
        }"
        :icon="faClipboardList"
        :icon-color="'var(--color-primary)'"
        :number="callOffActiveTickets ?? -1"
        :is-loading="!data"
        title="Active"
      >
        <template #suffix>
          <span class="suffix">&nbsp; Call Off</span>
        </template>
      </DashboardLink>
      <DashboardLink
        :to="{
          name: 'TicketsView',
          query: { ticketStatus: 'review', ticketType: 'dedicated' }
        }"
        :icon="faUserMagnifyingGlass"
        :number="dedicatedInReview ?? -1"
        :is-loading="!data"
        title="In Review"
      >
        <template #suffix>
          <span class="suffix">&nbsp; Dedicated</span>
        </template>
      </DashboardLink>
      <DashboardLink
        :to="{
          name: 'TicketsView',
          query: { ticketStatus: 'review', ticketType: 'calloff' }
        }"
        :icon="faUserMagnifyingGlass"
        :icon-color="'var(--color-primary)'"
        :number="callOffInReview ?? -1"
        :is-loading="!data"
        title="In Review"
      >
        <template #suffix>
          <span class="suffix">&nbsp; Call Off</span>
        </template>
      </DashboardLink>
      <DashboardLink
        :to="{
          name: 'TicketsView',
          query: {
            reviewStatus: 'approved',
            ticketStatus: 'all',
            ticketType: 'dedicated'
          }
        }"
        :icon="faClipboardListCheck"
        :number="dedicatedClosed ?? -1"
        :is-loading="!data"
        title="Approved"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.dashboard-actions {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .action-buttons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5em;

    @include bp.phone-landscape {
      grid-template-columns: repeat(2, 1fr);
    }

    @include bp.laptop {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
