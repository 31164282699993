<script setup lang="ts">
import InvoiceDeliveryStatusBadge from "@/components/Invoices/InvoiceDeliveryStatusBadge.vue";
import InvoicePaymentStatusBadge from "@/components/Invoices/InvoicePaymentStatusBadge.vue";
import QueryFilter from "@/components/QueryFilter.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { useFacets, type FacetComboOption } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { centsToDollars, dollarFormatter } from "@/formatters";
import { graphql } from "@/generated";
import {
  Delivery_Statuses_Enum,
  Order_By,
  Payment_Statuses_Enum,
  type Invoices_Bool_Exp,
  type Invoices_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

const { customerId = "" } = defineProps<{
  noMargin?: boolean;
  customerId?: string;
}>();

const filterViewMode = ref<CollectionView>(CollectionView.Table);

const orderByFilters: OrderSet<Invoices_Order_By> = {
  defaultValue: "issued",
  defaultOrderBy: Order_By.Desc,
  options: [
    {
      id: "ref",
      title: "Invoice ID",
      asc: [{ ref: Order_By.Asc }],
      desc: [{ ref: Order_By.Desc }]
    },
    {
      id: "issued",
      title: "Issued",
      asc: [{ issued_at: Order_By.Asc }],
      desc: [{ issued_at: Order_By.Desc }]
    },
    {
      id: "updated",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const statusFilters: Facet<Invoices_Bool_Exp> = {
  modelName: "ticketStatus",
  label: "Status",
  defaultValue: "open",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    {
      id: "open",
      title: "Open",
      predicate: { payment_status: { _neq: Payment_Statuses_Enum.Paid } }
    }
  ]
};

const {
  facetModels,
  filterWhereClause,
  resetFacets,
  searchString,
  sortOrder,
  updateFacet,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets([statusFilters], orderByFilters);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query InvoicesView(
      $where: invoices_bool_exp
      $order_by: [invoices_order_by!]
      $limit: Int!
      $offset: Int!
    ) {
      invoices(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        id
        ref
        created_at
        delivery_status
        payment_status
        sent_at
        issued_at
        total_cost_in_cents

        customer {
          title
        }
      }

      invoices_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        { deleted_at: { _is_null: true } },
        // this picks up when we're nested under a customer
        customerId ? { customer_id: { _eq: customerId } } : {},
        ...(filterWhereClause.value || []),
        // this adds any search text set from the filter
        { ref: { _ilike: `${searchString.value}%` } }
      ]
    },
    order_by: sortOrder.value,
    limit: 50,
    offset: 0
  })),
  pause: computed(() => !filterWhereClause.value),
  context: { additionalTypenames: ["invoices"] }
});

const invoices = computed(() => data.value?.invoices ?? []);
const invoiceCount = computed(
  () => data.value?.invoices_aggregate.aggregate?.count || 0
);
</script>

<template>
  <article class="invoices-view vertical" :class="{ xmargin: !noMargin }">
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[statusFilters]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="invoiceCount"
      clear-all-filters
      no-card-view
      no-calendar-view
      no-table-view
      placeholder="Search by Ref..."
      searchable
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event as FacetComboOption)"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Invoice Filter</template>
    </QueryFilter>
    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <SHTable
      v-else
      :rows="invoices"
      clickable
      empty-message="No invoices found."
      @row:click="
        $router.push({
          name: 'InvoiceDetail',
          params: { invoiceId: $event.id }
        })
      "
    >
      <template #header>
        <th>Ref</th>
        <th>Status</th>
        <th>Sent Date</th>
        <th>Issue Date</th>
        <th v-if="!customerId">Customer</th>
        <th style="text-align: right">Balance</th>
      </template>

      <template #row="{ row }">
        <td>
          {{ row.ref }}
        </td>
        <td>
          <template v-if="row.delivery_status === Delivery_Statuses_Enum.Draft">
            <InvoiceDeliveryStatusBadge :status="row.delivery_status" />
          </template>
          <template v-else>
            <InvoicePaymentStatusBadge :status="row.payment_status" />
          </template>
        </td>
        <td>
          <template v-if="row.sent_at">
            <SHInlineDate :d="row.sent_at" />
          </template>
          <template v-else>
            <em>Not yet sent</em>
          </template>
        </td>
        <td>
          <SHInlineDate :d="row.issued_at" />
        </td>
        <td v-if="!customerId">
          {{ row.customer.title }}
        </td>
        <td style="text-align: right" class="number">
          {{ dollarFormatter.format(centsToDollars(row.total_cost_in_cents)) }}
        </td>
      </template>

      <template #footer>
        <SHTableRowCount :count="invoiceCount" label="invoice" />
      </template>
    </SHTable>
  </article>
</template>
