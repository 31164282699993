<script async setup lang="ts">
import type { CustomFormContext, CustomFormToken } from "@/types";

import { v4 } from "uuid";
import {
  computed,
  defineAsyncComponent,
  inject,
  provide,
  ref,
  shallowRef,
  watch,
  type Component
} from "vue";
import { useRouter } from "vue-router";

import { useCustomForms } from "@/composables/useCustomForms";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import { ClientKey, UploadContextKey } from "@/providerKeys";
import { useMutation, useQuery } from "@urql/vue";
import { isAfter } from "date-fns";

import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHMenu from "@/components/SHMenu.vue";
import SHNote from "@/components/SHNote.vue";
import UserLink from "@/components/UserLink.vue";

import AppLink from "./AppLink.vue";

const { createToast } = useToaster();
const router = useRouter();

type CustomFormWithImport = { importPayload?: (_: unknown) => void } | null;

const {
  ticketId,
  ticketCustomFormEntryId = "",
  customFormDefinitionId,
  readonly = false
} = defineProps<{
  customFormDefinitionId: string; // required
  ticketId?: string; // we usually get this (not in admin screens)
  ticketCustomFormEntryId?: string; // if we're editing an existing entry
  readonly?: boolean;
  editable?: boolean | null;
}>();

const newId = ref(v4());
const formRef = ref<CustomFormWithImport>(null);
provide(UploadContextKey, {
  ticket_custom_form_entry_id: ticketCustomFormEntryId || newId.value
});
const urqlClient = inject(ClientKey);

const emit = defineEmits<{
  (e: "confirm", form: any): void; // eslint-disable-line @typescript-eslint/no-explicit-any
  (e: "undo"): void;
}>();

const { data: definitionData, error: definitionError } = await useQuery({
  query: graphql(/* GraphQL */ `
    query CustomFormLoader(
      $customFormDefinitionId: uuid!
      $ticketId: uuid!
      $ticketCustomFormEntryId: uuid!
      $includeTicket: Boolean!
      $includeFormEntry: Boolean!
    ) {
      # find definition
      custom_form_definitions_by_pk(id: $customFormDefinitionId) {
        id
        title
        component_name
        organization_name
        can_import_from_definition {
          id
          title
          component_name
        }
      }

      tickets_by_pk(id: $ticketId) @include(if: $includeTicket) {
        ...CustomFormEntries
        id
        ref
        work_order {
          customer {
            id
            abbreviation
            title
          }
        }

        product {
          title
        }

        work_site {
          id
          title
        }
      }

      ticket_custom_form_entries_by_pk(id: $ticketCustomFormEntryId)
        @include(if: $includeFormEntry) {
        id
        payload
        created_at
        updated_at
        author {
          ...UserLink
        }

        custom_form_definition {
          id
        }
      }
    }
  `),
  variables: computed(() => ({
    customFormDefinitionId,
    ticketId: ticketId ?? "",
    ticketCustomFormEntryId: ticketCustomFormEntryId || "",
    includeTicket: !!ticketId,
    includeFormEntry: !!ticketCustomFormEntryId
  }))
});

const definition = computed(
  () => definitionData.value?.custom_form_definitions_by_pk
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { log } = useLogger(
  `CustomFormLoader:${definition.value?.component_name}`
);

const ticket = computed(() => definitionData.value?.tickets_by_pk);
const { getCachedEntry, deleteCachedEntry } = useCustomForms(ticket, readonly);

const formEntry = computed(
  () => definitionData.value?.ticket_custom_form_entries_by_pk
);

const context = computed<CustomFormContext>(() => ({
  customer: ticket.value?.work_order.customer,
  workSite: ticket.value?.work_site,
  ticket: ticket.value,
  entry: definitionData.value?.ticket_custom_form_entries_by_pk
}));

const { data: importablePayloadData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query CustomFormLoader_ImportEntries(
      $thisDefinitionId: uuid!
      $thisFormEntriesWhere: ticket_custom_form_entries_bool_exp!
      $includeImportable: Boolean!
      $importableDefinitionId: uuid!
      $importEntriesWhere: ticket_custom_form_entries_bool_exp!
    ) {
      thisForm: custom_form_definitions_by_pk(id: $thisDefinitionId) {
        ticket_custom_form_entries(
          where: $thisFormEntriesWhere
          order_by: [{ updated_at: desc }]
          limit: 5
        ) {
          id
          updated_at
          author {
            full_name
          }
          ticket {
            ref
            product {
              title
            }
          }
        }
      }
      importForm: custom_form_definitions_by_pk(id: $importableDefinitionId)
        @include(if: $includeImportable) {
        ticket_custom_form_entries(
          where: $importEntriesWhere
          order_by: [{ updated_at: desc }]
          limit: 5
        ) {
          id
          updated_at
          author {
            full_name
          }
          ticket {
            ref
            product {
              title
            }
          }
        }
      }
    }
  `),
  variables: computed(() => ({
    thisDefinitionId: customFormDefinitionId,
    thisFormEntriesWhere: {
      _and: [
        ticketCustomFormEntryId
          ? { id: { _neq: ticketCustomFormEntryId } }
          : {},
        {
          ticket: {
            work_site_id: {
              _eq: context.value.workSite?.id || "unknown work_site_id"
            }
          }
        }
      ]
    },
    includeImportable: !!definition.value?.can_import_from_definition?.id,
    importableDefinitionId:
      definition.value?.can_import_from_definition?.id ||
      "unknown custom_form_definition_id",
    importEntriesWhere: {
      ticket: {
        work_site_id: {
          _eq: context.value.workSite?.id || "unknown work_site_id"
        }
      }
    }
  })),
  pause: computed(() => readonly)
});

const payload = computed({
  get() {
    const cache = getCachedEntry(customFormDefinitionId);

    // if (cache.value) {
    //   log("loading payload from local storage", cache.value);
    // } else {
    //   log("no payload in local storage");
    // }

    if (cache.value && formEntry.value) {
      if (
        isAfter(
          new Date(cache.value?.updated_at as string),
          new Date(formEntry.value.updated_at)
        )
      ) {
        // log("using locally stored payload", cache.value);
        return cache.value?.payload;
      }
      // log(
      //   "server data is newer, using server version",
      //   formEntry.value.payload
      // );
      return formEntry.value.payload;
    }

    if (!cache.value) {
      // log("no local storage, using server version", formEntry.value?.payload);
      return formEntry.value?.payload;
    }

    return cache.value?.payload;
  },
  set(value) {
    const cache = getCachedEntry(customFormDefinitionId);

    // log("payload about to be updated", value);
    cache.value = {
      updated_at: new Date().toISOString(),
      payload: value
    };
    // log("payload updated", cache.value?.payload);
  }
});

const { executeMutation: upsertEntry } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpsertCustomFormEntry(
      $form: ticket_custom_form_entries_insert_input!
    ) {
      insert_ticket_custom_form_entries_one(
        object: $form
        on_conflict: {
          constraint: ticket_custom_form_entries_pkey
          update_columns: [payload]
        }
      ) {
        id
        ticket {
          id
        }
      }
    }
  `)
);

const { executeMutation: upsertTokens } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpsertCustomFormTokens(
      $objects: [custom_form_domains_insert_input!]!
    ) {
      insert_custom_form_domains(
        objects: $objects
        on_conflict: {
          constraint: custom_form_domains_pkey
          update_columns: []
        }
      ) {
        affected_rows
        returning {
          token
        }
      }
    }
  `)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onConfirm = async (payload: any) => {
  const { data, error } = await upsertEntry({
    form: {
      id: ticketCustomFormEntryId || newId.value,
      ticket_id: ticketId || "",
      custom_form_definition_id: customFormDefinitionId,
      payload
    }
  });
  if (error) {
    createToast({
      title: `Unable to update ${definition?.value?.title}.`,
      message: error.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  } else if (data) {
    deleteCachedEntry(customFormDefinitionId);

    createToast({
      message: `${definition?.value?.title} updated.`,
      theme: "success"
    });

    if (!ticketCustomFormEntryId) {
      router.replace({
        name: "CustomFormDetail",
        query: {
          ticketId,
          ticketCustomFormEntryId:
            data.insert_ticket_custom_form_entries_one?.id,
          customFormDefinitionId
        }
      });
    }
    emit("confirm", payload);
  }
};

const onUpdateCustomDomain = (cfts: CustomFormToken[]) =>
  upsertTokens({
    objects: cfts.filter(x => x.domain && x.token && x.token.length > 2)
  });

const formComponent = shallowRef<Component | undefined>(
  defineAsyncComponent(() => {
    const componentName = readonly
      ? `${definition.value?.component_name}RO`
      : definition.value?.component_name;
    return import(
      `@/organizations/${definition.value?.organization_name}/components/${componentName}.vue`
    );
  })
);

watch(
  () => readonly,
  () => {
    formComponent.value = defineAsyncComponent(() => {
      const componentName = readonly
        ? `${definition.value?.component_name}RO`
        : definition.value?.component_name;
      return import(
        `@/organizations/${definition.value?.organization_name}/components/${componentName}.vue`
      );
    });
  }
);

const hasUnsavedChanges = computed(() => {
  const cache = getCachedEntry(customFormDefinitionId);
  return (
    (!formEntry.value && !!cache.value) ||
    (formEntry.value &&
      cache.value &&
      isAfter(
        new Date(cache.value?.updated_at as string),
        new Date(formEntry.value.updated_at)
      ))
  );
});

function handleDeleteCache() {
  const ok = confirm(
    "Are you sure you want to delete your unsaved changes? This action cannot be undone."
  );
  if (ok) {
    deleteCachedEntry(customFormDefinitionId);
  }
}

const importPayloadById = async (
  ticketCustomFormEntryId: string,
  isImport = false
) => {
  log("importing payload by id", ticketCustomFormEntryId);
  if (!urqlClient) {
    log("can't find urqlClient");
    return;
  }
  const { data: importableData, error } = await urqlClient.query(
    graphql(/* GraphQL */ `
      query CustomFormLoader_ImportPayload($ticketCustomFormEntryId: uuid!) {
        ticket_custom_form_entries_by_pk(id: $ticketCustomFormEntryId) {
          payload
        }
      }
    `),
    {
      ticketCustomFormEntryId
    }
  );
  if (error) {
    createToast({
      title: "Unable to import the form.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else if (importableData?.ticket_custom_form_entries_by_pk) {
    createToast({
      message: "Form imported.",
      theme: "success"
    });
    log(
      "got a payload back",
      importableData.ticket_custom_form_entries_by_pk.payload
    );
    if (isImport && formRef.value?.importPayload) {
      // tell whatever CustomForm is loaded to run it's import
      formRef.value.importPayload(
        importableData.ticket_custom_form_entries_by_pk?.payload
      );
    } else {
      payload.value = importableData.ticket_custom_form_entries_by_pk.payload;
    }
  }
};

const mostRecentEntries = computed(() =>
  (importablePayloadData.value?.thisForm?.ticket_custom_form_entries || []).map(
    e => ({
      id: e.id,
      label: `Load from #${e.ticket.ref} by ${e.author.full_name}`,
      onClick: () => importPayloadById(e.id, false)
    })
  )
);

const importableEntries = computed(() =>
  (
    importablePayloadData.value?.importForm?.ticket_custom_form_entries || []
  ).map(e => ({
    id: e.id,
    label: `Load from #${e.ticket.ref} by ${e.author.full_name}`,
    onClick: () => importPayloadById(e.id, true)
  }))
);
const assignRef = (el: CustomFormWithImport) => {
  // log("inside :ref bind", el);
  formRef.value = el;
};
</script>

<template>
  <SHNote v-if="definitionError" theme="danger">
    definition error:
    {{ definitionError.message }}
  </SHNote>
  <article v-else class="custom-form-loader vertical">
    <header v-if="!readonly" class="level-spread wrap loose">
      <SHNote v-if="hasUnsavedChanges" theme="warning">
        You have unsaved changes.
        <AppLink @click="handleDeleteCache">Delete changes.</AppLink>
      </SHNote>
      <div v-else class="spacer"></div>
      <div class="level tight noprint">
        <SHDropdown
          v-if="
            !readonly &&
            definition?.can_import_from_definition &&
            importableEntries.length
          "
          placement="bottom-end"
          strategy="fixed"
        >
          <template #default="{ toggle }">
            <SHButton
              color="info"
              :title="`Load ${definition?.can_import_from_definition?.title} from this work site`"
              @click="toggle"
            >
              Import previous
              {{ definition?.can_import_from_definition.title }}
              &hellip;
            </SHButton>
          </template>
          <template #popup="{ close }">
            <SHMenu :items="importableEntries" @click="close" />
          </template>
        </SHDropdown>

        <SHDropdown
          v-if="!readonly && definition && mostRecentEntries.length"
          placement="bottom-end"
          strategy="fixed"
        >
          <template #default="{ toggle }">
            <SHButton
              color="primary"
              :title="`Load other ${definition?.title} forms from this work site`"
              @click="toggle"
            >
              Previous {{ definition.title }} forms&hellip;
            </SHButton>
          </template>

          <template #popup="{ close }">
            <SHMenu :items="mostRecentEntries" @click="close" />
          </template>
        </SHDropdown>
      </div>
    </header>

    <section>
      <Component
        :is="formComponent"
        v-if="formComponent"
        :ref="assignRef"
        v-model:payload="payload"
        :definition="definition"
        :entry-id="ticketCustomFormEntryId ?? newId"
        :editable="hasUnsavedChanges"
        :ticket-id="ticketId"
        :context="context"
        @confirm="onConfirm"
        @update:custom-domain="onUpdateCustomDomain"
        @undo="$emit('undo')"
      />
      <SHNote v-else theme="danger">
        Unknown Custom Form {{ definition?.id ?? customFormDefinitionId }}
      </SHNote>
    </section>

    <footer v-if="!readonly">
      <SHField
        v-if="formEntry?.created_at"
        :label="`${definition?.title} last updated:`"
      >
        <div class="level tight">
          <span>
            <SHInlineDate :d="formEntry?.created_at" />
          </span>
          <span v-if="formEntry?.author">
            by
            <UserLink v-if="formEntry?.author" :user="formEntry.author" />
          </span>
        </div>
      </SHField>
    </footer>
  </article>
</template>
