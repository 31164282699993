<script setup lang="ts">
import CustomerContactForm from "@/components/CustomerContactForm.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type { Customer_Contacts_Set_Input } from "@/generated/graphql";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { createToast } = useToaster();

const { customerContactId } = defineProps<{
  customerContactId: string;
}>();

const { data, error, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomerContactEdit($id: uuid!) {
      customer_contacts_by_pk(id: $id) {
        ...CustomerContactForm
      }
    }
  `),
  variables: {
    id: customerContactId
  }
});

const { executeMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateCustomerContact(
      $id: uuid!
      $object: customer_contacts_set_input!
    ) {
      update_customer_contacts_by_pk(pk_columns: { id: $id }, _set: $object) {
        id
      }
    }
  `)
);

const customerContact = computed(() => data.value?.customer_contacts_by_pk);

const updates = ref<Customer_Contacts_Set_Input>();

async function onSubmit() {
  if (!updates.value) {
    return;
  }

  const { data, error } = await executeMutation({
    id: customerContactId,
    object: updates.value
  });

  if (error) {
    createToast({
      title: "Unable to update the contact.",
      message: error.message || "Unknown error.",
      theme: "danger",
      lifetimeMS: 5000
    });
  }

  if (data) {
    createToast({
      message: "Contact updated.",
      theme: "success"
    });
    router.replace({
      name: "CustomerContactDetail",
      params: { customerContactId: data.update_customer_contacts_by_pk?.id }
    });
  }
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="data && customerContact" class="vertical">
    <CustomerContactForm
      v-model:updates="updates"
      :customer-contact="customerContact"
    />

    <UndoConfirm
      :confirm-enabled="!!updates"
      @confirm="onSubmit"
      @undo="$router.back"
    />
  </article>
</template>
