import type { JSONContent } from "@tiptap/vue-3";
import { Invoice_Pivot_Options_Enum } from "@/generated/graphql";

export const dueDateOptions = [
  { label: "On Receipt", value: 0 },
  { label: "Net 30", value: 30 },
  { label: "Net 45", value: 45 },
  { label: "Net 60", value: 60 },
  { label: "Net 90", value: 90 }
] as const;

export const workLogSetOptions = [
  {
    label: "All Uninvoiced Work Logs",
    value: "all-uninvoiced"
  },
  {
    label: "All Uninvoiced Work Logs from Date Range",
    value: "all-uninvoiced-date-range"
  }
] as const;

export const pivotOptions = [
  {
    label: "By agent",
    description: "Combine logs for each agent into a single line item",
    value: Invoice_Pivot_Options_Enum.ByAgent
  },
  {
    label: "By ticket",
    description: "Combine logs for each ticket into a single line item",
    value: Invoice_Pivot_Options_Enum.ByTicket
  },
  {
    label: "By work order",
    description: "Combine logs for each work order into a single line item",
    value: Invoice_Pivot_Options_Enum.ByWorkOrder
  },
  {
    label: "By each log",
    description: "Each log is a separate line item",
    value: Invoice_Pivot_Options_Enum.None
  }
] as const;

export const expenseSetOptions = [
  {
    label: "All Uninvoiced Expenses",
    value: "all-uninvoiced"
  },
  {
    label: "All Uninvoiced Expenses from Date Range",
    value: "all-uninvoiced-date-range"
  }
] as const;

export const expensePivotOptions = [
  {
    label: "By agent",
    description: "Combine expenses for each agent into a single line item",
    value: "agent"
  },
  {
    label: "By category",
    description: "Combine expenses for each category into a single line item",
    value: "category"
  },
  {
    label: "By expense",
    description: "Each expense is a separate line item",
    value: null
  }
] as const;

export type InvoiceForm = {
  settings: {
    customerId: string | null;
    dateRange: [Date, Date] | null;
    includeWorkLogs: boolean;
    includeExpenseLogs: boolean;
    // includeTravelLogs: boolean;
    pivotOption: (typeof pivotOptions)[number]["value"];
  };
  invoice: {
    id: string;
    subject?: string | null;
    poNumber?: string | null;
    issueDate: Date;
    dueIn?: number;
    notesj?: JSONContent;
  };
  lineItems: {
    id: string;
    label: string;
    description?: string | null;
    details?: Record<string, string> | null;
    centsInvoiced?: number | null;
    centsCalculated: number;
  }[];
};
