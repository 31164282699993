<script setup lang="ts">
import SHBadge from "@/components/SHBadge.vue";
import UserLink from "@/components/UserLink.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  faBox,
  faChevronUp,
  faNoteSticky
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useTimeAgo } from "@vueuse/core";
import { ref } from "vue";
import AppLink from "./AppLink.vue";
import SHTextEditor from "./TextEditor/SHTextEditor.vue";

const fragment = graphql(/* GraphQL */ `
  fragment CatalogCard on catalogs {
    title
    updated_at
    notesj

    author {
      ...UserLink
    }

    products_aggregate {
      aggregate {
        count
      }
    }
  }
`);

const props = defineProps<{
  catalog: FragmentType<typeof fragment>;
}>();

const catalog = useFragment(fragment, props.catalog);
const updatedAgo = useTimeAgo(catalog.updated_at);
const showDetails = ref(false);
</script>

<template>
  <article class="catalog-card">
    <section class="vertical tight">
      <header class="level-spread">
        <span class="title">{{ catalog.title }}</span>
        <SHBadge>
          <div class="level tight number">
            <FontAwesomeIcon :icon="faBox" />
            <span>{{ catalog.products_aggregate.aggregate?.count }}</span>
          </div>
        </SHBadge>
      </header>

      <section class="vertical tight">
        <div class="level-spread">
          <div class="level tight">
            <span>Last Updated:</span>
            <span>{{ updatedAgo }}</span>
          </div>
          <div class="level tight">
            <span>Created By:</span>
            <span v-if="catalog.author">
              <UserLink :user="catalog.author" />
            </span>
            <span v-else>System User</span>
          </div>
        </div>
      </section>

      <section v-if="showDetails" class="level tight">
        <FontAwesomeIcon :icon="faNoteSticky" />
        <SHTextEditor v-if="catalog.notesj" :model-value="catalog.notesj" />
        <div v-else>No notes provided.</div>
      </section>
    </section>

    <footer class="level-center">
      <AppLink @click="showDetails = !showDetails">
        <FontAwesomeIcon
          class="chevron"
          :icon="faChevronUp"
          :rotation="showDetails ? undefined : 180"
          size="sm"
          style="transition: transform 150ms ease-in-out"
        />
      </AppLink>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.catalog-card {
  background: var(--color-surface-100);
  border-radius: var(--border-radius);
  padding: var(--padding);

  section {
    --link--color: var(--color-info);
    font-size: var(--font-size-sm);
  }

  .title {
    font-weight: 900;
  }

  .details {
    display: flex;
    grid-template-columns: 1em 1fr;

    svg {
      color: var(--color-secondary);
    }

    p {
      margin: 0;
      vertical-align: text-top;

      &.empty {
        color: var(--color-surface-300);
      }
    }
  }

  footer {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-extralight);
  }
}
</style>
