import { graphql, type FragmentType, useFragment } from "@/generated";
import type { CatalogChoiceFragment } from "@/generated/graphql";

const fragment = graphql(/* GraphQL */ `
  fragment CatalogChoice on catalogs {
    id
    title
  }
`);

export type CatalogChoice = CatalogChoiceFragment;

/**
 * Overload the useFragment function to provide a more specific type for the agent choice fragment.
 * The overloads are written this make typescript work better when agent is null or undefined.
 */
export function useCatalogChoice(obj: FragmentType<typeof fragment>) {
  return useFragment(fragment, obj);
}
