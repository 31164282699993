<script setup lang="ts">
import SHPill from "@/components/SHPill.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { getColor, getIcon } from "@/lib/ticketStatus";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

const fragment = graphql(/* GraphQL */ `
  fragment TicketBadge on tickets {
    ref
    ticket_status
  }
`);

const props = defineProps<{
  ticket: FragmentType<typeof fragment>;
}>();

const ticket = computed(() => useFragment(fragment, props.ticket));

const color = computed(() => getColor(ticket.value.ticket_status));
const icon = computed(() => getIcon(ticket.value.ticket_status));
</script>

<template>
  <SHPill class="number" :color="color">
    <template #left>
      <FontAwesomeIcon fixed-width :icon="icon" />
    </template>
    <template #right>
      {{ ticket.ref }}
    </template>
  </SHPill>
</template>
