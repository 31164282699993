import { useRole } from "@/composables/useRole";
import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";

import ModuleHeader from "@/components/ModuleHeader.vue";
import SHButton from "@/components/SHButton.vue";
import {
  faBox,
  faPen,
  faPlus,
  faToolbox
} from "@fortawesome/sharp-light-svg-icons";

import CatalogDetail from "@/views/CatalogDetail.vue";
import CatalogForm from "@/views/CatalogForm.vue";
import CatalogsView from "@/views/CatalogsView.vue";

import ProductCreate from "@/views/ProductCreate.vue";
import ProductEdit from "@/views/ProductEdit.vue";
import ProductsView from "@/views/ProductsView.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export const catalogRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "CatalogsView",
    components: {
      default: CatalogsView,
      header: h(
        ModuleHeader,
        { icon: faToolbox },
        {
          default: () => "Catalogs",
          nav: () =>
            useRole().can("catalogs:create")
              ? h(
                  SHButton,
                  {
                    to: { name: "CatalogCreate" },
                    color: "primary",
                    size: "sm"
                  },
                  () =>
                    h("div", [
                      h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                      h(FontAwesomeIcon, { icon: faToolbox, fixedWidth: true })
                    ])
                )
              : ""
        }
      )
    }
  },
  {
    path: "new",
    name: "CatalogCreate",
    components: {
      default: CatalogForm,
      header: h(ModuleHeader, () => "New Catalog")
    },
    props: true
  },
  {
    path: ":catalogId",
    children: [
      {
        path: "edit",
        name: "CatalogEdit",
        components: {
          default: CatalogForm,
          header: h(ModuleHeader, () => "Edit Catalog")
        },
        props: true
      },
      {
        path: "",
        name: "CatalogDetail",
        props: true,
        components: {
          default: CatalogDetail,
          header: h(
            ModuleHeader,
            { icon: faToolbox },
            {
              default: () => "Catalogs",
              nav: () =>
                useRole().can("catalogs:update")
                  ? h(
                      SHButton,
                      {
                        color: "primary",
                        to: { name: "CatalogEdit" },
                        size: "sm"
                      },
                      () =>
                        h(FontAwesomeIcon, { icon: faPen, fixedWidth: true })
                    )
                  : ""
            }
          )
        },
        redirect: { name: "ProductsView" },
        children: [
          {
            path: "products",
            children: [
              {
                path: "",
                name: "ProductsView",
                component: ProductsView,
                props: true
              }
            ]
          }
        ]
      },
      {
        path: "products",
        children: [
          {
            path: "new",
            name: "ProductCreate",
            components: {
              // @ts-expect-error - For some reason, TypeScript does not use the correct overload
              default: h(ProductCreate, { class: "xmargin" }),
              header: h(ModuleHeader, { icon: faBox }, () => "New Product")
            },
            props: true
          },
          {
            path: ":productId",
            name: "ProductDetail",
            redirect: { name: "ProductEdit" },
            children: [
              {
                path: "edit",
                name: "ProductEdit",
                components: {
                  // @ts-expect-error - For some reason, TypeScript does not use the correct overload
                  default: h(ProductEdit, { class: "xmargin" }),
                  header: h(ModuleHeader, { icon: faBox }, () => "Edit Product")
                },
                props: true
              }
            ]
          }
        ]
      }
    ]
  }
];
