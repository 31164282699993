<script setup lang="ts">
import { Payment_Statuses_Enum } from "@/generated/graphql";
import SHBadge from "@/components/SHBadge.vue";
import { computed } from "vue";

const props = defineProps<{
  status: Payment_Statuses_Enum | null | undefined;
}>();

const color = computed(() => {
  switch (props.status) {
    case Payment_Statuses_Enum.Paid:
      return "var(--color-success)";
    case Payment_Statuses_Enum.Partial:
      return "var(--color-info)";
    case Payment_Statuses_Enum.Pending:
      return "var(--color-primary)";
    case Payment_Statuses_Enum.Void:
    case Payment_Statuses_Enum.WrittenOff:
      return "var(--color-warning)";
    case Payment_Statuses_Enum.Inapplicable:
      return "var(--color-surface)";
    default:
      return "var(--color-grey)";
  }
});
</script>

<template>
  <SHBadge :color="color">{{ props.status }}</SHBadge>
</template>

<style lang="scss" scoped></style>
