import { graphql } from "@/generated";

import { Node } from "../TicketLinkNode.vue";

import defineMention from "./defineMention";

const query = graphql(/* GraphQL */ `
  query TicketSuggestion($searchText: String!) {
    items: tickets(
      where: { author: { fname: { _ilike: $searchText } } }
      limit: 5
    ) {
      id
      ref
      ...TicketLink
    }
  }
`);

export default defineMention({
  char: "!",
  query,
  getAttrs: i => ({ ticket: i }),
  getLabel: i => i.ref,
  node: Node
});
