<script setup lang="ts">
import type { SHToastOptions } from "@/composables/useToaster";
import {
  faCheckCircle,
  faExclamationCircle,
  faExplosion,
  faInfoCircle,
  faSpinner,
  faTimes
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, toValue } from "vue";

const { lifetimeMS, theme } = defineProps<
  SHToastOptions & {
    id: string;
  }
>();

const emit = defineEmits<{
  (e: "toast:remove", id: string): void;
}>();

const icon = computed(() =>
  theme === "loading"
    ? faSpinner
    : theme === "success"
      ? faCheckCircle
      : theme === "warning"
        ? faExclamationCircle
        : theme === "danger"
          ? faExplosion
          : faInfoCircle
);
const ttlString = computed(() => lifetimeMS + "ms");
const cssColor = computed(() => `var(--color-${theme})`);
const cssColorUp = computed(() => `var(--color-${theme}-up-200)`);
const cssColorAlpha = computed(() => `var(--color-${theme}-opacity-30)`);
</script>

<template>
  <article :class="{ [theme]: true }" class="sh-toast-card">
    <header class="level-spread loose">
      <span class="level tight">
        <FontAwesomeIcon
          v-if="icon"
          :spin="theme === 'loading'"
          :icon="icon"
          :color="cssColor"
        />
        {{ toValue(title) || theme.toLocaleUpperCase() }}
      </span>
      <a
        v-if="!notClosable"
        href="/"
        class="x"
        @click.prevent="emit('toast:remove', id)"
      >
        <FontAwesomeIcon :icon="faTimes" size="lg" />
      </a>
    </header>

    <main>{{ message }}</main>
    <component :is="component" />
    <!-- <footer v-if="requiresInteraction" class="level-spread loose">
      <SHButton
        v-if="requiresInteraction"
        :color="theme"
        @click="emit('toast:remove', id)"
      >
        OK
      </SHButton>
      <div class="timer number"></div>
    </footer> -->
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints" as bp;

@property --timer {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

article.sh-toast-card {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  border-radius: var(--border-radius);
  background: var(--color-surface-700);
  color: var(--color-surface-100);
  box-shadow:
    var(--box-shadow),
    0 0 1.5em 2px v-bind(cssColorAlpha);
  border-width: 1px;
  border-style: solid;

  min-height: 4em;
  min-width: 26em;

  border-color: v-bind(cssColor);

  header {
    padding: 0.5em;
    padding-bottom: 0.25em;
    font-size: 1em;
    font-weight: 500;

    a.x {
      display: block;
      color: v-bind(cssColor);
      border-radius: var(--border-radius);
      padding: 0.25em;
      &:hover {
        color: var(--color-surface-900);
        background: v-bind(cssColorUp);
      }
    }
  }

  main,
  footer {
    padding: 0.5em 1em;
    padding-top: 0;
    color: var(--color-surface-300);
    font-weight: 400;
  }

  @include bp.phone-only {
    box-shadow: none;
    border: 0;
    border-bottom: 4px solid v-bind(cssColor);
    border-radius: 0;
    min-width: unset;
  }

  @keyframes countdown {
    from {
      --timer: v-bind(lifetimeMS);
      font-weight: 900;
    }
    to {
      --timer: 0;
    }
  }

  .timer {
    font-size: 0.875em;
    font-weight: 300;

    // run the animation once for our lifetime
    animation: countdown v-bind(ttlString) ease-in;
    // this is the value being interpolated
    counter-reset: timer var(--timer);

    &::after {
      // draws the value of the counter named "timer"
      content: counter(timer);
    }
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.shake {
  animation-name: tilt-shaking;
  animation-duration: 0.15s;
  animation-iteration-count: infinite;
}
</style>
