<script setup lang="ts">
import { graphql } from "@/generated";
import type {
  Interfaces_Daily_Logs_Summary_Bool_Exp,
  Organization_Agents_Bool_Exp,
  Organization_Agents_Order_By
} from "@/generated/graphql";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

// import SHButton from "@/components/SHButton.vue";
// import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { useTimezone } from "@/composables/useTimezone";
// import SHTable from "@/components/SHTable.vue";
// import WorkOrderStatusBadge from "@/components/WorkOrderStatusBadge.vue";

const { orgTimezoneName, timezoneName } = useTimezone();

const {
  limit = 5,
  agentsWhere = {},
  activityWhere,
  orderBy
} = defineProps<{
  limit?: number;
  agentsWhere?: Organization_Agents_Bool_Exp;
  activityWhere?: Interfaces_Daily_Logs_Summary_Bool_Exp;
  orderBy?: Organization_Agents_Order_By[];
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query AgentsSummary(
      $agentsWhere: organization_agents_bool_exp!
      $activityWhere: interfaces_daily_logs_summary_bool_exp
      $limit: Int!
      $orderBy: [organization_agents_order_by!]
      $includeActivity: Boolean!
      $timezone: String!
    ) {
      organization_agents(
        where: $agentsWhere
        limit: $limit
        order_by: $orderBy
      ) {
        agent {
          id
          full_name
          ...UserLink
        }

        activity(
          args: { _timezone: $timezone }
          where: $activityWhere
          order_by: { day_started_at: asc }
        ) @include(if: $includeActivity) {
          day_started_at
          total_hours
        }
      }
      organization_agents_aggregate(where: $agentsWhere) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    agentsWhere,
    activityWhere,
    limit,
    orderBy,
    includeActivity: !!activityWhere,
    timezone: orgTimezoneName.value ?? timezoneName.value
  }))
});

const total = computed(
  () => data.value?.organization_agents_aggregate.aggregate?.count ?? 0
);
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else-if="data" class="agent-summary">
    <div :class="{ 'level-spread': total }">
      <h2 class="level tight"><slot /></h2>
      <slot v-if="total > limit" name="seeall" v-bind="{ total, limit }" />

      <slot v-else-if="total === 0" name="none">No agents found.</slot>
    </div>

    <div class="vertical tight">
      <div
        v-for="{ agent, activity } in data.organization_agents"
        :key="agent.id"
      >
        <slot name="perAgent" v-bind="{ agent, activity }" />
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.agent-summary {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>
