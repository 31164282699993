<script lang="ts">
import component from "./WorkOrderLinkNode.vue";

import { Node as TipTapNode } from "@tiptap/core";
import { VueNodeViewRenderer, mergeAttributes } from "@tiptap/vue-3";

export const Node = TipTapNode.create({
  name: "workOrderLink",
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      workOrder: {
        default: null,
        parseHTML: el => {
          const workOrder = el.getAttribute("data-work-order");

          return {
            workOrder: workOrder ? JSON.parse(workOrder) : null
          };
        },
        renderHTML: attributes => ({
          "data-work-order": JSON.stringify(attributes.workOrder)
        })
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-work-order]`
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  renderText({ node }) {
    return `#${node.attrs.workOrder.ref}`;
  },

  addNodeView() {
    return VueNodeViewRenderer(component);
  }
});
</script>

<script setup lang="ts">
import { NodeViewWrapper, type NodeViewProps } from "@tiptap/vue-3";
import WorkOrderLink from "../WorkOrder/WorkOrderLink.vue";

const props = defineProps<{
  node: NodeViewProps["node"];
}>();

const workOrder = props.node.attrs.workOrder;
</script>

<template>
  <NodeViewWrapper as="div" class="work-order-link-node">
    <WorkOrderLink
      v-if="workOrder"
      :work-order="workOrder"
      prefix="#"
      hide-icon
    />
  </NodeViewWrapper>
</template>

<style lang="scss">
.work-order-link-node {
  display: inline;
}
</style>
