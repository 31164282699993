<script setup lang="ts">
import ReportsTableRow from "@/components/ReportsTableRow.vue";
import SHTable from "@/components/SHTable.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import type { ReportsTableFragment } from "@/generated/graphql";
import { computed } from "vue";
import SHTableRowCount from "./SHTableRowCount.vue";

const fragment = graphql(/* GraphQL */ `
  fragment ReportsTable on report_uploads {
    id
    created_at
    updated_at
    report_type
    report_params
    content_size
    content_type
    url
    filename
    title
    author_id
    author {
      ...UserLink
    }
  }
`);

const props = defineProps<{
  data: FragmentType<typeof fragment>[];
}>();

defineEmits<{
  (e: "delete", value: ReportsTableFragment): void;
}>();

const reports = computed(() => useFragment(fragment, props.data));

const reportsCount = computed(() => props.data.length);
</script>

<template>
  <SHTable
    :rows="reports"
    :columns="['Date Generated', 'Title', 'Author', 'Download', 'Delete']"
    empty-message="No reports found."
  >
    <template #body="{ rows }">
      <ReportsTableRow
        v-for="row of rows"
        :key="row.id"
        :row="row"
        @delete="$event => $emit('delete', $event)"
      />
    </template>

    <template #footer>
      <SHTableRowCount :count="reportsCount" label="report" />
    </template>
  </SHTable>
</template>

<style lang="scss" scoped></style>
