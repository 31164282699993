<script async setup lang="ts">
import { useBillingUnits } from "@/composables/useBillingUnits";
import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation } from "@urql/vue";
import { v4 } from "uuid";
import { computed, nextTick, ref, watch } from "vue";
import ComboBox from "./ComboBox/ComboBox.vue";
import SHButton from "./SHButton.vue";
import SHDollarInput from "./SHDollarInput.vue";
import SHField from "./SHField.vue";
import type SHInput from "./SHInput.vue";
import UndoConfirm from "./UndoConfirm.vue";

const fragment = graphql(/* GraphQL */ `
  fragment ProductPriceEditor on product_prices {
    cents_per_unit
    valid_from
    billing_unit
  }
`);

const optionsFragment = graphql(/* GraphQL */ `
  fragment ProductPriceEditorOptions on query_root {
    billing_units {
      id: value
      label: label
    }
  }
`);

const { inline, ...props } = defineProps<{
  priceBookId: string;
  productId: string;
  productPrice?: FragmentType<typeof fragment>;
  options: FragmentType<typeof optionsFragment>;
  inline?: boolean;
}>();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "update:product-price"): void;
  (e: "update:is-editing", value: boolean): void;
}>();

const { billingUnits } = await useBillingUnits();

const productPrice = computed(() => useFragment(fragment, props.productPrice));

const form = ref<{
  centsPerUnit?: number | null;
  billingUnit: string | null;
}>({
  centsPerUnit: null,
  billingUnit: null
});

const isValid = computed(() => {
  return (
    (!!form.value.billingUnit || !!productPrice.value?.billing_unit) &&
    (!!form.value.centsPerUnit || !!productPrice.value?.cents_per_unit) &&
    !(!form.value.centsPerUnit && !form.value.billingUnit)
  );
});

const { executeMutation: insert } = useMutation(
  graphql(/* GraphQL */ `
    mutation ProductPriceEditorInsert($object: product_prices_insert_input!) {
      insert_product_prices_one(object: $object) {
        id
      }
    }
  `)
);

async function onConfirm() {
  const { data } = await insert({
    object: {
      id: v4(),
      product_id: props.productId,
      price_book_id: props.priceBookId,
      cents_per_unit:
        form.value.centsPerUnit ?? productPrice.value?.cents_per_unit,
      billing_unit: form.value.billingUnit ?? productPrice.value?.billing_unit,
      valid_from: "now()"
    }
  });

  if (data) {
    emit("update:product-price");
  }
}

function onCancel() {
  emit("cancel");
}

const inputEl = ref<InstanceType<typeof SHInput>>();

watch(inputEl, async () => {
  await nextTick();
  inputEl.value?.focus();
  inputEl.value?.select();
});

const billingUnit = computed({
  get: () =>
    billingUnits.value.find(unit =>
      form.value.billingUnit
        ? unit.value === form.value.billingUnit
        : unit.value === productPrice.value?.billing_unit
    ),
  set: val => {
    if (!val) return;
    form.value.billingUnit = val.value;
  }
});
</script>

<template>
  <article class="product-price-editor level" :class="{ inline }">
    <component :is="inline ? 'div' : SHField" label="Cost">
      <SHDollarInput
        ref="inputEl"
        type="number"
        :model-value="form.centsPerUnit ?? productPrice?.cents_per_unit"
        placeholder="0.00"
        @keydown="$event.key === 'Enter' && onConfirm()"
        @update:model-value="form.centsPerUnit = $event"
      />
    </component>
    <component :is="inline ? 'div' : SHField" label="Billing Unit">
      <ComboBox
        v-model="billingUnit"
        :options="billingUnits"
        mobile-header="Billing Unit"
      />
      <template #help>
        <p>
          The billing unit will be used to calculate the price of the product on
          invoices.
        </p>
      </template>
    </component>
    <div v-if="inline" class="level-end tight">
      <SHButton color="success" link square @click="onConfirm">
        <FontAwesomeIcon :icon="faCheckCircle" />
      </SHButton>
      <SHButton color="danger" link square @click="onCancel">
        <FontAwesomeIcon :icon="faTimesCircle" />
      </SHButton>
    </div>
    <UndoConfirm
      v-else
      class="level-spread"
      :confirm-enabled="isValid"
      @confirm="onConfirm"
      @undo="onCancel"
    />
  </article>
</template>

<style lang="scss" scoped>
.product-price-editor {
  display: flex;
  flex-direction: column;

  gap: 1em;

  &.inline {
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
  }
}
</style>
