<script setup lang="ts">
import { toRefs, watch } from "vue";
import SHButton from "./SHButton.vue";
import { faTimes } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke } from "@vueuse/core";

const props = defineProps<{
  show?: boolean;
  fullscreen?: boolean;
  noHeader?: boolean;
}>();

const { show } = toRefs(props);

const emit = defineEmits<{
  (e: "update:show", value: boolean): void;
  (e: "close"): void;
}>();

watch(show, val => (val ? preventScroll() : enableScroll()));
watch(show, val => !val && emit("close"));

function preventScroll() {
  document.body.style.overflow = "hidden";
}

function enableScroll() {
  document.body.style.overflow = "";
}

onKeyStroke("Escape", () => {
  if (show.value) {
    emit("close");
  }
});
</script>

<template>
  <Teleport to="body">
    <article v-show="show" class="sh-modal-wrapper" v-bind="$attrs">
      <slot v-if="!noHeader" name="header">
        <header>
          <section>
            <slot v-if="$slots.brand" name="brand"></slot>
          </section>
          <aside>
            <slot name="nav">
              <SHButton
                square
                link
                @click.stop="
                  () => {
                    emit('close');
                    emit('update:show', false);
                  }
                "
              >
                <FontAwesomeIcon :icon="faTimes" />
              </SHButton>
            </slot>
          </aside>
        </header>
      </slot>

      <main>
        <slot></slot>
      </main>

      <footer></footer>
    </article>
  </Teleport>
</template>

<style lang="scss" scoped>
.sh-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 1000; // Will need to investigate how to calculate this
  background-color: var(--color-surface-50);

  display: grid;
  grid-template-rows: min-content 1fr min-content;

  > header {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas: "brand aside";
    padding: var(--padding);

    > section {
      grid-area: brand;
    }
    > aside {
      grid-area: aside;
    }
  }

  > main {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}
</style>
