<script setup lang="ts">
import { graphql, useFragment, type FragmentType } from "@/generated";
import { Work_Order_Types_Enum } from "@/generated/graphql";
import { faDiagramNested } from "@fortawesome/sharp-light-svg-icons";
import AppLink from "@/components/AppLink.vue";
import SHBadge from "../SHBadge.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const workOrderFragment = graphql(/* GraphQL */ `
  fragment WorkOrderLink on work_orders {
    id
    ref
    work_order_type
  }
`);

const props = defineProps<{
  workOrder: FragmentType<typeof workOrderFragment>;
  hideIcon?: boolean;
}>();

const workOrder = useFragment(workOrderFragment, props.workOrder);
</script>

<template>
  <AppLink
    v-if="workOrder?.work_order_type === Work_Order_Types_Enum.Dedicated"
    :to="{ name: 'WorkOrderDetail', params: { workOrderId: workOrder.id } }"
    class="level"
  >
    <FontAwesomeIcon
      v-if="!hideIcon"
      color="var(--color-secondary)"
      :icon="faDiagramNested"
      fixed-width
    />
    <slot>{{ workOrder.ref }}</slot>
  </AppLink>
  <SHBadge v-else color="var(--color-surface-400)">CALLOFF</SHBadge>
</template>
