import { useLogger } from "@/logger";
import type { Auth0VueClient } from "@auth0/auth0-vue";
import * as Sentry from "@sentry/vue";
import { devtoolsExchange } from "@urql/devtools";
import { authExchange } from "@urql/exchange-auth";
import type { AnyVariables, Operation } from "@urql/vue";
import {
  Client,
  CombinedError,
  cacheExchange,
  fetchExchange,
  mapExchange,
  subscriptionExchange,
  type Exchange
} from "@urql/vue";
import { Kind, type OperationDefinitionNode } from "graphql";
import {
  createClient as createWebSocketClient,
  type SubscribePayload
} from "graphql-ws";
import { v4 } from "uuid";
import type { Ref } from "vue";

const { log } = useLogger("urql");

const operationsForLogging: Record<string, Sentry.Transaction> = {};

export function createClient(
  authExchange: Exchange,
  subscriptionExchange: Exchange
): Client {
  return new Client({
    url: window.config.GRAPHQL_URL || import.meta.env.VITE_GRAPHQL_URL,
    exchanges: [
      devtoolsExchange,
      cacheExchange,
      mapExchange({
        onOperation(operation) {
          if (["query", "mutation"].includes(operation.kind)) {
            const query = operation.query.definitions.at(
              0
            ) as OperationDefinitionNode;
            const queryName = query.name?.value ?? "unknown";

            operation.context.id = v4();

            const transaction = Sentry.startTransaction({
              name: queryName,
              op: `gql:${operation.kind}`,
              data: {
                query: operation.query.loc?.source.body,
                variables: JSON.stringify(operation.variables, null, 2)
              }
            });

            log(operation.kind, queryName, { variables: operation.variables });
            operationsForLogging[operation.context.id] = transaction;
          }
        },
        onResult(result) {
          if (["query", "mutation"].includes(result.operation.kind)) {
            const transaction =
              operationsForLogging[result.operation.context.id];
            transaction?.finish();
          }
        },
        onError(error, operation) {
          if (error instanceof CombinedError) {
            if (error.networkError) {
              log("network error", error.message, error.networkError);
            } else if (error.graphQLErrors) {
              log("combined error", error.message, error.graphQLErrors);
            } else {
              log("unknown error", { error, operation });
            }
          }

          Sentry.captureException(error);
          const transaction = operationsForLogging[operation.context.id];
          transaction?.finish();
        }
      }),
      authExchange,
      fetchExchange,
      subscriptionExchange
    ]
  });
}

export const createAuthExchange = (
  client: Auth0VueClient,
  role: Ref<string> | null
): Exchange =>
  authExchange(async utils => {
    let accessToken = "";

    return {
      didAuthError: error => {
        return error.graphQLErrors[0]?.extensions?.code === "invalid-jwt";
      },
      willAuthError(operation) {
        return !isPublicOperation(operation) && !accessToken;
      },
      async refreshAuth() {
        try {
          accessToken = await client.getAccessTokenSilently();
        } catch (error) {
          await client.logout({
            logoutParams: {
              returnTo: window.location.origin
            }
          });
        }
      },
      addAuthToOperation(operation) {
        if (!accessToken) return operation;
        if (isPublicOperation(operation)) return operation;

        return utils.appendHeaders(operation, {
          Authorization: `Bearer ${accessToken}`,
          ...(role?.value ? { "X-Hasura-Role": role.value } : {})
        });
      }
    };
  });

export function createSubscriptionExchange(
  authClient: Auth0VueClient,
  role: Ref<string> | null
) {
  const url =
    window.config.GRAPHQL_WS_ENDPOINT ||
    import.meta.env.VITE_GRAPHQL_WS_ENDPOINT;

  let accessToken = "";
  const wsClient = createWebSocketClient({
    url,
    connectionParams: async () => {
      accessToken = await authClient.getAccessTokenSilently();
      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-Hasura-Role": role?.value ?? "public"
        }
      };
    },
    on: {
      connected() {
        log("graphql-ws connected...");
      },
      closed() {
        log("graphql-ws closed...");
      }
    },
    retryAttempts: 5
  });

  return subscriptionExchange({
    forwardSubscription(operation) {
      return {
        subscribe: sink => {
          const dispose = wsClient.subscribe(
            operation as SubscribePayload,
            sink
          );
          return {
            unsubscribe: dispose
          };
        }
      };
    }
  });
}

function isPublicOperation(op: Operation<unknown, AnyVariables>): boolean {
  const _publicOperations = ["OrganizationProvider", "AuthenticationProvider"];

  if (op.kind === "query") {
    const defs = op.query.definitions.filter(
      d => d.kind === Kind.OPERATION_DEFINITION && d.name
    );
    const def = defs.shift() as OperationDefinitionNode | undefined;
    if (def) {
      const name = def.name?.value || "";
      const isPublic = _publicOperations.includes(name);
      // log("op:", name, "is public:", isPublic);
      return isPublic;
    }
  }
  return false;
}
