<script setup lang="ts">
import { computed, ref } from "vue";
import { endOfDay, startOfDay } from "date-fns";
import SHButton from "@/components/SHButton.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import type { DateRangePreset } from "@/lib/datetime";
import type { VueDatePickerProps } from "@vuepic/vue-datepicker";

const props = defineProps<
  Pick<VueDatePickerProps, "position"> & {
    modelValue: [Date, Date] | null | undefined;
    presets?: DateRangePreset[] | null;
  }
>();

const emit = defineEmits<{
  (e: "update:modelValue", value: [Date, Date] | undefined | null): void;
}>();

const modelValue = computed({
  get: () => props.modelValue,
  set: value => {
    if (value?.at(0) && value?.at(1)) {
      // move to midnight boundaries
      emit("update:modelValue", [startOfDay(value[0]), endOfDay(value[1])]);
    } else {
      emit("update:modelValue", null);
    }
  }
});

const vdp = ref();

const close = () => {
  if (vdp.value) {
    vdp.value.closeMenu();
  }
};

const setRange = (start: string, end: string) => {
  emit("update:modelValue", [new Date(start), new Date(end)]);
};
</script>

<template>
  <VueDatePicker
    ref="vdp"
    v-bind="($attrs, $props)"
    v-model="modelValue"
    :enable-time-picker="false"
    :year-range="[2024, 2100]"
    range
    auto-apply
    multi-calendars="2"
    six-weeks="fair"
    class="vdp"
  >
    <template v-if="presets" #left-sidebar>
      <div class="vertical tight">
        <div v-if="presets" class="vertical tight">
          <SHButton
            v-for="preset in presets"
            :key="preset.id"
            color="secondary"
            size="sm"
            style="padding: 0.25em"
            @click="
              modelValue = preset.dates;
              close();
            "
          >
            {{ preset.title_short || preset.title }}
          </SHButton>
        </div>

        <slot name="buttons" v-bind="{ setRange }"></slot>
      </div>
    </template>
  </VueDatePicker>
</template>

<style lang="scss">
.dp__sidebar_left {
  max-height: 20em;
  overflow-y: scroll;
  padding: 10px 5px;
  width: 100%;
}
</style>
