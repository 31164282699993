import { graphql, type FragmentType, useFragment } from "@/generated";
import type { AgentChoiceFragment } from "@/generated/graphql";

const fragment = graphql(/* GraphQL */ `
  fragment AgentChoice on users {
    id
    label: full_name
    full_name
    organization_agents {
      user_roles {
        user_role {
          ...UserRole
          short_name
          auth0_id
          title
        }
      }
    }
  }
`);

export type AgentChoice = AgentChoiceFragment;

/**
 * Overload the useFragment function to provide a more specific type for the agent choice fragment.
 * The overloads are written this make typescript work better when agent is null or undefined.
 */
export function useAgentChoice(agent: null): null;
export function useAgentChoice(agent: undefined): undefined;
export function useAgentChoice(
  agent: FragmentType<typeof fragment>
): AgentChoice;
export function useAgentChoice(
  agent: FragmentType<typeof fragment> | null | undefined
): AgentChoice | null | undefined;
export function useAgentChoice(
  agent: FragmentType<typeof fragment> | null | undefined
) {
  return useFragment(fragment, agent);
}
