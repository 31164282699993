<script setup lang="ts">
import { computed } from "vue";
import { colorFromSeed } from "@/lib/colors";
import { getInitials } from "@/lib/strings";
import { injectStrict } from "@/lib/helpers";
import { DarkModeKey } from "@/providerKeys";

// it's easier to center the text on odd values
const px_sizes = {
  small: 23,
  medium: 33,
  large: 59,
  huge: 127
};

const { initialsColor = "black", size = "medium" } = defineProps<{
  initialsColor?: string;
  seedString: string;
  size?: keyof typeof px_sizes;
  noColor?: boolean;
}>();
const isDark = injectStrict(DarkModeKey);
const avatarDiameter = computed(() => px_sizes[size]);
const avatarInitials = computed(() => initialsColor);
const dynamicTextStyle = computed(() => ({
  color: `${avatarInitials.value}`,
  "font-size": `${avatarDiameter.value * 0.7}px`,
  "font-weight": `bold`
}));
</script>

<template>
  <svg
    v-if="seedString"
    :width="avatarDiameter"
    :height="avatarDiameter"
    class="avatar"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
  >
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      :fill="
        noColor ? 'var(--color-surface-200)' : colorFromSeed(seedString, isDark)
      "
    />
    <text
      x="50%"
      y="49%"
      dominant-baseline="central"
      text-anchor="middle"
      :style="dynamicTextStyle"
    >
      {{ getInitials(seedString) }}
    </text>
  </svg>
</template>

<style lang="scss" scoped>
svg.avatar {
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  fill-rule: evenodd;
  clip-rule: evenodd;
  width: max-content;
  text {
    font-family: var(--font-family-monospace);
    fill: var(--color-surface-0);
  }
}
svg.avatar.stacked {
  text {
    font-size: 1em !important;
    font-weight: 500 !important;
  }
  margin-right: -15px;
}
</style>
