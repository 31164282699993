<script async setup lang="ts">
import QueryFilter from "@/components/QueryFilter.vue";
import ReportsTable from "@/components/ReportsTable.vue";
import SHNote from "@/components/SHNote.vue";
import SHPill from "@/components/SHPill.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { useFacets } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { useToaster } from "@/composables/useToaster";
import { formatBytes } from "@/formatters";
import { graphql } from "@/generated";
import {
  Order_By,
  type Report_Uploads_Bool_Exp,
  type Report_Uploads_Order_By,
  type ReportsTableFragment
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { DATE_FILTER_PRESETS, type DateRangePreset } from "@/lib/datetime";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";

const { createToast } = useToaster();
const currentUser = injectStrict(CurrentUserKey);
const filterViewMode = ref<CollectionView>(CollectionView.Table);

const { data: optionsData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query ReportsViewOptions($currentUserId: String!) {
      organization_agents(
        where: { agent_id: { _neq: $currentUserId } }
        order_by: { agent: { full_name: asc } }
      ) {
        agent {
          full_name
          id
        }
      }
    }
  `),
  variables: { currentUserId: currentUser.value.id }
});

const { executeMutation: deleteReportMutation } = useMutation(
  graphql(/* GraphQL */ `
    mutation DeleteReportUpload($id: uuid!) {
      delete_report_uploads_by_pk(id: $id) {
        id
      }
    }
  `)
);

const deleteReport = async (report: ReportsTableFragment) => {
  const { data, error } = await deleteReportMutation({
    id: report.id
  });
  if (error) {
    createToast({
      title: "Unable to delete the report.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
  } else if (data) {
    createToast({
      message: "Report deleted.",
      theme: "success"
    });
  }
};

const agents = computed(() => optionsData.value?.organization_agents ?? []);
const authorFilters: Facet<Report_Uploads_Bool_Exp> = {
  modelName: "authors",
  hideOnMobile: true,
  label: "Author",
  defaultValue: "all",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...agents.value.map(oa => ({
      id: oa.agent.id,
      title: `${oa.agent.full_name} `,
      predicate: {
        author: { id: { _eq: oa.agent.id } }
      }
    }))
  ]
};

const buildDatePredicates = (dates: [Date, Date]) => {
  return {
    _or: [
      { created_at: { _gte: dates[0], _lte: dates[1] } },
      { updated_at: { _gte: dates[0], _lte: dates[1] } }
    ]
  };
};

const dateRangeFilters: Facet<Report_Uploads_Bool_Exp> = {
  defaultValue: "all",
  hideOnMobile: true,
  label: "Dates",
  modelName: "dateRange",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...DATE_FILTER_PRESETS.map((preset: DateRangePreset) => ({
      id: preset.id,
      title: preset.title,
      predicate: buildDatePredicates(preset.dates)
    }))
  ]
};

const orderByFilters: OrderSet<Report_Uploads_Order_By> = {
  defaultValue: "created_at",
  options: [
    {
      id: "created_at",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "author",
      title: "Author",
      asc: [{ author: { fname: Order_By.Asc } }],
      desc: [{ author: { fname: Order_By.Desc } }]
    }
  ]
};

const {
  facetModels,
  filterWhereClause,
  resetFacets,
  sortOrder,
  updateFacet,
  updateSortDirection,
  updateSortType
} = useFacets([authorFilters, dateRangeFilters], orderByFilters);

const { data, fetching, error } = useQuery({
  query: graphql(`
    query ReportsView(
      $where: report_uploads_bool_exp!
      $order_by: [report_uploads_order_by!]
      $limit: Int!
      $offset: Int!
    ) {
      report_uploads(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        id
        ...ReportsTable
      }

      report_uploads_aggregate(where: $where) {
        aggregate {
          count
          sum {
            content_size
          }
        }
      }

      unfiltered_aggregate: report_uploads_aggregate(where: $where) {
        aggregate {
          count
          sum {
            content_size
          }
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [...(filterWhereClause.value || [])]
    },
    order_by: sortOrder.value,
    limit: 100,
    offset: 0
  })),
  context: { additionalTypenames: ["report_uploads"] },
  requestPolicy: "network-only"
});

const count = computed(
  () => data.value?.unfiltered_aggregate.aggregate?.count || 0
);

const size = computed(
  () => data.value?.unfiltered_aggregate.aggregate?.sum?.content_size
);
</script>

<template>
  <article class="reports-view vertical">
    <header class="level-spread">
      <h1>Reports</h1>
      <div class="level loose">
        <SHPill
          color="var(--color-surface-400)"
          left="Total Files"
          :right="`${count ?? 0}`"
        />
        <SHPill
          color="var(--color-surface-400)"
          left="Total Size"
          :right="formatBytes(size || 0)"
        />
      </div>
    </header>

    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[authorFilters, dateRangeFilters]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="count"
      clear-all-filters
      no-calendar-view
      no-card-view
      no-table-view
      placeholder="Search by Title..."
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    ></QueryFilter>

    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">{{ error.message }}</SHNote>

    <ReportsTable
      v-if="data?.report_uploads"
      :data="data.report_uploads"
      @delete="deleteReport"
    />
  </article>
</template>
