<script setup lang="ts">
import { computed, provide } from "vue";
import { useBreakpoints, useLocalStorage } from "@vueuse/core";
import { IsMobileKey, BreakPointsKey } from "@/providerKeys";
import SHSpinner from "./SHSpinner.vue";
import SHNote from "./SHNote.vue";

const { ENV = import.meta.env.VITE_ENV } = window.config;

const breakpoints = useBreakpoints({
  mobile: 0,
  mobileLandscape: 431,
  tablet: 600,
  laptop: 768,
  desktop: 992
});

const isMobile = breakpoints.smaller("mobileLandscape");
provide(IsMobileKey, isMobile);
provide(BreakPointsKey, breakpoints.current());

const isCollapsed = useLocalStorage("sidebarCollapsed", true);
const toggle = () => (isCollapsed.value = !isCollapsed.value);

const bannerMessage = computed(() => {
  if (ENV === "staging") {
    if (isMobile.value) {
      return "Staging: Data used for testing only";
    } else {
      return "You are on the staging version of Spearhead. Data input is used for test purposes only";
    }
  }

  return "";
});
</script>

<template>
  <article class="site-layout" :class="{ isCollapsed, isMobile }">
    <header v-if="bannerMessage" class="banner">
      <SHNote theme="warning" class="number">
        {{ bannerMessage }}
      </SHNote>
    </header>
    <main>
      <section class="content">
        <Suspense>
          <div>
            <slot
              name="default"
              :is-mobile="isMobile"
              :breakpoints="breakpoints"
            ></slot>
          </div>
          <template #fallback>
            <SHSpinner />
          </template>
        </Suspense>
        <footer class="site-footer"><slot name="footer"></slot></footer>
      </section>
      <aside v-if="!isMobile">
        <slot name="sidebar" :toggle="toggle" :is-collapsed="isCollapsed" />
      </aside>
    </main>
    <slot v-if="isMobile" name="bottomNav" />
  </article>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints" as bp;

$sidebarWidthExpanded: 13em;
$sidebarWidthCollapsed: 4em;

.site-layout {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;

  .banner {
    :deep(.sh-note) {
      border-radius: 0;
      border: none;
      background-color: var(--color-warning);
      color: var(--color-surface-900);
      font-size: 0.9em;
      font-weight: 500;
    }
  }

  @media print {
    main {
      grid-template-columns: 0px 1fr !important;

      > aside {
        display: none;
      }
    }
  }

  &.isCollapsed {
    main {
      grid-template-columns: $sidebarWidthCollapsed 1fr;
      aside {
        //overflow-x: hidden;
        width: $sidebarWidthCollapsed;
      }
    }
  }
  &.isMobile {
    main {
      display: flex; // drop the grid
      flex-direction: column;
      justify-content: flex-start;
      z-index: 0; // this makes sure that the main content is below the bottom navigation which is set to z-index: 10;
      .content {
        padding-bottom: 8vh;
      }
    }
  }

  main {
    flex-grow: 1;
    display: grid;
    transition: all 0.25s ease-in-out;
    grid-template-columns: $sidebarWidthExpanded 1fr;

    aside {
      grid-column: 1;
      grid-row: 1;
      box-sizing: border-box;
      width: $sidebarWidthExpanded;
      transition: all 0.25s ease-in-out;
    }
    .content {
      grid-column: 2;
      margin: 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      flex-grow: 1;
      scrollbar-gutter: stable;

      footer {
        width: 100%;
        padding: 2em;
        font-size: 0.75em;
        color: var(--color-surface-600);
      }

      @include bp.up-to-phone-landscape {
        padding-bottom: 6em; // enough for bottom nav
      }

      @include bp.desktop {
        max-width: 1450px;
      }
    }
  }
}
</style>
